import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import Logo from "../../../Components/Common/logo";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";

const SalaryTrend = () => {
    const [vessel,setVessel] = useState([]);
    const [vesselId,setVesselId] = useState(0);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(),1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth(),1));
    const [reportData,setReportData] = useState([]);
    const [headerData,setHeaderData] = useState({});
    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessel(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    useEffect(() => {
        getVesselData();
    },[])


    const getData = (vesselId = 0, dateFrom = '',dateTo = '') => {
        axiosClient.post('/company/salary-trend',{
            vessel_id: vesselId,
            date_from: dateFrom,
            date_to: dateTo
        })
            .then(({data}) => {
                setReportData(data?.data)
                setHeaderData(data?.header)

            }).catch(() => {

        })
    }


    useEffect(() => {
        let dateFromData = dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1);
        let dateToData = dateTo.getFullYear() + '-' + (dateTo.getMonth() + 1);
        getData(vesselId,dateFromData,dateToData);
    },[vesselId,dateFrom,dateTo])
    return (
       <>
           <Breadcrumb title='Salary Trend'      />

           <section className="content">
               <div className="container-fluid">
                   <div className="row">
                       <div className="col-12">
                           <div className="card">
                               <div className="card-header">
                                   <div className='row'>
                                       <div className="col-12 col-md-9">
                                           <div className="row">
                                               <div className='col-12 col-md-4'>
                                                   <div className="form-group">
                                                       <select style={{maxWidth: '100%'}}
                                                               className="form-control"
                                                               value={vesselId}
                                                               onChange={(e) => setVesselId(e.target.value)}
                                                       >
                                                           <option  value="0" selected>All Vessel</option>
                                                           {vessel.map((data,index) => (
                                                               <option key={index}    value={data?.vessel_id}>{data?.vessel_name}</option>
                                                           ))}

                                                       </select>
                                                   </div>
                                               </div>
                                               <div className='col-12 col-md-4'>
                                                   <DatePicker
                                                       selected={dateFrom}
                                                       dateFormat='MM-yyyy'
                                                       className="form-control"
                                                       onChange={(date) => setDateFrom(date)}
                                                       showMonthYearPicker
                                                       showFullMonthYearPicker
                                                   />
                                               </div>
                                               <div className='col-12 col-md-4'>
                                                   <DatePicker
                                                       selected={dateTo}
                                                       dateFormat='MM-yyyy'
                                                       className="form-control"
                                                       onChange={(date) => setDateTo(date)}
                                                       showMonthYearPicker
                                                       showFullMonthYearPicker
                                                   />
                                               </div>
                                           </div>
                                       </div>
                                       <div className="col-12 col-md-1 ">
                                           <button className='btn btn-block  btn-success'>Print</button>
                                       </div>

                                   </div>
                               </div>

                               <div className="card-body ">

                                   <div className="justify-content-center d-flex">
                                       <div className="d-flex">
                                           <div className="logo">
                                               <Logo img={headerData?.logo} />
                                           </div>
                                           <div className="header text">
                                               <h5 className="text-center">{headerData?.company_name}</h5>
                                               <h6 className="text-center">Salary Trend</h6>
                                               <h6 className="text-center">{headerData?.report_date}</h6>
                                               <p className="text-center">{headerData?.vessel_name}</p>
                                           </div>
                                       </div>

                                   </div>

                                   <table id="" className="table table-bordered table-hover">
                                       <thead>
                                       <tr>
                                           <th>SL</th>
                                           <th>Month </th>
                                           <th>Total Salary</th>
                                       </tr>
                                       </thead>
                                       <tbody>


                                       {
                                           reportData?.length > 0  &&   reportData?.map((data,index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{data?.month_name}</td>
                                                        <td>{data?.total_salary}</td>
                                                    </tr>
                                           ))
                                       }

                                       </tbody>

                                   </table>

                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
       </>
    );
};

export default SalaryTrend;