import './styles/App.css';
import * as COMPANYROUTE from './constants/companyRoutes'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CompanyDashboard from "./pages/Company/Dashboard";
import Landing from "./Components/Landing";
import Login from "./pages/Auth/Login";
import CompanyLayout from "./Components/Company/Layout/Company/CompanyLayout";
import SignOnOffReport from "./pages/Company/Report/signOnOffReport";
import NoOfContract from "./pages/Company/Report/NoOfContract";
import SignOffReport from "./pages/Company/Report/signOffReport";
import SupplyDemandReport from "./pages/Company/Report/supplyDemandReport";
import SeafarerContractDuration from "./pages/Company/Report/seafarerContractDuration";
import SeafarerTurnOver from "./pages/Company/Report/seafarerTurnOver";
import SeafarerNewPromotion from "./pages/Company/Report/seafarerNewPromotion";
import SeafarerEmergencySignOff from "./pages/Company/Report/seafarerEmergencySignOff";
import SeafarerContractExtension from "./pages/Company/Report/seafarerContractExtension";
import SeafarerIllness from "./pages/Company/Report/seafarerIllness";
import Seafarer from "./pages/Company/Seafarer/Seafarer";
import AddNewSeafarer from "./pages/Company/Seafarer/AddNewSeafarer";
import AddToVessel from "./pages/Company/Seafarer/AddToVessel";
import SeafarerEditId from "./pages/Company/Seafarer/SeafarerEditId";
import PendingDocument from "./pages/Company/Document/PendingDocument";
import CompanyUserList from "./pages/Company/CompanyUser/CompanyUserList";
import CompanyUserLoginHistory from "./pages/Company/CompanyUser/CompanyUserLoginHistory";
import CreateCompanyUser from "./pages/Company/CompanyUser/CreateCompanyUser";
import JobList from "./pages/Company/Job";
import BudgetHead from "./pages/Company/Account/BudgetHead/BudgetHead";
import FleetBudget from "./pages/Company/Account/FleetBudget";
import PortageBill from "./pages/Company/Account/PortageBill";
import BasicWages from "./pages/Company/Account/BasicWages";
import SalaryPolicy from "./pages/Company/Account/SalaryPolicy";
import CreateSalaryPolicy from "./pages/Company/Account/SalaryPolicy/create";
import EarningDeductionItem from "./pages/Company/Account/EarningDeductionItem";
import CreateParticularItem from "./pages/Company/Account/EarningDeductionItem/create";
import EarningDeductionEntry from "./pages/Company/Account/EarningDeductionEntry";
import EarningDeductionCreate from "./pages/Company/Account/EarningDeductionEntry/create";
import HiredCrews from "./pages/Company/HiredCrews";
import VesselList from "./pages/Company/Vessel";
import CreateVessel from "./pages/Company/Vessel/create";
import SeafarerTrash from "./pages/Company/Seafarer/SeafarerTrash";
import SalaryTrend from "./pages/Company/Analytics/SalaryTrend";
import CompanyModule from "./pages/Company/CompanyModule";
import CompanyModuleAdd from "./pages/Company/CompanyModule/create";
import CompanyModuleEdit from "./pages/Company/CompanyModule/edit";
import Profile from "./pages/Company/Profile/Profile";
import PersonalInfo from "./pages/Company/Profile/PersonalInfo";
import Passport from "./pages/Company/Profile/Document_visa/Passport";
import Visa from "./pages/Company/Profile/Document_visa/Visa";
import CreateEducation from "./pages/Company/Profile/education/CreateEducation";
import CreateCertification from "./pages/Company/Profile/certification/CreateCertification";
import CreateExperience from "./pages/Company/Profile/experience/CreateExperience";
import CreateFamily from "./pages/Company/Profile/family/CreateFamily";
import EditVessel from "./pages/Company/Vessel/edit";
import CrewList from "./pages/Company/Vessel/CrewList";
import CrewPlanner from "./pages/Company/Vessel/CrewPlanner/CrewPlanner";
import VesselBudget from "./pages/Company/Vessel/budget/VesselBudget";
import Agreement from "./pages/Company/Seafarer/Agreement";
import AgreementExtension from "./pages/Company/Seafarer/AgreementExtension";
import Checklist from "./pages/Company/Seafarer/Checklist";
import UploadPerformance from "./pages/Company/Profile/UploadPerformance";
import UpdateReadiness from "./pages/Company/Profile/UpdateReadiness";
import PassportEdit from "./pages/Company/Profile/Document_visa/PassportEdit";
import VisaEdit from "./pages/Company/Profile/Document_visa/VisaEdit";
import EditEducation from "./pages/Company/Profile/education/EditEducation";
import EditCertification from "./pages/Company/Profile/certification/EditCertification";
import EditExperience from "./pages/Company/Profile/experience/EditExperience";
import EditFamily from "./pages/Company/Profile/family/EditFamily";
import MedicalInfo from "./pages/Company/Profile/Others/MedicalInfo";
import TrainingInfo from "./pages/Company/Profile/Others/TrainingInfo";
import BankInfo from "./pages/Company/Profile/Others/BankInfo";
import OthersInfo from "./pages/Company/Profile/Others/OthersInfo";
import EditToVessel from "./pages/Company/Seafarer/EditToVessel";
import SeafarerLeftReport from "./pages/Company/Report/seafarerLeftReport";
import SeafarerIndividualContractDuration from "./pages/Company/Report/seafarerIndividualContractDuration";
import Document from "./pages/Company/Document/Document";
import AddDocument from "./pages/Company/Document/AddDocument";
import EditDocument from "./pages/Company/Document/EditDocument";
import AddNewJob from "./pages/Company/Job/AddNewJob";
import EditJob from "./pages/Company/Job/EditJob";
import AppliedJob from "./pages/Company/Job/AppliedJob";
import ShortlistedSeafarer from "./pages/Company/Job/ShortlistedSeafarer";
import AddBudgetHead from "./pages/Company/Account/BudgetHead/AddBudgetHead";
import EditBudgetHead from "./pages/Company/Account/BudgetHead/EditBudgetHead";
import IndividualVesselBudget from "./pages/Company/Account/IndividualVesselBudget";
import EditParticularItem from "./pages/Company/Account/EarningDeductionItem/edit";
import EarningDeductionEdit from "./pages/Company/Account/EarningDeductionEntry/edit";
import TopFourPortageBill from "./pages/Company/Account/TopFourPortageBill";
import TopFourOfficerSalary from "./pages/Company/Account/TopFourOfficerSalary";
import CrewPlannerReplace from "./pages/Company/Vessel/CrewPlanner/CrewPlannerReplace";
import CrewPlannerReplaceExternalPool from "./pages/Company/Vessel/CrewPlanner/CrewPlannerReplaceExternalPool";
import CrewPlannerReplaceTanker from "./pages/Company/Vessel/CrewPlanner/CrewPlannerReplaceTanker";
import CrewPlannerReplaceTankerExternalPool
    from "./pages/Company/Vessel/CrewPlanner/CrewPlannerReplaceTankerExternalPool";
import VesselBudgetShow from "./pages/Company/Vessel/budget/VesselBudgetShow";
import VesselBudgetEdit from "./pages/Company/Vessel/budget/VesselBudgetEdit";
import RankGroup from "./pages/Company/rankGroup";
import RankGroupAdd from "./pages/Company/rankGroup/create";
import RankGroupEdit from "./pages/Company/rankGroup/edit";
import EditCompanyUser from "./pages/Company/CompanyUser/EditCompanyUser";
import EditCompanyUserPassword from "./pages/Company/CompanyUser/EditCompanyUserPassword";
import CompanyPermission from "./pages/Company/CompanyPermission";
import CompanyPermissionAdd from "./pages/Company/CompanyPermission/create";
import CompanyPermissionEdit from "./pages/Company/CompanyPermission/edit";
import CompanyPermissionShow from "./pages/Company/CompanyPermission/show";
import RankPermission from "./pages/Company/rankPermission";
import RankPermissionAdd from "./pages/Company/rankPermission/create";
import RankPermissionEdit from "./pages/Company/rankPermission/edit";
import * as UserRoute from "./constants/UserRoute";
import SeafarerLayout from "./Components/Company/Layout/Seafarer/SeafarerLayout";
import NotFound from "./pages/NotFound";
import UploadDocument from "./pages/Company/CompanyDocument/UploadDocument";
import AddCompnayDocument from "./pages/Company/CompanyDocument/AddDocument";
import RankList from "./pages/Company/Rank";
import RankAdd from "./pages/Company/Rank/create";
import RankEdit from "./pages/Company/Rank/edit";

import VesselTypeList from "./pages/Company/VesselType";
import VesselTypeAdd from "./pages/Company/VesselType/create";
import VesselTypeEdit from "./pages/Company/VesselType/edit";
import PortList from "./pages/Company/Port";
import PortAdd from "./pages/Company/Port/create";
import PortEdit from "./pages/Company/Port/edit";

import SupplyDemand from "./pages/Company/Analytics/SupplyDemand";
import AutoSupplyDemand from "./pages/Company/Analytics/AutoSupplyDemand";
import EmploymentContract from "./pages/Company/HiredCrews/EmploymentContract";
import PortHealthDocument from "./pages/Company/PriviligedUser/PortHealthDocument";
import DormantReport from "./pages/Company/Report/dormantReport";
import DocumentPrintPreview from "./pages/Company/DocumentPrint/DocumentPrintPreview";
import DocumentPrintPreviewThirtyDay from "./pages/Company/DocumentPrint/DocumentPrintPreviewThirtyDay";
import DocumentPrintPreviewOverdue from "./pages/Company/DocumentPrint/DocumentPrintPreviewOverdue";
import CompanyPolicy from "./pages/Company/Seafarer/CompanyPolicy";
import SeaportImmigration from "./pages/Company/Seafarer/SeaportImmigration";
import SignOnLetter from "./pages/Company/Seafarer/SignOnLetter";
import ApplicationForm from "./pages/Company/Seafarer/ApplicationForm";
import TermsCondition from "./pages/Company/Seafarer/TermsCondition";
import TermsConditionCreate from "./pages/Company/Seafarer/TermsCondition/create";
import TermsConditionShow from "./pages/Company/Seafarer/TermsCondition/show";
import PreEmploymentMedical from "./pages/Company/Seafarer/PreEmploymentMedical";
import PreEmploymentMedicalCreate from "./pages/Company/Seafarer/PreEmploymentMedical/create";
import PreEmploymentMedicalEdit from "./pages/Company/Seafarer/PreEmploymentMedical/edit";
import PreEmploymentMedicalShow from "./pages/Company/Seafarer/PreEmploymentMedical/show";
import MedicalQuestionnarie from "./pages/Company/Seafarer/MedicalQuestionnarie";
import MedicalQuestionnarieCreate from "./pages/Company/Seafarer/MedicalQuestionnarie/create";
import MedicalQuestionnarieEdit from "./pages/Company/Seafarer/MedicalQuestionnarie/edit";
import MedicalQuestionnarieShow from "./pages/Company/Seafarer/MedicalQuestionnarie/show";
import SeafarerTopFourRankContractReport from "./pages/Company/Report/seafarerTopFourRankContractReport";
import SmsLog from "./pages/Company/SmsLog/SmsLog";
import Chat from "./pages/Company/chat";
import Inbox from "./pages/Company/chat/inbox";

import PriviligedUser from "./pages/Company/PriviligedUser";


import SeafarerDashboard from "./pages/Seafarer/Dashboard";
import SeafarerProfile from "./pages/Seafarer/Profile/Profile";
import SeafarerUploadPerformance from "./pages/Seafarer/Profile/UploadPerformance";
import SeafarerUpdateReadiness from "./pages/Seafarer/Profile/UpdateReadiness";
import SeafarerPersonalInfo from "./pages/Seafarer/Profile/PersonalInfo";
import SeafarerPassport from "./pages/Seafarer/Profile/Document_visa/Passport";
import SeafarerPassportEdit from "./pages/Seafarer/Profile/Document_visa/PassportEdit";
import SeafarerVisa from "./pages/Seafarer/Profile/Document_visa/Visa";
import SeafarerVisaEdit from "./pages/Seafarer/Profile/Document_visa/VisaEdit";
import SeafarerCreateEducation from "./pages/Seafarer/Profile/education/CreateEducation";
import SeafarerEditEducation from "./pages/Seafarer/Profile/education/EditEducation";
import SeafarerCreateCertification from "./pages/Seafarer/Profile/certification/CreateCertification";
import SeafarerEditCertification from "./pages/Seafarer/Profile/certification/EditCertification";
import SeafarerCreateExperience from "./pages/Seafarer/Profile/experience/CreateExperience";
import SeafarerEditExperience from "./pages/Seafarer/Profile/experience/EditExperience";
import SeafarerCreateFamily from "./pages/Seafarer/Profile/family/CreateFamily";
import SeafarerEditFamily from "./pages/Seafarer/Profile/family/EditFamily";
import SeafarerMedicalInformation from "./pages/Seafarer/Profile/Others/MedicalInfo";
import SeafarerOtherInformation from "./pages/Seafarer/Profile/Others/OthersInfo";
import SeafarerBankInformation from "./pages/Seafarer/Profile/Others/BankInfo";
import SeafarerTrainingInformation from "./pages/Seafarer/Profile/Others/TrainingInfo";
import SeafarerDocument from "./pages/Seafarer/Document/Document";
import AddSeafarerDocument from "./pages/Seafarer/Document/AddDocument";
import EditSeafarerDocument from "./pages/Seafarer/Document/EditDocument";
import PreJoiningDocument from "./pages/Seafarer/PreJoining/Document";
import SeafarerChat from "./pages/Seafarer/chat";
import SeafarerInbox from "./pages/Seafarer/chat/inbox";
import SeafarerNewInbox from "./pages/Seafarer/chat/newInbox";

import newInbox from "./pages/Company/chat/newInbox";
import NewInbox from "./pages/Company/chat/newInbox";












function App() {
  return (
      <div className="wrapper">

          {/*<BrowserRouter basename={'/spdv2'}>*/}
          <BrowserRouter>
              <Routes >
                  <Route path="/" element={<Landing />} />
                  <Route path="/login" element={<Login />} />
                  <Route path={COMPANYROUTE.DASHBOARD} element={<CompanyLayout> <CompanyDashboard /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.DOCUMENT_PRINT_PREVIEW} element={<CompanyLayout><DocumentPrintPreview /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.DOCUMENT_PRINT_PREVIEW_THIRTY_DAYS} element={<CompanyLayout><DocumentPrintPreviewThirtyDay /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.DOCUMENT_PRINT_PREVIEW_OVERDUE} element={<CompanyLayout><DocumentPrintPreviewOverdue /> </CompanyLayout>} />


                  <Route path={COMPANYROUTE.SIGN_ON_OFF} element={<CompanyLayout><SignOnOffReport /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SIGN_OFF} element={<CompanyLayout><SignOffReport /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_DORMANT} element={<CompanyLayout><DormantReport /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.NO_OF_CONTRACT} element={<CompanyLayout><NoOfContract /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SUPPLY_DEMAND_REPORT} element={<CompanyLayout><SupplyDemandReport /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_CONTRACT_EXTENSION} element={<CompanyLayout><SeafarerContractExtension /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_LEFT_REPORT} element={<CompanyLayout><SeafarerLeftReport /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_CONTRACT_DURATION_REPORT} element={<CompanyLayout><SeafarerContractDuration /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_INDIVIDUAL_CONTRACT_DURATION_REPORT} element={<CompanyLayout><SeafarerIndividualContractDuration /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_TURNOVER_REPORT} element={<CompanyLayout><SeafarerTurnOver /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_NEW_PROMOTION_REPORT} element={<CompanyLayout><SeafarerNewPromotion /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_EMERGENCY_SIGN_OFF_REPORT} element={<CompanyLayout><SeafarerEmergencySignOff /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_ILLNESS_REPORT} element={<CompanyLayout><SeafarerIllness /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SEAFARER_TOP_FOUR_RANK_CONTRACT_REPORT} element={<CompanyLayout><SeafarerTopFourRankContractReport /> </CompanyLayout>} />

                  <Route   path={COMPANYROUTE.SEAFARER} element={<CompanyLayout><Seafarer /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.ADD_NEW_SEAFARER} element={<CompanyLayout><AddNewSeafarer /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.ADD_TO_VESSEL}:id`} element={<CompanyLayout><AddToVessel /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDIT_TO_VESSEL}:id`} element={<CompanyLayout><EditToVessel /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.CREW_EDIT_ID}:id`} element={<CompanyLayout><SeafarerEditId /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.SEAFARER_AGREEMENT}:id`} element={<CompanyLayout><Agreement /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.SEAFARER_AGREEMENT_EXTENSION}:id`} element={<CompanyLayout><AgreementExtension /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.SEAFARER_CHECKLIST}:id`} element={<CompanyLayout><Checklist /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.COMPANY_POLICY}:id`} element={<CompanyLayout><CompanyPolicy /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.SEAPORT_IMMIGRATION}:id`} element={<CompanyLayout><SeaportImmigration /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.SIGN_ON_LETTER}:id`} element={<CompanyLayout><SignOnLetter /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.APPLICATION_FORM}:id`} element={<CompanyLayout><ApplicationForm /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.TERMS_CONDITION}:id`} element={<CompanyLayout><TermsCondition /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.TERMS_CONDITION_CREATE}:id`} element={<CompanyLayout><TermsConditionCreate /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.TERMS_CONDITION_SHOW}:id`} element={<CompanyLayout><TermsConditionShow /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.PRE_EMPLOYMENT_MEDICAL}:user_id`} element={<CompanyLayout><PreEmploymentMedical /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.PRE_EMPLOYMENT_MEDICAL_CREATE}:user_id`} element={<CompanyLayout><PreEmploymentMedicalCreate /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.PRE_EMPLOYMENT_MEDICAL_EDIT}:id`} element={<CompanyLayout><PreEmploymentMedicalEdit /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.PRE_EMPLOYMENT_MEDICAL_SHOW}:id`} element={<CompanyLayout><PreEmploymentMedicalShow /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE}:user_id`} element={<CompanyLayout><MedicalQuestionnarie /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE_CREATE}:user_id`} element={<CompanyLayout><MedicalQuestionnarieCreate /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE_EDIT}:id`} element={<CompanyLayout><MedicalQuestionnarieEdit /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE_SHOW}:id`} element={<CompanyLayout><MedicalQuestionnarieShow /> </CompanyLayout>} />



                  <Route path={`${COMPANYROUTE.SEAFARER_DOCUMENTS}:id`} element={<CompanyLayout><Document /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.ADD_NEW_SEAFARER_DOCUMENTS}:id`} element={<CompanyLayout><AddDocument /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDIT_SEAFARER_DOCUMENTS}:id`} element={<CompanyLayout><EditDocument /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.PENDING_DOCUMENTS} element={<CompanyLayout><PendingDocument /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.COMPANY_USER_LIST} element={<CompanyLayout><CompanyUserList /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.COMPANY_USER_LOGIN_HISTORY} element={<CompanyLayout><CompanyUserLoginHistory /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.CREATE_COMPANY_USER} element={<CompanyLayout><CreateCompanyUser /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDIT_COMPANY_USER}:id`} element={<CompanyLayout><EditCompanyUser /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDIT_COMPANY_USER_PASSWORD}:id`} element={<CompanyLayout><EditCompanyUserPassword /> </CompanyLayout>} />


                    <Route path={COMPANYROUTE.COMPANY_PERMISSION} element={<CompanyLayout><CompanyPermission /> </CompanyLayout>} />
                    <Route path={COMPANYROUTE.COMPANY_PERMISSION_ADD} element={<CompanyLayout><CompanyPermissionAdd /> </CompanyLayout>} />
                    <Route path={`${COMPANYROUTE.COMPANY_PERMISSION_EDIT}:id`}  element={<CompanyLayout><CompanyPermissionEdit /> </CompanyLayout>} />
                    <Route path={`${COMPANYROUTE.COMPANY_PERMISSION_SHOW}:id`}  element={<CompanyLayout><CompanyPermissionShow /> </CompanyLayout>} />


                  <Route path={COMPANYROUTE.RANK_PERMISSION} element={<CompanyLayout><RankPermission /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.RANK_PERMISSION_ADD} element={<CompanyLayout><RankPermissionAdd /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.RANK_PERMISSION_EDIT}:id`} element={<CompanyLayout><RankPermissionEdit /> </CompanyLayout>} />


                  <Route path={COMPANYROUTE.JOB_LIST} element={<CompanyLayout><JobList /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.ADD_NEW_JOB} element={<CompanyLayout><AddNewJob /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDIT_JOB}:id`} element={<CompanyLayout><EditJob /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.APPLIED_JOB}:id`} element={<CompanyLayout><AppliedJob /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.SHORTLISTED_JOB}:id`} element={<CompanyLayout><ShortlistedSeafarer /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.BUDGET_HEAD_LIST} element={<CompanyLayout><BudgetHead /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.ADD_BUDGET_HEAD} element={<CompanyLayout><AddBudgetHead /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDIT_BUDGET_HEAD}:id`}  element={<CompanyLayout><EditBudgetHead /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.ADD_BUDGET_SUB_HEAD}:id`}  element={<CompanyLayout><AddBudgetHead /> </CompanyLayout>} />


                  <Route path={COMPANYROUTE.FLEET_BUDGET} element={<CompanyLayout><FleetBudget /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.INDIVIDUAL_VESSEL_BUDGET} element={<CompanyLayout><IndividualVesselBudget /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.PORTAGE_BILL} element={<CompanyLayout><PortageBill /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.TOP_FOUR_PORTAGE_BILL} element={<CompanyLayout><TopFourPortageBill /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.BASIC_WAGES} element={<CompanyLayout><BasicWages /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.TOP_FOUR_OFFICER_SALARY} element={<CompanyLayout><TopFourOfficerSalary /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.SALARY_POLICY} element={<CompanyLayout><SalaryPolicy /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SALARY_POLICY_CREATE} element={<CompanyLayout><CreateSalaryPolicy /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.PARTICULARS} element={<CompanyLayout><EarningDeductionItem /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.PARTICULARS_CREATE} element={<CompanyLayout><CreateParticularItem /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.PARTICULARS_EDIT}:id`}  element={<CompanyLayout><EditParticularItem /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.PARTICULARS_TRANSACTION} element={<CompanyLayout><EarningDeductionEntry /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.PARTICULARS_TRANSACTION_CREATE} element={<CompanyLayout><EarningDeductionCreate /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.PARTICULARS_TRANSACTION_EDIT}:id`} element={<CompanyLayout><EarningDeductionEdit /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.HIRED_CREWS} element={<CompanyLayout><HiredCrews /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EMPLOYMENT_CONTRACT}:id`} element={<CompanyLayout><EmploymentContract /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.VESSEL} element={<CompanyLayout><VesselList /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.ADD_NEW_VESSEL} element={<CompanyLayout><CreateVessel /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDIT_VESSEL}:id`} element={<CompanyLayout><EditVessel /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.VESSEL_CREW_LIST}:id`}  element={<CompanyLayout><CrewList /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.VESSEL_CREW_PLANNER}:id`} element={<CompanyLayout><CrewPlanner /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE}:vessel_id/:category_id`} element={<CompanyLayout><CrewPlannerReplace /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE_EXTERNAL_POOL}:vessel_id/:category_id`} element={<CompanyLayout><CrewPlannerReplaceExternalPool /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE_TANKER}:vessel_id/:category_id/:company`} element={<CompanyLayout><CrewPlannerReplaceTanker /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE_TANKER_EXTERNAL_POOL}:vessel_id/:category_id/:company`} element={<CompanyLayout><CrewPlannerReplaceTankerExternalPool /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.VESSEL_BUDGET}:id`} element={<CompanyLayout><VesselBudget /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.VESSEL_BUDGET_SHOW}:year/:id`} element={<CompanyLayout><VesselBudgetShow /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.VESSEL_BUDGET_EDIT}:year/:id`} element={<CompanyLayout><VesselBudgetEdit /> </CompanyLayout>} />


                  <Route path={COMPANYROUTE.SEAFARER_TRASH} element={<CompanyLayout><SeafarerTrash /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.SALARY_TREND} element={<CompanyLayout><SalaryTrend /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.SUPPLY_DEMAND} element={<CompanyLayout><SupplyDemand /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.AUTO_SUPPLY_DEMAND} element={<CompanyLayout><AutoSupplyDemand /> </CompanyLayout>} />


                  <Route path={COMPANYROUTE.COMPANY_MODULE} element={<CompanyLayout><CompanyModule /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.COMPANY_MODULE_ADD} element={<CompanyLayout><CompanyModuleAdd /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.COMPANY_MODULE_EDIT}:id`} element={<CompanyLayout><CompanyModuleEdit /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.RANK_GROUP} element={<CompanyLayout><RankGroup /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.RANK_GROUP_ADD} element={<CompanyLayout><RankGroupAdd /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.RANK_GROUP_EDIT}:id`} element={<CompanyLayout><RankGroupEdit /> </CompanyLayout>} />


                  <Route path={COMPANYROUTE.UPLOAD_DOCUMENT} element={<CompanyLayout><UploadDocument /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.ADD_COMPANY_DOCUMENT} element={<CompanyLayout><AddCompnayDocument /> </CompanyLayout>} />


                  <Route path={COMPANYROUTE.RANK} element={<CompanyLayout><RankList /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.ADD_RANK} element={<CompanyLayout><RankAdd /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDIT_RANK}:id`}  element={<CompanyLayout><RankEdit /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.VESSEL_TYPE} element={<CompanyLayout><VesselTypeList /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.VESSEL_TYPE_ADD} element={<CompanyLayout><VesselTypeAdd /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.VESSEL_TYPE_EDIT}:id`}  element={<CompanyLayout><VesselTypeEdit /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.PORT} element={<CompanyLayout><PortList /> </CompanyLayout>} />
                  <Route path={COMPANYROUTE.PORT_ADD} element={<CompanyLayout><PortAdd /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.PORT_EDIT}:id`}  element={<CompanyLayout><PortEdit /> </CompanyLayout>} />

                  <Route path={COMPANYROUTE.SMS_LOG} element={<CompanyLayout><SmsLog /> </CompanyLayout>} />


                  {/*chat */}
                  <Route path={COMPANYROUTE.CHAT} element={<CompanyLayout><Chat /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.INBOX}:slug`}  element={<CompanyLayout><Inbox /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.NEW_INBOX}:id`}  element={<CompanyLayout><NewInbox /> </CompanyLayout>} />

                  {/*profile*/}

                  <Route path={`${COMPANYROUTE.USER_PROFILE}:id`} element={<CompanyLayout><Profile /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.UPLOAD_PERFORMANCE}:id`} element={<CompanyLayout><UploadPerformance /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.UPDATE_READINESS}:id`} element={<CompanyLayout><UpdateReadiness /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.UPDATE_PERSONAL_INFO}:id`}  element={<CompanyLayout><PersonalInfo /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.USER_PASSPORT}:id`} element={<CompanyLayout><Passport /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.USER_PASSPORT_EDIT}:id`} element={<CompanyLayout><PassportEdit /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.USER_VISA}:id`} element={<CompanyLayout><Visa /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.USER_VISA_EDIT}:id`} element={<CompanyLayout><VisaEdit /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.ADD_NEW_EDUCATION}:id`} element={<CompanyLayout><CreateEducation /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EDUCATION_EDIT}:id`} element={<CompanyLayout><EditEducation /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.ADD_NEW_CERTIFICATION}:id`} element={<CompanyLayout><CreateCertification /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.CERTIFICATION_EDIT}:id`} element={<CompanyLayout><EditCertification /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.ADD_NEW_EXPERIENCE}:id`} element={<CompanyLayout><CreateExperience /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.EXPERIENCE_EDIT}:id`} element={<CompanyLayout><EditExperience /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.ADD_NEW_FAMILY}:id`} element={<CompanyLayout><CreateFamily /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.FAMILY_EDIT}:id`} element={<CompanyLayout><EditFamily /> </CompanyLayout>} />

                  <Route path={`${COMPANYROUTE.MEDICAL_INFORMATION}:id`} element={<CompanyLayout><MedicalInfo /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.TRAINING_INFORMATION}:id`} element={<CompanyLayout><TrainingInfo /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.OTHERS_INFORMATION}:id`} element={<CompanyLayout><OthersInfo /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.BANK_INFORMATION}:id`} element={<CompanyLayout><BankInfo /> </CompanyLayout>} />



                  <Route path={COMPANYROUTE.GUEST_SEAFARER_LIST} element={<CompanyLayout><PriviligedUser /> </CompanyLayout>} />
                  <Route path={`${COMPANYROUTE.PORT_HEALTH_DOCUMENT}:id`} element={<CompanyLayout><PortHealthDocument /> </CompanyLayout>} />





                  {/*seafarer dashboard*/}

                  <Route path={UserRoute.DASHBOARD} element={<SeafarerLayout> <SeafarerDashboard /> </SeafarerLayout>} />
                  <Route path={UserRoute.PROFILE} element={<SeafarerLayout> <SeafarerProfile /> </SeafarerLayout>} />
                  <Route path={UserRoute.UPLOAD_PERFORMANCE} element={<SeafarerLayout><SeafarerUploadPerformance /> </SeafarerLayout>} />
                  <Route path={UserRoute.UPDATE_READINESS} element={<SeafarerLayout><SeafarerUpdateReadiness /> </SeafarerLayout>} />
                  <Route path={UserRoute.UPDATE_PERSONAL_INFO}  element={<SeafarerLayout><SeafarerPersonalInfo /> </SeafarerLayout>} />

                  <Route path={UserRoute.USER_PASSPORT} element={<SeafarerLayout><SeafarerPassport /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.USER_PASSPORT_EDIT}:id`} element={<SeafarerLayout><SeafarerPassportEdit /> </SeafarerLayout>} />
                  <Route path={UserRoute.USER_VISA} element={<SeafarerLayout><SeafarerVisa /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.USER_VISA_EDIT}:id`} element={<SeafarerLayout><SeafarerVisaEdit /> </SeafarerLayout>} />

                  <Route path={UserRoute.ADD_NEW_EDUCATION} element={<SeafarerLayout><SeafarerCreateEducation /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.EDUCATION_EDIT}:id`} element={<SeafarerLayout><SeafarerEditEducation /> </SeafarerLayout>} />

                  <Route path={UserRoute.ADD_NEW_CERTIFICATION} element={<SeafarerLayout><SeafarerCreateCertification /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.CERTIFICATION_EDIT}:id`} element={<SeafarerLayout><SeafarerEditCertification /> </SeafarerLayout>} />

                  <Route path={UserRoute.ADD_NEW_EXPERIENCE} element={<SeafarerLayout><SeafarerCreateExperience /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.EXPERIENCE_EDIT}:id`} element={<SeafarerLayout><SeafarerEditExperience /> </SeafarerLayout>} />

                  <Route path={UserRoute.ADD_NEW_FAMILY} element={<SeafarerLayout><SeafarerCreateFamily /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.FAMILY_EDIT}:id`} element={<SeafarerLayout><SeafarerEditFamily /> </SeafarerLayout>} />

                  <Route path={UserRoute.MEDICAL_INFORMATION} element={<SeafarerLayout><SeafarerMedicalInformation /> </SeafarerLayout>} />
                  <Route path={UserRoute.TRAINING_INFORMATION} element={<SeafarerLayout><SeafarerTrainingInformation /> </SeafarerLayout>} />
                  <Route path={UserRoute.OTHERS_INFORMATION} element={<SeafarerLayout><SeafarerOtherInformation /> </SeafarerLayout>} />
                  <Route path={UserRoute.BANK_INFORMATION} element={<SeafarerLayout><SeafarerBankInformation /> </SeafarerLayout>} />

                  <Route path={UserRoute.SEAFARER_DOCUMENTS} element={<SeafarerLayout><SeafarerDocument /> </SeafarerLayout>} />
                  <Route path={UserRoute.ADD_NEW_SEAFARER_DOCUMENTS} element={<SeafarerLayout><AddSeafarerDocument /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.EDIT_SEAFARER_DOCUMENTS}:id`} element={<SeafarerLayout><EditSeafarerDocument /> </SeafarerLayout>} />


                  <Route path={UserRoute.PRE_JOINING_FAMILIARISATION} element={<SeafarerLayout><PreJoiningDocument /> </SeafarerLayout>} />

                  {/*chat */}
                  <Route path={UserRoute.CHAT} element={<SeafarerLayout><SeafarerChat /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.INBOX}:slug`}  element={<SeafarerLayout><SeafarerInbox /> </SeafarerLayout>} />
                  <Route path={`${UserRoute.NEW_INBOX}:id`}  element={<SeafarerLayout><SeafarerNewInbox /> </SeafarerLayout>} />




                  <Route path="*" element={<NotFound />} />





              </Routes>
          </BrowserRouter>
      </div>
  );
}

export default App;
