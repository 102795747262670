import React, { useState} from 'react';
import axiosClient from "../../../axios-client";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ProfileImage = ({imagePath}) => {
    const [profileImage,setProfileImage] = useState(null);
    const [profileImagePreview,setProfileImagePreview] = useState(null)

    const handleChangeProfileImage = (e) => {
        if (e.target.files) {
            setProfileImage(e.target.files[0]);
            setProfileImagePreview(window.URL.createObjectURL(e.target.files[0]))
        }
    }

    const handleUploadProfileImage = (e) => {
        if (!profileImage) {
            toast.error('Profile Image Field is required', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        axiosClient.post('/seafarer/profile-image-update',{
            crew_image:profileImage,
        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                toast.success(data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    toast.error(response.data?.errors?.crew_image[0], {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        })
    }
    return (
        <>

            <div className="text-center">
                {profileImagePreview !== null  ?
                    <img className="profile-user-img img-fluid  "
                         src={profileImagePreview}
                         alt="User profile picture" />
                    :
                    <img className="profile-user-img img-fluid  "
                         src={imagePath ? imagePath : '/images/demo-user.png'}
                         alt="User profile picture" />
                }


            </div>

            <div className="d-flex justify-content-between">
                <div className="form-group">
                    <label className="btn btn-sm btn-info" htmlFor="profile_image"> Change</label>
                    <input onChange={handleChangeProfileImage} type="file" id="profile_image" className="d-none" name="profile_image"/>
                </div>
                <div className="form-group">
                    <button onClick={handleUploadProfileImage} className="btn btn-sm btn-info">Upload</button>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};

export default ProfileImage;