import React, {useEffect, useReducer, useState} from "react";
import '../styles/style.css'
import Pagination from "react-js-pagination";
import LoadingBar from 'react-top-loading-bar';
import {Link} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {
    EarningDeductionParticularAmountReducer,
    ACTION_TYPES,
    INITIAL_STATE
} from "../../../../reducers/Company/EarningDeductionParticularAmountReducer";
const EarningDeductionAmount = () => {
    const [progress, setProgress] = useState(10);

    const [state,dispatch] = useReducer(EarningDeductionParticularAmountReducer,INITIAL_STATE);

    const getReportData = (pageNumber = 1) => {
        setProgress(50)
        dispatch({type:ACTION_TYPES.GET_DATA});
        axiosClient.get(`/company/accounts/particular-amount/?page=${pageNumber}`)
            .then(({data}) => {
                dispatch({type:ACTION_TYPES.GET_DATA_SUCCESS,payload:data.data});
                setProgress(100);

            }).catch(() => {
            dispatch({type:ACTION_TYPES.GET_DATA_ERROR});

        })
    }



    useEffect(() => {
        getReportData(1)
    },[])









    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Earnings and Deduction Particular Amount'    addnew={true}  route={COMPANYROUTE.PARTICULARS_TRANSACTION_CREATE}     />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className=" table-responsive new-card-body"  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Seafarer Name</th>
                                            <th>Type</th>
                                            <th>Particular</th>
                                            <th>Amount</th>
                                            <th>Remarks</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {state.loading ? "Loading" : ""}

                                        {state?.data?.data?.length > 0 ?  state?.data?.data?.map((data,index) =>{
                                            let first_name = data?.first_name != null ? data?.first_name : '';
                                            let middle_name = data?.middle_name != null ? data?.middle_name : '';
                                            let last_name = data?.last_name != null ? data?.last_name : '';

                                            let name = first_name + ' ' + last_name + ' ' + middle_name;


                                        return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{new Date(data?.created_at).toLocaleDateString()}</td>
                                                <td>{name}</td>
                                                <td style={{ textTransform: 'capitalize' }}>{data?.type}</td>
                                                <td>{data?.name}</td>
                                                <td>{data?.amount}</td>
                                                <td>{data?.remarks}</td>
                                                <td>
                                                    <Link to={`${COMPANYROUTE.PARTICULARS_TRANSACTION_EDIT}${data?.id}`} className='btn btn-sm btn-info'><i className="fa fa-pencil-alt"></i></Link>
                                                </td>

                                            </tr>
                                        )}) : <tr><td colSpan={4} className='text-center'>No Data Found</td></tr>}

                                        {state.error ? <tr><td colSpan={13} className='text-center text text-danger'>Something Went Wrong!</td></tr> : '' }

                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">

                                        <Pagination
                                            activePage={state?.data?.current_page ? state?.data?.current_page : 0}
                                            itemsCountPerPage={state?.data?.per_page ? state?.data?.per_page : 0 }
                                            totalItemsCount={state?.data?.total ? state?.data?.total : 0}
                                            onChange={(pageNumber) => {
                                                getReportData(pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
};

export default EarningDeductionAmount;