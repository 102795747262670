import ExcelJS from 'exceljs';

 const UsePortageBillExportHook = (fileExtension,data,title,file_name) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);



     const headerRow1 = worksheet.addRow([]);
     headerRow1.height = 30;
     headerRow1.alignment = { vertical: 'middle', horizontal: 'center' };

     let currentColIndex = 1;

     data?.headers1.forEach((header) => {
         const cell = headerRow1.getCell(currentColIndex);

         cell.alignment = { vertical: 'middle', horizontal: 'center' };
         cell.font = { bold: true };

         if (typeof header === 'object' && header.colspan) {
             const { colspan, text } = header;
             const mergeStartColIndex = currentColIndex;
             const mergeEndColIndex = currentColIndex + colspan - 1;

             if (mergeStartColIndex !== mergeEndColIndex) {
                 worksheet.mergeCells(headerRow1.number, mergeStartColIndex, headerRow1.number, mergeEndColIndex);
             }

             cell.value = text;
             currentColIndex += colspan;
         } else {
             cell.value = header;
             currentColIndex++;
         }

         cell.width = 100;

     });

        const headerRow2 = worksheet.addRow([]);
        headerRow2.height = 30;
        headerRow2.alignment = { vertical: 'middle', horizontal: 'center' };

        let currentColIndex2 = 1;
        data?.headers2.forEach((header) => {
            const cell = headerRow2.getCell(currentColIndex2);

            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.font = { bold: true };

            if (typeof header === 'object' && header.colspan) {
                const { colspan, text } = header;
                const mergeStartColIndex = currentColIndex2;
                const mergeEndColIndex = currentColIndex2 + colspan - 1;

                if (mergeStartColIndex !== mergeEndColIndex) {
                    worksheet.mergeCells(headerRow2.number, mergeStartColIndex, headerRow2.number, mergeEndColIndex);
                }

                cell.value = text;
                currentColIndex2 += colspan;
            } else {
                cell.value = header;
                currentColIndex2++;
            }

        });


     worksheet.columns.forEach((column) => {
         column.width = 15;
     });


     data?.data?.forEach((dataRow) => {
         const row = worksheet.addRow(dataRow);
         row.height = 30;
         row.alignment = { vertical: 'middle', horizontal: 'center' };
     });

     const totalRow = worksheet.addRow([]);
     totalRow.height = 30;
     totalRow.alignment = { vertical: 'middle', horizontal: 'center' };

     let totalColIndex = 1;
     data?.data2?.forEach((header) => {
         const cell = totalRow.getCell(totalColIndex);

         cell.alignment = { vertical: 'middle', horizontal: 'center' };
         cell.font = { bold: true };


         if (typeof header === 'object' && header.colspan) {
             const { colspan, text } = header;
             const mergeStartColIndex = totalColIndex;
             const mergeEndColIndex = totalColIndex + colspan - 1;

             if (mergeStartColIndex !== mergeEndColIndex && fileExtension === 'xlxs') {
                 worksheet.mergeCells(totalRow.number, mergeStartColIndex, totalRow.number, mergeEndColIndex);
             }

             if (fileExtension === 'csv') {
                 for (let i = 0; i <  header.colspan - 1; i++) {
                     const emptyCell = totalRow.getCell(totalColIndex);
                     emptyCell.value = '';
                 }
             }

             cell.value = text;
             totalColIndex += colspan;
         } else {
             cell.value = header;
             totalColIndex++;
         }


     });



     if(fileExtension === 'xlxs'){
         workbook.xlsx.writeBuffer().then((buffer) => {
             const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
             const fileName = file_name + '.xlsx';

             const link = document.createElement('a');
             link.href = URL.createObjectURL(blob);
             link.download = fileName;
             link.click();
         });
     }

     if(fileExtension === 'csv'){
            workbook.csv.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'text/csv' });
                const fileName = file_name + '.csv';

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            });
     }


};

 export default UsePortageBillExportHook;


