import React, {useEffect, useState} from 'react';
import Logo from "../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import './styles/checklist.css'
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {Link, useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../hooks/usePrintWindow";
const CompanyPolicy = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [data, setData] = useState({})

    const navigate = useNavigate();

    const getData = () => {

        axiosClient.get('/company/company-policy/'+id)
            .then(({data}) => {
                if(data.data  == false){
                    toast.error('Seafarer  not found')
                    navigate(COMPANYROUTE.SEAFARER)
                }
                setData(data.data)
                setProgress(100)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/company-policy/print/' + id,
    });
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Company  Policy'  prev={COMPANYROUTE.SEAFARER}    />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-10 offset-md-1">
                                    <div className="checklist-panel">


                                        <div className="justify-content-center d-flex mb-2">
                                    <div className="d-flex">
                                        <div className="logo">
                                            <Logo />
                                        </div>
                                        <div className="header-text">
                                            <h5 className="text-center company-name">{data?.company_name}</h5>
                                              <div className="d-flex justify-content-center">
                                                  <div className="header-border">
                                                      FILE NO. F– 01
                                                  </div>
                                                  <div className="header-border">
                                                      BRSML/FORM-02-R0
                                                  </div>
                                              </div>
                                            <h6 className="text-center title">DRUG AND ALCOHOL POLICY</h6>

                                        </div>
                                    </div>
                                </div>
                                        <div className="content-body">
                                            <p >The Company’s Drug and Alcohol Abuse Policy applies to all sea and shore personnel who may sail on, visit
                                                the Company’s vessels, owned and or managed and work in the office embraces.</p>

                                            <h6 className="  title">DRUGS</h6>
                                            <p>The misuse of legitimate drugs or the use, possession, distribution or sale of illicit or unprescribed controlled
                                                drugs ashore or onboard ship or by shore staff / crew members on leave is prohibited.
                                                <br />
                                                    The Company will not employ any persons who are users of or have a recent record of the use of illegal drugs or
                                                    have abused the use of prescribed drugs. Any employee found in contravention of the Company’s drug policy
                                                    will be instantly dismissed and handed over to the appropriate authorities for prosecution. All are reminded that
                                                    in certain countries possession of illegal drugs can result in the <b>DEATH PENALTY</b>
                                            </p>
                                            <h5  className="title">ALCOHOL</h5>
                                            <p  >The use, possession, distribution or sale of Alcohol on board ship is prohibited. Any employee found in
                                                contravention of the Company’s Alcohol policy will be instantly dismissed and handed over to the appropriate
                                                authorities for prosecution. <br />
                                                    Seafarers are advised to report to the Master of any illegal activity of Drug and Alcohol on board who would
                                                    take appropriate action.</p>

                                            <h5 className="title">TOBACCO</h5>
                                            <p >Tobacco smoking is a health hazard. <br/>
                                                Smoking is prohibited in <strong> GALLEYS, MACHINERY SPACES, STORE ROOMS and DECK (INCLUDING
                                                CARGO HOLDS)</strong> at all times. Smoking is only permitted in mess room and crews quarters except during
                                                Bunkering.
                                            </p>
                                            <h5 className="title">DECLARATION BY EMPLOYEE :</h5>
                                            <p >I have read and understood the contents of <strong>Drug and Alcohol Policy</strong> of Brave Royal Shipping Ltd. and agree
                                                to comply with the policy at all stages of service.</p>

                                            <div className="d-flex justify-content-between mb-3">
                                                <div className="col-md-4 col-12">
                                                    Name: {data?.name}
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    Rank: {data?.rank}
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    CDC NO: {data?.cdc}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="col-md-4 col-12">
                                                    Vessel: {data?.vessel}
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    Date:.....................
                                                </div>
                                                <div className="col-md-4 col-12">
                                                   Signature: <img src={data?.signature} alt=""/>
                                                </div>
                                            </div>
                                            <h6  className="title">Original: Office (DPA)</h6>


                                      </div>

                                 </div>


                                </div>


                                <div className="col-md-10 offset-md-1 mt-5">
                                    <div className="checklist-panel">


                                        <div className="justify-content-center d-flex mb-2">
                                    <div className="d-flex">
                                        <div className="logo">
                                            <Logo />
                                        </div>
                                        <div className="header-text">
                                            <h5 className="text-center company-name">{data?.company_name}</h5>
                                              <div className="d-flex justify-content-center">
                                                  <div className="header-border">
                                                      FILE NO. F – 01
                                                  </div>
                                                  <div className="header-border">
                                                      BRSML/FORM-01-R0
                                                  </div>
                                              </div>
                                            <h6 className="text-center title">SAFETY AND ENVIRONMENTAL PROTECTION POLICY</h6>

                                        </div>
                                    </div>
                                </div>
                                        <div className="content-body">

                                            <p>It is the policy of the Company to provide healthy and safe working conditions, and to maintain a safe and
                                                pollution-free operating practice that complies with national and international regulations and relevant standards
                                                and guidelines
                                                <br />
                                                    The Company Safety Management Manual describes the Company's management system for the safe operation
                                                    of ships (owned and or managed) and for pollution prevention. Its contents have been reviewed and indicate
                                                    conformity with the requirements of I.M.O. Resolution A741/18 – The International Safety Management Code.
                                            </p>

                                            <p > Company objectives are to:</p>
                                            <ul >
                                                <li> 1.  Provide for safe practices in ship operation and a safe working environment. </li>
                                                <li> 2.  Assess all identified risks to its ships, personnel and the environment and establish appropriate safeguards. </li>
                                                <li> 3.  Continuously improve the safety management skills of personnel ashore and aboard ship, including preparing for emergencies related both to safety and environmental protection.  </li>
                                            </ul>

                                            <p> These objectives will be achieved by::</p>
                                            <ul>
                                                <li > 1.  Maintaining high standards of safety consciousness, personal discipline and individual accountability by
                                                    adherence to a comprehensive and documented system of training.</li>
                                                <li> 2.  Actively promoting employee participation in measures aimed at improving safety and protecting the
                                                    environment. </li>
                                                <li> 3.  Keeping all personnel fully informed of any known or potential hazards that may affect themselves,
                                                    their colleagues, the ship or the environment by transmittal of pertinent documentation.  </li>
                                                <li>4. Ensuring adherence at all times to the documented operating procedures by a system of internal
                                                    verification of procedures and activities</li>
                                                <li>5. Continuously reviewing all mandatory rules, regulations, industry codes and guidelines that are relevant
                                                    to specific ship types and trades.</li>
                                            </ul>

                                            <p>All employees are expected to comply with Safety and Pollution Prevention Regulations and Procedures at all
                                                times, and to take the necessary precautions to protect themselves, their colleagues, the ship, its cargo and the
                                                environment.</p>


                                            <h5 className="title">DECLARATION BY EMPLOYEE :</h5>
                                            <p >I have read and understood the contents of <strong>Safety and Environmental Protection Policy of Brave Royal
                                                Ship Management (BD) Limited.</strong> and agree to comply with the policy at all stages of service.</p>

                                            <div className="d-flex justify-content-between mb-3">
                                                <div className="col-md-4 col-12">
                                                    Name: {data?.name}
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    Rank: {data?.rank}
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    CDC NO: {data?.cdc}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="col-md-4 col-12">
                                                    Vessel: {data?.vessel}
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    Date:.....................
                                                </div>
                                                <div className="col-md-4 col-12">
                                                   Signature: <img src={data?.signature} alt=""/>
                                                </div>
                                            </div>
                                            <h6  className="title">Original: Office (DPA)</h6>


                                      </div>





                                 </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default CompanyPolicy;