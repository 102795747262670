import React, {useEffect, useRef} from 'react';

const MessageBox = ({messages}) => {
    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <ul id="chat"  ref={chatContainerRef}  >
            {
             messages?.length > 0 ?  messages?.map((data,index) => (
             <div key={index}>
                 {
                   data?.message_type === 'receiver' && (
                          <li className="you">
                                <div className="entete">
                                    <span className="status green"></span>
                                    <h2>{data?.name}</h2>
                                    <h3>{data?.message_time}  </h3>
                                </div>
                                   {
                                    data?.conversation_type === 'image' ? (
                                      <img height="200px" width="200px" src={data?.message} alt=""/>
                                      ) :   data?.conversation_type === 'text' ? (
                                            <>
                                                <div className="triangle"></div>
                                                <div className="message">
                                                   {data?.message}
                                                </div>
                                            </>
                                           ) :  data?.conversation_type === 'file' ? (
                                            <a href={data?.message} target="_blank" download>
                                                Download PDF
                                            </a>
                                          ): ''
                                     }
                   </li>
                     )
                 }
                 {
                     data?.message_type === 'sender' && (
                         <li className="me">
                             <div className="entete">
                                 <h3>{data?.message_time}</h3>
                                 <h2>{data?.name}</h2>
                                 <span className="status blue"></span>
                             </div>
                             {
                                 data?.conversation_type === 'image' ? (
                                     <img height="200px" width="200px" src={data?.message} alt=""/>
                                 ) :   data?.conversation_type === 'text' ? (
                                     <>
                                         <div className="triangle"></div>
                                         <div className="message">
                                             {data?.message}
                                         </div>
                                     </>
                                 ) :  data?.conversation_type === 'file' ? (
                                     <a href={data?.message} target="_blank" download>
                                         Download PDF
                                     </a>
                                 ): ''
                             }


                         </li>
                     )
                 }

             </div>
                 )) : <p className="text-center">No message found</p>
            }

        </ul>

    );


};

export default MessageBox;