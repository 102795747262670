import React, {useEffect, useRef, useState} from 'react';
import '../../styles/chat.css'
import ConversationList from "../../../Components/Chat/ConversationList";
import {useParams} from "react-router-dom";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import MessageForm from "../../../Components/Chat/MessageForm";
import MessageBox from "../../../Components/Chat/MessageBox";
import * as UserRoute from "../../../constants/UserRoute";

import io from "socket.io-client";
import {useStateContext} from "../../../context/ContextProvider";
import SearchInput from "../../../Components/Chat/SearchInput";
import SearchUserList from "../../../Components/Chat/SearchUserList";
import SeafarerChatSidebar from "../../../Components/Chat/SeafarerChatSidebar";

const socket = io(process.env.REACT_APP_SOCKET_URL);


const Inbox = () => {
    const {slug} = useParams();
    const {userId} =  useStateContext();
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const [conversation,setConversation] = useState([]);
    const [messages,setMessages] = useState([]);
    const [userData,setUserData] = useState("")
    const [disabled,setDisabled] = useState(false)
    const [receiverId,setReceiverId] = useState("");
    const [messageText,setMessageText] = useState("")
    const [fileSelected, setFileSelected] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [files,setFiles] = useState(null)
    const [image,setImage] = useState(null)
    const [searchExist,setSearchExist] = useState(false)

    useEffect(() => {
        socket.on('sendMessageToClient', function (message) {
            if (parseInt(message?.message?.receiver_id) === parseInt(userId) && message?.message?.slug === slug) {
                setMessages((prevMessages) => [...prevMessages, message?.message]);
                // setConversation(message?.conversation)
            }
        })
    }, []);

    const getData = () => {
        axiosClient.get(`/seafarer/chat/${slug}` )
            .then(({data}) => {
                setMessages(data?.messages)
                setUserData(data?.user_info)
                setReceiverId(data?.receiver_id)
                setConversation(data?.conversation_list)
            }).catch((e) => {
            toast(e?.response?.data?.message)
        })
    }
    useEffect(() => {
        getData()
    },[slug])




    const onSubmit = (e) => {
        e.preventDefault();
        // setDisabled(true)

        axiosClient.post('/seafarer/chat/send-message', {
            message_text: messageText,
            user_id: receiverId,
            message_file:files,
            message_image:image
        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                setMessages((prevMessages) => [...prevMessages, data?.data]);
                setConversation(data?.conversation_list)

                const modifyMessage = {...data?.data,message_type:'receiver',slug:data?.conversation?.slug}
                socket.emit('joinRoom', data?.conversation?.user_id);
                socket.emit('sendMessageToServer',{
                    message:modifyMessage,
                    conversation:data?.receiver_conversation_list
                })

                setMessageText("")
                fileInputRef.current.value = null;
                imageInputRef.current.value = null;
                setFileSelected(false)
                setImageSelected(false)
                setDisabled(true)
                setFiles(null)
                setImage(null)

            }).catch(err => {
            const response = err.response;
            setDisabled(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    useEffect(() => {
    }, [messages,conversation]);


    useEffect(() => {

    },[searchExist])



    return (
        <>
            <div id="container">
                <SeafarerChatSidebar
                    searchExist={searchExist}
                    setSearchExist={setSearchExist}
                    conversation={conversation} />

                <main>
                    <header>
                        <img src={userData?.image} alt="" />
                        <div>
                            <h2>{userData?.name}</h2>
                        </div>
                    </header>
                    <MessageBox   messages={messages} />

                    <footer>
                        <MessageForm
                            onSubmit={onSubmit}
                            messageText={messageText}
                            setMessageText={setMessageText}
                            setFiles={setFiles}
                            setImage={setImage}
                            fileInputRef={fileInputRef}
                            imageInputRef={imageInputRef}
                            fileSelected={fileSelected}
                            setFileSelected={setFileSelected}
                            imageSelected={imageSelected}
                            setImageSelected={setImageSelected}
                            disabled={disabled}
                            setDisabled={setDisabled}
                        />

                    </footer>
                </main>
        </div>
     </>
    );
};

export default Inbox;