import React, {useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import AllRank from "../../../../Components/Company/Common/AllRank";
import DatePicker from "react-datepicker";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const CreateSalaryPolicy = () => {
    const navigate = useNavigate();
    var date = new Date();
    const [year,setYear] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [rankSelect,setRankSelect] = useState("");
    const [seniority,setSeniority] = useState("");
    const [contractPeriod,setContractPeriod] = useState("");
    const [contractPlus,setContractPlus] = useState("");
    const [contractMinus,setContractMinus] = useState("");
    const [basicSalary,setBasicSalary] = useState("");
    const [overtime,setOverTime] = useState("");
    const [leavePay,setLeavePay] = useState("");
    const [subAllow,setSubAllow] = useState("");
    const [uniformAllow,setUniformAllow] = useState("");
    const [ownersAllow,setOwnersAllow] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosClient.post('/company/accounts/salary-policy', {
            policy_year: year.getFullYear(),
            category_id: rankSelect,
            seniority: seniority,
            contract_period: contractPeriod,
            contract_plus: contractPlus,
            contract_minus: contractMinus,
            basic_salary: basicSalary,
            overtime: overtime,
            leave_pay: leavePay,
            sub_allow: subAllow,
            uniform_allow: uniformAllow,
            owners_allow: ownersAllow,

        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.SALARY_POLICY}`);
                },1000);

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    return (
        <div>
            <Breadcrumb title='Create Salary Policy' prev={COMPANYROUTE.SALARY_POLICY}    />
            <section className="content">
                <div className="container-fluid">

                    <div className="card card-primary">

                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="first_name">Select Year </label>
                                            <DatePicker
                                                selected={year}
                                                dateFormat='yyyy'
                                                className="form-control"
                                                onChange={(date) => setYear(date)}
                                                showYearDropdown
                                                showYearPicker
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="last_name">Choose Rank</label>
                                             <AllRank rankSelect={rankSelect} setRankSelect={setRankSelect} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="seniority">Seniority</label>
                                            <input
                                                value={seniority}
                                                onChange={(e) => setSeniority(e.target.value)}
                                                type="text" name="seniority" className="form-control" id="seniority"
                                                   placeholder="Enter Seniority" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Contract Month</label>
                                            <input
                                                value={contractPeriod}
                                                onChange={(e) => setContractPeriod(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Contact Month" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Contract Month (+)</label>
                                            <input
                                                value={contractPlus}
                                                onChange={(e) => setContractPlus(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Contact Month (+)" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Contract Month (-)</label>
                                            <input
                                                value={contractMinus}
                                                onChange={(e) => setContractMinus(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Contact Month (-)" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Basic Salary</label>
                                            <input
                                                value={basicSalary}
                                                onChange={(e) => setBasicSalary(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Basic Salary" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Overtime</label>
                                            <input
                                                value={overtime}
                                                onChange={(e) => setOverTime(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Overtime" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Leave Pay</label>
                                            <input
                                                value={leavePay}
                                                onChange={(e) => setLeavePay(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Leave Pay" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Sub Allow</label>
                                            <input
                                                value={subAllow}
                                                onChange={(e) => setSubAllow(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Sub Allow" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Uniform Allow</label>
                                            <input
                                                value={uniformAllow}
                                                onChange={(e) => setUniformAllow(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Uniform Allow" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="contract_month">Owners Allow</label>
                                            <input
                                                value={ownersAllow}
                                                onChange={(e) => setOwnersAllow(e.target.value)}
                                                type="number" name="contract_month" className="form-control" id="contract_month"
                                                   placeholder="Enter Owners Allow" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="card-footer">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default CreateSalaryPolicy;