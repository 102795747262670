import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component';

import './report.css';
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";
import Pagination from "react-js-pagination";
const SignOnOffReport = () => {
    const [vesselSelect,setVesselSelect] = useState('');
    const [reportData,setReportData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));
    const [data, setData] = useState(reportData);
    const [status, setStatus] = useState('');


    const getReportData = (vessel = 0,dateFromData = null,dateToData = null,status = '',pageNumber = 1) => {
        axiosClient.post('/company/report/sign-on-off',{
            vessel_id: vessel,
            date_from: dateFromData,
            date_to: dateToData,
            status:status,
            page:pageNumber
        })
            .then(({data}) => {
                console.log(data)
                setReportData(data)
            }).catch((e) => {
            console.log(e)
        })
    }








    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getReportData(vesselSelect,dateFromData,dateToData,status,1)

    },[vesselSelect,dateFrom,dateTo,status])

    useEffect(() => {
        setData(reportData)
    },[reportData])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/sign-on-off/print',
        payload: { vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString(),status:status },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Vessel Name', key: 'vessel_name', width: 20 },
        { header: 'Seafarer Name', key: 'user_name', width: 20 },
        { header: 'Rank', key: 'rank', width: 20 },
        { header: 'Status', key: 'status', width: 20 },
        { header: 'Sign On Date', key: 'joining_date', width: 20 },
        { header: 'Contract Due Date/Sign Off Date', key: 'expiry_date', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/sign-on-off/export',{
            vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString(),status:status
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Sign On Off Report','sign-on-off-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/sign-on-off/export',{
            vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Sign On Off Report','sign-on-off-report',exportColumns)
            }).catch(() => {
        })
    };




    return (
        <>
         <Breadcrumb title='Sign On Off Report'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-3'>
                                                    <AllVessel vesselSelect={vesselSelect} setVesselSelect={setVesselSelect} />
                                                </div>
                                                <div className='col-12 col-md-3'>
                                                    <DatePicker
                                                        selected={dateFrom}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateFrom(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                                <div className='col-12 col-md-3'>
                                                    <DatePicker
                                                        selected={dateTo}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateTo(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                                <div className="col-md-3 col-12">
                                                    <select className="form-control"  onChange={e => setStatus(e.target.value)} >
                                                        <option value="">Select Status</option>
                                                        <option value="Current">Sign On</option>
                                                        <option value="Leave">Sign Off</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-3">
                                            <div className="float-right">
                                                <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body  ">


                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Vessel Name</th>
                                            <th>Seafarer Name</th>
                                            <th>Rank</th>
                                            <th>Status</th>
                                            <th>Sign On Date</th>
                                            <th>Contract Due Date/Sign Off Date</th>

                                        </tr>
                                        </thead>
                                        <tbody>


                                        {reportData?.data?.length > 0 ?  reportData?.data?.map((data,index) => (

                                            <tr  key={index} >
                                                <td>{data?.vessel_name}</td>
                                                <td>{data?.user_name}</td>
                                                <td>{data?.rank}</td>
                                                <td>{data?.status}</td>
                                                <td>{data?.joining_date}</td>
                                                <td>{data?.expiry_date}</td>


                                            </tr>
                                        )) : <tr><td colSpan={6} className='text-center'>No Data Found</td></tr>}



                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">


                                        <Pagination
                                            activePage={reportData?.pagination?.current_page ? reportData?.pagination?.current_page : 0}
                                            itemsCountPerPage={reportData?.pagination?.per_page ? reportData?.pagination?.per_page : 0 }
                                            totalItemsCount={reportData?.pagination?.total ? reportData?.pagination?.total : 0}
                                            onChange={(pageNumber) => {
                                                let dateFromData = new Date(dateFrom).toLocaleDateString();
                                                let dateToData = new Date(dateTo).toLocaleDateString();
                                                getReportData(vesselSelect,dateFromData,dateToData,status,pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
    );
};

export default SignOnOffReport;