import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import Logo from "../../../Components/Common/logo";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UsePortageBillExportHook from "../../../hooks/UsePortageBillExportHook";

const TopFourPortageBill = () => {
    let  basicSalary = 0;
    let  grossTotal = 0;
    let  workingDays = 0;
    let  currentSalary = 0;
    let  amountDollar = 0;
    let  conversionRate = 0;
    let  amountTaka = 0;
    let count = 0;
    var date = new Date();
    const [reportData,setReportData] = useState([]);

    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(),1));


    const [vessel,setVessel] = useState([]);
    const [vesselId,setVesselId] = useState(1);

    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessel(data.data)
                setVesselId(data?.data[0]?.vessel_id)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getVesselData();
    },[])

    const getData = (vesselId = 0, dateFrom = '') => {
        axiosClient.post('/company/accounts/top-four-portage-bill',{
            vessel_id: vesselId,
            date_from: dateFrom
        })
            .then(({data}) => {
                console.log(data)
                setReportData(data)

            }).catch((e) => {
            console.log(e)

        })
    }


    useEffect(() => {
        let dateFromData = dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1);
        getData(vesselId,dateFromData);
    },[vesselId,dateFrom])


    const { handlePrint, loading } = usePrintWindow({
        url: '/company/accounts/top-four-portage-bill/print',
        payload: { vessel_id: vesselId,  date_from: new Date(dateFrom).toLocaleDateString() },
    });



    const exportExcelFile = () => {
        axiosClient.post('/company/accounts/top-four-portage-bill/export',{
            vessel_id: vesselId, date_from: new Date(dateFrom).toLocaleDateString()
        })
            .then(({data}) => {
                UsePortageBillExportHook('xlxs', data,'Top Four Portage Bill','top-four-portage-bill')
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/accounts/top-four-portage-bill/export',{
            vessel_id: vesselId,  date_from: new Date(dateFrom).toLocaleDateString()
        })
            .then(({data}) => {
                UsePortageBillExportHook('csv', data,'Top Four Portage Bill','top-four-portage-bill')

            }).catch(() => {
        })
    };


    return (
       <>
           <Breadcrumb title='Top Four Portage Bill'      />

           <section className="content">
               <div className="container-fluid">
                   <div className="row">
                       <div className="">
                           <div className="card">
                               <div className="card-header">
                                   <div className='row'>
                                        <div className="col-12 col-md-7">
                                           <div className="row">
                                               <div className='col-12 col-md-6'>
                                                   <div className="form-group">
                                                       <select style={{maxWidth: '100%'}}
                                                               className="form-control"
                                                               value={vesselId}
                                                               onChange={(e) => setVesselId(e.target.value)}
                                                       >
                                                           {vessel.map((data,index) => (
                                                               <option key={index}    value={data?.vessel_id}>{data?.vessel_name}</option>
                                                           ))}

                                                       </select>
                                                   </div>
                                               </div>
                                               <div className='col-12 col-md-6'>
                                                   <DatePicker
                                                       selected={dateFrom}
                                                       dateFormat='MM-yyyy'
                                                       className="form-control"
                                                       onChange={(date) => setDateFrom(date)}
                                                       showMonthYearPicker
                                                       showFullMonthYearPicker
                                                   />
                                               </div>
                                           </div>
                                       </div>
                                       <div className="col-12 col-md-5">
                                           <div className="float-right">
                                               {
                                                   vesselId != 0 && vesselId != '' ? (
                                                       <>
                                                           <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                           <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                           <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>
                                                       </>
                                                   ) : ''

                                               }
                                               <Link to={COMPANYROUTE.PORTAGE_BILL} className='btn btn-sm  btn-info'> Portage Bill</Link>

                                           </div>
                                       </div>


                                   </div>
                               </div>
                               <div className="card-body ">

                                   <div className="justify-content-center d-flex">
                                       <div className="d-flex">
                                           <div className="logo">
                                               <Logo />
                                           </div>
                                           <div className="header text">
                                               <h5 className="text-center">{reportData?.headerData?.company_name}</h5>
                                               <h6 className="text-center">Salary Sheet </h6>
                                               <h6 className="text-center">Date: {reportData?.headerData?.report_date}</h6>
                                               <h6 className="text-center">{reportData?.headerData?.vessel_name}</h6>
                                           </div>
                                       </div>

                                   </div>

                                   <table id="" className="table table-bordered table-hover seafarer-list">
                                       <thead>
                                       <tr>
                                           <th>SL</th>
                                           <th>Employee</th>
                                           <th>CDC Number</th>
                                           <th>Rank</th>
                                           <th>Account No</th>
                                           <th className="text-center" colSpan={2}>Salary Information</th>
                                           <th className="text-center"  colSpan={3}>Duration</th>
                                           <th>Current Salary</th>
                                           <th>Total Earning</th>
                                           <th>Total Deduction</th>
                                           <th>Amount In Dollar</th>
                                           <th>Conversion Rate</th>
                                           <th>Amount In Taka</th>
                                       </tr>
                                       <tr>
                                           <th colSpan={5}></th>
                                           <th>Basic</th>
                                           <th>Gross</th>
                                           <th>From</th>
                                           <th>Upto</th>
                                           <th>Working Days</th>
                                           <th colSpan={7}></th>
                                       </tr>
                                       </thead>
                                       <tbody>

                                       {
                                            reportData?.data?.length > 0 &&  reportData?.data?.map((data,index) => {

                                                amountTaka  = parseFloat(amountTaka) +  parseFloat(data?.amount_taka);
                                                amountDollar = parseFloat(amountDollar) + parseFloat(data?.amount_dollar);
                                                conversionRate  = parseFloat(conversionRate) + parseFloat(data?.conversion_rate);
                                                basicSalary = parseFloat(basicSalary) + parseFloat(data?.basic_salary);
                                                grossTotal  = parseFloat(grossTotal) + parseFloat(data?.gross);
                                                currentSalary  = parseFloat(currentSalary) + parseFloat(data?.current_salary);
                                                workingDays  = parseInt(workingDays) + parseInt(data?.working_days);
                                                count = count + 1;

                                                return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data?.name}</td>
                                                    <td>{data?.cdc_no}</td>
                                                    <td>{data?.rank}</td>
                                                    <td>{data?.bank_account}</td>
                                                    <td>{data?.basic_salary}</td>
                                                    <td>{data?.gross}</td>
                                                    <td>{data?.from}</td>
                                                    <td>{data?.up_to}</td>
                                                    <td>{data?.working_days}</td>
                                                    <td>{data?.current_salary}</td>
                                                    <td>{data?.earnings}</td>
                                                    <td>{data?.deductions}</td>
                                                    <td>{data?.amount_dollar}</td>
                                                    <td>{data?.conversion_rate}</td>
                                                    <td>{data?.amount_taka}</td>

                                                </tr>

                                            )})

                                       }

                                       <tr>
                                           <td colSpan={5} className="text-center">Total</td>
                                           <td>{basicSalary.toFixed(2)}</td>
                                           <td>{grossTotal.toFixed(2)}</td>
                                           <td></td>
                                           <td></td>
                                           <td>{workingDays}</td>
                                           <td>{currentSalary.toFixed(2)}</td>
                                           <td></td>
                                           <td></td>
                                           <td>{amountDollar.toFixed(2)}</td>
                                           <td>{conversionRate.toFixed(2) / count}</td>
                                           <td>{amountTaka.toFixed(2)}</td>
                                       </tr>


                                       </tbody>

                                   </table>

                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
       </>
    );
};

export default TopFourPortageBill;