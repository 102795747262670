export const DASHBOARD = '/seafarer/dashboard';
export const PROFILE = '/seafarer/profile';


export const USER_PROFILE = '/seafarer/profile'

export const UPLOAD_PERFORMANCE = '/seafarer/upload-performance'
export const UPDATE_READINESS = '/seafarer/update-readiness'

export const UPDATE_PERSONAL_INFO = '/seafarer/profile/personal-info'

export const USER_PASSPORT = '/seafarer/passport';
export const USER_PASSPORT_EDIT = '/seafarer/passport-edit/';
export const USER_VISA = '/seafarer/visa/';
export const USER_VISA_EDIT = '/seafarer/visa-edit/';

export const ADD_NEW_EDUCATION = '/seafarer/education-add'
export const EDUCATION_EDIT = '/seafarer/education-edit/';

export const ADD_NEW_CERTIFICATION = '/seafarer/certification-add'
export const CERTIFICATION_EDIT = '/seafarer/certification-edit/';
export const ADD_NEW_EXPERIENCE = '/seafarer/experience-add/'
export const EXPERIENCE_EDIT = '/seafarer/experience-edit/';

export const ADD_NEW_FAMILY = '/seafarer/family-add/'
export const FAMILY_EDIT = '/seafarer/family-edit/';

export const MEDICAL_INFORMATION = '/seafarer/medical-information'
export const TRAINING_INFORMATION = '/seafarer/training-information'
export const OTHERS_INFORMATION = '/seafarer/others-information'
export const BANK_INFORMATION = '/seafarer/bank-information'

export const SEAFARER_DOCUMENTS = '/seafarer/document'
export const ADD_NEW_SEAFARER_DOCUMENTS = '/seafarer/document/add'
export const EDIT_SEAFARER_DOCUMENTS = '/seafarer/document/edit/'

export const PRE_JOINING_FAMILIARISATION = '/seafarer/pre-joining-familiarization'


export const CHAT = '/seafarer/chat';
export const INBOX = '/seafarer/inbox/';
export const NEW_INBOX = '/seafarer/new-conversation/';
