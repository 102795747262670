import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

const Edit = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [data,setData] = useState([]);
    const [disabled,setDisabled] = useState(false);
    const [module,setModule] = useState([]);

    const getData = () => {
        axiosClient.get('/company/company-permission/create')
            .then(({data}) => {
                setData(data)
            }).catch(() => {
        })
    }

    const getEditData = () => {
        axiosClient.get('/company/company-permission/'+id+'/edit')
            .then(({data}) => {
                let filterData = data?.data?.map((item) => item?.module_access === '1' && item?.module_id)
                filterData = filterData.filter((item) => item !== false)
                setModule(filterData)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getData()
        getEditData()
    },[])




    const handleSubmit = (e) => {
        e.preventDefault();
         setDisabled(true)
        axiosClient.put('/company/company-permission/'+id,{
            module_access:module
        })
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
               navigate(`${COMPANYROUTE.COMPANY_PERMISSION}`);
            }).catch(err => {
            setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }
    return (
        <div>
            <Breadcrumb title='Edit  Permission User' prev={COMPANYROUTE.COMPANY_PERMISSION}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className=" col-12">
                                            <div className="row">

                                                <div className="col-12 ">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Module Name</th>
                                                                <th>Permission</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            data?.modulesData?.length > 0 &&
                                                            data?.modulesData?.map((item,index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item?.name}</td>
                                                                    <td>
                                                                        <input className=" "
                                                                               onChange={e => {
                                                                                   const {checked} = e.target;
                                                                                   if (checked) {
                                                                                       setModule([...module,item?.id])
                                                                                   } else {
                                                                                       const newRankGroup = module.filter((r) => r !== item?.id);
                                                                                       setModule(newRankGroup)
                                                                                   }
                                                                               }}
                                                                               checked={module.includes(item?.id)}

                                                                               type="checkbox" value="" id={item?.id} />
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div>
                                        </div>


                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">{
                                            disabled ? 'Please Wait....' : 'Submit'
                                        }</button>
                                    </div>
                                </form>

                            </div>

                </div>
            </section>
        </div>

    );
};

export default Edit;