import axiosClient from "../../axios-client";

export const userSearch = async (value, setSearchExist, setSearchUserList,searchUrl) => {
    try {
        if (value === "" || value.length === 0) {
            setSearchExist(false);
        } else {
            setSearchExist(true);
        }

        const { data } = await axiosClient.post(searchUrl, {
            search_text: value,
        });
        setSearchUserList(data?.data);
    } catch (err) {
        console.log(err);
    }
};