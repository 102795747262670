import React from 'react';

const OtherInfoData = ({medicalData,surgeryData,medicalVaccinationData,othersTrainingData,othersData,bankData}) => {
    return (
        <div className="row mt-2">
            {
                medicalData && (
                    <div className="col-12">
                        <p>♦  Signed off from a vessel on Medical Ground details.</p>
                        {
                            medicalData?.status === "Yes" ? (
                                <table className="table table-bordered ">
                                    <thead>
                                    <tr>
                                        <th>Name of Vessel</th>
                                        <th>Date signed off</th>
                                        <th>Reason / Brief Description of Illness/Injury/ Accident</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{medicalData?.vessel}</td>
                                        <td>{medicalData?.signed_date === '0000-00-00' ? '' : medicalData?.signed_date}</td>
                                        <td>{medicalData?.reason}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            ) : medicalData?.status
                        }

                    </div>
                )
            }

            {
                surgeryData && (
                    <div className="col-12">
                        <p>♦  Last 12 months any surgery / Illness details.</p>
                        {
                            surgeryData?.status === "Yes" ? (
                                <table className="table table-bordered ">
                                    <thead>
                                    <tr>
                                        <th>Date of Illness / Surgery</th>
                                        <th>Details of Illness
                                            and Treatment received</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{surgeryData?.surgery_date === '0000-00-00' ? '' : surgeryData?.surgery_date}</td>
                                        <td>{surgeryData?.details}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            ) : surgeryData?.status
                        }

                    </div>
                )
            }

            {
                medicalVaccinationData && medicalVaccinationData.length > 0  ? (
                    <div className="col-12">
                        <p>♦    <p>&diams; Medical and vaccination details below.</p></p>

                        <table className="table table-bordered ">
                            <thead>
                            <tr>
                                <th>Medical and vaccination </th>
                                <th>Issue date</th>
                                <th>Expiry date</th>
                                <th>Issued at</th>
                            </tr>
                            </thead>
                            <tbody>

                            { medicalVaccinationData.map((data,index) => (
                                <tr key={index}>
                                    <td>{data?.title}</td>
                                    <td>{data?.issue_date === '0000-00-00' ? '' : data?.issue_date}</td>
                                    <td>{data?.expiry_date === '0000-00-00' ? '' : data?.expiry_date}</td>
                                    <td>{data?.issue_at}</td>
                                </tr>
                            ))}

                            </tbody>
                        </table>

                    </div>
                ) : ''
            }


            {
                othersTrainingData && (
                    <div className="col-12">
                        { othersTrainingData?.ecdis && <p>♦  Ecdis (Generic)- {othersTrainingData?.ecdis}</p> }
                        { othersTrainingData?.ecdis_type && <p>♦  Ecdis Type Specific - {othersTrainingData?.ecdis_type}</p> }

                        {
                            othersTrainingData?.ecdis_type === "Yes" ? (
                                <p>♦  Ecdis Details - {othersTrainingData?.ecdis_details}</p>
                            ) :''
                        }

                        { othersTrainingData?.bridge_status && <p>♦ Bridge Team Management - {othersTrainingData?.bridge_status}</p> }

                        {
                            othersTrainingData?.bridge_status === "Yes" ? (
                                <table className="table table-bordered ">
                                    <thead>
                                    <tr>
                                        <th>Issue Date</th>
                                        <th>Expiry Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{othersTrainingData?.bridge_issue === '0000-00-00' ? '' : othersTrainingData?.bridge_issue}</td>
                                        <td>{othersTrainingData?.bridge_expiry === '0000-00-00' ? '' : othersTrainingData?.bridge_expiry}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            ) : ''
                        }

                        { othersTrainingData?.bridge_resource && <p>♦ Bridge Resource Management  - {othersTrainingData?.bridge_resource}</p> }

                        {
                            othersTrainingData?.bridge_status === "Yes" ? (
                                <table className="table table-bordered ">
                                    <thead>
                                    <tr>
                                        <th>Issue Date</th>
                                        <th>Expiry Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{othersTrainingData?.bridge_resource_issue === '0000-00-00' ? '' : othersTrainingData?.bridge_resource_issue}</td>
                                        <td>{othersTrainingData?.bridge_resource_expiry === '0000-00-00' ? '' : othersTrainingData?.bridge_resource_expiry}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            ) : ''
                        }

                        { othersTrainingData?.ship_handling && <p>♦ Ship Handling Simulator Course - - {othersTrainingData?.ship_handling}</p> }

                        { othersTrainingData?.cargo_handling && <p>♦ Cargo Handling Simulator Course  - {othersTrainingData?.cargo_handling}</p> }

                    </div>
                )
            }

            {
                othersData && (
                    <div className="col-12">
                        { othersData?.court_offence && <p>♦ Have you ever been charged in court for any offence? -  {othersData?.court_offence}</p> }
                        { othersData?.maritime_incident && <p>♦ Have you been involved in any Maritime Incident in your career? -  {othersData?.maritime_incident}</p> }
                        { othersData?.psc && <p>♦ Port State Control Inspection (PSC) - {othersData?.psc}</p> }
                        { othersData?.psc_details && <p>{othersData?.psc_details.split(',').join(' ')}</p> }
                        { othersData?.fsi && <p>♦ Flag State Inspection (FSI) -  {othersData?.fsi}</p> }
                        { othersData?.fsi_details && <p>{ othersData?.fsi_details.split(',').join(' ') }</p> }
                        { othersData?.si && <p>♦  Sire Inspection (SI) -  {othersData?.si}</p> }
                        { othersData?.si_details && <p>{othersData?.si_details.split(',').join(' ')}</p> }
                        { othersData?.cdi && <p>♦  CDI Inspection -  {othersData?.cdi}</p> }
                        { othersData?.navigation && <p>♦  Navigation Experience -  {othersData?.navigation}</p> }
                        { othersData?.ne_details && <p>{othersData?.ne_details.split(',').join(' ')}</p> }
                        { othersData?.rank_exp && <p>♦  Rank Experience -  {othersData?.rank_exp}</p> }
                        { othersData?.re_details && <p>♦  Total / seatime bases rank experience (years/month) -  {othersData?.re_details}</p> }
                        { othersData?.sailing_record && <p>♦ Ship Type Sailing Record -  {othersData?.sailing_record}</p> }
                        { othersData?.stsr_details && <p>{othersData?.stsr_details.split(',').join(' ')}</p> }
                        { othersData?.gas_tanker && <p>♦ Experience in Inert Gas Tanker  -  {othersData?.gas_tanker}</p> }
                        { othersData?.uscg && <p>♦ Experience with USCG( TVEL/COC)  -  {othersData?.uscg}</p> }
                        { othersData?.sts && <p>♦ Experience on STS Operation  -  {othersData?.sts}</p> }
                        { othersData?.cargo_carried && <p>♦ Types of Cargo Carried  -  {othersData?.cargo_carried}</p> }
                        { othersData?.radio_qua && <p>♦ Radio Qualification -  {othersData?.radio_qua}</p> }
                        { othersData?.english_pro && <p>♦  English Proficiency Level -  {othersData?.english_pro}</p> }
                        { othersData?.marlin_result && <p>♦  Marlin Test Result- in percentage -  {othersData?.marlin_result}</p> }
                        { othersData?.last_company && <p>♦ Name of last Shipping Company Served -  {othersData?.last_company}</p> }
                        { othersData?.nationalities && <p>♦ Nationalities Sailed -  {othersData?.nationalities}</p> }


                    </div>
                )
            }


            {
                bankData ? (
                    <div className="col-12">
                        <p>Bank Information</p>
                        <table className="table table-bordered ">
                            <thead>
                            <tr>
                                <th>Bank Name</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>Routing/Swift Number</th>
                                <th>Address</th>
                                <th>Account Type</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{bankData?.bank_name}</td>
                                <td>{bankData?.account_name}</td>
                                <td>{bankData?.account_number}</td>
                                <td>{bankData?.routing_number}</td>
                                <td>{bankData?.address}</td>
                                <td>{bankData?.account_type}</td>
                            </tr>
                            </tbody>
                        </table>


                    </div>
                ) : ''
            }

        </div>

    );
};

export default OtherInfoData;