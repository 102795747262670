import React, {useEffect, useRef, useState} from 'react';
import uploadImage from "./image/ico_picture.png";
import uploadFileImage from "./image/ico_file.png";


const MessageForm = ({
                         onSubmit,
                         messageText,
                         setMessageText,
                         setFiles,
                         setImage,
                         fileInputRef,
                         imageInputRef,
                         fileSelected,
                         setFileSelected,
                         imageSelected,
                         setImageSelected,
                         disabled,
                         setDisabled
}) => {
    const [imagePreview,setImagePreview] = useState(null)
    const [fileSelectPreview,setFileSelectedPreview] = useState(null)
    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFileSelected(true);
            setMessageText("");
            setFiles(e.target.files[0]);
            setFileSelectedPreview(e.target.files[0]?.name)
            setDisabled(false)
        } else {
            setFileSelected(false);
        }
    };


    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            setImageSelected(true);
            setMessageText("");
            setImage(e.target.files[0]);
            setImagePreview(window.URL.createObjectURL(e.target.files[0]))
            setDisabled(false)
        } else {
            setImageSelected(false);
        }
    };

    const clearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Clear the file input
            setFileSelected(false);
            setFiles(null)
            setDisabled(true)
        }
    };

    const clearImageInput = () => {
        if (imageInputRef.current) {
            imageInputRef.current.value = null; // Clear the file input
            setImageSelected(false);
            setImage(null)
            setDisabled(true)
        }
    };


    useEffect(() => {
        if(messageText !== ""){
            setDisabled(false)
        }
    },[messageText])



    return (
        <>
            {imageSelected  && (
                <>
                    {
                        imagePreview && (
                            <img className="preview-image" src={imagePreview}   alt="" />
                        )
                    }
                    <button type="button" className="btn btn-sm btn-danger" onClick={clearImageInput}>
                        <i className="fa fa-times"></i>
                    </button>
                </>
            )}

            {fileSelected  && (
                <>
                    {
                        fileSelectPreview
                    }
                    <button type="button" className="btn btn-sm btn-danger" onClick={clearFileInput}>
                        <i className="fa fa-times"></i>
                    </button>
                </>
            )}
            <form onSubmit={onSubmit}>

                    <textarea
                        name="message"
                        value={messageText}
                        disabled={imageSelected || fileSelected}
                        onChange={(e) => setMessageText(e.target.value)}
                        placeholder="Type Message ..."
                        className="form-control"
                    />
                <input
                    type="file"
                    className="d-none"
                    id="uploadImage"
                    onChange={handleImageChange}
                    ref={imageInputRef}
                    accept="image/*"
                />

                <input
                    type="file"
                    className="d-none"
                    id="uploadFile"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept=".pdf"
                />

                {!imageSelected && !fileSelected   ?  (
                    <>
                        <label htmlFor="uploadImage"> <img src={uploadImage} alt="" /></label>
                    </>
                ) : ''}

                {!fileSelected && !imageSelected    ?   (
                    <>
                        <label htmlFor="uploadFile"> <img src={uploadFileImage} alt="" /></label>
                    </>
                ) : ''}
                 <button disabled={disabled} type="submit" className="btn btn-primary float-right">
                    <i className="fa fa-paper-plane"></i> Send
                </button>
            </form>
        </>

    )



};

export default MessageForm;