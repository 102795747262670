import React, {useState} from 'react';
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";

const CrewHireModal = ({data,hide}) => {

    const [action,setAction] = useState(null)
    const [disable,setDisable] = useState(false)


    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/hire-crew-status', {
            hire_id:  parseInt(data),
            hire_status: action,
        })
            .then(({data}) => {
                setDisable(false)
                toast.success(data?.message)
                hide(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }).catch(err => {
            console.log(err)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-md">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title">HIRE CREW </h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="">Choose Action</label>
                                    <select className="form-control" value={action} onChange={e => setAction(e.target.value)}>
                                        <option value="" disabled selected>Choose Action</option>
                                        <option value="1">Accept</option>
                                        <option value="0">Cancel</option>
                                    </select>
                                </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                            <button type="submit" className="btn btn-success">{
                                disable ? 'Please Wait...' : 'Submit'
                            }</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CrewHireModal;