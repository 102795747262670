import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import axiosClient from "../../../../axios-client";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const EarningDeductionCreate = () => {
    const navigate = useNavigate();
    const [vessels,setVessels] = useState([]);
    const [seafarer,setSeafarer] = useState([]);
    const [particular,setParticular] = useState([]);

    const [vesselSelect,setVesselSelect] = useState(0);
    const [seafarerSelect,setSeafarerSelect] = useState("");
    const [type, setType] = useState('');
    const [particularSelect,setParticularSelect] = useState('');
    const [amount,setAmount] = useState('');
    const [date,setDate] = useState('');
    const [remarks,setRemarks] = useState('');

    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessels(data.data)
            }).catch(() => {
        })
    }
    useEffect(() => {
        getVesselData()
    },[])

    const getSeafarerData = (vesselSelect = 0) => {
        axiosClient.post('/company/accounts/vessel-crew-lists',{
            vessel_id:vesselSelect
        })
            .then(({data}) => {
                setSeafarer(data.data)
            }).catch(() => {
        })
    }
    useEffect(() => {
        getSeafarerData(vesselSelect)
    },[vesselSelect])

    const getParticularData = (type = "") => {
        axiosClient.post('/company/accounts/particular-by-type',{
            particular_type:type
        })
            .then(({data}) => {
                setParticular(data.data)
            }).catch(() => {
        })
    }
    useEffect(() => {
        getParticularData(type)
    },[type])

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosClient.post('/company/accounts/particular-amount', {
            vessel_id: vesselSelect,
            emp_id: seafarerSelect,
            particular_id: particularSelect,
            particular_type: type,
            amount: amount,
            date: date,
            remarks: remarks,
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.PARTICULARS_TRANSACTION}`);
                },1000);

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    return (
        <div>
            <Breadcrumb title='Create Earning and Deduction' prev={COMPANYROUTE.PARTICULARS_TRANSACTION}    />
            <section className="content">
                <div className="container-fluid">

                    <div className="card card-primary">

                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="first_name">Choose Vessel </label>
                                            <select style={{maxWidth: '100%'}}
                                                    onChange={e => setVesselSelect(e.target.value)}
                                                    value={vesselSelect}
                                                    className="form-control"
                                            >
                                                <option  value="0" selected>All Vessel</option>
                                                {vessels.map((vessel,index) => (
                                                    <option key={index} value={vessel?.vessel_id}>{vessel?.vessel_name}</option>
                                                ))}

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="first_name">Choose Seafarer </label>
                                            <select name="" className="form-control" id=""
                                                    onChange={e => setSeafarerSelect(e.target.value)}
                                                    value={seafarerSelect}
                                            >
                                                <option value="">Choose</option>
                                                {
                                                    seafarer.length > 0 &&   seafarer?.map((sea,index) => (
                                                        <option key={index} value={sea?.emp_id}>{sea?.sur_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="first_name">Choose Particular Type </label>
                                            <select name=""
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}
                                                    className="form-control" id="">
                                                <option value="">Choose</option>
                                                <option value="earning">Earning</option>
                                                <option value="deduction">Deduction</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="first_name">Choose Particular  </label>
                                            <select name="" className="form-control"
                                                    onChange={e => setParticularSelect(e.target.value)}
                                                    value={particularSelect}
                                            >
                                                <option value="">Choose</option>
                                                {
                                                    particular.length > 0 &&   particular?.map((p,index) => (
                                                        <option key={index} value={p?.id}>{p?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="particular_type">Amount</label>
                                            <input
                                                onChange={e => setAmount(e.target.value)}
                                                value={amount}
                                                type="number" name="amount" className="form-control" id="particular_type"
                                                   placeholder="Enter Amount" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="particular_type">Date</label>
                                            <DatePicker
                                                selected={date ? new Date(new Date(date)) : ''}
                                                dateFormat='dd-MM-yyyy'
                                                className="form-control"
                                                onChange={e => setDate(e)}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="form-group">
                                            <label htmlFor="particular_type">Remarks</label>
                                            <input
                                                onChange={e => setRemarks(e.target.value)}
                                                value={remarks}
                                                className='form-control date_picker' placeholder="Remarks" type="text"/>
                                        </div>
                                    </div>


                                </div>


                            </div>

                            <div className="card-footer">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </div>
    );

};

export default EarningDeductionCreate;