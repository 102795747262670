import React, {useEffect, useState} from 'react';
import Logo from "../../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import './../styles/checklist.css'
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import {Link, useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../../hooks/usePrintWindow";
const CompanyPolicy = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [data, setData] = useState({})

    const navigate = useNavigate();

    const getData = () => {

        axiosClient.get('/company/terms-condition/show/'+id)
            .then(({data}) => {
                console.log(data)
                if(data  == 0){
                    toast.error('Terms and Condition not found')
                    navigate(-1)
                }
                setData(data.data)
                setProgress(100)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/terms-condition/print/' + id,
    });
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Terms Condition'   backButton={true}    />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-10 offset-md-1">
                                    <div className="checklist-panel">


                                        <div className="justify-content-center d-flex mb-2">
                                    <div className="d-flex">
                                        <div className="logo">
                                            <Logo />
                                        </div>
                                        <div className="header-text">
                                            <h5 className="text-center company-name">{data?.company_name}</h5>
                                            <h6 className="text-center title">TERMS AND CONDITIONS OF SERVICE</h6>

                                        </div>
                                    </div>
                                </div>
                                        <div className="content-body">
                                            <p >This agreement is made on the {data?.day}  of {data?.date}   between The <strong>{data?.company_name} , Chittagong, Bangladesh</strong> hereinafter called the company or manager and
                                                Mr.{data?.name}    Rank {data?.rank}  holder of C.D.C. No. {data?.cdc}  hereinafter called the seafarer.</p>

                                            <ul>
                                                <li>
                                                    1.    These Terms and Conditions of Service are applicable to all Seafarers other then RATING
                                                    of BRSML vessels.
                                                </li>
                                                <li>2. The vessel Joining {data?.vessel},      Flag- <strong>BANGLADESH</strong>  .</li>
                                                <li>3. The service is transferable to any other Vessel of the company at the discretion of the management. </li>
                                                <li>4. The company shall pay monthly salary to the officer for his service as per agreed SEAFARER EMPLOYMENT AGREEMENT   (SEA).  </li>
                                                <li>5. Seafarer Employment Agreement termination notice both for the seafarer and for the ship owner,
                                                    is 30 days, except by mutual agreement,  if otherwise.  </li>
                                                <li>6. The part or full wages is paid either in equivalent Bangladesh Taka or in foreign currency (USD) at the convenience of the Company.</li>
                                                <li> 7. The period of this agreement is  {data?.agreement_month} months commencing from the date of officer’s signing on the Article of Agreement or the day of joining vessel at the port of engagement whichever is later. During the first three months of the above period, the officer shall be on probation and during the probation period if for any reason, company is not satisfied with the officer’s services, the company may terminate the agreement without any prior notice to the officer.    </li>
                                                <li> 8.  In the event of the officer leaving the employment on less than 1 (one) month’s notice. The company shall recover from the officer’s salary for the remaining period of notice (from the date of leaving until the date of expiry of the notice period) the officer’s repatriation expenses plus the cost of placing reliever on board the same vessel. The company also reserves the right to recover all costs and/or loss incurred by the office which would otherwise not have arisen due to non-fulfillment of the contract.     </li>
                                                <li> 9. All matters relating to personal taxes and obtaining the requisite tax clearance, if applicable, shall be the responsibility of the Seafarers.     </li>
                                                <li> 10. The normal work on board be eight (8) hours per day for watch keeping Officer. All overtime payment for Officers is included in consolidated fixed overtime compensation shown in SEA.     </li>
                                                <li> 11. The officer hereby unconditionally indemnifies the company from any maritime lien or industrial action in respect of wages and allowances, so long as these have been paid to the officer by the company in accordance with terms set out in this agreement and or in DMLC Part II.     </li>
                                                <li> 12. The officer shall be required to work during such hours and at such times as may from time to time be ordered by the Master/superior officer of the vessel and/or the company without any additional payment.      </li>
                                                <li> 13.The officer shall not drink or use any alcohol/drug on board the vessel nor will board the vessel in such state. The officer shall conduct himself on board in a most sober manner at all the time. The company expects the officer to wear prescribed uniform while on board the vessel.      </li>
                                                <li> 14. At the time of signing this agreement, the officer declares that he is in every respect fit for duty and has no past symptom of tuberculosis, asthma, heart ailments of any kind, gall bladder and kidney ailments of any kind, tumor, cancer and cancerous growth, AIDS and other rare diseases. Should at a later date the misrepresentation of the above declaration be found, the company may instantly terminate the agreement in respect of his employment.      </li>
                                                <li> 15.  The officer shall be subject to instant dismissal on disciplinary ground without further liability on company’s part if found guilty of misconduct, drunkenness, negligence, noncompliance, incompetence as judged by the officer’s superiors and management, misappropriating company’s funds which
                                                    include acceptance of unaccounted commission/gratitude/cash compensation from any ship suppliers/ship chandlers/ship repairers/ship yards/charterer etc. smuggling, assaults, willful damage to the ship or any property on board, theft or possession of stolen property, possession of offensive  weapons, persistent of willful failure to perform duty, unlawful possession of drugs, combination with others at sea to impede the progress of the voyage of navigation of the ship, disobedience of orders relating to safety of the ship or any person on board, to be asleep on duty or fail to remain on duty, to smoke or use naked light or an unapproved electric torch in any part of ship carrying dangerous cargo or stores where smoking or the use of naked lights or unapproved torches is prohibited, intimidation, coercion and interference with the work of others on board, permitting unauthorized persons on board the ship any time etc. The Company’s decision in this respect shall be final and binding. The company shall recover from the Officer all expenses or losses caused to the company by the officer.    </li>
                                                <li> 16. The company will provide the officer with medical treatment (V.D./AIDS excluded) including dental treatment for extraction only, so long his employment with the company under the terms of this agreement remains valid. In case of illness/accident requiring medical attention, the company will be responsible for the cost of medical treatment (including hospitalization) as long as such medical attention is required not exceeding THREE months.     </li>
                                                <li> 17. During continued entitlement to such medical treatment shall be according to the final  approval  of company Doctor.       </li>
                                                <li> 18.In case of death or permanent disability to the employee due to accident occurred during his services on board, the owners agree to pay him by way of compensation the sum or sums which in any event shall not exceed USD 50,000 ( Fifty Thousand)  or as per Bangladesh  shipping  office article of agreement whichever is higher.      </li>
                                                <li> 19. For foreign National (other than Bangladesh National) the death and / or permanent disability  compensation will be USD 50,000 (Fifty Thousand) as full and final amount     </li>
                                                <li> 20.  The Company shall assign a Seafarer to a war zone or war risk area on a mutual agreement /Company Policy basis.     </li>
                                                <li> 21. Company complied with financial security for repatriation (Regulation 2.5) and ship owners’ liability
                                                    & social security 4.2      </li>
                                                <li> 22. The officer declares the following:     </li>
                                                <div className="d-flex   mb-3">
                                                    <div className="col-md-6 col-12">
                                                        Next of Kin : {data?.kin_name}
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        Relation: {data?.kin_relation}
                                                    </div>

                                                </div>
                                                <div className="d-flex  ">
                                                    <div className="col-md-6 col-12">
                                                        Phone:{data?.kin_telephone}
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        Address: {data?.kin_address}
                                                    </div>

                                                </div>
                                                <p  >I, the undersigned employee declare that I have read and fully understood the content of this Agreement.</p>

                                                <div className="d-flex">
                                                    <div className="col-md-6 col-12">
                                                        <p>For and on behalf of
                                                            <br />
                                                                <strong>{data?.company_name}</strong>	 </p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <p>   <strong>The Officer</strong>
                                                            <br/> Address & Mobile Number: </p>
                                                    </div>
                                                </div>

                                            </ul>



                                      </div>

                                 </div>


                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default CompanyPolicy;