import React, { useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import VisaAddForm from "../../../../Components/Profile/DocumentVisa/VisaAddForm";

const Visa = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disabled,setDisabled] = useState(false);

    const [documentForm,setDocumentForm] = useState({
        user_id:id,
        passport_type:"",
        visa_number:'',
        issue_date:'',
        expiry_date:'',
        issued_at:'',
        country_id:""
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);
        axiosClient.post('/company/visa-store', documentForm)
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.USER_PROFILE}${id}`, {state: {type: 'documentVisa'}});
                },1000);

            }).catch(err => {
            const response = err.response;
            setDisabled(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    return (
        <div>
            <Breadcrumb title='Add Visa' prev={`${COMPANYROUTE.USER_PROFILE}${id}`} state="documentVisa" />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-6 col-12">
                        <form  onSubmit={onSubmit}>
                            <VisaAddForm disabled={disabled} documentForm={documentForm} setDocumentForm={setDocumentForm} />
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Visa;