
const ExcelJS = require('exceljs');



   const UseExportHook = (fileExtension,data,title,file_name,columns,columns2) => {
       function setColumns(sheet, columns) {
           columns.forEach((column, columnIndex) => {
               sheet.getColumn(columnIndex + 1).key = column.key;
               sheet.getColumn(columnIndex + 1).header = column.header;
               sheet.getColumn(columnIndex + 1).width = column.width;
           });
       }

       function populateData(sheet, data, columns) {
           data?.forEach((item, index) => {
               const row = {};
               columns.forEach((column) => {
                   if (column.key === 'sl') {
                       row[column.key] = index + 1;
                   } else {
                       row[column.key] = item[column.key];
                   }
               });
               sheet.addRow(row);
           });
       }
       const sheetTitles = ['Termination', 'Turnover'];


       if(fileExtension === 'xlsx') {

           const workBook = new ExcelJS.Workbook();

           sheetTitles.forEach((t) => {
               const sheet = workBook.addWorksheet(t);
               sheet.properties.defaultRowHeight = 20;
               sheet.getRow(1).font = {
                   family: 2,
                   size: 10,
                   bold: true,
               };
               if (t === 'Termination') {
                   setColumns(sheet, columns);
                   populateData(sheet, data?.termination, columns);
               } else if (t === 'Turnover') {
                   setColumns(sheet, columns2);
                   populateData(sheet, data?.turnover, columns2);
               }
           });

           const writeBufferPromise =  workBook.xlsx.writeBuffer();

           writeBufferPromise.then((data) => {
               const blob = new Blob([data], {
                   type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet'
               });
               const url = window.URL.createObjectURL(blob);
               const anchor = document.createElement('a');
               anchor.href = url;
               anchor.download = `${file_name}..${fileExtension}`;
               anchor.click();
               window.URL.revokeObjectURL(url);
           });
       }else if(fileExtension === 'csv'){
           sheetTitles.forEach((t) => {
               const workBook = new ExcelJS.Workbook();
               const sheet = workBook.addWorksheet(t);
               sheet.properties.defaultRowHeight = 20;
               sheet.getRow(1).font = {
                   family: 2,
                   size: 10,
                   bold: true,
               };

               if (t === 'Termination') {
                   setColumns(sheet, columns);
                   populateData(sheet, data?.termination, columns);
               } else if (t === 'Turnover') {
                   setColumns(sheet, columns2);
                   populateData(sheet, data?.turnover, columns2);
               }

               const writeBufferPromise =  workBook.csv.writeBuffer();

               writeBufferPromise.then((data) => {
                   const blob = new Blob([data], {
                       type: 'text/csv', // Specify the file format as text/csv
                   });
                   const url = window.URL.createObjectURL(blob);
                   const anchor = document.createElement('a');
                   anchor.href = url;
                   anchor.download = `${file_name}_${t}.${fileExtension}`;
                   anchor.click();
                   window.URL.revokeObjectURL(url);
               });
           });
       }
    };

export default UseExportHook;