import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import Logo from "../../../Components/Common/logo";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UsePortageBillExportHook from "../../../hooks/UsePortageBillExportHook";

const BasicWages = () => {
    var date = new Date();
    const [reportData,setReportData] = useState([]);

    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(),1));


    const [vessel,setVessel] = useState([]);
    const [vesselId,setVesselId] = useState(1);

    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessel(data.data)
                setVesselId(data?.data[0]?.vessel_id)

            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    useEffect(() => {
        getVesselData();
    },[])

    const getData = (vesselId = 0, dateFrom = '') => {
        axiosClient.post('/company/accounts/balance-wages',{
            vessel_id: vesselId,
            date_from: dateFrom
        })
            .then(({data}) => {
                setReportData(data)

            }).catch(() => {

        })
    }


    useEffect(() => {
        let dateFromData = dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1);
        getData(vesselId,dateFromData);
    },[vesselId,dateFrom])


    const { handlePrint, loading } = usePrintWindow({
        url: '/company/accounts/balance-wages/print',
        payload: { vessel_id: vesselId,  date_from: new Date(dateFrom).toLocaleDateString() },
    });



    const exportExcelFile = () => {
        axiosClient.post('/company/accounts/balance-wages/export',{
            vessel_id: vesselId, date_from: new Date(dateFrom).toLocaleDateString()
        })
            .then(({data}) => {
                console.log(data?.data2)
                UsePortageBillExportHook('xlxs', data,'Balance Wages','balance-wages')
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/accounts/balance-wages/export',{
            vessel_id: vesselId,  date_from: new Date(dateFrom).toLocaleDateString()
        })
            .then(({data}) => {
                UsePortageBillExportHook('csv', data,'Balance Wages','balance-wages')

            }).catch(() => {
        })
    };



    return (
        <>
            <Breadcrumb title='Vessel Balance Wages'      />

            <section className="content">
                <div className="container-fluid">
                     <div className="row">
                        <div className="">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-4'>
                                                    <div className="form-group">
                                                        <select style={{maxWidth: '100%'}}
                                                                className="form-control"
                                                                value={vesselId}
                                                                onChange={(e) => setVesselId(e.target.value)}
                                                        >
                                                            <option value={0}>Select Vessel</option>
                                                            {vessel.map((data,index) => (
                                                                <option key={index}    value={data?.vessel_id}>{data?.vessel_name}</option>
                                                            ))}

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateFrom}
                                                        dateFormat='MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateFrom(date)}
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            {
                                                vesselId != 0 && vesselId != ''  ?
                                                    <>
                                                        <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                        <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                        <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>
                                                    </> : ''
                                          }
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body ">

                                    <div className="justify-content-center d-flex">
                                        <div className="d-flex">
                                            <div className="logo">
                                                <Logo />
                                            </div>
                                            <div className="header text">
                                                <h5 className="text-center">{reportData?.headerData ? reportData?.headerData?.company_name : 'Brave Royal Ship Management Ltd.'}</h5>
                                                <h6 className="text-center">Balance Wages </h6>
                                                <h6 className="text-center">{reportData?.headerData ? 'Date:' : '' } {reportData?.headerData?.report_date}</h6>
                                                <h6 className="text-center">{reportData?.headerData?.vessel_name}</h6>
                                            </div>
                                        </div>

                                    </div>

                                    <table id="" className="table table-bordered table-hover seafarer-list">
                                        <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Employee</th>
                                            <th>Rank</th>
                                            <th>Account No</th>
                                            <th className="text-center" colSpan={2}>Salary Information</th>
                                            <th className="text-center"  colSpan={4}>Duration</th>
                                            <th>Current Salary</th>
                                            <th>On Board Promotion</th>
                                            <th className="text-center"  colSpan={reportData?.earningsData?.length + 1}>Earning</th>
                                            <th className="text-center"  colSpan={reportData?.deductionsData?.length + 1}>Deductions</th>
                                            <th>Amount In Dollar</th>
                                            <th>Conversion Rate</th>
                                            <th>Amount In Taka</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}></th>
                                            <th>Basic</th>
                                            <th>Gross</th>
                                            <th>From</th>
                                            <th>Upto</th>
                                            <th>Working Days</th>
                                            <th>Standby Days</th>
                                            <th></th>
                                            <th></th>
                                            {
                                                reportData?.earningsData?.map((data,index) => (
                                                    <th key={index}>{data?.name}</th>
                                                ))
                                            }
                                            <th>Total Earning</th>
                                            {
                                                reportData?.deductionsData?.map((data,index) => (
                                                    <th key={index}>{data?.name}</th>
                                                ))
                                            }
                                            <th>Total Deduction</th>
                                            <th colSpan={3}></th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData?.data?.length > 0 &&  reportData?.data?.map((data,index) => {


                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{data?.name}</td>
                                                        <td>{data?.rank}</td>
                                                        <td>{data?.bank_account}</td>
                                                        <td>{data?.basic_salary}</td>
                                                        <td>{data?.gross}</td>
                                                        <td>{data?.from}</td>
                                                        <td>{data?.up_to}</td>
                                                        <td>{data?.working_days}</td>
                                                        <td>{data?.arrival_days}</td>
                                                        <td>{data?.current_salary}</td>
                                                        <td>{data?.onboard_duration}</td>
                                                        {
                                                            data?.earnings?.map((d,index) => (
                                                                <td key={index}>{d}</td>
                                                            ))
                                                        }
                                                        {
                                                            data?.deduction?.map((d,index) => (
                                                                <td key={index}>{d}</td>
                                                            ))
                                                        }
                                                        <td>{data?.amount_dollar}</td>
                                                        <td>{data?.conversion_rate}</td>
                                                        <td>{data?.amount_taka}</td>

                                                    </tr>

                                                )})

                                        }

                                        <tr>
                                            <td colSpan={4}>Total</td>
                                            <td>{reportData?.totalCalculation?.basic_salary}</td>
                                            <td>{reportData?.totalCalculation?.gross}</td>
                                            <td colSpan={2}></td>
                                            <td>{reportData?.totalCalculation?.working_days}</td>
                                            <td>{reportData?.totalCalculation?.arrival_days}</td>
                                            <td>{reportData?.totalCalculation?.current_salary}</td>
                                            <td></td>
                                            {
                                                reportData?.totalEarnings?.map((data,e) => (
                                                    <td key={e}>{data}</td>
                                                ))
                                            }
                                            {
                                                reportData?.totalDeductions?.map((data,d) => (
                                                    <td key={d}>{data}</td>
                                                ))
                                            }
                                            <td>{reportData?.totalCalculation?.amount_dollar}</td>
                                            <td>{reportData?.totalCalculation?.conversion_rate}</td>
                                            <td>{reportData?.totalCalculation?.amount_taka}</td>

                                        </tr>

                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BasicWages;