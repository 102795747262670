import React, {useEffect, useState} from 'react';
import Logo from "../../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import './../styles/checklist.css'
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import {Link, useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../../hooks/usePrintWindow";
const CompanyPolicy = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [data, setData] = useState({})

    const navigate = useNavigate();

    const getData = () => {

        axiosClient.get('/company/pre-employment-medical-examination/show/'+id)
            .then(({data}) => {
                console.log(data)
                if(data  == 0){
                    toast.error('Pre Employee Medical not found')
                    navigate(-1)
                }
                setData(data.data)
                setProgress(100)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/pre-employment-medical-examination/print/' + id,
    });
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Pre Employment Medical'   backButton={true}    />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-10 offset-md-1">
                                    <div className="checklist-panel">


                                        <div className="justify-content-center d-flex mb-2">
                                    <div className="d-flex">
                                        <div className="logo">
                                            <Logo />
                                        </div>
                                        <div className="header-text">
                                            <h5 className="text-center company-name">{data?.company_name}</h5>
                                            <h6 className="text-center title">Pre Employment Medical</h6>

                                        </div>
                                    </div>
                                </div>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-md-8 col-12">
                                                    <table className="table table-bordered">
                                                          <tr>
                                                              <td colSpan='2'><strong>Name:</strong> {data?.name}</td>
                                                          </tr>
                                                        <tr>
                                                            <td colSpan='2'><strong>Address:</strong> {data?.address}</td>
                                                        </tr>
                                                        <tr>
                                                            <td ><strong> Date Of Birth:</strong> {data?.dob}</td>
                                                            <td><strong>  Blood Group: </strong> {data?.blood}</td>
                                                        </tr>
                                                        <tr>
                                                            <td ><strong> Place Of Birth:</strong>  {data?.dop}</td>
                                                            <td ><strong>  Name Of Ship: </strong> {data?.vesel_name}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className="col-md-4 col-12 justify-content-center">
                                                    <img src={data?.image_url} width="120" height="120" alt=""/>
                                                    <p>Signature</p>
                                                    <img src={data?.signature_url} width="120" height="60" alt=""/>
                                                     <p>Date: {data?.date}</p>
                                                </div>

                                                <div className="col-md-12 col-12">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                             <tr>
                                                                 <td>Examination</td>
                                                                 <td>Result Of Examination</td>
                                                                 <td>Examination</td>
                                                                 <td>Result Of Examination</td>

                                                             </tr>
                                                        </thead>
                                                        <tbody>
                                                           <tr>
                                                               <td><strong>1. Medical History Questionnaire </strong></td>
                                                               <td>{data?.medical_history_questionare}</td>
                                                               <td><strong>9.ECG</strong></td>
                                                               <td>{data?.ecg}</td>
                                                           </tr>
                                                           <tr>
                                                               <td><strong>2. Physical Examination </strong></td>
                                                               <td>{data?.physical_exam}</td>
                                                               <td><strong>10. Urine Analysis</strong></td>
                                                               <td>{data?.urine_analysis}</td>
                                                           </tr>
                                                           <tr>
                                                               <td><strong>3. Dental Examination</strong></td>
                                                               <td>{data?.dental_exam}</td>
                                                               <td><strong>11. Fecalysis  <br/> (Food Service/Handlers only)</strong></td>
                                                               <td>{data?.fecalysis}</td>
                                                           </tr>   <tr>
                                                               <td><strong>4. Psychological Test </strong></td>
                                                               <td>{data?.psychological_test}</td>
                                                               <td><strong>12. Complete Blood Count</strong></td>
                                                               <td>{data?.complete_blood_count}</td>
                                                           </tr>
                                                           <tr>
                                                               <td><strong>5. Visual Test (STCW Code, Section A-1/9) </strong></td>
                                                               <td>{data?.visual_test}</td>
                                                               <td><strong> 13. VDRL</strong></td>
                                                               <td>{data?.vdrl}</td>
                                                           </tr>
                                                           <tr>
                                                               <td><strong>6. Color Vision(STCW Code, Section A-1/9) </strong></td>
                                                               <td>{data?.color_vision}</td>
                                                               <td><strong> 14. Stress Test</strong></td>
                                                               <td>{data?.stress_test}</td>
                                                           </tr>

                                                           <tr>
                                                               <td><strong>7. Audiometry(STCW Code, Section A-1/9) </strong></td>
                                                               <td>{data?.audiometry}</td>
                                                               <td><strong> 15. Dibetes</strong></td>
                                                               <td>{data?.diabetes}</td>
                                                           </tr>
                                                           <tr>
                                                               <td><strong> 8. Chest X- ray </strong></td>
                                                               <td>{data?.cheast_xray}</td>
                                                               <td><strong> 16. Spirometry</strong></td>
                                                               <td>{data?.spirometry}</td>
                                                           </tr>

                                                        </tbody>
                                                    </table>
                                                    <table className="table table-bordered text-center">
                                                        <tr>
                                                            <td><strong>If failed in any above mentioned examination, please provide reasons with examination number:</strong> </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{data?.fail_exam_with_reason}</td>
                                                        </tr>
                                                    </table>
                                                    <table className="table table-bordered">
                                                        <tr>
                                                            <td><strong>Name of Medical Clinic:</strong></td>
                                                            <td>{data?.medical_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Address of Medical Clinic: </strong></td>
                                                            <td>{data?.medical_address}</td>

                                                        </tr>
                                                        <tr>
                                                            <td><strong>Contact Phone:</strong></td>
                                                            <td>{data?.medical_contact}</td>

                                                        </tr>
                                                        <tr>
                                                            <td><strong>Name and Degree of Physician:</strong></td>
                                                            <td>{data?.physician_degree}</td>

                                                        </tr>
                                                        <tr>
                                                            <td><strong>Date of Issue of Physician's License: </strong></td>
                                                            <td>{data?.date_issue_physician_licensing}</td>

                                                        </tr>
                                                        <tr>
                                                            <td><strong>Date of Examination:</strong></td>
                                                            <td>{data?.exam_date}</td>

                                                        </tr>
                                                        <tr>
                                                            <td><strong>Physician's Comment: </strong></td>
                                                            <td>{data?.physician_comment}</td>

                                                        </tr>
                                                        <tr>
                                                            <td><strong>Remarks:</strong></td>
                                                            <td>{data?.remarks}</td>

                                                        </tr>

                                                    </table>
                                                    <hr />
                                                    <p className="text-center">This certificate is issued by our local management in compliance with the requirements of the STCW Convention, 1978, as amended and the Maritime Labour Convention,2006</p>

                                                </div>

                                            </div>


                                      </div>

                                 </div>


                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default CompanyPolicy;