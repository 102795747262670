import React from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb"; 
import useDatePicker from "../../../hooks/useDatePicker";
import useDatatable from "../../../hooks/useDatatable";

const PendingDocument = () => {
    useDatePicker();
    useDatatable();
    return (
        <>
            <Breadcrumb title='Pending Document'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">


                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Seafarer Name</th>
                                            <th>Rank</th>
                                            <th>Document Name</th>
                                            <th>Details </th>
                                            <th>Status </th>
                                            <th>Action </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        <tr>
                                            <td>1</td>
                                            <td>vessel</td>
                                            <td>vessel</td>
                                            <td>vessel</td>
                                            <td>vessel</td>
                                            <td>vessel</td>
                                            <td>vessel</td>
                                        </tr>

                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PendingDocument;