import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

const EditVessel = () => {
    const {id} = useParams();
    const [vesselType,setVesselType] = useState([]);
    const [veselImage,setVesselImage] = useState(null);
    const navigate = useNavigate();

    const [form,setForm] = useState({
        vessel_id: id,
        imo_number: '',
        vessel_name: '',
        vessel_type_id: '',
        dwt_number: '',
        grt_number: '',
        engine_type: '',
        bhp_number: '',
        flag_state: '',
        capacity: '',
        vessel_details: '',
        vessel_image: null,
        old_image: null,
    });


    const getVesselTypes = () => {
        axiosClient.get('/company/get-vessel-types')
            .then(({data}) => {
                setVesselType(data?.data)
            }).catch(() => {
        })
    }

    const getData = (d) => {
        axiosClient.get('/company/vessel/'+id+'/edit')
            .then(({data}) => {
                setForm({
                    vessel_id: data?.data?.vessel_id,
                    imo_number: data?.data?.imo_number,
                    vessel_name: data?.data?.vessel_name,
                    vessel_type_id: data?.data?.vessel_type_id,
                    dwt_number: data?.data?.dwt_number,
                    grt_number: data?.data?.grt_number,
                    engine_type: data?.data?.engine_type,
                    bhp_number: data?.data?.bhp_number,
                    flag_state: data?.data?.flag_state,
                    capacity: data?.data?.capacity,
                    vessel_details: data?.data?.vessel_details,
                    vessel_image: null,
                    old_image: data?.data?.vessel_image,
                })
                setVesselImage(data?.data?.image)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getData();
        getVesselTypes()
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosClient.post('/company/vessel/update', form , {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.VESSEL}`);
                },1000);

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }
    return (
        <div>
            <Breadcrumb title='Edit Vessel' prev={COMPANYROUTE.VESSEL}    />
            <section className="content">
                <div className="container-fluid">

                    <div className="card card-primary">

                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="vessel_type">Vessel Type Name </label>
                                            <select className="form-control"
                                                    onChange={e => setForm({...form,vessel_type_id:e.target.value})}
                                                    value={form.vessel_type_id}
                                                    name="vessel_type" id="vessel_type">
                                                <option value="">Chose Vessel</option>
                                                {
                                                    vesselType?.length > 0  ? vesselType?.map((item,index) => {
                                                        return(
                                                            <option key={index+1} value={item.vessel_type_id}>{item.vessel_type_name}</option>
                                                        )
                                                    }) : null
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="last_name">Vessel Name</label>
                                            <input
                                                onChange={e => setForm({...form,vessel_name:e.target.value})}
                                                value={form.vessel_name}
                                                type="text" name="vessel_name" className="form-control" id="vessel_name"
                                                placeholder="Enter Vessel Name" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="imo_number">IMO Number</label>
                                            <input
                                                onChange={e => setForm({...form,imo_number:e.target.value})}
                                                value={form.imo_number}
                                                type="text" name="imo_number" className="form-control" id="email"
                                                placeholder="Enter IMO Number" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="dwt">DWT</label>
                                            <input
                                                onChange={e => setForm({...form,dwt_number:e.target.value})}
                                                value={form.dwt_number}
                                                type="text" name="dwt" className="form-control" id="dwt"
                                                placeholder="Enter DWT" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="grt">GRT</label>
                                            <input
                                                onChange={e => setForm({...form,grt_number:e.target.value})}
                                                value={form.grt_number}
                                                type="text" name="grt" className="form-control" id="grt"
                                                placeholder="Enter GRT" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="engine_type">Engine Type</label>
                                            <input
                                                onChange={e => setForm({...form,engine_type:e.target.value})}
                                                value={form.engine_type}
                                                type="text" name="engine_type" className="form-control" id="engine_type"
                                                placeholder="Enter Engine Type" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="bhp">BHP</label>
                                            <input
                                                onChange={e => setForm({...form,bhp_number:e.target.value})}
                                                value={form.bhp_number}
                                                type="text" name="bhp" className="form-control" id="bhp"
                                                placeholder="Enter BHP" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="flag_state">Flag State Of Ship</label>
                                            <input
                                                onChange={e => setForm({...form,flag_state:e.target.value})}
                                                value={form.flag_state}
                                                type="text" name="flag_state" className="form-control" id="flag_state"
                                                placeholder="Enter Flag State" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="lifeboat">Lifeboat Capacity</label>
                                            <input
                                                onChange={e => setForm({...form,capacity:e.target.value})}
                                                value={form.capacity}
                                                type="text" name="lifeboat" className="form-control" id="lifeboat"
                                                placeholder="Enter Lifeboat Capacity" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="image">Vessel Image</label>
                                            <input type="file" name="image" onChange={e => setForm({...form,vessel_image:e.target.files[0]})}  className="form-control" id="image"  />
                                        </div>
                                    </div>
                                    {
                                        veselImage ? (
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <img width="200px" height="200px" src={veselImage} alt=""/>
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="image">Vessel Details</label>
                                            <textarea className="form-control"
                                                      onChange={e => setForm({...form,vessel_details:e.target.value})}
                                                      value={form.vessel_details} name="" id="" cols="30" rows="3"></textarea>
                                        </div>
                                    </div>

                                </div>


                            </div>

                            <div className="card-footer">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </div>

    );
};

export default EditVessel;