import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";

am4core.useTheme(am4themes_animated);
const SeafarerTurnOverChart = () => {
    const [turnOverData,setTurnOverData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));

    const getTurnOverData = (dateFromData = null,dateToData = null) => {
        axiosClient.post('/company/chart/turn-over',{
            date_from: dateFromData,
            date_to: dateToData
        })
            .then(({data}) => {
                setTurnOverData(data.data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getTurnOverData(dateFromData,dateToData)
    },[dateFrom,dateTo])

    useLayoutEffect(() => {
        let x = am4core.create("SeafarerTurnOverChartDiv", am4charts.PieChart);
        x.innerRadius = am4core.percent(40);
        x.logo.disabled = true;


        x.data = [{
            "title": "Retirements",
            "value": turnOverData[0],
            "color": am4core.color("#00a65a")
        },{
            "title": "Left After Few Contracts(Better Opportunities)",
            "value": turnOverData[1],
            "color": am4core.color("#17a2b8")
        },{
            "title": "Illness",
            "value": turnOverData[2],
            "color": am4core.color("#ffc107")
        },{
            "title": "Under Performance",
            "value": turnOverData[3],
            "color": am4core.color("#dd4b39")
        }, {
            "title": "Others",
            "value": turnOverData[4],
            "color": am4core.color("#3c8dbc")
        },
        ];



        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "title";
        pieSeries.slices.template.propertyFields.fill = "color";

        pieSeries.slices.template.tooltipText = "{category}:{value}";

        //pie series label disable if value is 0
        pieSeries.labels.template.adapter.add("text", function(text, target) {
            return target.dataItem.value > 0 ? text : "";
        });

        let label = pieSeries.labels.template;
        label.text = "{category}:{value}";

        x.legend = new am4charts.Legend();
        pieSeries.legendSettings.valueText = " ";
        x.legend.itemContainers.template.maxWidth = 120;




        return () => {
            x.dispose();
        };
    }, [turnOverData]);

    return (
        <>
            <div className="card card-secondary">
                <div className="card-header">
                    <h3 className="card-title">TurnOver </h3>

                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus"></i>
                        </button>

                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 offset-md-3 mt-1 ">
                            <DatePicker
                                selected={dateFrom}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={(date) => setDateFrom(date)}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                        <div className="col-md-3 mt-1">
                            <DatePicker
                                selected={dateTo}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={(date) => setDateTo(date)}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                    </div>
                    <div className="chart">
                        <div id="SeafarerTurnOverChartDiv" style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default SeafarerTurnOverChart;

