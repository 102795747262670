import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import {useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';

const AddToVessel = () => {
     const {id} = useParams();
    const [progress, setProgress] = useState(10);
     const navigate = useNavigate()
    const [illnessCheck,setIllnessCheck] = useState(false);
    const [ranks,setRanks] = useState([]);
    const [vessel,setVessel] = useState([]);
    const [country,setCountry] = useState([]);
    const [port,setPort] = useState([]);
    const [form,setForm] = useState({
        emp_id:id,
        country_id:'',
        category_id:'',
        vessel_id:'',
        port_id:'',
        promotion:'',
        signing:'',
        embarkation:'',
        joining_date:'',
        expiry_date:'',
        contract_month:'',
        contract_plus:'',
        contract_minus:'',
        basic_wages:'',
        leave_pay:'',
        overtime:'',
        tanker_allowance:'',
        other_allowance:'',
        medical_details:'',
    })

    const getRankData = () => {
        axiosClient.get('/company/get-ranks')
            .then(({data}) => {
                setProgress(30)
                setRanks(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const getCountryData = () => {
        axiosClient.get('/company/get-country')
            .then(({data}) => {
                setProgress(50)
                setCountry(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessel(data.data)
                setProgress(70)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const getPortData = () => {
        axiosClient.get('/company/get-port')
            .then(({data}) => {
                setProgress(100)
                setPort(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/seafarers/add-to-vessel',form)
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.SEAFARER}`);
                },1000);

            }).catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }


    useEffect(() => {
        getRankData()
        getCountryData()
        getVesselData()
        getPortData()
    },[])


    return (
        <div>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Seafarer Joining' prev={COMPANYROUTE.SEAFARER}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={onSubmit}>
                                    <div className="card-body">
                                        <fieldset>
                                            <legend>Joining Details:</legend>
                                        <div className="row">

                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="vessel">Choose Vessel </label>
                                                    <select style={{maxWidth: '100%'}}
                                                            onChange={e => setForm({...form,vessel_id:e.target.value})}
                                                            className="form-control"
                                                    >
                                                        <option  value="" selected>Select Vessel</option>
                                                        {vessel.map((data,index) => (
                                                            <option key={index}    value={data?.vessel_id}>{data?.vessel_name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Choose Rank </label>
                                                    <select style={{maxWidth: '100%'}}
                                                            onChange={e => setForm({...form,category_id:e.target.value})}
                                                            className="form-control"
                                                    >
                                                        <option  value="" selected>Select Rank</option>
                                                        {ranks.map((data,index) => (
                                                            <option key={index}    value={data?.category_id}>{data?.category_name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="mother_name">Select Country</label>
                                                        <select style={{maxWidth: '100%'}}
                                                                onChange={e => setForm({...form,country_id:e.target.value})}
                                                                value={form?.country_id}
                                                                className="form-control"
                                                        >
                                                            <option  value="" selected>Select Country</option>
                                                            {country.map((item,index) => (
                                                                <option key={index}   value={item?.country_id}>{item?.country_name}</option>
                                                            ))}

                                                        </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Choose Port </label>
                                                    <select style={{maxWidth: '100%'}}
                                                            onChange={e => setForm({...form,port_id:e.target.value})}
                                                            value={form?.port_id}
                                                            className="form-control"
                                                    >
                                                        <option  value="" selected>Select Port</option>
                                                        {port.map((item,index) => (
                                                            <option key={index}   value={item?.id}>{item?.name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Choose Promotion </label>
                                                    <select className='form-control'
                                                            onChange={e => setForm({...form,promotion:e.target.value})}
                                                    >
                                                        <option value="">Select Promotion</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Contract Signing Date</label>
                                                    <DatePicker
                                                        selected={form?.signing ? new Date(new Date(form.signing)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,signing:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Departure Date</label>
                                                    <DatePicker
                                                        selected={form?.embarkation ? new Date(new Date(form.embarkation)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,embarkation:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Joining  Date</label>
                                                    <DatePicker
                                                        selected={form?.joining_date ? new Date(new Date(form.joining_date)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,joining_date:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Expiry Date (Contract Duration Due Date)</label>
                                                    <DatePicker
                                                        selected={form?.expiry_date ? new Date(new Date(form.expiry_date)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,expiry_date:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Contract Month</label>
                                                    <input onChange={e => setForm({...form,contract_month:e.target.value})} className='form-control' placeholder="Enter Contract Month" type="text"/>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Contract (+)</label>
                                                    <input className='form-control' onChange={e => setForm({...form,contract_plus:e.target.value})} placeholder="Enter Contract Month (+)" type="text"/>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Contract Month (-)</label>
                                                    <input className='form-control' onChange={e => setForm({...form,contract_minus:e.target.value})} placeholder="Enter Contract Month (-)" type="text"/>
                                                </div>
                                            </div>

                                        </div>
                                        </fieldset>

                                        <fieldset>
                                            <legend>Payment Details:</legend>
                                            <div className="row">

                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Basic Wages</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,basic_wages:e.target.value})} placeholder="Enter Basic Wages" type="number"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Leave Pay</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,leave_pay:e.target.value})} placeholder="Enter Leave Pay" type="number"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Fixed Overtime</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,overtime:e.target.value})} placeholder="Enter Fixed Overtime" type="number"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Tanker Allowance</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,tanker_allowance:e.target.value})} placeholder="Enter Tanker Allowance" type="number"/>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Other Allowance</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,other_allowance:e.target.value})} placeholder="Enter Other Allowance" type="number"/>
                                                    </div>
                                                </div>
                                                <div className=" col-12">
                                                    <div className="form-group">
                                                        <p>
                                                            <input type="checkbox" onChange={(e) => setIllnessCheck(e.target.checked) } name="illness" className="filled-in"
                                                                   id="illnessCheckbox" />
                                                                <label className="text-black ml-2" htmlFor="illnessCheckbox"> Any Illness During Leave</label>
                                                        </p>


                                                        {
                                                            illnessCheck ? (
                                                                <div id="illnessDiv"  >
                                                                    <label className="text-black" htmlFor="medical_details">Medical
                                                                        History Details</label>

                                                                    <textarea name="medical_details" onChange={e => setForm({...form,medical_details:e.target.value})} className="form-control" id="medical_details"
                                                                              placeholder="Enter details"></textarea>
                                                                </div>
                                                            ) : ''
                                                        }

                                                    </div>
                                                </div>


                                            </div>
                                        </fieldset>




                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>

                </div>
            </section>
        </div>
    );
};

export default AddToVessel;