import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

const Create = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [form,setForm] = useState({
        type: '',
        name: '',
        route: '',
        status: '',
    });

    const [disabled,setDisabled] = useState(false);

    const getData = () => {
        axiosClient.get('/company/company-module/' + id + '/edit')
            .then(({data}) => {
                setForm({
                    type: data?.data?.type,
                    name: data?.data?.name,
                    route: data?.data?.route,
                    status: data?.data?.status,
                })
            }).catch(() => {
        })
    }

    useEffect(() => {
        getData()
    },[])




    const handleSubmit = (e) => {
        e.preventDefault();
         setDisabled(true)
        axiosClient.put('/company/company-module/'+id, form)
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.COMPANY_MODULE}`);
                },1000);

            }).catch(err => {
                setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }
    return (
        <div>
            <Breadcrumb title='Edit Module' prev={COMPANYROUTE.COMPANY_MODULE}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="col-md-6 col-12">
                                           <div className="row">
                                            <div className="col-12  ">
                                                <div className="form-group">
                                                    <label htmlFor="grt">Type</label>
                                                    <input
                                                        onChange={e => setForm({...form,type:e.target.value})}
                                                        value={form.type}
                                                        type="number" name="grt" className="form-control" id="grt"
                                                           placeholder="Enter Type" />
                                                </div>
                                            </div>
                                            <div className="col-12 ">
                                                <div className="form-group">
                                                    <label htmlFor="engine_type">Name</label>
                                                    <input
                                                        onChange={e => setForm({...form,name:e.target.value})}
                                                        value={form.name}
                                                        type="text" name="engine_type" className="form-control" id="engine_type"
                                                           placeholder="Enter Name" />
                                                </div>
                                            </div>
                                            <div className="col-12 ">
                                                <div className="form-group">
                                                    <label htmlFor="bhp">Route</label>
                                                    <input
                                                        onChange={e => setForm({...form,route:e.target.value})}
                                                        value={form.route}
                                                        type="text" name="bhp" className="form-control" id="bhp"
                                                           placeholder="Enter Route" />
                                                </div>
                                            </div>

                                               <div className="col-12 ">
                                                   <div className="form-group">
                                                       <label htmlFor="bhp">Status</label>
                                                       <select
                                                              onChange={e => setForm({...form,status:e.target.value})}
                                                                value={form.status}
                                                              className="form-control">
                                                              <option value="1">Active</option>
                                                              <option value="0">Inactive</option>
                                                         </select>
                                                   </div>
                                               </div>

                                        </div>
                                        </div>


                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">{
                                            disabled ? 'Please Wait....' : 'Update'
                                        }</button>
                                    </div>
                                </form>
                            </div>

                </div>
            </section>
        </div>

    );
};

export default Create;