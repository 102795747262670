import React, {useEffect, useState} from 'react';
import '../../styles/chat.css'
import io from 'socket.io-client';

import axiosClient from "../../../axios-client";

import CompanyChatSidebar from "../../../Components/Chat/CompanyChatSidebar";


const Chat = () => {
    const [conversation,setConversation] = useState([]);
    const [searchExist,setSearchExist] = useState(false)

    const getData = () => {
        axiosClient.get(`/company/chat` )
            .then(({data}) => {
                console.log(data?.data)
                setConversation(data?.data)
            }).catch((e) => {
            console.log(e)
        })
    }
    useEffect(() => {
        getData()
    },[])

    useEffect(() => {

    },[searchExist])

    return (
        <>
            <div id="container">
                <CompanyChatSidebar searchExist={searchExist} setSearchExist={setSearchExist} conversation={conversation} />
                <main>
                    <header>
                        <div>
                            <h2>Welcome to {process.env.REACT_APP_NAME}</h2>
                        </div>
                    </header>

                </main>
            </div>
        </>
    )

    // return (
    //     <div className="row">
    //         <div className="col-md-4">
    //             <SearchInput handleSearch={handleSearch} />
    //             {
    //                 searchExist ? <SearchUserList searchUserList={searchUserList} /> : <ConversationList conversation={conversation} inbox_url={COMPANYROUTE.INBOX}/>
    //             }
    //         </div>
    //
    //     <div className="col-md-8 " >
    //         <div className="card card-primary direct-chat chat-box direct-chat-primary">
    //             <div className="card-header">
    //                 <h3 className="card-title">Welcome to {process.env.REACT_APP_NAME}</h3>
    //             </div>
    //         </div>
    //     </div>
    //     </div>
    // );
};

export default Chat;