import React, {useState} from 'react';

import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import DatePicker from "react-datepicker";

const Create = () => {
    const navigate = useNavigate();
    const [disabled,setDisabled] = useState(false);
    const {user_id} = useParams();
    console.log(user_id)
    const [form,setForm] = useState({
        user_id:user_id,
        date:'',
        physician_name:'',
        physician_address:'',
        physician_phone:'',

        ultrasound:'',
        hepatitis_b:'',
        hiv:'',
        sgpt_sgot:'',
        blood_sugar:'',
        allergies:'',
        hba1c:'',
        alcohol_drug_test:'',

        family_diabetes:'',
        family_diabetes_details:'',
        family_high_blood_pressure:'',
        family_high_blood_pressure_details:'',
        family_heart_disease:'',
        family_heart_disease_details:'',
        family_cancer:'',
        family_cancer_details:'',
        family_mental_illness:'',
        family_mental_illness_details:'',
        family_epilepsy_seizure:'',
        family_epilepsy_seizure_details:'',

        any_other_major_condition:'',
        any_other_major_condition_details:'',


        diabetes:'',
        heart_trouble:'',
        high_blood_pressure:'',
        jaundice:'',
        dizziness:'',
        back_problems:'',
        shortness_breath:'',
        slipped_disc:'',
        chest_pain:'',
        wrist_problem:'',
        chronic_cough:'',
        fractured_vertebrae:'',
        asthma:'',
        arthritis:'',
        tuberculosis:'',
        kidney_problem:'',
        rheumatic_fever:'',
        cancer_tumor:'',
        frequent_headaches:'',
        rashor_skin_problem:'',
        vision_problem:'',
        hernia:'',
        twenty_twenty_vision:'',
        varicose_veins:'',
        epilepsy:'',
        drug_problem:'',
        hearing_problem:'',
        mental_breakdown:'',
        psychological_impairment:'',
        sexually_transmitted:'',


        prostate_problem:'',
        prostate_problem_details:'',
        testicular_lumps:'',
        testicular_lumps_details:'',
        penile_discharge:'',
        penile_discharge_details:'',


        any_doctor_care:'',
        whats_problem:'',
        doctor_care_physician_name:'',
        doctor_care_physician_address:'',

        history_of_surgery:'',
        surgery_date:'',
        surgery_details:'',

        presentyly_any_medication:'',
        presentyly_any_medication_details:'',

        do_smoke:'',
        how_long:'',
        packer_per_day:'',

        alcholic_bevarage:'',
        alcholic_bevarage_details:'',

        any_drug:'',
        any_drug_details:'',

        health_condition:'',



    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        axiosClient.post('/company/medical-history-questionnaire/store', form)
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                navigate(`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE}${user_id}`);
            }).catch(err => {

            setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    return (
        <div>
            <Breadcrumb title='Add New Medical History Questionnarie' prev={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE}${user_id}`}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Date</label>
                                                    <DatePicker
                                                        selected={form?.date ? new Date(new Date(form.date)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,date:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="physician_name">Personal Physician Name Or Clinic</label>
                                                    <input type="text"
                                                              value={form.physician_name}
                                                                onChange={(e) => setForm({...form,physician_name:e.target.value})}
                                                           name="mobile" className="form-control" id="physician_name"
                                                          />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="physician_address">Physician Address</label>
                                                    <input type="text"
                                                           value={form.physician_address}
                                                           onChange={(e) => setForm({...form,physician_address:e.target.value})}
                                                           name="mobile" className="form-control" id="physician_address"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="dental_exam">Physician Phone</label>
                                                    <input type="text"
                                                           value={form.physician_phone}
                                                           onChange={(e) => setForm({...form,physician_phone:e.target.value})}
                                                           name="dental_exam" className="form-control" id="physician_phone"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-12">
                                                <h6>Medical Test</h6>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="ultrasound">Ultrasound of Liver & Kidney</label>
                                                    <input type="text"
                                                           value={form.ultrasound}
                                                           onChange={(e) => setForm({...form,ultrasound:e.target.value})}
                                                             className="form-control" id="ultrasound"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="hepatitis_b">Hepatitis B</label>
                                                    <input type="text"
                                                           value={form.hepatitis_b}
                                                           onChange={(e) => setForm({...form,hepatitis_b:e.target.value})}
                                                            className="form-control" id="hepatitis_b"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="hiv">Hepatitis B</label>
                                                    <input type="text"
                                                           value={form.hiv}
                                                           onChange={(e) => setForm({...form,hiv:e.target.value})}
                                                            className="form-control" id="hiv"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="sgpt_sgot">SGPT & SGOT</label>
                                                    <input type="text"
                                                           value={form.sgpt_sgot}
                                                           onChange={(e) => setForm({...form,sgpt_sgot:e.target.value})}
                                                           className="form-control" id="sgpt_sgot"
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="blood_sugar">Fasting Blood Suger</label>
                                                    <input type="text"
                                                           value={form.blood_sugar}
                                                           onChange={(e) => setForm({...form,blood_sugar:e.target.value})}
                                                           className="form-control" id="blood_sugar"
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="allergies">Allergies</label>
                                                    <input type="text"
                                                           value={form.allergies}
                                                           onChange={(e) => setForm({...form,allergies:e.target.value})}
                                                           className="form-control" id="allergies"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="hba1c">HbA1c</label>
                                                    <input type="text"
                                                           value={form.hba1c}
                                                           onChange={(e) => setForm({...form,hba1c:e.target.value})}
                                                           className="form-control" id="hba1c"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="alcohol_drug_test">Alcohol/Drug Test</label>
                                                    <input type="text"
                                                           value={form.alcohol_drug_test}
                                                           onChange={(e) => setForm({...form,alcohol_drug_test:e.target.value})}
                                                           className="form-control" id="alcohol_drug_test"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                               <h6>Family History Has any one in your family ever had</h6>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="family_diabetes">Diabetes</label>
                                                    <select className="form-control"
                                                            id="family_diabetes"
                                                            value={form.family_diabetes}
                                                            onChange={(e) => setForm({...form,family_diabetes:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.family_diabetes === 'Yes' && (
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="family_diabetes_details">Details</label>
                                                            <input type="text"
                                                                     value={form.family_diabetes_details}
                                                                        onChange={(e) => setForm({...form,family_diabetes_details:e.target.value})}
                                                                  className="form-control" id="family_diabetes_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="family_high_blood_pressure">High Blood  Pressure</label>
                                                    <select className="form-control"
                                                            id="family_high_blood_pressure"
                                                            value={form.family_high_blood_pressure}
                                                            onChange={(e) => setForm({...form,family_high_blood_pressure:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.family_high_blood_pressure === 'Yes' && (
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="family_high_blood_pressure_details">Details</label>
                                                            <input type="text"
                                                                   value={form.family_high_blood_pressure_details}
                                                                   onChange={(e) => setForm({...form,family_high_blood_pressure_details:e.target.value})}
                                                                   className="form-control" id="family_high_blood_pressure_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="family_heart_disease">Heart  Disease</label>
                                                    <select className="form-control"
                                                            id="family_heart_disease"
                                                            value={form.family_heart_disease}
                                                            onChange={(e) => setForm({...form,family_heart_disease:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.family_heart_disease === 'Yes' && (
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="family_heart_disease_details">Details</label>
                                                            <input type="text"
                                                                   value={form.family_heart_disease_details}
                                                                   onChange={(e) => setForm({...form,family_heart_disease_details:e.target.value})}
                                                                   className="form-control" id="family_heart_disease_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }


                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="family_cancer">Cancer</label>
                                                <select className="form-control"
                                                        id="family_cancer"
                                                        value={form.family_cancer}
                                                        onChange={(e) => setForm({...form,family_cancer:e.target.value})}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">NO</option>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            form.family_cancer === 'Yes' && (
                                                <div className="col-12 col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="family_cancer_details">Details</label>
                                                        <input type="text"
                                                               value={form.family_cancer_details}
                                                               onChange={(e) => setForm({...form,family_cancer_details:e.target.value})}
                                                               className="form-control" id="family_cancer_details"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }


                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="family_mental_illness">Mental Illness</label>
                                                    <select className="form-control"
                                                            id="family_mental_illness"
                                                            value={form.family_mental_illness}
                                                            onChange={(e) => setForm({...form,family_mental_illness:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.family_mental_illness === 'Yes' && (
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="family_mental_illness_details">Details</label>
                                                            <input type="text"
                                                                   value={form.family_mental_illness_details}
                                                                   onChange={(e) => setForm({...form,family_mental_illness_details:e.target.value})}
                                                                   className="form-control" id="family_mental_illness_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="family_epilepsy_seizure">Epilepsy/Seizure</label>
                                                    <select className="form-control"
                                                            id="family_epilepsy_seizure"
                                                            value={form.family_epilepsy_seizure}
                                                            onChange={(e) => setForm({...form,family_epilepsy_seizure:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.family_epilepsy_seizure === 'Yes' && (
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="family_epilepsy_seizure_details">Details</label>
                                                            <input type="text"
                                                                   value={form.family_epilepsy_seizure_details}
                                                                   onChange={(e) => setForm({...form,family_epilepsy_seizure_details:e.target.value})}
                                                                   className="form-control" id="family_epilepsy_seizure_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }


                                        </div>


                                        <div className="row">
                                            <div className="col-12">
                                                <h6>Any Other Major Condition</h6>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="family_diabetes">Any Other Major Condition</label>
                                                    <select className="form-control"
                                                            id="family_diabetes"
                                                            value={form.any_other_major_condition}
                                                            onChange={(e) => setForm({...form,any_other_major_condition:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.any_other_major_condition === 'Yes' && (
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="any_other_major_condition_details">Details</label>
                                                            <input type="text"
                                                                   value={form.any_other_major_condition_details}
                                                                   onChange={(e) => setForm({...form,any_other_major_condition_details:e.target.value})}
                                                                   className="form-control" id="any_other_major_condition_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <h6>Do you have or have recelved treatment for the following:</h6>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="diabetes">Diabetes</label>
                                                    <select className="form-control"
                                                            id="diabetes"
                                                            value={form.diabetes}
                                                            onChange={(e) => setForm({...form,diabetes:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="heart_trouble">Heart Trouble</label>
                                                    <select className="form-control"
                                                            id="heart_trouble"
                                                            value={form.heart_trouble}
                                                            onChange={(e) => setForm({...form,heart_trouble:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="high_blood_pressure">High Blood Pressure</label>
                                                    <select className="form-control"
                                                            id="high_blood_pressure"
                                                            value={form.high_blood_pressure}
                                                            onChange={(e) => setForm({...form,high_blood_pressure:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="jaundice">Jaundice or Hepatitis</label>
                                                    <select className="form-control"
                                                            id="jaundice"
                                                            value={form.jaundice}
                                                            onChange={(e) => setForm({...form,jaundice:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="dizziness">Dizziness</label>
                                                    <select className="form-control"
                                                            id="dizziness"
                                                            value={form.dizziness}
                                                            onChange={(e) => setForm({...form,dizziness:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="back_problems">Back Problems</label>
                                                    <select className="form-control"
                                                            id="back_problems"
                                                            value={form.back_problems}
                                                            onChange={(e) => setForm({...form,back_problems:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="shortness_breath">Shortness of Breath</label>
                                                    <select className="form-control"
                                                            id="shortness_breath"
                                                            value={form.shortness_breath}
                                                            onChange={(e) => setForm({...form,shortness_breath:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="slipped_disc">Slipped Disc</label>
                                                    <select className="form-control"
                                                            id="slipped_disc"
                                                            value={form.slipped_disc}
                                                            onChange={(e) => setForm({...form,slipped_disc:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="chest_pain">Chest Pain</label>
                                                    <select className="form-control"
                                                            id="chest_pain"
                                                            value={form.chest_pain}
                                                            onChange={(e) => setForm({...form,chest_pain:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="wrist_problem">Wrist Problems</label>
                                                    <select className="form-control"
                                                            id="wrist_problem"
                                                            value={form.wrist_problem}
                                                            onChange={(e) => setForm({...form,wrist_problem:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="chronic_cough">Chronic Cough</label>
                                                    <select className="form-control"
                                                            id="chronic_cough"
                                                            value={form.chronic_cough}
                                                            onChange={(e) => setForm({...form,chronic_cough:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="fractured_vertebrae">Fractured Vertebrae</label>
                                                    <select className="form-control"
                                                            id="fractured_vertebrae"
                                                            value={form.fractured_vertebrae}
                                                            onChange={(e) => setForm({...form,fractured_vertebrae:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="asthma">Asthma</label>
                                                    <select className="form-control"
                                                            id="asthma"
                                                            value={form.asthma}
                                                            onChange={(e) => setForm({...form,asthma:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="arthritis">Arthritis/Gout</label>
                                                    <select className="form-control"
                                                            id="arthritis"
                                                            value={form.arthritis}
                                                            onChange={(e) => setForm({...form,arthritis:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="tuberculosis">Tuberculosis</label>
                                                    <select className="form-control"
                                                            id="tuberculosis"
                                                            value={form.tuberculosis}
                                                            onChange={(e) => setForm({...form,tuberculosis:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="kidney_problem">Kidney Problems</label>
                                                    <select className="form-control"
                                                            id="kidney_problem"
                                                            value={form.kidney_problem}
                                                            onChange={(e) => setForm({...form,kidney_problem:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="rheumatic_fever">Rheumatic Fever</label>
                                                    <select className="form-control"
                                                            id="rheumatic_fever"
                                                            value={form.rheumatic_fever}
                                                            onChange={(e) => setForm({...form,rheumatic_fever:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="cancer_tumor">Cancer/Tumor</label>
                                                    <select className="form-control"
                                                            id="cancer_tumor"
                                                            value={form.cancer_tumor}
                                                            onChange={(e) => setForm({...form,cancer_tumor:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>



                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="frequent_headaches">Frequent Headaches</label>
                                                    <select className="form-control"
                                                            id="frequent_headaches"
                                                            value={form.frequent_headaches}
                                                            onChange={(e) => setForm({...form,frequent_headaches:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="rashor_skin_problem">Rashor Skin Problem</label>
                                                    <select className="form-control"
                                                            id="rashor_skin_problem"
                                                            value={form.rashor_skin_problem}
                                                            onChange={(e) => setForm({...form,rashor_skin_problem:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="vision_problem">Vision Problems</label>
                                                    <select className="form-control"
                                                            id="vision_problem"
                                                            value={form.vision_problem}
                                                            onChange={(e) => setForm({...form,vision_problem:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="hernia">Hernia/Hydrocele</label>
                                                    <select className="form-control"
                                                            id="hernia"
                                                            value={form.hernia}
                                                            onChange={(e) => setForm({...form,hernia:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="twenty_twenty_vision">20/20 Vision</label>
                                                    <select className="form-control"
                                                            id="twenty_twenty_vision"
                                                            value={form.twenty_twenty_vision}
                                                            onChange={(e) => setForm({...form,twenty_twenty_vision:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="varicose_veins">VaricoseVeins</label>
                                                    <select className="form-control"
                                                            id="varicose_veins"
                                                            value={form.varicose_veins}
                                                            onChange={(e) => setForm({...form,varicose_veins:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="epilepsy">Epilepsy</label>
                                                    <select className="form-control"
                                                            id="epilepsy"
                                                            value={form.epilepsy}
                                                            onChange={(e) => setForm({...form,epilepsy:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="drug_problem">Drug Problems</label>
                                                    <select className="form-control"
                                                            id="drug_problem"
                                                            value={form.drug_problem}
                                                            onChange={(e) => setForm({...form,drug_problem:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="hearing_problem">Hearing Problems</label>
                                                    <select className="form-control"
                                                            id="hearing_problem"
                                                            value={form.hearing_problem}
                                                            onChange={(e) => setForm({...form,hearing_problem:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="mental_breakdown">Mental Breakdown</label>
                                                    <select className="form-control"
                                                            id="mental_breakdown"
                                                            value={form.mental_breakdown}
                                                            onChange={(e) => setForm({...form,mental_breakdown:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="psychological_impairment">Psychological Impairment, Beperssionor Mental Illness</label>
                                                    <select className="form-control"
                                                            id="psychological_impairment"
                                                            value={form.psychological_impairment}
                                                            onChange={(e) => setForm({...form,psychological_impairment:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="sexually_transmitted">Sexually Transmitted Disease</label>
                                                    <select className="form-control"
                                                            id="sexually_transmitted"
                                                            value={form.sexually_transmitted}
                                                            onChange={(e) => setForm({...form,sexually_transmitted:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-12">
                                                <h6>Males Only </h6>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="prostate_problem">Prostate  Problems</label>
                                                    <select className="form-control"
                                                            id="prostate_problem"
                                                            value={form.prostate_problem}
                                                            onChange={(e) => setForm({...form,prostate_problem:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.prostate_problem === 'Yes' && (
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="prostate_problem_details">Details</label>
                                                            <input type="text"
                                                                   value={form.prostate_problem_details}
                                                                   onChange={(e) => setForm({...form,prostate_problem_details:e.target.value})}
                                                                   className="form-control" id="prostate_problem_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="testicular_lumps">Testicular  Lumps</label>
                                                    <select className="form-control"
                                                            id="testicular_lumps"
                                                            value={form.testicular_lumps}
                                                            onChange={(e) => setForm({...form,testicular_lumps:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.testicular_lumps === 'Yes' && (
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="testicular_lumps_details">Details</label>
                                                            <input type="text"
                                                                   value={form.testicular_lumps_details}
                                                                   onChange={(e) => setForm({...form,testicular_lumps_details:e.target.value})}
                                                                   className="form-control" id="testicular_lumps_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="penile_discharge">Testicular  Lumps</label>
                                                    <select className="form-control"
                                                            id="penile_discharge"
                                                            value={form.penile_discharge}
                                                            onChange={(e) => setForm({...form,penile_discharge:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.penile_discharge === 'Yes' && (
                                                    <div className="col-12 col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="penile_discharge_details">Details</label>
                                                            <input type="text"
                                                                   value={form.penile_discharge_details}
                                                                   onChange={(e) => setForm({...form,penile_discharge_details:e.target.value})}
                                                                   className="form-control" id="penile_discharge_details"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="row">

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="any_doctor_care">Are you currently under a doctor's care?</label>
                                                    <select className="form-control"
                                                            id="any_doctor_care"
                                                            value={form.any_doctor_care}
                                                            onChange={(e) => setForm({...form,any_doctor_care:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                form.any_doctor_care === 'Yes' && (
                                                    <>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="whats_problem">What's Problem</label>
                                                                <input type="text"
                                                                       value={form.whats_problem}
                                                                       onChange={(e) => setForm({...form,whats_problem:e.target.value})}
                                                                       className="form-control" id="whats_problem"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="doctor_care_physician_name">Physician(s) Name</label>
                                                                <input type="text"
                                                                       value={form.doctor_care_physician_name}
                                                                       onChange={(e) => setForm({...form,doctor_care_physician_name:e.target.value})}
                                                                       className="form-control" id="doctor_care_physician_name"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="doctor_care_physician_address">Physician(s) Address</label>
                                                                <input type="text"
                                                                       value={form.doctor_care_physician_address}
                                                                       onChange={(e) => setForm({...form,doctor_care_physician_address:e.target.value})}
                                                                       className="form-control" id="doctor_care_physician_address"
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }


                                        </div>
                                        <div className="row">

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="history_of_surgery">History of surgeries / hospitalizations:</label>
                                                    <select className="form-control"
                                                            id="history_of_surgery"
                                                            value={form.history_of_surgery}
                                                            onChange={(e) => setForm({...form,history_of_surgery:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                form.history_of_surgery === 'Yes' && (
                                                    <>
                                                        <div className="col-12 col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="surgery_date">Surgery Date</label>
                                                                <DatePicker
                                                                    selected={form?.surgery_date ? new Date(new Date(form.surgery_date)) : ''}
                                                                    dateFormat='dd-MM-yyyy'
                                                                    className="form-control"
                                                                    onChange={e => setForm({...form,surgery_date:e})}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="surgery_details">Surgery Details</label>
                                                                <input type="text"
                                                                       value={form.surgery_details}
                                                                       onChange={(e) => setForm({...form,surgery_details:e.target.value})}
                                                                       className="form-control" id="surgery_details"
                                                                />
                                                            </div>
                                                        </div>


                                                    </>
                                                )
                                            }
                                        </div>

                                        <div className="row">

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="presentyly_any_medication">Presently on any medication:</label>
                                                    <select className="form-control"
                                                            id="presentyly_any_medication"
                                                            value={form.presentyly_any_medication}
                                                            onChange={(e) => setForm({...form,presentyly_any_medication:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                form.presentyly_any_medication === 'Yes' && (
                                                    <>

                                                        <div className="col-12 col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="presentyly_any_medication_details">Surgery Details</label>
                                                                <input type="text"
                                                                       value={form.presentyly_any_medication_details}
                                                                       onChange={(e) => setForm({...form,presentyly_any_medication_details:e.target.value})}
                                                                       className="form-control" id="presentyly_any_medication_details"
                                                                />
                                                            </div>
                                                        </div>


                                                    </>
                                                )
                                            }
                                        </div>

                                        <div className="row">

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="do_smoke">Do you or Did you smoke?</label>
                                                    <select className="form-control"
                                                            id="do_smoke"
                                                            value={form.do_smoke}
                                                            onChange={(e) => setForm({...form,do_smoke:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                form.do_smoke === 'Yes' && (
                                                    <>

                                                        <div className="col-12 col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="how_long">How Long</label>
                                                                <input type="text"
                                                                       value={form.how_long}
                                                                       onChange={(e) => setForm({...form,how_long:e.target.value})}
                                                                       className="form-control" id="how_long"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="packer_per_day">Packet Per Day</label>
                                                                <input type="text"
                                                                       value={form.packer_per_day}
                                                                       onChange={(e) => setForm({...form,packer_per_day:e.target.value})}
                                                                       className="form-control" id="packer_per_day"
                                                                />
                                                            </div>
                                                        </div>


                                                    </>
                                                )
                                            }
                                        </div>


                                        <div className="row">

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="alcholic_bevarage">Do you use alcoholic beverages?</label>
                                                    <select className="form-control"
                                                            id="alcholic_bevarage"
                                                            value={form.alcholic_bevarage}
                                                            onChange={(e) => setForm({...form,alcholic_bevarage:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                form.alcholic_bevarage === 'Yes' && (
                                                    <>

                                                        <div className="col-12 col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="alcholic_bevarage_details">How much /often?</label>
                                                                <input type="text"
                                                                       value={form.alcholic_bevarage_details}
                                                                       onChange={(e) => setForm({...form,alcholic_bevarage_details:e.target.value})}
                                                                       className="form-control" id="alcholic_bevarage_details"
                                                                />
                                                            </div>
                                                        </div>




                                                    </>
                                                )
                                            }
                                        </div>


                                        <div className="row">

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="any_drug">Do you use or  take any Drug?</label>
                                                    <select className="form-control"
                                                            id="any_drug"
                                                            value={form.any_drug}
                                                            onChange={(e) => setForm({...form,any_drug:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">NO</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                form.any_drug === 'Yes' && (
                                                    <>

                                                        <div className="col-12 col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="any_drug_details">Details</label>
                                                                <input type="text"
                                                                       value={form.any_drug_details}
                                                                       onChange={(e) => setForm({...form,any_drug_details:e.target.value})}
                                                                       className="form-control" id="any_drug_details"
                                                                />
                                                            </div>
                                                        </div>




                                                    </>
                                                )
                                            }
                                        </div>

                                        <div className="row">

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="health_condition">Health Condition</label>
                                                    <select className="form-control"
                                                            id="health_condition"
                                                            value={form.health_condition}
                                                            onChange={(e) => setForm({...form,health_condition:e.target.value})}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Excellent">Excellent</option>
                                                        <option value="Good">Good</option>
                                                        <option value="Fair">Fair</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>



                                    </div>





                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">{
                                            disabled ? 'Save.....' : 'Save'
                                        }</button>
                                    </div>
                                </form>
                            </div>

                </div>
            </section>
        </div>

    );
};

export default Create;