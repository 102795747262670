const PersonalInfoData = ({user,empData}) => {
    return (
        <>
            <div className="col-md-4 col-6">
                <div className="card   card-outline">
                    <div className="card-body ">
                        <ul className="list-group list-group-unbordered mb-3">
                            <li className="list-group-item">
                                <b>Status</b>
                                <p className="float-right">{empData?.status}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Email</b>
                                <p className="float-right">{user?.email}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Mobile</b>
                                <p className="float-right">{user?.mobile}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Telephone</b>
                                <p className="float-right">{empData?.telephone}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Father Name</b>
                                <p className="float-right">{empData?.father_name}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Mother Name</b>
                                <p className="float-right">{empData?.mother_name}</p>
                            </li>
                            <li className="list-group-item">
                                <b>NID</b>
                                <p className="float-right">{empData?.nid}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Birth Certificate Number</b>
                                <p className="float-right">{empData?.birth_certificate}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Gender</b>
                                <p className="float-right">{empData?.gender}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Blood Group</b>
                                <p className="float-right">{empData?.blood}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Nationality</b>
                                <p className="float-right">{empData?.country}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Marital Status</b>
                                <p className="float-right">{empData?.marital_status}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Present Address</b>
                                <p className="float-right">{empData?.address}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Postal Code</b>
                                <p className="float-right">{empData?.post_code}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Date Of Birth</b>
                                <p className="float-right">{empData?.date_birth}</p>
                            </li>

                            <li className="list-group-item">
                                <b>Place Of Birth</b>
                                <p className="float-right">{empData?.dop}</p>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="card   card-outline">
                    <div className="card-body ">
                        <ul className="list-group list-group-unbordered mb-3">

                            <li className="list-group-item">
                                <b>Age</b>
                                <p className="float-right">{empData?.age}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Height</b>
                                <p className="float-right">{empData?.height}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Weight</b>
                                <p className="float-right">{empData?.weight}</p>
                            </li>
                            <li className="list-group-item">
                                <b>BMI</b>
                                <p className="float-right">{empData?.bmi}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Cover All Size</b>
                                <p className="float-right">{empData?.cover_size}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Shoe Size</b>
                                <p className="float-right">{empData?.shoe_size}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Religion</b>
                                <p className="float-right">{empData?.religion}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Skype</b>
                                <p className="float-right">{empData?.skype}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Whatsapp</b>
                                <p className="float-right">{empData?.whatsapp}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Kin Name</b>
                                <p className="float-right">{empData?.kin_name}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Kin Email</b>
                                <p className="float-right">{empData?.kin_email}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Kin NID</b>
                                <p className="float-right">{empData?.kin_nid}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Kin Birth Certificate</b>
                                <p className="float-right">{empData?.kin_birth_certificate}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Kin Relationship</b>
                                <p className="float-right">{empData?.relation}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Kin Address</b>
                                <p className="float-right">{empData?.kin_address}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Kin Telephone</b>
                                <p className="float-right">{empData?.kin_telephone}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonalInfoData;