import React, {useEffect, useLayoutEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import Logo from "../../../Components/Common/logo";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const SupplyDemand = () => {
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(),1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth(),1));
    const [reportData,setReportData] = useState([]);

    const [master,setMaster] = useState(0);
    const [chiefOfficer,setChiefOfficer] = useState(0);
    const [chiefEngineer,setChiefEngineer] = useState(0);
    const [secondEngineer,setSecondEngineer] = useState(0);

    const [analyticsData,setAnalyticsData] = useState([]);
    const [supplyData,setSupplyData] = useState([]);
    const [demandData,setDemandData] = useState([]);



    const getData = (dateFrom = '',dateTo = '',master = 0,chiefOfficer = 0,chiefEngineer = 0,secondEngineer = 0) => {
        axiosClient.post('/company/supply-demand',{
            date_from: dateFrom,
            date_to: dateTo,
            master: master,
            ch_officer: chiefOfficer,
            ch_engineer: chiefEngineer,
            second_engineer: secondEngineer
        })
            .then(({data}) => {
                setAnalyticsData(data?.analyticsData)
                setSupplyData(data?.seafarerTotalSupplyData)
                setDemandData(data?.seafarerTotalDemandData)
            }).catch(() => {

        })
    }


    useEffect(() => {
        let dateFromData = dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1) + '-' + dateFrom.getDate();
        let dateToData = dateTo.getFullYear() + '-' + (dateTo.getMonth() + 1) + '-' + dateTo.getDate()
        getData(dateFromData,dateToData,master,chiefOfficer,chiefEngineer,secondEngineer);
    },[dateFrom,dateTo,master,chiefOfficer,chiefEngineer,secondEngineer])



    useLayoutEffect(() => {
        let chart = am4core.create("supplyDemandChart",  am4charts.XYChart);
        chart.logo.disabled = true;

        chart.data = [{
            "name": "Master",
            "supply": supplyData[0],
            "demand": demandData[0],
        },{
            "name": "Chief Engineer",
            "supply": supplyData[1],
            "demand": demandData[1],
        },{
            "name": "Chief Officer",
            "supply": supplyData[2],
            "demand": demandData[2],
        },{
            "name": "2nd Engineer",
            "supply": supplyData[3],
            "demand": demandData[3],
        } ];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;

        var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "supply";
        series.dataFields.categoryX = "name";
        series.name = "Supply";
        series.tooltipText = "{name} Total Supply: [bold]{valueY}[/]";
        series.columns.template.fill = am4core.color("#17a2b8");


        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "demand";
        series2.dataFields.categoryX = "name";
        series2.name = "Demand";
        series2.tooltipText = "{name} Total Demand : [bold]{valueY}[/]";
        series2.columns.template.fill = am4core.color("#ffc107");

        chart.cursor = new am4charts.XYCursor();

        chart.legend = new am4charts.Legend();



        return () => {
            chart.dispose();
        };
    }, [supplyData,demandData]);



    return (
       <>
           <Breadcrumb title='Supply Demand'      />
           <div className="content-header">
               <div className="container-fluid">
                   <div className="row mb-2">

                       <div className="col-12">
                           <div className="float-right">
                               <Link to={COMPANYROUTE.AUTO_SUPPLY_DEMAND} className='btn btn-sm btn-success mr-2'> Auto Supply Demand</Link>

                           </div>

                       </div>
                   </div>
               </div>
           </div>

           <section className="content">
               <div className="container-fluid">
                   <div className="row">
                       <div className="col-12">
                           <div className="card">
                               <div className="card-header">
                                   <div className='row'>
                                       <div className="col-12">
                                           <div className="row">
                                               <div className="col-12 col-md-6">
                                                   <div className="form-group">
                                                       <label htmlFor="master">Master</label>
                                                       <input type="number" value={master} onChange={e => setMaster(e.target.value)} className="form-control"/>
                                                   </div>
                                               </div>
                                               <div className="col-12 col-md-6">
                                                   <div className="form-group">
                                                       <label htmlFor="master">Chief Officer(C/O)</label>
                                                       <input type="number" value={chiefOfficer} onChange={e => setChiefOfficer(e.target.value)} className="form-control"/>
                                                   </div>
                                               </div>
                                               <div className="col-12 col-md-6">
                                                   <div className="form-group">
                                                       <label htmlFor="master">Chief Engineer(C/E)</label>
                                                       <input type="number" value={chiefEngineer} onChange={e => setChiefEngineer(e.target.value)} className="form-control"/>
                                                   </div>
                                               </div>
                                               <div className="col-12 col-md-6">
                                                   <div className="form-group">
                                                       <label htmlFor="master">2nd Engineer(2/E)</label>
                                                       <input type="number" value={secondEngineer} onChange={e => setSecondEngineer(e.target.value)} className="form-control"/>
                                                   </div>
                                               </div>
                                               <div className='col-12 col-md-6'>
                                                   <DatePicker
                                                       selected={dateFrom}
                                                       dateFormat='dd-MM-yyyy'
                                                       className="form-control"
                                                       onChange={(date) => setDateFrom(date)}
                                                       showMonthDropdown
                                                       showYearDropdown
                                                   />
                                               </div>
                                               <div className='col-12 col-md-6'>
                                                   <DatePicker
                                                       selected={dateTo}
                                                       dateFormat='dd-MM-yyyy'
                                                       className="form-control"
                                                       onChange={(date) => setDateTo(date)}
                                                       showMonthDropdown
                                                       showYearDropdown
                                                   />
                                               </div>
                                           </div>
                                       </div>

                                   </div>
                               </div>

                               <div className="card-body ">

                                   <div className="row">
                                       <div className="col-md-6 col-12">
                                           <div className="chart">
                                               <div id="supplyDemandChart" style={{ width: "100%", height: "500px" }}></div>
                                           </div>
                                       </div>
                                       <div className="col-md-6 col-12">
                                           <table id="" className="table table-bordered table-hover">
                                               <thead>
                                               <tr>
                                                   <th>#</th>
                                                   <th>Rank </th>
                                                   <th>Demand</th>
                                                   <th>Supply</th>
                                               </tr>
                                               </thead>
                                               <tbody>


                                               {
                                                   analyticsData?.length > 0  &&   analyticsData?.map((data,index) => (
                                                       <tr key={index}>
                                                           <td>{index + 1}</td>
                                                           <td>{data?.rank}</td>
                                                           <td>{data?.demand}</td>
                                                           <td>{data?.supply}</td>
                                                       </tr>
                                                   ))
                                               }

                                               </tbody>

                                           </table>
                                       </div>
                                   </div>


                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
       </>
    );
};

export default SupplyDemand;