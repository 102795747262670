import React from 'react';
import DatePicker from "react-datepicker";

const MedicalInfoForm = ({medicalInfo,setMedicalInfo,setSurgery,surgery,medicalVaccination,handleVaccinationChange,handleVaccinationIssueDateChange,handleVaccinationExpiryDateChange,disable}) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ If you signed off from a vessel on Medical Ground, Please select yes for details</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="medical_ground" onChange={e => setMedicalInfo({...medicalInfo,status:e.target.value})} checked={medicalInfo.status === 'Yes'}   type="radio" id="medical_ground_yes"  value="Yes" />
                                <label htmlFor="medical_ground_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="medical_ground" onChange={e => setMedicalInfo({...medicalInfo,status:e.target.value})}   checked={medicalInfo.status === 'No'}  type="radio" id="medical_ground_no"  value="No" />
                                <label htmlFor="medical_ground_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="medical_ground" onChange={e => setMedicalInfo({...medicalInfo,status:e.target.value})}  checked={medicalInfo.status === 'Not'}    type="radio" id="medical_ground_not"  value="Not" />
                                <label htmlFor="medical_ground_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {medicalInfo?.status === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <label htmlFor="" className="text-sm">Vessel</label>
                                <input type="text" onChange={e => setMedicalInfo({...medicalInfo,vessel:e.target.value})} value={medicalInfo.vessel}  className="form-control"/>
                            </div>
                            <div className="col-md-3 col-12">
                                <label htmlFor=""  className="text-sm">Date Signed Off</label>
                                <DatePicker
                                    selected={medicalInfo?.signed_date ? new Date(new Date(medicalInfo.signed_date)) : ''}
                                    dateFormat='dd-MM-yyyy'
                                    className="form-control"
                                    onChange={e => setMedicalInfo({...medicalInfo,signed_date:e})}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                            <div className="col-md-6 col-12">
                                <label htmlFor=""  className="text-sm">Reason / Brief Description of Illness / Injury / Accident</label>
                                <input type="text" onChange={e => setMedicalInfo({...medicalInfo,reason:e.target.value})} value={medicalInfo.reason} className="form-control"/>
                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ If you have any surgery / Illness within 12 months, Please select yes for details</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="surgery" onChange={e => setSurgery({...surgery,status:e.target.value})}  checked={surgery.status === 'Yes'}  type="radio" id="surgery_yes"  value="Yes" />
                                <label htmlFor="surgery_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="surgery" onChange={e => setSurgery({...surgery,status:e.target.value})}  checked={surgery.status === 'No'}   type="radio" id="surgery_no"  value="No" />
                                <label htmlFor="surgery_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="surgery" onChange={e => setSurgery({...surgery,status:e.target.value})}  checked={surgery.status === 'Not'}     type="radio" id="surgery_not"  value="Not" />
                                <label htmlFor="surgery_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {surgery?.status === 'Yes' ? (
                    <>
                        <div className="row">

                            <div className="col-md-3 col-12">
                                <label htmlFor=""  className="text-sm">Date of Surgery / Illness </label>
                                <DatePicker
                                    selected={surgery?.surgery_date ? new Date(new Date(surgery.surgery_date)) : ''}
                                    dateFormat='dd-MM-yyyy'
                                    className="form-control"
                                    onChange={e => setSurgery({...surgery,surgery_date:e})}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                            <div className="col-md-5 col-12">
                                <label htmlFor=""  className="text-sm">Details of Illness and Treatment received</label>
                                <input type="text" onChange={e => setSurgery({...surgery,details:e.target.value})} value={surgery.details}  className="form-control"/>
                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className=" col-12">
                        <p>♦ Medical and vaccination details below</p>

                        {   medicalVaccination.length > 0  ? medicalVaccination?.map((item,index) => (
                            <div className="row" key={index}>
                                <div className="col-md-3 col-6 ">
                                    <label htmlFor=""  className="text-sm">{item?.title}</label>

                                    <input  name='title'
                                            onChange={event => handleVaccinationChange(event, index)}
                                            value={item?.title}
                                            disabled
                                            type="text"    className="form-control"/>
                                </div>
                                <div className="col-md-3 col-6 ">
                                    <label htmlFor=""  className="text-sm">Issue Date</label>
                                    <DatePicker
                                        value={item?.issue_date}
                                        selected={item?.issue_date ? new Date(new Date(item?.issue_date)) : ''}
                                        dateFormat='dd-MM-yyyy'
                                        className="form-control"
                                        onChange={event => handleVaccinationIssueDateChange(event, index)}
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                                <div className="col-md-3 col-6 ">
                                    <label htmlFor=""  className="text-sm">Expiry Date</label>
                                    <DatePicker
                                        value={item?.expiry_date}
                                        selected={item?.expiry_date ? new Date(new Date(item?.expiry_date)) : ''}
                                        dateFormat='dd-MM-yyyy'
                                        className="form-control"
                                        onChange={event => handleVaccinationExpiryDateChange(event, index)}
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                                <div className="col-md-3 col-6 ">
                                    <label htmlFor=""  className="text-sm">Issued At</label>
                                    <input  name='issue_at'
                                            onChange={event => handleVaccinationChange(event, index)}
                                            value={item?.issue_at}
                                            type="text"    className="form-control"/>
                                </div>
                            </div>
                        )) : ''}
                    </div>
                </div>





                <div className="col-12 mt-2">
                    <button type="submit" disabled={disable} className="btn btn-info">{
                        disable ? 'Updating...' : 'Update'
                    }</button>
                </div>

            </div>
        </div>

    );
};

export default MedicalInfoForm;