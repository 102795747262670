import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import axiosClient from "../../../axios-client";
import React, {useEffect, useState} from "react";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";


const SeafarerIndividualContractDuration = () => {
    const [vesselSelect,setVesselSelect] = useState('');
    const [data,setData] = useState([]);


    const getData = (vessel = 0) => {
        axiosClient.post('/company/report/seafarer-individual-contact-duration',{
            vessel_id: vessel,
        })
            .then(({data}) => {
                setData(data.data)
            }).catch(() => {
        })
    }
    useEffect(() => {
         getData(vesselSelect)
    },[vesselSelect])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-individual-contact-duration/print',
        payload: { vessel_id: vesselSelect},
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Rank', key: 'rank', width: 20 },
        { header: 'Current Crew', key: 'vessel', width: 100 },
        { header: 'Previous Crew', key: 'leave', width: 100 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-individual-contact-duration/export',{
            vessel_id: vesselSelect
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Seafarer Individual Contract Duration Reports','seafarer-individual-contract-duration-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-individual-contact-duration/export',{
            vessel_id: vesselSelect
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Seafarer Individual Contract Duration Reports','seafarer-individual-contract-duration-report',exportColumns)
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Seafarer Individual Contract Duration Reports'    />

            <section className="contents">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                        <div className='row'>
                                            <div className="col-12 col-md-9">
                                                <div className="row">
                                                    <div className='col-12 col-md-4'>
                                                        <AllVessel vesselSelect={vesselSelect} setVesselSelect={setVesselSelect} />
                                                    </div>



                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="float-right">
                                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                    <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                    <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                                </div>
                                            </div>

                                        </div>

                                </div>
                                <div className="card-body">
                                    <table  className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Rank</th>
                                            <th>Current Crew</th>
                                            <th>Previous Crew</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            data.length > 0 ? data.map((d,index) => (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{d?.rank}</td>
                                                    <td>{d?.vessel}</td>
                                                    <td>{d?.leave}</td>
                                                </tr>
                                            )) : ''
                                        }


                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerIndividualContractDuration;