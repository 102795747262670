import React from 'react';
import DatePicker from "react-datepicker";

const TrainingInfoForm = ({form,setForm,disable}) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Ecdis (Generic) ?</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="ecdis" onChange={e => setForm({...form,ecdis:e.target.value})} checked={form?.ecdis === "Yes"}   type="radio" id="ecdis_yes"  value="Yes" />
                                <label htmlFor="ecdis_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="ecdis" onChange={e => setForm({...form,ecdis:e.target.value})} checked={form?.ecdis === "No"}    type="radio" id="ecdis_no"  value="No" />
                                <label htmlFor="ecdis_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="ecdis" onChange={e => setForm({...form,ecdis:e.target.value})} checked={form?.ecdis === "Not"}     type="radio" id="ecdis_not"  value="Not" />
                                <label htmlFor="ecdis_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦  Ecdis Type Specific- Yes/No, if yes , please provide details below</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="ecdis_type" onChange={e => setForm({...form,ecdis_type:e.target.value})}  checked={form?.ecdis_type === "Yes"}    type="radio" id="ecdis_type_yes"  value="Yes" />
                                <label htmlFor="ecdis_type_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="ecdis_type" onChange={e => setForm({...form,ecdis_type:e.target.value})}   checked={form?.ecdis_type === "No"}    type="radio" id="ecdis_type_no"  value="No" />
                                <label htmlFor="ecdis_type_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="ecdis_type" onChange={e => setForm({...form,ecdis_type:e.target.value})}   checked={form?.ecdis_type === "Not"}      type="radio" id="ecdis_type_not"  value="Not" />
                                <label htmlFor="ecdis_type_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.ecdis_type === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <label htmlFor="" className="text-sm">Provide Details</label>
                                <input type="text" onChange={e => setForm({...form,ecdis_details:e.target.value})} value={form?.ecdis_details}  className="form-control"/>
                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Bridge Team Management- Yes/No, if yes, issue date/Expiry Date</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="bridge_status" onChange={e => setForm({...form,bridge_status:e.target.value})}  checked={form?.bridge_status === "Yes"}     type="radio" id="bridge_status_yes"  value="Yes" />
                                <label htmlFor="bridge_status_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="bridge_status" onChange={e => setForm({...form,bridge_status:e.target.value})}  checked={form?.bridge_status === "No"}     type="radio" id="bridge_status_no"  value="No" />
                                <label htmlFor="bridge_status_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="bridge_status" onChange={e => setForm({...form,bridge_status:e.target.value})}  checked={form?.bridge_status === "Not"}       type="radio" id="bridge_status_not"  value="Not" />
                                <label htmlFor="bridge_status_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.bridge_status === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <label htmlFor="" className="text-sm">Issue Date</label>
                                <DatePicker
                                    selected={form?.bridge_issue ? new Date(new Date(form.bridge_issue)) : ''}
                                    dateFormat='dd-MM-yyyy'
                                    className="form-control"
                                    onChange={e => setForm({...form,bridge_issue:e})}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                            <div className="col-md-3 col-12">
                                <label htmlFor="" className="text-sm">Expiry Date</label>
                                <DatePicker
                                    selected={form?.bridge_expiry ? new Date(new Date(form.bridge_expiry)) : ''}
                                    dateFormat='dd-MM-yyyy'
                                    className="form-control"
                                    onChange={e => setForm({...form,bridge_expiry:e})}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Bridge Resource Management- Yes/No, if yes, issue date/Expiry Date</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="bridge_resource" onChange={e => setForm({...form,bridge_resource:e.target.value})}  checked={form?.bridge_resource === "Yes"}    type="radio" id="bridge_resource_yes"  value="Yes" />
                                <label htmlFor="bridge_resource_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="bridge_resource" onChange={e => setForm({...form,bridge_resource:e.target.value})}  checked={form?.bridge_resource === "No"}     type="radio" id="bridge_resource_no"  value="No" />
                                <label htmlFor="bridge_resource_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="bridge_resource" onChange={e => setForm({...form,bridge_resource:e.target.value})}  checked={form?.bridge_resource === "Not"}      type="radio" id="bridge_resource_not"  value="Not" />
                                <label htmlFor="bridge_resource_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.bridge_resource === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <label htmlFor="" className="text-sm">Issue Date</label>
                                <DatePicker
                                    selected={form?.bridge_resource_issue ? new Date(new Date(form.bridge_resource_issue)) : ''}
                                    dateFormat='dd-MM-yyyy'
                                    className="form-control"
                                    onChange={e => setForm({...form,bridge_resource_issue:e})}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                            <div className="col-md-3 col-12">
                                <label htmlFor="" className="text-sm">Expiry Date</label>
                                <DatePicker
                                    selected={form?.bridge_resource_expiry ? new Date(new Date(form.bridge_resource_expiry)) : ''}
                                    dateFormat='dd-MM-yyyy'
                                    className="form-control"
                                    onChange={e => setForm({...form,bridge_resource_expiry:e})}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Ship Handling Simulator Course ?</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="ship_handling" onChange={e => setForm({...form,ship_handling:e.target.value})}  checked={form?.ship_handling === "Yes"}    type="radio" id="ship_handling_yes"  value="Yes" />
                                <label htmlFor="ship_handling_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="ship_handling" onChange={e => setForm({...form,ship_handling:e.target.value})}  checked={form?.ship_handling === "No"}     type="radio" id="ship_handling_no"  value="No" />
                                <label htmlFor="ship_handling_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="ship_handling" onChange={e => setForm({...form,ship_handling:e.target.value})}   checked={form?.ship_handling === "Not"}     type="radio" id="ship_handling_not"  value="Not" />
                                <label htmlFor="ship_handling_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Cargo Handling Simulator Course ? </p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="cargo_handling" onChange={e => setForm({...form,cargo_handling:e.target.value})}  checked={form?.cargo_handling === "Yes"}     type="radio" id="cargo_handling_yes"  value="Yes" />
                                <label htmlFor="cargo_handling_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="cargo_handling" onChange={e => setForm({...form,cargo_handling:e.target.value})}  checked={form?.cargo_handling === "No"}     type="radio" id="cargo_handling_no"  value="No" />
                                <label htmlFor="cargo_handling_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="cargo_handling" onChange={e => setForm({...form,cargo_handling:e.target.value})}   checked={form?.cargo_handling === "Not"}     type="radio" id="cargo_handling_not"  value="Not" />
                                <label htmlFor="cargo_handling_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-2">
                    <button type="submit" disabled={disable} className="btn btn-info">{
                        disable ? 'Saving...' : 'Save'
                    }</button>
                </div>

            </div>
        </div>

    );
};

export default TrainingInfoForm;