import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import OtherInfoForm from "../../../../Components/Profile/OtherInfo/OtherInfoForm";

const OthersInfo = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disable,setDisable] = useState(false)
    const [form,setForm] = useState({
        user_id:id,
        others_id:0,
        court_offence:'',
        maritime_incident:'',
        psc:'',
        psc_details:'',
        fsi:'',
        fsi_details:'',
        si:'',
        si_details:'',
        cdi:'',
        navigation:'',
        ne_details:'',
        rank_exp:'',
        re_details:'',
        sailing_record:'',
        stsr_details:'',
        english_pro:'',
        gas_tanker:'',
        uscg:'',
        sts:'',
        cargo_carried:'',
        radio_qua:'',
        marlin_result:'',
        last_company:'',
        reason_leaving:'',
        nationalities:''
    });


    const getData = () => {
        axiosClient.get('/company/others-info/'+id)
            .then(({data}) => {
                if (data.data !== null) {
                    const splitDetails = (detail) =>
                        data.data[detail]
                            ?.split(',')
                            ?.filter((item) => item !== undefined && item !== null);

                    setForm({
                        user_id: id,
                        others_id: data.data.others_id,
                        court_offence: data.data.court_offence,
                        maritime_incident: data.data.maritime_incident,
                        psc: data.data.psc,
                        psc_details: splitDetails('psc_details'),
                        fsi: data.data.fsi,
                        fsi_details: splitDetails('fsi_details'),
                        si: data.data.si,
                        si_details: splitDetails('si_details'),
                        cdi: data.data.cdi,
                        navigation: data.data.navigation,
                        ne_details: splitDetails('ne_details'),
                        rank_exp: data.data.rank_exp,
                        re_details: data.data.re_details,
                        sailing_record: data.data.sailing_record,
                        stsr_details: splitDetails('stsr_details'),
                        english_pro: data.data.english_pro,
                        gas_tanker: data.data.gas_tanker,
                        uscg: data.data.uscg,
                        sts: data.data.sts,
                        cargo_carried: data.data.cargo_carried,
                        radio_qua: data.data.radio_qua,
                        marlin_result: data.data.marlin_result,
                        last_company: data.data.last_company,
                        reason_leaving: data.data.reason_leaving,
                        nationalities: data.data.nationalities,
                    });
                }

            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const handleInputChange= (e) => {
        const { name, value } = e.target;

        const updateDetails = (detailsName) => {
            const details = [...form[detailsName]];
            if (e.target.checked) {
                details.push(value);
            } else {
                details.splice(details.indexOf(value), 1);
            }
            setForm({...form, [detailsName]: details});
        }

        switch (name) {
            case 'psc_details':
                updateDetails('psc_details');
                break;
            case 'fsi_details':
                updateDetails('fsi_details');
                break;
            case 'si_details':
                updateDetails('si_details');
                break;
            case 'ne_details':
                updateDetails('ne_details');
                break;
            case 'stsr_details':
                updateDetails('stsr_details');
                break;
            default:
                break;
        }
    }



    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.post('/company/save-others-info', form)
            .then(({data}) => {
                toast.success(data.message);
                navigate(`${COMPANYROUTE.USER_PROFILE}${id}`, { state: { type:'others' } });
                setDisable(false)
            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

      useEffect(() => {
        getData();
      },[])

    return (
        <>
            <Breadcrumb title='Others Information' prev={`${COMPANYROUTE.USER_PROFILE}${id}`} state="others"  />
            <section className="content">
                <div className="container-fluid">
                    <div className=" col-12">
                        <form onSubmit={onSubmit}>
                            <OtherInfoForm
                                form={form}
                                setForm={setForm}
                                handleInputChange={handleInputChange}
                                disable={disable}
                            />
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OthersInfo;