import React from 'react';

const BankInfoForm = ({form,setForm,disable}) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Bank Name</label>
                            <input className='form-control'  onChange={e => setForm({...form,bank_name:e.target.value})} value={form?.bank_name} type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Account Name  </label>
                            <input className='form-control  '  onChange={e => setForm({...form,account_name:e.target.value})} value={form?.account_name}  type="text"/>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Account Number  </label>
                            <input className='form-control'  onChange={e => setForm({...form,account_number:e.target.value})} value={form?.account_number}  type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Routing Number  </label>
                            <input className='form-control'  onChange={e => setForm({...form,routing_number:e.target.value})} value={form?.routing_number}   type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Address  </label>
                            <input className='form-control'  onChange={e => setForm({...form,address:e.target.value})} value={form?.address}   type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Account Type  </label>
                            <input className='form-control'  onChange={e => setForm({...form,account_type:e.target.value})}  value={form?.account_type}  type="text"/>
                        </div>
                    </div>

                    <div className="col-12">
                        <button type="submit" disabled={disable} className="btn btn-info">{
                            disable ? 'Saving' : 'Save'
                        }</button>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default BankInfoForm;