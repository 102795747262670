import React, {useEffect, useReducer, useState} from 'react';
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {ACTION_TYPES, INITIAL_STATE, seafarerReducer} from "../../../reducers/Company/SeafarerReducer";

const LeftRehireModal = ({data,hide}) => {
    const [state,dispatch] = useReducer(seafarerReducer,INITIAL_STATE);

    const [form,setForm] = useState({
        emp_id:data,
        contract_no: '',
        contract_remarks:'',
        termination:'',
        reasons:'',
        termination_remarks:'',

    })




    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/seafarer/crew-leaving-contract',form)
            .then(({data}) => {
                toast.success(data.message);
                hide();
                 setTimeout(() => {
                        window.location.reload();
                 },1000)

            }).catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }






    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }
    const UT_reasons = ["Retirements", "Illness", "Left After Few Contracts(better opportunity)", "Others"];
    const BT_reasons = ["Under Performance", "Others"];

    const [terminationReason, setTerminationReason] = useState([]);

    useEffect(() => {
        let reasonsArray = [];
        if (form.termination === "Unavoidable Terminations") {
            reasonsArray = UT_reasons;
        }
        if (form.termination === "Beneficial Terminations") {
            reasonsArray = BT_reasons;
        }
        setTerminationReason(reasonsArray);
    }, [form.termination]);




    return (
        <>

            <div className="modal show fade"  style={modelStyle} >
            <div className="modal-dialog modal-lg">
                <form onSubmit={onSubmit}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Seafarer Left </h5>
                    </div>
                    <div className="modal-body">

                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="rank">Leaving Contract No.</label>
                                        <input onChange={e => setForm({...form,contract_no:e.target.value})}  className='form-control'  type="text"/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="rank">Leaving Contract Remarks.</label>
                                        <input onChange={e => setForm({...form,contract_remarks:e.target.value})}  className='form-control'  type="text"/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="rank">Termination Type.</label>
                                        <select name="termination" className="form-control" onChange={e => setForm({...form,termination:e.target.value})}
                                                id="termination">
                                            <option value="" disabled selected>Choose one</option>
                                            <option value="Unavoidable Terminations">Unavoidable Terminations </option>
                                            <option value="Beneficial Terminations">Beneficial Terminations </option>
                                            <option value="Not Applicable">Not Applicable</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="rank">Termination Reason.</label>
                                        <select id="termination_reason"  onChange={e => setForm({...form,reasons:e.target.value})}   className="form-control">
                                            <option value="">Choose one</option>
                                            {terminationReason.map((reason) => (
                                                <option key={reason} value={reason}>{reason}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="rank">Termination Remarks.</label>
                                        <input  onChange={e => setForm({...form,termination_remarks:e.target.value})}     className='form-control'  type="text"/>
                                    </div>
                                </div>
                            </div>

                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-danger"  onClick={hide} >Close</button>
                        <button type="submit" className="btn btn-success">Save</button>
                    </div>

                  </div>
                </form>
            </div>
        </div>
        </>
    );
};

export default LeftRehireModal;