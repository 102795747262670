import React, {useEffect, useRef, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import axiosClient from "../../../axios-client";
import PrintHeader from "../../../Components/Common/PrintHeader";
import UseExportHook from "../../../hooks/UseExportHook";
import usePrintWindow from "../../../hooks/usePrintWindow";

const FleetBudget = () => {
    var date = new Date();
    const [reportData,setReportData] = useState([]);

    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));

    const getReportData = (dateFromData = null) => {
        axiosClient.post('/company/fleet-budget',{
            budget_year: dateFromData,
        })
            .then(({data}) => {
                console.log(data)
                setReportData(data)
            }).catch(() => {
        })
    }


    useEffect(() => {
        let dateFromData = dateFrom.getFullYear();
        getReportData(dateFromData)
    },[dateFrom])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/fleet-budget/print',
        payload: { budget_year: dateFrom.getFullYear() },
    });



    const columns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Vessel', key: 'vessel_name', width: 20 },
        { header: 'Title', key: 'title', width: 20 },
        { header: 'Budget', key: 'budget', width: 20 },
        { header: 'Expense', key: 'expense', width: 20 },
        { header: 'Variance', key: 'variance', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/fleet-budget/export',{
            budget_year: dateFrom.getFullYear(),
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Fleet Budget','fleet-budget',columns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/fleet-budget/export',{
            budget_year: dateFrom.getFullYear(),
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Fleet Budget','fleet-budget',columns)
            }).catch(() => {
        })
    };




    return (
        <div>
            <Breadcrumb title='Fleet Budget'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                       <div className="col-md-8 col-12">
                                           <div className="row">
                                               <div className='col-12 col-md-4'>
                                                   <DatePicker
                                                       selected={dateFrom}
                                                       dateFormat='yyyy'
                                                       className="form-control"
                                                       onChange={(date) => setDateFrom(date)}
                                                       showYearDropdown
                                                       showYearPicker
                                                   />
                                               </div>

                                           </div>
                                       </div>
                                        <div className="col-md-4 col-12 ">
                                            <div className="float-right">
                                            <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                             <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                            <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                            </div>
                                        </div>
                                   </div>
                                </div>


                                <div className="card-body "  >
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead  className="bg-info">
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Vessel </th>
                                                <th>Budget </th>
                                                <th>Expense </th>
                                                <th>Variance </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData?.data?.length > 0  ? reportData?.data?.map((item,index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{item?.title}</td>
                                                        <td>{item?.vessel_name}</td>
                                                        <td>{item?.budget}</td>
                                                        <td>{item?.expense}</td>
                                                        <td>{item?.variance}</td>
                                                    </tr>
                                                )
                                            }) : ''
                                        }
                                        <tr>
                                            <th colSpan="3" >Total</th>
                                            <th>{reportData?.totalBudgetData}</th>
                                            <th>{reportData?.totalExpense}</th>
                                            <th>{reportData?.totalVariance}</th>
                                        </tr>


                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </div>
    );
};

export default FleetBudget;