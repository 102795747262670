import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import useDatatable from "../../../hooks/useDatatable";
import useDatePicker from "../../../hooks/useDatePicker";
import Logo from "../../../Components/Common/logo";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import AllRank from "../../../Components/Company/Common/AllRank";
import * as COMPANYROUTE from '../../../constants/companyRoutes'
import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {ACTION_TYPES, INITIAL_STATE, seafarerReducer} from "../../../reducers/Company/SeafarerReducer";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import LoadingBar from "react-top-loading-bar";


const SeafarerTrash = () => {
    const [progress, setProgress] = useState(10);
    const [rankSelect,setRankSelect] = useState('');
    const [reportData,setReportData] = useState([])



    const getReportData = (rank = 0) => {
        setProgress(50)
        axiosClient.get(`/company/seafarer-trash`,{
            params: {
                rank_id: rank,
            }
        })
            .then(({data}) => {
                console.log(data)
                setProgress(100);
                setReportData(data?.data)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getReportData(rankSelect)
    },[rankSelect])

    const onRestore = (data) => {
        if(!window.confirm(`Are you sure you want to restore ${data?.name}?`)){
            return false;
        }
        axiosClient.post('/company/seafarer-restore',{
            user_id: data?.user_id
        })
            .then((data) => {
                console.log(data)
                toast.success(data?.message);
                getReportData();
            }).catch((error) => {
            console.log(error)
        })
    }


    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Seafarer Trash'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className='col-12 col-md-4'>
                                            {/*<AllRank rankSelect={rankSelect} setRankSelect={setRankSelect} />*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body ">
                                    <table id="" className="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Unique Id</th>
                                            <th>Vessel</th>
                                            <th>Rank</th>
                                            <th>Experience</th>
                                            <th>Country</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData?.length > 0 && reportData.map((item,index) => (
                                                <tr key={index}>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.cdc}</td>
                                                    <td>{item?.vessel}</td>
                                                    <td>{item?.rank}</td>
                                                    <td>{item?.experience}</td>
                                                    <td>{item?.country_name}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.mobile}</td>
                                                    <td>
                                                        <button  onClick={e => onRestore(item)}   className="btn btn-success text-sm btn-sm"  >Restore</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }


                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerTrash;