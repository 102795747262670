import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import axiosClient from "../../../axios-client";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";

const Create = () => {
    const navigate = useNavigate();

    const [rankName,setRankName] = useState('');
    const [disabled,setDisabled] = useState(false);


    const onSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)

        axiosClient.post('/company/rank', {
            rank_name: rankName
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(-1);
                },1000);
                setDisabled(false)

            }).catch(err => {
            const response = err.response;
            setDisabled(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    return (
        <div>
            <Breadcrumb title='Create Rank' prev={COMPANYROUTE.RANK}    />
            <section className="content">
                <div className="container-fluid">

                    <div className="card card-primary">

                        <form onSubmit={onSubmit}>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="particular_type">Rank Name</label>
                                            <input
                                                value={rankName}
                                                onChange={(e) => setRankName(e.target.value)}
                                                type="text" name="particular_type" className="form-control" id="particular_type"
                                                   placeholder="Enter Rank Name" />
                                        </div>
                                    </div>

                                </div>


                            </div>

                            <div className="card-footer">
                                <button type="submit" disabled={disabled} className="btn btn-success">{
                                    disabled ? 'Loading...' : 'Save'
                                }</button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default Create;