import React from 'react';
import {Link} from "react-router-dom";
import * as UserRoute from "../../../constants/UserRoute";

const DocumentVisaList = ({passportData,visaData,passportEditRoute,visaEditRoute}) => {
    return (
        <table className="table table-bordered mt-3">
            <thead>
            <tr>
                <th>Document & Visa</th>
                <th>Passport No</th>
                <th>Visa No</th>
                <th>Issue Date</th>
                <th>Expiry Date</th>
                <th>Issued At</th>
                <th>Country</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {passportData && passportData.length > 0 ? passportData.map((data,index) => (
                <tr key={index}>
                    <td>{data?.info_type === 'Seaman Passport' ? 'Seaman Id' : data?.info_type}</td>
                    <td>{data?.number}</td>
                    <td>  </td>
                    <td>{data?.issue_date}</td>
                    <td>{data?.expiry_date}</td>
                    <td>{data?.issued_at}</td>
                    <td>{data?.country}</td>
                    <td> <Link to={`${passportEditRoute}${data?.passport_id}`} className="btn btn-info"><i className="fas fa-pencil-alt"></i></Link>  </td>
                </tr>
            )) : "" }
            {visaData && visaData.length > 0 ? visaData.map((data,index) => (
                <tr key={index}>
                    <td>{data?.passport_type}</td>
                    <td> </td>
                    <td>{data?.visa_number}</td>
                    <td>{data?.issue_date}</td>
                    <td>{data?.expiry_date}</td>
                    <td>{data?.issued_at}</td>
                    <td>{data?.country}</td>
                    <td>  <Link to={`${visaEditRoute}${data?.visa_id}`} className="btn btn-info"><i className="fas fa-pencil-alt"></i></Link>  </td>
                </tr>
            )) : "" }

            </tbody>
        </table>

    );
};

export default DocumentVisaList;