import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import useDatePicker from "../../../../hooks/useDatePicker";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import PassportEditForm from "../../../../Components/Profile/DocumentVisa/PassportEditForm";

const PassportEdit = () => {
    const {id} = useParams();
    const navigate = useNavigate();


    const [documentForm,setDocumentForm] = useState({
        passport_id:id,
        number:'',
        issue_date:'',
        expiry_date:'',
        issued_at:'',
        country_id:""
    });


    const getData = () => {
        axiosClient.get('/company/document-edit/'+id)
            .then(({data}) => {
                setDocumentForm(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/document-update', documentForm)
            .then(({data}) => {
                toast.success(data.message);
                navigate(-1)

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    useEffect(() => {
        getData()
    },[])
    return (
        <div>
            <Breadcrumb title={`Edit ${documentForm?.info_type === 'Seaman Passport' ? 'Seaman Id' : documentForm?.info_type}`}  backButton={true}    />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-6 col-12">

                       <form  onSubmit={onSubmit}>
                           <PassportEditForm documentForm={documentForm} setDocumentForm={setDocumentForm}  />
                       </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PassportEdit;