import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";

const CrewDocumentReviewModal = ({data,month,modalHide}) => {

    const [reportData,setReportData] = useState([]);
    const [userName,setUserName] = useState("");





    const getData = () => {
        axiosClient.get('/company/crew-document-review/'+data+'/'+month)
            .then(({data}) => {
                setUserName(data?.username)
                setReportData(data?.data)
            }).catch(() => {
        })
    }


    useEffect(() => {
        getData()
    },[])

    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        modalHide(false)
    }


    return (
        <div className="modal show fade "  style={modelStyle} >
            <div className="modal-dialog modal-xl">
                <div className="modal-content" style={{overflowY:'scroll', height:'600px'}}>
                    <div className="modal-header">
                        <h5 className="modal-title">{userName} ON-SIGNER DOCUMENT REVIEW </h5>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Document Name</th>
                                    <th>Validity</th>
                                    <th>Status</th>
                                    <th>View</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    reportData?.length > 0 && reportData.map((item,index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item?.title}</td>
                                                <td>{item?.validity_month}</td>
                                                <td>{
                                                    item?.status === true ?
                                                        <i className="fa fa-check text text-success"></i>
                                                    : <i className="fa fa-times text text-danger"></i>
                                                }</td>
                                                <td>
                                                    {
                                                        item?.doc_file_url != null &&
                                                        item?.doc_file_url != ''  &&
                                                        <a href={item?.doc_file_url} target="_blank"   className="btn btn-sm btn-info">View</a>
                                                    }

                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrewDocumentReviewModal;