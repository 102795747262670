import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";

const PassportEditForm = ({documentForm,setDocumentForm}) => {
    const [country,setCountry] = useState([]);
    const getCountryData = () => {
        axiosClient.get('/get-country')
            .then(({data}) => {
                setCountry(data.data)
            }).catch(() => {

        })
    }

     useEffect(() => {
           getCountryData()
     },[])

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">{documentForm?.info_type === 'Seaman Passport' ? 'Seaman Id' : documentForm?.info_type} No</label>
                            <input className='form-control' onChange={e => setDocumentForm({...documentForm,number:e.target.value})} value={documentForm.number}   type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Issue Date  </label>
                            <DatePicker
                                selected={documentForm?.issue_date ? new Date(new Date(documentForm.issue_date)) : ''}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={e => setDocumentForm({...documentForm,issue_date:e})}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Expiry  Date  </label>
                            <DatePicker
                                selected={documentForm?.expiry_date ? new Date(new Date(documentForm.expiry_date)) : ''}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={e => setDocumentForm({...documentForm,expiry_date:e})}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Issue at </label>
                            <input className='form-control  '  onChange={e => setDocumentForm({...documentForm,issued_at:e.target.value})} value={documentForm.issued_at} placeholder="Enter Issue at" type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="mother_name">Select Country</label>
                            <select style={{maxWidth: '100%'}}
                                    onChange={e => setDocumentForm({...documentForm,country_id:e.target.value})}
                                    value={documentForm.country_id}
                                    className="form-control"
                            >
                                <option  value="" selected>Select Country</option>
                                {country.map((item,index) => (
                                    <option key={index} selected={documentForm.country_id === item?.country_id}   value={item?.country_id}>{item?.country_name}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <button className="btn btn-info" type="submit">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PassportEditForm;