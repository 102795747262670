import React, {useEffect, useState} from 'react';
import Logo from "../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import './styles/checklist.css'
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {Link, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../hooks/usePrintWindow";
const Checklist = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [seafarerInfo,setSeafarerInfo] = useState();
    const [certificateData,setCertificateData] = useState();
    const getData = () => {

        axiosClient.get('/company/crew-pre-arrival-checklist/'+id)
            .then(({data}) => {
                setSeafarerInfo(data.seafarerInfo)
                setCertificateData(data.stcwCertificateData)
                setProgress(100)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/crew-pre-arrival-checklist/print/' + id,
    });
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Seafarer Checklist'  prev={COMPANYROUTE.SEAFARER}    />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-8 offset-md-2">
                                    <div className="checklist-panel">


                                        <div className="justify-content-center d-flex mb-2">
                                    <div className="d-flex">
                                        <div className="logo">
                                            <Logo />
                                        </div>
                                        <div className="header text">
                                            <h5 className="text-center">Brave Royal Ship Management Limited</h5>
                                            <h6 className="text-center">CHECKLIST FOR SEA-STAFF JOINING VESSEL</h6>
                                              <div className="d-flex justify-content-center">
                                                  <div className="header-border">
                                                      File No:03
                                                  </div>
                                                  <div className="header-border">
                                                      BRSML/CKL-38R4
                                                  </div>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                                        <div className="checklist-data">
                                  <div className="row">
                                      <div className="col-12 col-md-6">
                                          <p>Name of Vessel: {seafarerInfo?.vesselName}</p>
                                      </div>
                                      <div className="col-12 col-md-6">
                                          <p>Port Of Joining: {seafarerInfo?.portJoining}</p>
                                      </div>
                                      <div className="col-12 col-md-4">
                                          <p>Name: {seafarerInfo?.name}</p>
                                      </div>
                                      <div className="col-12 col-md-4">
                                          <p>CDC: {seafarerInfo?.cdc}</p>
                                      </div>
                                      <div className="col-12 col-md-4">
                                          <p>Rank: {seafarerInfo?.rank}</p>
                                      </div>
                                  </div>
                               </div>
                                        <div className="checklist-title my-3 px-2">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <h6>A. Following documents checked by Office:-</h6>
                                                </div>
                                                <div className="col-md-6 col-12 float-right">
                                                    <h6 className="float-right">Received On Board Master</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="checklist-data mt-2">
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th><strong></strong></th>
                                                    <th><strong></strong></th>
                                                    <th><strong></strong></th>
                                                    <th><strong>YES</strong></th>
                                                    <th><strong>No</strong></th>
                                                    <th><strong>NA</strong></th>
                                                </tr>
                                                <tr>
                                                    <td>1. Passport No: {seafarerInfo?.passportNo} </td>
                                                    <td>Issue date:  {seafarerInfo?.passportIssueDate}</td>
                                                    <td>Expiry: {seafarerInfo?.passportExpiryDate} </td>
                                                    <td>&nbsp;</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2. CDC No: {seafarerInfo?.cdcNo} </td>
                                                    <td>Issue date:{seafarerInfo?.cdcIssueDate}  </td>
                                                    <td>Expiry: {seafarerInfo?.cdcExpiryDate}</td>
                                                    <td>&nbsp;</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3. SID: {seafarerInfo?.sidNo} </td>
                                                    <td>Issue date: {seafarerInfo?.sidIssueDate} </td>
                                                    <td>Expiry: {seafarerInfo?.sidExpiryDate} </td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div className="checklist-title my-3 px-2">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <h6>B. C O C & Qualification/ Certificate: -</h6>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="checklist-data mt-2">
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th><strong></strong></th>
                                                    <th><strong></strong></th>
                                                    <th><strong></strong></th>
                                                    <th><strong>YES</strong></th>
                                                    <th><strong>No</strong></th>
                                                    <th><strong>NA</strong></th>
                                                </tr>
                                                <tr>
                                                    <td>1. COC No: {seafarerInfo?.cocNo} </td>
                                                    <td>Issue date:  {seafarerInfo?.cocIssueDate}</td>
                                                    <td>Expiry: {seafarerInfo?.cocExpiryDate} </td>
                                                    <td>&nbsp;</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2. GMDSS No: {seafarerInfo?.gmdssNo} </td>
                                                    <td>Issue date:{seafarerInfo?.gmdssIssueDate}  </td>
                                                    <td>Expiry: {seafarerInfo?.gmdssExpiryDate}</td>
                                                    <td>&nbsp;</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3.  COR(COC) No: {seafarerInfo?.corCOCNo} </td>
                                                    <td>Issue date: {seafarerInfo?.corCOCIssueDate} </td>
                                                    <td>Expiry: {seafarerInfo?.corCOCExpiryDate} </td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3.  COR(COC) No: {seafarerInfo?.corGMDSSNo} </td>
                                                    <td>Issue date: {seafarerInfo?.corGMDSSIssueDate} </td>
                                                    <td>Expiry: {seafarerInfo?.corGMDSSExpiryDate} </td>
                                                    <td> </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="checklist-title my-3 px-2">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <h6>C. Medical -</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="checklist-data mt-2">
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th><strong></strong></th>
                                                    <th><strong>YES</strong></th>
                                                    <th><strong>No</strong></th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        1. Port Health Medical report endorsed in CDC?
                                                        2.Port Health Certificate No.: {seafarerInfo?.portHealthNo}   Issue Date:  {seafarerInfo?.portHealthIssueDate} Expiry:  {seafarerInfo?.portHealthExpiryDate}
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3. COVID-19 Vaccine 1st Dose Date:{seafarerInfo?.covidFirstDose} </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>4. COVID-19 Vaccine 2nd Dose Date:{seafarerInfo?.covidSecondDose} </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>5. COVID-19 Sign / Any Symptom? :{seafarerInfo?.covidSymptom} </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>


                                            </table>
                                        </div>

                                        <div className="checklist-title my-3 px-2">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <h6>D. Training -</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="checklist-data mt-2">
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th><strong></strong></th>
                                                    <th><strong>YES</strong></th>
                                                    <th><strong>No</strong></th>
                                                </tr>

                                                <tr>
                                                    <td>1. Company’s Safety and Environmental Policy read and understood </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2. Company’s Drug and Alcohol Policy read and understood signed </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3. Have you taken ECDIS Type-specific training? If yes  Type:{seafarerInfo?.ECDISType} </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div className="checklist-title my-3 px-2">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <h6>E. Cadet and Rating</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="checklist-data mt-2">
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th><strong></strong></th>
                                                    <th><strong>YES</strong></th>
                                                    <th><strong>No</strong></th>
                                                </tr>

                                                <tr>
                                                    <td>a) Training And Record Book (TARB) In hand and up to date version for cadet </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>b) Crew onboard training record book </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </div>


                                        <div className="checklist-title my-3 px-2">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <h6>F. (All) STCW 2010 as amended.</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="checklist-data mt-2">
                                            <table className="table table-bordered">
                                                 <thead>
                                                 <th><strong>S.L</strong></th>
                                                 <th><strong>Name of Certificate</strong></th>
                                                 <th><strong>Certificate Number</strong></th>
                                                 <th><strong>Date of Issue</strong></th>
                                                 <th><strong>Place of Issue</strong></th>
                                                 <th><strong>Date of Expiry </strong></th>
                                                 <th><strong>YES</strong></th>
                                                 <th><strong>No</strong></th>
                                                 <th><strong>NA</strong></th>
                                                 </thead>
                                                <tbody>
                                                {certificateData && certificateData.length > 0 ? certificateData.map((data,index) => (
                                                    <tr key={index}>
                                                        <td>{index++}</td>
                                                        <td>{data?.name}</td>
                                                        <td>{data?.number}</td>
                                                        <td>{data?.issueDate}</td>
                                                        <td>{data?.issuePlace}</td>
                                                        <td>{data?.expiryDate}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                )) : "" }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="checklist-title my-3 px-2">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <h6>G: Familiarization</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="checklist-data mt-2">
                                            <table className="table table-bordered">

                                                <tr>
                                                    <td> <strong>Technical Department:</strong><br/>
                                                        All officer (Deck / Engine), El. Engineer,
                                                    </td>
                                                    <td>  ............................................................................<br />
                                                        Technical Superintendent Signature
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>   <strong>Safety / Security Department: </strong><br />
                                                        (Master / Ch. Officer / 2nd Office / 3rd Officer)<br/>
                                                        (Ch. Engineer / 2nd Engineer / 3rd Engineer/ 4th Engineer / El. Engineer)
                                                    </td>
                                                    <td>
                                                        ............................................................................<br/>
                                                        DPA/CSO Signature
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Operation Department ( Master, C/O, & C/E)</strong><br/>
                                                    </td>
                                                    <td>
                                                        ............................................................................<br/>
                                                        OPS Signature
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Management Level Officer, Engineers & Cadet (Deck / Engine)</strong><br/>
                                                    </td>
                                                    <td>
                                                        ............................................................................<br/>
                                                        CEO Signature <br/>
                                                        ( * In case of non-availability of CEO, SPD superintendent to complete )
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div className="checklist-data mt-2">
                                            <table className="table table-bordered">


                                                <tr>
                                                    <td>
                                                        <strong>Sea Staff</strong><br/><br/><br/>
                                                        <strong>.................................</strong><br/>
                                                        <strong>Signature</strong><br/>
                                                        <strong>Date: ............................</strong>
                                                    </td>
                                                    <td>
                                                        <strong>Marine Superintendent, SPD</strong><br/><br/><br/>
                                                        <strong>.................................</strong><br/>
                                                        <strong>Signature</strong><br/>
                                                        <strong>Date: .......................</strong>
                                                    </td>
                                                    <td>
                                                        <strong>Master (After Receive all Doc.)</strong><br/><br/><br/>
                                                        <strong>.................................</strong><br/>
                                                        <strong>Signature</strong><br/>
                                                        <strong>Date: ......................</strong>
                                                    </td>
                                                </tr>

                                            </table>
                                        </div>

                                        <div className="checklist-title my-3 px-2">
                                            <div className="row">
                                                <div className="col-12">
                                                    <small>Remarks: Master to send the completed form by E mail to SPD with signature and ships stamp. Original to
                                                        file in (C-01).
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                 </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default Checklist;