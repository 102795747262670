import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import React, {useEffect, useState} from "react";
import TopFourRank from "../../../Components/Company/Common/TopFourRank";
import axiosClient from "../../../axios-client";
import DataTable from "react-data-table-component";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Pagination from "react-js-pagination";


const NoOfContract = () => {
    const [vesselSelect,setVesselSelect] = useState('');
    const [rankSelect,setRankSelect] = useState('');
    const [status,setStatus] = useState('');
    const [reportData,setReportData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState("");
    const [dateTo,setDateTo] = useState("");


    const getReportData = (vessel = 0,rank = 0,status = null,dateFrom = '',dateTo = '',pageNumber = 1) => {
        axiosClient.post(`/company/report/seafarer-contracts/`,{
            vessel_id: vessel,
            rank_id: rank,
            status: status,
            date_from: dateFrom,
            date_to: dateTo,
            page: pageNumber
        })
            .then(({data}) => {
                console.log(data)
                setReportData(data)
            }).catch((e) => {
            console.log(e)
        })
    }





    useEffect(() => {

        let dateFromData = dateFrom != '' ?   dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1) : '';
        let dateToData = dateTo != '' ? dateTo.getFullYear() + '-' + (dateTo.getMonth() + 1) : '';
        getReportData(vesselSelect,rankSelect,status,dateFromData,dateToData,1)
    },[vesselSelect,rankSelect,status,dateFrom,dateTo])


    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-contracts/print',
        payload: { vessel_id: vesselSelect,  rank_id: rankSelect, status: status },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Seafarer Name', key: 'name', width: 20 },
        { header: 'CDC No', key: 'cdc', width: 20 },
        { header: 'Last Vessel Name', key: 'vessel', width: 20 },
        { header: 'Rank', key: 'rank_name', width: 20 },
        { header: 'No Of Contract', key: 'contract_number', width: 20 },
        { header: 'Contract With BRSML', key: 'contract_with_brsml', width: 20 },
        { header: '3 Consecutive Contracts Streak', key: 'consecutive_contract', width: 20 },
        { header: 'Total Rank Experience', key: 'total_rank_experience', width: 20 },
        { header: 'Status', key: 'status', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-contracts/export',{
            vessel_id: vesselSelect,  rank_id: rankSelect, status: status, date_from: dateFrom, date_to: dateTo,
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Top 4 Seafarers No. Of Contracts On Board','no-of-contract-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-contracts/export',{
            vessel_id: vesselSelect,  rank_id: rankSelect, status: status, date_from: dateFrom, date_to: dateTo,
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Top 4 Seafarers No. Of Contracts On Board','no-off-contract-report',exportColumns)
            }).catch(() => {
        })
    };



    return (
         <>
             <Breadcrumb title='Top 4 Seafarers No. Of Contracts On Board'   />

             <section className="content">
                 <div className="container-fluid">
                     <div className="row">
                         <div className="col-12">
                             <div className="card">
                                 <div className="card-header">
                                     <div className="card-header">
                                         <div className='row'>
                                             <div className="col-12 col-md-9">
                                                 <div className="row">
                                                     <div className='col-12 col-md-4'>
                                                         <AllVessel vesselSelect={vesselSelect} setVesselSelect={setVesselSelect} />
                                                     </div>
                                                     <div className='col-12 col-md-4'>
                                                         <TopFourRank rankSelect={rankSelect} setRankSelect={setRankSelect} />
                                                     </div>
                                                     <div className='col-12 col-md-4'>
                                                         <select style={{maxWidth: '100%'}}
                                                                 onChange={e => setStatus(e.target.value)}
                                                                 value={status}
                                                                 className="form-control"
                                                                 name="status">
                                                             <option value=""   selected>Choose Status</option>
                                                             <option value="Current">On Board</option>
                                                             <option value="Leave">Leave</option>
                                                         </select>
                                                     </div>
                                                     <div className='col-12 col-md-4'>
                                                         <DatePicker
                                                             selected={dateFrom}
                                                             dateFormat='MM-yyyy'
                                                             placeholderText="Select a from month and year"
                                                             className="form-control"
                                                             onChange={(date) => setDateFrom(date)}
                                                             showMonthYearPicker
                                                             showFullMonthYearPicker
                                                         />
                                                     </div>
                                                     <div className='col-12 col-md-4'>
                                                         <DatePicker
                                                             selected={dateTo}
                                                             dateFormat='MM-yyyy'
                                                             placeholderText="Select a to month and year"
                                                             className="form-control"
                                                             onChange={(date) => setDateTo(date)}
                                                             showMonthYearPicker
                                                             showFullMonthYearPicker
                                                         />
                                                     </div>

                                                 </div>
                                             </div>
                                             <div className="col-12 col-md-3">
                                                 <div className="float-right">
                                                     <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                     <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                     <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                                 </div>
                                             </div>
                                         </div>
                                     </div>

                                 </div>


                                 <div className="card-body  "  style={{paddingTop: '0px'}} >

                                     <table id="" className="table table-bordered table-hover seafarer-list ">
                                         <thead >
                                         <tr>
                                             <th>Seafarer Name</th>
                                             <th>CDC No</th>
                                             <th>Last Vessel Name</th>
                                             <th>Rank</th>
                                             <th>No Of Contract</th>
                                             <th>Contract With BRSML</th>
                                             <th>3 Consecutive Contracts Streak</th>
                                             <th>Total Rank Experience</th>
                                             <th>Status</th>
                                         </tr>
                                         </thead>
                                         <tbody>


                                         {reportData?.data?.length > 0 ?  reportData?.data?.map((data,index) => (

                                             <tr  key={index} >
                                                 <td>{data?.name}</td>
                                                 <td>{data?.cdc}</td>
                                                 <td>{data?.vessel_name}</td>
                                                 <td>{data?.rank_name}</td>
                                                 <td>{data?.contract_number}</td>
                                                 <td>{data?.contract_with_brsml}</td>
                                                 <td>

                                                     {
                                                         (data?.consecutive_contract != null && data?.consecutive_contract.length > 0) ? (
                                                             data?.consecutive_contract.map((d, index) => (
                                                                 <span
                                                                     key={index}
                                                                     style={{ color: d < 0 ? 'red' : 'inherit', padding: '5px' }}
                                                                 >
                                                                  {d < 0 ? Math.abs(d) : d}
                                                                  </span>
                                                             ))
                                                         ) : null
                                                     }
                                                     {
                                                         data?.left_rehire_reason != null && data?.left_rehire_reason != '' ? '(' + data?.left_rehire_reason + ')' : null
                                                     }
                                                 </td>
                                                 <td>{data?.total_rank_experience}</td>
                                                 <td><span className={`badge ${data?.status  == 'Current' ? 'badge-success' : 'badge-danger'}`}  >{data?.status == 'Leave' ? 'Leave' : 'On Board'}</span></td>


                                             </tr>
                                         )) : <tr><td colSpan={13} className='text-center'>No Data Found</td></tr>}



                                         </tbody>

                                     </table>

                                     <div className="mt-2 justify-content-center d-flex">




                                         <Pagination
                                             activePage={reportData?.pagination?.current_page ? reportData?.pagination?.current_page : 0}
                                             itemsCountPerPage={reportData?.pagination?.per_page ? reportData?.pagination?.per_page : 0 }
                                             totalItemsCount={reportData?.pagination?.total ? reportData?.pagination?.total : 0}
                                             onChange={(pageNumber) => {
                                                 let dateFromData = dateFrom != '' ?   dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1) : '';
                                                 let dateToData = dateTo != '' ? dateTo.getFullYear() + '-' + (dateTo.getMonth() + 1) : '';
                                                 getReportData(vesselSelect,rankSelect,status,dateFromData,dateToData,pageNumber)
                                             }}
                                             pageRangeDisplayed={8}
                                             itemClass="page-item"
                                             linkClass="page-link"

                                         />
                                     </div>

                                 </div>



                             </div>
                         </div>
                     </div>
                 </div>
             </section>
         </>
    );
};

export default NoOfContract;