import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {Link, useParams} from "react-router-dom";
import Pagination from "react-js-pagination";

const PortHealthDocument = () => {
    const {id} = useParams();
    const [reportData,setReportData] = useState([])


    const getReportData = (pageNumber = 1) => {
        axiosClient.get(`/company/guest/port-health-document/${id}`)
            .then(({data}) => {
                setReportData(data?.data)
            }).catch((e) => {
        })
    }

    useEffect(() => {
        getReportData(1)
    },[])



    return (
        <>
            <Breadcrumb title='Port Health Document  List'   />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">


                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Document Type</th>
                                            <th>File Name</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData?.length > 0 && reportData?.map((data,index) => {
                                                return (
                                                    <tr>
                                                        <td>{index+1}</td>
                                                        <td>{data?.type}</td>
                                                        <td>{data?.title}</td>
                                                        <td>
                                                            <a className="btn  btn-info btn-sm" href={data?.file} target="_blank"><i className="fa fa-eye"> </i></a>
                                                        </td>


                                                    </tr>
                                                )
                                            })
                                        }



                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PortHealthDocument;