import React, {useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import {useNavigate} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import * as UserRoute from "../../../../constants/UserRoute";
import ExperienceForm from "../../../../Components/Profile/Experience/ExperienceForm";

const CreateExperience = () => {
    const navigate = useNavigate();
    const [disable,setDisable] = useState(false);
    const [form,setForm] = useState({
        user_id:"",
        vessel_name:"",
        manning_company:"",
        shipping_company:"",
        flag:"",
        vessel_type_id:"",
        dwt:null,
        grt:null,
        engine_type:"",
        bhp:null,
        cargo_crane:"",
        category_id:"",
        from:"",
        to:"",
    });



    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.post('/seafarer/experience', form)
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(UserRoute.USER_PROFILE, { state: { type:'experience' } });
                },1000);
                setDisable(false)

            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    return (
        <>
            <Breadcrumb title='Add New Experience'  prev={UserRoute.USER_PROFILE} state="experience"  />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-12  ">
                        <form onSubmit={onSubmit}>
                            <ExperienceForm form={form} setForm={setForm}  disable={disable}   />
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CreateExperience;