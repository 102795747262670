import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";

const CrewRemoveModal = ({data,hide}) => {

    const navigate = useNavigate();
    const {id} = useParams()


    const [portData,setPortData] = useState([]);
    const [countryData,setCountryData] = useState([]);

    const [signOffDate,setSignOffDate] = useState("");
    const [arrivalDate,setArrivalDate] = useState("");
    const [readinessDate,setReadinessDate] = useState("");
    const [signOffPort,setSignOffPort] = useState("");
    const [country,setCountry] = useState("");
    const [emergencySignOff,setEmergencySignOff] = useState(false);
    const [emergencyDeviationTo, setEmergencyDeviationTo] = useState('');
    const [emergencyDeviationPosition, setEmergencyDeviationPosition] = useState('');
    const [emergencyDeviationDate, setEmergencyDeviationDate] = useState('');
    const [emergencyDeviationTime, setEmergencyDeviationTime] = useState('');
    const [emergencyArrivalDate, setEmergencyArrivalDate] = useState('');
    const [emergencyArrivalTime, setEmergencyArrivalTime] = useState('');
    const [emergencyDepartureDate, setEmergencyDepartureDate] = useState('');
    const [emergencyDepartureTime, setEmergencyDepartureTime] = useState('');
    const [emergencyOnHireDate, setEmergencyOnHireDate] = useState('');
    const [emergencyOnHireTime, setEmergencyOnHireTime] = useState('');
    const [emergencyRemarks, setEmergencyRemarks] = useState('');


    const [illness,setIllness] = useState(false)
    const [medicalHistory,setMedicalHistory] = useState("")

    const [leftRehire,setLeftRehire] = useState(false)
    const [leftRehireForm,setLeftRehireForm] = useState({
        contract_no: '',
        contract_remarks:'',
        termination:'',
        reasons:'',
        termination_remarks:'',

    })

    const UT_reasons = ["Retirements", "Illness", "Left After Few Contracts(better opportunity)", "Others"];
    const BT_reasons = ["Under Performance", "Others"];

    const [terminationReason, setTerminationReason] = useState([]);

    useEffect(() => {
        let reasonsArray = [];
        if (leftRehireForm.termination === "Unavoidable Terminations") {
            reasonsArray = UT_reasons;
        }
        if (leftRehireForm.termination === "Beneficial Terminations") {
            reasonsArray = BT_reasons;
        }
        setTerminationReason(reasonsArray);
    }, [leftRehireForm.termination]);

    const getPort = (dateFromData = null) => {
        axiosClient.get('/company/get-port')
            .then(({data}) => {
                setPortData(data?.data)
            }).catch(() => {
        })
    }

    const getCountry = (dateFromData = null) => {
        axiosClient.get('/company/get-country')
            .then(({data}) => {
                setCountryData(data?.data)
            }).catch(() => {
        })
    }


    useEffect(() => {
        getPort()
        getCountry()
    },[])

    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosClient.post('/company/vessel-crew-remove',{
            crews_id: data.crews_id,
            sign_off: signOffDate,
            embarkation: arrivalDate,
            country_id: country,
            port_id: signOffPort,

            availability_date: readinessDate,
            emp_id: data.emp_id,

            emergency: emergencySignOff,
            deviation_to: emergencyDeviationTo,
            deviation_position: emergencyDeviationPosition,
            deviation_date: emergencyDeviationDate,
            deviation_time: emergencyDeviationTime,
            arrival_date: emergencyArrivalDate,
            arrival_time: emergencyArrivalTime,
            departure_date: emergencyDepartureDate,
            departure_time: emergencyDepartureTime,
            hire_date: emergencyOnHireDate,
            hire_time: emergencyOnHireTime,
            remarks: emergencyRemarks,

            illness: illness,
            medical_details: medicalHistory,

            left_rehire: leftRehire,
            contract_no: leftRehireForm.contract_no,
            contract_remarks: leftRehireForm.contract_remarks,
            termination: leftRehireForm.termination,
            reasons: leftRehireForm.reasons,
            termination_remarks: leftRehireForm.termination_remarks,

        })
            .then(({data}) => {
                toast.success(data.message);
                hideModal()
                setTimeout(() => {
                    window.location.reload();
                },1000)

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    const hideModal = () => {
        hide(false)
    }


    return (
        <div className="modal show fade "  style={modelStyle} >
            <div className="modal-dialog modal-xl">
                <form onSubmit={handleSubmit}>
                    <div className="modal-content" style={{overflowY:'scroll', height:'600px'}}>
                        <div className="modal-header">
                            <h5 className="modal-title">Remove Crew </h5>
                        </div>
                        <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Sign-off date</label>
                                    <DatePicker
                                        selected={signOffDate}
                                        dateFormat='dd-MM-yyyy'
                                        className="form-control"
                                        onChange={(date) => setSignOffDate(date)}
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Arrival date(Home port)</label>
                                    <DatePicker
                                        selected={arrivalDate}
                                          dateFormat='dd-MM-yyyy'
                                        className="form-control"
                                        onChange={(date) => setArrivalDate(date)}
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Join Readiness  Date</label>
                                    <DatePicker
                                        selected={readinessDate}
                                          dateFormat='dd-MM-yyyy'
                                        className="form-control"
                                        onChange={(date) => setReadinessDate(date)}
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Sign Off Port</label>
                                    <select name="" id="" className="form-control"
                                            onChange={(e) => setSignOffPort(e.target.value)}
                                            value={signOffPort}
                                    >
                                        <option value="">Select</option>
                                        {
                                            portData?.length > 0 ? portData?.map((item,index) => {
                                                    return(
                                                        <option key={index} value={item?.id}>{item?.name}</option>
                                                    )
                                                }
                                            ) : ''
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Country</label>
                                    <select name="" id="" className="form-control"
                                            onChange={(e) => setCountry(e.target.value)}
                                            value={country}
                                    >
                                        <option value="">Select</option>
                                        {
                                            countryData?.length > 0 ? countryData?.map((item,index) => {
                                                    return(
                                                        <option key={index} value={item?.country_id}>{item?.country_name}</option>
                                                    )
                                                }
                                            ) : ''
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className=" col-12">
                            <div className="form-group">
                                <p>
                                    <input type="checkbox" onChange={(e) => setEmergencySignOff(e.target.checked) } checked={emergencySignOff}   className="filled-in"
                                    />
                                    <label className="text-black ml-2" htmlFor="illnessCheckbox"> Emergency Sign Off</label>
                                </p>
                            </div>
                        </div>

                        {
                            emergencySignOff ?
                                <>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Deviation To (Port)</label>
                                                <select name="" id="" className="form-control"
                                                        onChange={(e) => setEmergencyDeviationTo(e.target.value)}
                                                        value={emergencyDeviationTo}
                                                >
                                                    <option value="">Select</option>
                                                    {
                                                        portData?.length > 0 ? portData?.map((item,index) => {
                                                                return(
                                                                    <option key={index} value={item?.id}>{item?.name}</option>
                                                                )
                                                            }
                                                        ) : ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Deviation Position</label>
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setEmergencyDeviationPosition(e.target.value)}
                                                       value={emergencyDeviationPosition}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Deviation Date(UTC)</label>
                                                <DatePicker
                                                    selected={emergencyDeviationDate}
                                                      dateFormat='dd-MM-yyyy'
                                                    className="form-control"
                                                    onChange={(date) => setEmergencyDeviationDate(date)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Deviation Time(UTC)</label>
                                                <TimePicker
                                                    className="form-control"
                                                    onChange={setEmergencyDeviationTime}
                                                    value={emergencyDeviationTime}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Arrival Date(UTC)</label>
                                                <DatePicker
                                                    selected={emergencyArrivalDate}
                                                      dateFormat='dd-MM-yyyy'
                                                    className="form-control"
                                                    onChange={(date) => setEmergencyArrivalDate(date)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Arrival Time(UTC)</label>

                                                <TimePicker
                                                    className="form-control "
                                                    onChange={setEmergencyArrivalTime}
                                                    value={emergencyArrivalTime}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Departure Date(UTC)</label>
                                                <DatePicker
                                                    selected={emergencyDepartureDate}
                                                      dateFormat='dd-MM-yyyy'
                                                    className="form-control"
                                                    onChange={(date) => setEmergencyDepartureDate(date)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Departure Time(UTC)</label>
                                                <TimePicker
                                                    className="form-control"
                                                    onChange={setEmergencyDepartureTime}
                                                    value={emergencyDepartureTime}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">On Hire Date(UTC)</label>
                                                <DatePicker
                                                    selected={emergencyOnHireDate}
                                                      dateFormat='dd-MM-yyyy'
                                                    className="form-control"
                                                    onChange={(date) => setEmergencyOnHireDate(date)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">On Hire Time(UTC)</label>
                                                <TimePicker
                                                    className="form-control"
                                                    onChange={setEmergencyOnHireTime}
                                                    value={emergencyOnHireTime}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Remarks</label>
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setEmergencyRemarks(e.target.value)}
                                                       value={emergencyRemarks}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : ''
                        }

                        <div className=" col-12">
                            <div className="form-group">
                                <p>
                                    <input type="checkbox" onChange={(e) => setIllness(e.target.checked) } checked={illness}   className="filled-in"
                                    />
                                    <label className="text-black ml-2" > Any Illness During On-Board Period </label>
                                </p>
                            </div>
                        </div>
                        {
                            illness ?   <div className=" col-12">
                                <div className="form-group">
                                    <label htmlFor="">Medical History Details</label>
                                    <textarea className="form-control"
                                              onChange={(e) => setMedicalHistory(e.target.value)}
                                              value={medicalHistory}>
                                        </textarea>
                                </div>
                            </div> : ''
                        }

                        <div className=" col-12">
                            <div className="form-group">
                                <p>
                                    <input type="checkbox" onChange={(e) => setLeftRehire(e.target.checked) } checked={leftRehire}   className="filled-in"
                                    />
                                    <label className="text-black ml-2" >  Left/Rehire </label>
                                </p>
                            </div>
                        </div>
                        {
                            leftRehire ? <>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="rank">Leaving Contract No.</label>
                                                <input onChange={e => setLeftRehireForm({...leftRehireForm,contract_no:e.target.value})}  className='form-control'  type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="rank">Leaving Contract Remarks.</label>
                                                <input onChange={e => setLeftRehireForm({...leftRehireForm,contract_remarks:e.target.value})}  className='form-control'  type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="rank">Termination Type.</label>
                                                <select name="termination" className="form-control" onChange={e => setLeftRehireForm({...leftRehireForm,termination:e.target.value})}
                                                        id="termination">
                                                    <option value="" disabled selected>Choose one</option>
                                                    <option value="Unavoidable Terminations">Unavoidable Terminations </option>
                                                    <option value="Beneficial Terminations">Beneficial Terminations </option>
                                                    <option value="Not Applicable">Not Applicable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="rank">Termination Reason.</label>
                                                <select id="termination_reason"  onChange={e => setLeftRehireForm({...leftRehireForm,reasons:e.target.value})}   className="form-control">
                                                    <option value="">Choose one</option>
                                                    {terminationReason.map((reason) => (
                                                        <option key={reason} value={reason}>{reason}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="rank">Termination Remarks.</label>
                                                <input  onChange={e => setLeftRehireForm({...leftRehireForm,termination_remarks:e.target.value})}     className='form-control'  type="text"/>
                                            </div>
                                        </div>
                                    </div>

                                </>
                                : ''
                        }

                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default" onClick={hideModal}  >Close</button>
                        <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                </div>
            </form>
            </div>
        </div>
    );
};

export default CrewRemoveModal;