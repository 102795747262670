import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";

const VisaEditForm = ({documentForm,setDocumentForm,disabled}) => {
    const [country,setCountry] = useState([]);
    const getCountryData = () => {
        axiosClient.get('/get-country')
            .then(({data}) => {
                setCountry(data.data)
            }).catch(() => {

        })
    }


    useEffect(() => {
        getCountryData()
    },[])
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Select Visa</label>
                            <select onChange={e => setDocumentForm({...documentForm,passport_type:e.target.value})} className="form-control">
                                <option value=""    >Choose Visa</option>
                                <option selected={documentForm.passport_type === 'US Visa'} value="US Visa">US Visa</option>
                                <option selected={documentForm.passport_type === 'Schengen Visa'} value="Schengen Visa">Schengen Visa</option>
                            </select>

                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="rank">Visa Number </label>
                        <input className='form-control  '  onChange={e => setDocumentForm({...documentForm,visa_number:e.target.value})} value={documentForm.visa_number} placeholder="Enter Visa Number" type="text"/>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="rank">Issue Date  </label>
                        <DatePicker
                            selected={documentForm?.issue_date ? new Date(new Date(documentForm.issue_date)) : ''}
                            dateFormat='dd-MM-yyyy'
                            className="form-control"
                            onChange={e => setDocumentForm({...documentForm,issue_date:e})}
                            showMonthDropdown
                            showYearDropdown
                        />
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="rank">Expiry  Date  </label>
                        <DatePicker
                            selected={documentForm?.expiry_date ? new Date(new Date(documentForm.expiry_date)) : ''}
                            dateFormat='dd-MM-yyyy'
                            className="form-control"
                            onChange={e => setDocumentForm({...documentForm,expiry_date:e})}
                            showMonthDropdown
                            showYearDropdown
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="rank">Issue at </label>
                        <input className='form-control  '  onChange={e => setDocumentForm({...documentForm,issued_at:e.target.value})} value={documentForm.issued_at} placeholder="Enter Issue at" type="text"/>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="mother_name">Select Country</label>
                        <select style={{maxWidth: '100%'}}
                                onChange={e => setDocumentForm({...documentForm,country_id:e.target.value})}
                                value={documentForm.country_id}
                                className="form-control"
                        >
                            <option  value="" selected>Select Country</option>
                            {country.map((item,index) => (
                                <option key={index} selected={documentForm.country_id === item?.country_id}   value={item?.country_id}>{item?.country_name}</option>
                            ))}

                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <button className="btn btn-info" type="submit">{
                            disabled ? 'Saving...' : 'Save'
                        }</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default VisaEditForm;