import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import useDatePicker from "../../../../hooks/useDatePicker";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import PassportAddForm from "../../../../Components/Profile/DocumentVisa/PassportAddForm";
import * as UserRoute from "../../../../constants/UserRoute";

const Passport = () => {
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate()


    const [documentForm,setDocumentForm] = useState({
        user_id:id,
        type:location.state.type,
        number:'',
        issue_date:'',
        expiry_date:'',
        issue_at:'',
        country_id:""
    });

    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/seafarer/document-store', documentForm)
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(UserRoute.USER_PROFILE, { state: { type:'documentVisa' } });
                },1000);

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    return (
        <div>
            <Breadcrumb title={location.state.title} prev={UserRoute.USER_PROFILE} state="documentVisa"   />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-6 col-12">
                       <form  onSubmit={onSubmit}>
                           <PassportAddForm documentForm={documentForm} setDocumentForm={setDocumentForm} location={location} />
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Passport;