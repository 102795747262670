import {Link, useParams} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import CertificationList from "../../Profile/Certification/CertificationList";

const Certification = ({certificationData}) => {
    const {id} = useParams();

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete ${data?.certificate_title}?`)){
            return false;
        }
        axiosClient.delete(`/seafarer/certification/${data?.certificate_id}`)
            .then((data) => {
                toast.success(data.data.message);
                setTimeout(() => {
                    window.location.reload();
                },1000)
            })
    }




    return (
        <>
            <div className="col-12 ">
                <div className="float-right">
                    <Link to={`${COMPANYROUTE.ADD_NEW_CERTIFICATION}${id}`} className="btn btn-success mb-2">Add New <i className="fa fa-plus-circle"></i></Link>
                </div>
            </div>

            <CertificationList certificationData={certificationData} onDelete={onDelete} route={COMPANYROUTE.CERTIFICATION_EDIT} />
             
        </>
    );
};

export default Certification;