import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component';
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";

const CompanyUserLoginHistory = () => {
    const [userSelect,setUserSelect] = useState('');
    const [reportData,setReportData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState(reportData);

    const getReportData = (userSelect = 0,dateFromData = null,dateToData = null) => {
        axiosClient.post('/company/login-history',{
            user_id: userSelect,
            date_from: dateFromData,
            date_to: dateToData
         })
            .then(({data}) => {
                setReportData(data.data)
            }).catch(() => {
        })
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row?.first_name + ' ' + row?.last_name,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Role',
            selector: row => row.role_id == 1 ? 'Super Admin' : row.role_id == 2 ? 'General Admin' : row.role_id == 4 ? 'Priviliged User' : '',
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Time',
            selector: row => row.time,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Remarks',
            selector: row => row?.type == 'in' ? 'Login' : row?.type == 'out' ? 'Logout' : '',
            sortable: true,
            className: 'header-row',
        },
    ];





    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getReportData(userSelect,dateFromData,dateToData)

    },[userSelect,dateFrom,dateTo])

    useEffect(() => {
        setData(reportData)
    },[reportData])


    const [companyUser,setCompanyUser] = useState([])


    const getCompanyUser = () => {
        axiosClient.get('/company/company-users')
            .then(({data}) => {
                setCompanyUser(data?.data)
            }).catch((e) => {
        })
    }

    useEffect(() => {
        getCompanyUser()
    },[])


    const { handlePrint, loading } = usePrintWindow({
        url: '/company/login-history/print',
        payload: { user_id: userSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString() },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Name', key: 'name', width: 20 },
        { header: 'Mobile', key: 'mobile', width: 20 },
        { header: 'Role', key: 'role', width: 20 },
        { header: 'Date', key: 'date', width: 20 },
        { header: 'Time', key: 'time', width: 20 },
        { header: 'Remarks', key: 'remarks', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/login-history/export',{
            user_id: userSelect, date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Login History Report','login-history-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/login-history/export',{
            user_id: userSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Login History Report','login-history-report',exportColumns)
            }).catch(() => {
        })
    };





    return (
        <>
            <Breadcrumb title='Login History'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-4'>
                                                    <select className="form-control" value={userSelect} onChange={(e) => setUserSelect(e.target.value)}>
                                                        <option value="">All User</option>
                                                        {
                                                            companyUser?.length > 0 &&  companyUser.map((item,index) => {
                                                                return(
                                                                    <option key={index} value={item.id}>{item?.first_name + ' ' + item?.last_name} ({item?.mobile})</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateFrom}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateFrom(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateTo}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateTo(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="float-right">
                                                <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="card-body  ">

                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        pagination={true}
                                        paginationPerPage={30}
                                        paginationRowsPerPageOptions={[30, 50, 100,500]}
                                        fixedHeader={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CompanyUserLoginHistory;