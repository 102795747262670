import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axiosClient from "../../../axios-client";
import DatePicker from "react-datepicker";

am4core.useTheme(am4themes_animated);
const SupplyDemandChart = ({vessels,ranks}) => {

    const [supplyData,setSupplyData] = useState([]);
    const [demandData,setDemandData] = useState([]);
    const [vesselSelect,setVesselSelect] = useState('');
    const [rankSelect,setRankSelect] = useState('');
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));


    const getChartData = (vessel = 0,rank = 0,dateFromData = null,dateToData = null) => {
        axiosClient.post('/company/chart/supply-demand',{
            vessel_id: vessel,
            category_id: rank,
            date_from: dateFromData,
            date_to: dateToData
        })
            .then(({data}) => {
                setSupplyData(data.data[0])
                setDemandData(data.data[1])
            }).catch(() => {
        })
    }

    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getChartData(vesselSelect,rankSelect,dateFromData,dateToData)
    },[vesselSelect,rankSelect,dateFrom,dateTo])

    useLayoutEffect(() => {
        let chart = am4core.create("supplyDemandChart",  am4charts.XYChart);
        chart.logo.disabled = true;

        chart.data = [{
            "name": "Master",
            "supply": supplyData[0],
            "demand": demandData[0],
        },{
            "name": "Chief Engineer",
            "supply": supplyData[1],
            "demand": demandData[1],
        },{
            "name": "Chief Officer",
            "supply": supplyData[2],
            "demand": demandData[2],
        },{
            "name": "2nd Engineer",
            "supply": supplyData[3],
            "demand": demandData[3],
        } ];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;

        var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "[bold]No of Seafarer's[/]";

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "supply";
        series.dataFields.categoryX = "name";
        series.name = "Supply";
        series.tooltipText = "{name} Total Supply: [bold]{valueY}[/]";
        series.columns.template.fill = am4core.color("#17a2b8");


        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "demand";
        series2.dataFields.categoryX = "name";
        series2.name = "Demand";
        series2.tooltipText = "{name} Total Demand : [bold]{valueY}[/]";
        series2.columns.template.fill = am4core.color("#ffc107");

        chart.cursor = new am4charts.XYCursor();

        chart.legend = new am4charts.Legend();



        return () => {
            chart.dispose();
        };
    }, [supplyData,demandData]);

    return (
        <>
            <div className="card card-warning">
                <div className="card-header">
                    <h3 className="card-title">Top 4 Seafarer Supply Demand Chart </h3>

                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 mt-1">
                            <select style={{maxWidth: '100%',background:'navy',color:'white'}}
                                    onChange={e => setVesselSelect(e.target.value)}
                                    value={vesselSelect}
                                    className="form-control"
                            >
                                <option  value="0" selected>All Vessel</option>
                                {vessels.map((vessel,index) => (
                                    <option key={index} value={vessel?.vessel_id}>{vessel?.vessel_name}</option>
                                ))}

                            </select>
                        </div>
                        <div className="col-md-3 mt-1">
                            <DatePicker
                                selected={dateFrom}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={(date) => setDateFrom(date)}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                        <div className="col-md-3 mt-1">
                            <DatePicker
                                selected={dateTo}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={(date) => setDateTo(date)}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                        <div className="col-md-3 mt-1">
                            <select style={{maxWidth: '100%',background:'navy',color:'white'}}
                                    onChange={e => setRankSelect(e.target.value)}
                                    value={rankSelect}
                                    className="form-control"
                            >
                                <option  value="0" selected>All Ranks</option>
                                {ranks.map((rank,index) => (
                                    <option key={index} value={rank?.category_id}>{rank?.category_name}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                    <div className="chart">
                        <div id="supplyDemandChart" style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default SupplyDemandChart;

