import React, {useState} from 'react';

import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import DatePicker from "react-datepicker";

const Create = () => {
    const navigate = useNavigate();
    const [disabled,setDisabled] = useState(false);
    const {id} = useParams();
    const [form,setForm] = useState({
        user_id:id,
        date:'',
        day:'',
        agreement_month:'',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        axiosClient.post('/company/terms-condition/store', form)
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                navigate(`${COMPANYROUTE.TERMS_CONDITION}${id}`);
            }).catch(err => {

            setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    return (
        <div>
            <Breadcrumb title='Add New Terms Condition' prev={`${COMPANYROUTE.TERMS_CONDITION}${id}`}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Date</label>
                                                    <DatePicker
                                                        selected={form?.date ? new Date(new Date(form.date)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,date:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="day">Day</label>
                                                  <select className="form-control"
                                                          id="day"
                                                          value={form.day}
                                                          onChange={(e) => setForm({...form,day:e.target.value})}
                                                  >
                                                      <option value=""   selected>Choose your option</option>
                                                      <option value="Sunday">Sunday</option>
                                                      <option value="Monday">Monday</option>
                                                      <option value="Tuesday">Tuesday</option>
                                                      <option value="Wednesday">Wednesday</option>
                                                      <option value="Thursday">Thursday</option>
                                                      <option value="Friday">Friday</option>
                                                      <option value="Saturday">Saturday</option>
                                                  </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">Agreement Month</label>
                                                    <input type="text"
                                                              value={form.agreement_month}
                                                                onChange={(e) => setForm({...form,agreement_month:e.target.value})}
                                                           name="mobile" className="form-control" id="mobile"
                                                           placeholder="Enter Agreement Month" />
                                                </div>
                                            </div>



                                        </div>


                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">{
                                            disabled ? 'Save.....' : 'Save'
                                        }</button>
                                    </div>
                                </form>
                            </div>

                </div>
            </section>
        </div>

    );
};

export default Create;