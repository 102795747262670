import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import CrewPerfomanceModal from "./CrewPerfomanceModal";
import CrewDocumentReviewModal from "./CrewDocumentReviewModal";

const ContractPeriodModal = ({data,hide}) => {


    const [month,setMonth] = useState(0)
    const [modal,setModal] = useState(false)



    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(month <= 0){
            toast.error('Please Enter Month')
        }else {
            setModal(true)
        }
    }


    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-md">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title">ENTER CONTRACT PERIOD </h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="">Enter Month</label>
                                    <input value={month} onChange={(e) => setMonth(e.target.value) } type="number" className="form-control"/>
                                </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                            <button type="submit" className="btn btn-success">Check Validity</button>

                        </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                modal === true && <CrewDocumentReviewModal data={data} month={month} modalHide={() => setModal(false)}    />
            }
        </>
    );
};

export default ContractPeriodModal;