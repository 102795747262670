import React, { useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';

const AddDocument = () => {
     const [loading,setLoading] = useState(false);
    const [progress, setProgress] = useState(100);
     const navigate = useNavigate()
    const location = useLocation();



    const [form,setForm] = useState({
        title:'',
        doc_file:null,
        status: location?.state?.type === 'pre-joining' ? 1 : 0,
    })


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axiosClient.post('/company/company-document',form,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                toast.success(data.message);
                setLoading(false);
                setTimeout(() => {
                    navigate(-1);
                },1000);

            }).catch(err => {
            setLoading(false);
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }








    return (
        <div>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Add Company  Document' backButton={true}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={onSubmit}>
                                    {
                                        location?.state?.type === 'pre-joining' &&
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mx-2">
                                                    <label htmlFor="title" > <small className="ml-1">Document Title</small></label>
                                                    <select name="title" className="form-control" onChange={e => setForm({...form,title:e.target.value})}        >
                                                        <option value="">Select Document</option>
                                                        <option value="Company Organization and Structure">Company Organization and Structure</option>
                                                        <option value="Company’s Vision and Mission">Company’s Vision and Mission</option>

                                                        <optgroup label="Key Policies">
                                                            <option value="Maintenance and Spare Parts Policy">Maintenance and Spare Parts Policy</option>
                                                            <option value="Environmental Policy">Environmental Policy</option>
                                                            <option value="Health, Safety and Quality Policy">Health, Safety and Quality Policy</option>
                                                            <option value="Data Protection and Cyber Policy">Data Protection and Cyber Policy</option>
                                                            <option value="Drug and Alcohol Policy">Drug and Alcohol Policy</option>
                                                            <option value="Work and Rest Hour Policy">Work and Rest Hour Policy</option>
                                                            <option value="Security Policy">Security Policy</option>
                                                        </optgroup>

                                                        <optgroup label="Key Safety Management System Procedures">
                                                            <option value="Risk Assessment">Risk Assessment</option>
                                                            <option value="Hot work">Hot work</option>
                                                            <option value="Permit to Work system">Permit to Work system</option>
                                                            <option value="Planned Maintenance System">Planned Maintenance System</option>
                                                            <option value="Bunkering Operations">Bunkering Operations</option>
                                                            <option value="Navigation">Navigation</option>
                                                            <option value="Cargo Operations">Cargo Operations</option>
                                                            <option value="Crew Complaints Procedure">Crew Complaints Procedure</option>
                                                        </optgroup>

                                                        <option value="Company Circulars">Company Circulars</option>
                                                        <option value="PSC Detainable Deficiencies">PSC Detainable Deficiencies</option>
                                                        <option value="Company Incident Sharing">Company Incident Sharing</option>
                                                        <option value="Good Health Habits">Good Health Habits</option>

                                                        <optgroup label="Ship Familiarization Tour">
                                                            <option value="List of Life Saving Appliances">List of Life Saving Appliances</option>
                                                            <option value="List of Fire Fighting Appliances">List of Fire Fighting Appliances</option>
                                                            <option value="Muster Lists">Muster Lists</option>
                                                            <option value="Video Tour">Video Tour</option>
                                                            <option value="Cargo Equipment">Cargo Equipment</option>
                                                        </optgroup>

                                                        <option value="Test On Pre-joining Familiarization">Test On Pre-joining Familiarization</option>
                                                    </select>

                                            </div>
                                            </div>


                                        </div>
                                    }

                                    {
                                        location?.state?.type === 'company-document' &&
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mx-2">
                                                    <label htmlFor="title" > <small className="ml-1">Document Title</small></label>
                                                    <input name="title" className="form-control" onChange={e => setForm({...form,title:e.target.value})}     type="text" id="title"   />
                                                </div>
                                            </div>


                                        </div>
                                    }

                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mx-2">
                                                <label htmlFor="file" > <small className="ml-1">Document File</small></label>
                                                <input name="file" className="form-control" onChange={e => setForm({...form,doc_file:e.target.files[0]})}     type="file" id="file"   />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        {
                                            loading ? <button className="btn btn-success" type="button" disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</button> :
                                                <button className="btn btn-success" type="submit">Save <i className="fas fa-paper-plane"></i></button>
                                        }

                                    </div>

                                </form>
                            </div>

                </div>
            </section>
        </div>
    );
};

export default AddDocument;