import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import * as UserRoute from "../../../../constants/UserRoute";


const Sidebar = () => {

    let location = window.location.pathname;






    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">

            {/*<a href="src/Components/Company/Layout/Company/Sidebar#" className="brand-link">*/}
            {/*    <img src="/asset/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"*/}
            {/*         className="brand-image img-circle elevation-3"   />*/}
            {/*        <span className="brand-text font-weight-light">AdminLTE 3</span>*/}
            {/*</a>*/}

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    {/*<div className="image">*/}
                    {/*    <img src="/asset/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />*/}
                    {/*</div>*/}
                    <div className="info">
                        <Link  className="d-block" to={UserRoute.DASHBOARD}>DEMO</Link>
                    </div>
                </div>


                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li className="nav-item ">
                            <Link to={UserRoute.DASHBOARD} className={`nav-link ${location == UserRoute.DASHBOARD ? 'active' : ''}`}>
                                <i className="nav-icon fa fa-tachometer-alt"></i>
                                <p>
                                    Dashboard
                                </p>
                            </Link>
                        </li>

                        <li className="nav-item ">
                            <Link state={{type:'personalInfo'}} to={UserRoute.PROFILE} className={`nav-link ${location == UserRoute.PROFILE ? 'active' : ''}`}>
                                <i className="nav-icon fa fa-user-alt"></i>
                                <p>
                                    Profile
                                </p>
                            </Link>
                        </li>

                        <li className="nav-item ">
                            <Link   to={UserRoute.SEAFARER_DOCUMENTS} className={`nav-link ${location == UserRoute.SEAFARER_DOCUMENTS ? 'active' : ''}`}>
                                <i className="nav-icon fa fa-file"></i>
                                <p>
                                    Document
                                </p>
                            </Link>
                        </li>



                    </ul>
                </nav>

            </div>
        </aside>
    );
};

export default Sidebar;