import React from 'react';
import DatePicker from "react-datepicker";

const FamilyForm = ({form,setForm,disable}) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Relationship</label>
                            <input className='form-control' value={form.relationship}  onChange={e => setForm({...form,relationship:e.target.value})} type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">First Name  </label>
                            <input className='form-control  ' value={form.firstname}  onChange={e => setForm({...form,firstname:e.target.value})} type="text"/>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Last Name  </label>
                            <input className='form-control' value={form.lastname}  onChange={e => setForm({...form,lastname:e.target.value})}  type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Date Of Birth</label>
                            <DatePicker
                                selected={form?.dob ? new Date(new Date(form.dob)) : ''}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={e => setForm({...form,dob:e})}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-info">{
                            disable ? 'Saving...' : 'Save'
                        }</button>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default FamilyForm;