import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";

const PersonalInfoForm = ({setUser,user,setEmpData,empData,disabled}) => {
    const [ranks,setRanks] = useState([]);
    const [country,setCountry] = useState([]);

    const getRankData = () => {
        axiosClient.get('/get-ranks')
            .then(({data}) => {
                setRanks(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const getCountryData = () => {
        axiosClient.get('/get-country')
            .then(({data}) => {
                setCountry(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    useEffect(() => {
        getRankData();
        getCountryData()
    },[])
    return (
        <div className="row">
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <input onChange={e => setUser({...user,first_name:e.target.value})} value={user.first_name}  className='form-control' id="first_name" placeholder="Enter First Name" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="middle_name">Middle Name</label>
                    <input  onChange={e => setUser({...user,middle_name:e.target.value})} value={user.middle_name} className='form-control' id="middle_name" placeholder="Enter Middle Name" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <input  onChange={e => setUser({...user,last_name:e.target.value})} value={user.last_name} id="last_name" className='form-control' placeholder="Enter Last Name" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="father_name">Father Name</label>
                    <input  onChange={e => setEmpData({...empData,father_name:e.target.value})} value={empData?.father_name} id="father_name" className='form-control' placeholder="Enter Father Name" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="mother_name">Mother Name</label>
                    <input  onChange={e => setEmpData({...empData,mother_name:e.target.value})} value={empData?.mother_name} id="mother_name" className='form-control' placeholder="Enter Mother Name" type="text"/>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="mother_name">Select Rank</label>
                    <select style={{maxWidth: '100%'}}
                            onChange={e => setEmpData({...empData,category_id:e.target.value})}
                            className="form-control"
                    >
                        <option  value="" selected>Select Rank</option>
                        {ranks.map((rank,index) => (
                            <option key={index} selected={empData?.category_id === rank?.category_id}  value={rank?.category_id}>{rank?.category_name}</option>
                        ))}

                    </select>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="email">Email </label>
                    <input  onChange={e => setUser({...user,email:e.target.value})} value={user.email} id="email" className='form-control' placeholder="Enter Email" type="email"/>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="mobile">Mobile </label>
                    <input  onChange={e => setUser({...user,mobile:e.target.value})} value={user.mobile} id="mobile" className='form-control' placeholder="Enter Mobile Number" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="mother_name">NID</label>
                    <input  onChange={e => setEmpData({...empData,nid:e.target.value})} value={empData?.nid} id="nid" className='form-control' placeholder="Enter Nid Number" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="birth_certificate">Birth Certificate</label>
                    <input  onChange={e => setEmpData({...empData,birth_certificate:e.target.value})} value={empData?.birth_certificate} id="birth_certificate" className='form-control' placeholder="Enter Birth Certificate" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="telephone">Telephone</label>
                    <input  onChange={e => setEmpData({...empData,telephone:e.target.value})} value={empData?.telephone} id="telephone" className='form-control' placeholder="Enter Telephone Number" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="telephone">Date Of Birth</label>
                    <DatePicker
                        selected={empData?.dob ? new Date(new Date(empData.dob)) : ''}
                        dateFormat='dd-MM-yyyy'
                        className="form-control"
                        onChange={e => setEmpData({...empData,dob:e})}
                        showMonthDropdown
                        showYearDropdown
                    />
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="dop">Place Of Birth</label>
                    <input  onChange={e => setEmpData({...empData,dop:e.target.value})} value={empData?.dop} id="dop" className='form-control' placeholder="Enter Place of Birth" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="dop">Gender</label>
                    <select style={{maxWidth: '100%'}}
                            onChange={e => setEmpData({...empData,gender:e.target.value})}
                            value={empData?.gender}
                            className="form-control"
                    >
                        <option  value="">Select Gender</option>
                        <option selected={empData?.gender === "Male"}  value="Male">Male</option>
                        <option selected={empData?.gender === "Female"}   value="Female">Female</option>
                    </select>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="dop">Blood Group</label>
                    <select style={{maxWidth: '100%'}}
                            onChange={e => setEmpData({...empData,blood:e.target.value})}
                            value={empData?.blood}
                            className="form-control"
                    >
                        <option  value="">Select Gender</option>
                        <option selected={empData?.blood === "A+"}  value="A+">A(+) </option>
                        <option selected={empData?.blood === "A-"}  value="A-">A(-) </option>
                        <option selected={empData?.blood === "B+"}  value="B+">B(+)</option>
                        <option selected={empData?.blood === "B-"}  value="B-">B(-)</option>
                        <option selected={empData?.blood === "AB+"}  value="B-">AB(+)</option>
                        <option selected={empData?.blood === "AB-"}  value="AB-">AB(-)</option>
                        <option selected={empData?.blood === "O+"}  value="O+">O(+)</option>
                        <option selected={empData?.blood === "O-"}  value="O-">O(-)</option>
                    </select>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="height">Height</label>
                    <input  onChange={e => setEmpData({...empData,height:e.target.value})} value={empData?.height} id="height" className='form-control' placeholder="Enter height" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="weight">Weight</label>
                    <input  onChange={e => setEmpData({...empData,weight:e.target.value})} value={empData?.weight} id="weight" className='form-control' placeholder="Enter weight" type="text"/>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="cover_size">Cover Size</label>
                    <select style={{maxWidth: '100%'}}
                            onChange={e => setEmpData({...empData,cover_size:e.target.value})}
                            value={empData?.cover_size}
                            className="form-control"
                    >
                        <option  value="">Select Size</option>
                        <option selected={empData?.cover_size === "XS"}  value="XS">XS</option>
                        <option selected={empData?.cover_size === "S"}  value="S">S </option>
                        <option selected={empData?.cover_size === "M"}  value="M">M</option>
                        <option selected={empData?.cover_size === "L"}  value="L">L</option>
                        <option selected={empData?.cover_size === "XL"}  value="XL">XL</option>
                        <option selected={empData?.cover_size === "XXL"}  value="XXL">XXL</option>
                    </select>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="cover_size">Shoe Size</label>
                    <select style={{maxWidth: '100%'}}
                            onChange={e => setEmpData({...empData,shoe_size:e.target.value})}
                            value={empData?.shoe_size}
                            className="form-control"
                    >
                        <option  value="">Select Size</option>
                        {Array.from(Array(21).keys(),x => x+30).map((item,index) => (
                            <option selected={empData?.shoe_size === item}  value={item}>{item}</option>
                        ))}


                    </select>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="religion">Religion</label>
                    <select style={{maxWidth: '100%'}}
                            onChange={e => setEmpData({...empData,religion:e.target.value})}
                            value={empData?.religion}
                            className="form-control"
                    >
                        <option  value="">Select Religion</option>
                        <option selected={empData?.religion === "Islam"}  value="Islam">Islam</option>
                        <option selected={empData?.religion === "Christianity"}  value="Christianity">Christianity </option>
                        <option selected={empData?.religion === "Hinduism"}  value="Hinduism">Hinduism</option>
                        <option selected={empData?.religion === "Buddhism"}  value="Buddhism">Buddhism</option>
                        <option selected={empData?.religion === "Judaism"}  value="Judaism">Judaism</option>
                        <option selected={empData?.religion === "Other"}  value="Other">Other</option>
                    </select>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="skype">Skype Id</label>
                    <input  onChange={e => setEmpData({...empData,skype:e.target.value})} value={empData?.skype} id="skype" className='form-control' placeholder="Enter skype id" type="text"/>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="marital_status">Marital Status</label>
                    <select style={{maxWidth: '100%'}}
                            onChange={e => setEmpData({...empData,marital_status:e.target.value})}
                            value={empData?.marital_status}
                            className="form-control"
                    >
                        <option  value="">Select Marital Status</option>
                        <option selected={empData?.marital_status === "Single"}  value="Single">Single</option>
                        <option selected={empData?.marital_status === "Married"}  value="Married">Married </option>
                        <option selected={empData?.marital_status === "Unknown"}  value="Unknown">Unknown</option>
                    </select>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="mother_name">Select Country</label>
                    <select style={{maxWidth: '100%'}}
                            onChange={e => setEmpData({...empData,country_id:e.target.value})}
                            value={empData?.country_id}
                            className="form-control"
                    >
                        <option  value="" selected>Select Country</option>
                        {country.map((item,index) => (
                            <option key={index} selected={empData?.country_id === item?.country_id}  value={item?.country_id}>{item?.country_name}</option>
                        ))}

                    </select>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input  onChange={e => setEmpData({...empData,address:e.target.value})} value={empData?.address} id="address" className='form-control' placeholder="Enter address" type="text"/>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="post_code">Postal Code</label>
                    <input  onChange={e => setEmpData({...empData,post_code:e.target.value})} value={empData?.post_code} id="post_code" className='form-control' placeholder="Enter Post Code" type="text"/>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="kin_name">Kin Name</label>
                    <input  onChange={e => setEmpData({...empData,kin_name:e.target.value})} value={empData?.kin_name} id="kin_name" className='form-control' placeholder="Enter Kin Name" type="text"/>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="kin_email">Kin Email</label>
                    <input  onChange={e => setEmpData({...empData,kin_email:e.target.value})} value={empData?.kin_email} id="kin_email" className='form-control' placeholder="Enter Kin Email" type="email"/>
                </div>
            </div>

            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="kin_telephone">Kin Telephone</label>
                    <input  onChange={e => setEmpData({...empData,kin_telephone:e.target.value})} value={empData?.kin_telephone} id="kin_telephone" className='form-control' placeholder="Enter Kin Telephone" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="relation">Kin Relation</label>
                    <input  onChange={e => setEmpData({...empData,relation:e.target.value})} value={empData?.relation} id="relation" className='form-control' placeholder="Enter Kin Relation" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="kin_nid">Kin NID</label>
                    <input  onChange={e => setEmpData({...empData,kin_nid:e.target.value})} value={empData?.kin_nid} id="kin_nid" className='form-control' placeholder="Enter Kin NID" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="kin_address">Kin Address</label>
                    <input  onChange={e => setEmpData({...empData,kin_address:e.target.value})} value={empData?.kin_address} id="kin_address" className='form-control' placeholder="Enter Kin Address" type="text"/>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="form-group">
                    <label htmlFor="kin_birth_certificate">Kin Birth Certificate</label>
                    <input  onChange={e => setEmpData({...empData,kin_birth_certificate:e.target.value})} value={empData?.kin_birth_certificate} id="kin_birth_certificate" className='form-control' placeholder="Enter Kin Birth Certificate" type="text"/>
                </div>
            </div>

            <div className="col-12">
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">{
                        disabled ? 'Please Wait' : 'Update'
                    }</button>
                </div>
            </div>


        </div>

    );
};

export default PersonalInfoForm;