import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import VisaEditForm from "../../../../Components/Profile/DocumentVisa/VisaEditForm";
import * as UserRoute from "../../../../constants/UserRoute";

const VisaEdit = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disabled,setDisabled] = useState(false);


    const [documentForm,setDocumentForm] = useState({
        visa_id:id,
        passport_type:"",
        visa_number:'',
        issue_date:'',
        expiry_date:'',
        issued_at:'',
        country_id:""
    });


    const getData = () => {
        axiosClient.get('/seafarer/visa-edit/'+id)
            .then(({data}) => { 
                setDocumentForm(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);
        axiosClient.post('/seafarer/visa-update', documentForm)
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                navigate(UserRoute.USER_PROFILE, { state: { type:'documentVisa' } });
            }).catch(err => {
            const response = err.response;
            setDisabled(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    useEffect(() => {
        getData()
    },[])
    return (
        <div>
            <Breadcrumb title="Edit Visa"  prev={UserRoute.USER_PROFILE} state="documentVisa"    />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-6 col-12">
                       <form  onSubmit={onSubmit}>
                            <VisaEditForm documentForm={documentForm} setDocumentForm={setDocumentForm} disabled={disabled} />
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default VisaEdit;