export const DASHBOARD = '/company/dashboard';
export const DOCUMENT_PRINT_PREVIEW = '/company/document-print-preview';
export const DOCUMENT_PRINT_PREVIEW_THIRTY_DAYS = '/company/document-print-preview/thirty-days';
export const DOCUMENT_PRINT_PREVIEW_OVERDUE = '/company/document-print-preview/overdue';
export const SIGN_ON_OFF = '/company/sign-on-off-report';
export const SIGN_OFF = '/company/sign-off-report';

export const SEAFARER_DORMANT = '/company/seafarer-dormant-report';
export const NO_OF_CONTRACT = '/company/no-off-contract-report';
export const SUPPLY_DEMAND_REPORT = '/company/supply-demand-report';
export const SEAFARER_CONTRACT_EXTENSION = '/company/seafarer-contract-extension';
export const SEAFARER_LEFT_REPORT = '/company/seafarer-left-report';
export const SEAFARER_CONTRACT_DURATION_REPORT = '/company/seafarer-contract-duration-report';
export const SEAFARER_INDIVIDUAL_CONTRACT_DURATION_REPORT = '/company/seafarer-individual-contract-duration-report';
export const SEAFARER_TURNOVER_REPORT = '/company/seafarer-turnover-report';
export const SEAFARER_NEW_PROMOTION_REPORT = '/company/seafarer-new-promotion-report';
export const SEAFARER_EMERGENCY_SIGN_OFF_REPORT = '/company/seafarer-emergency-sign-off-report';
export const SEAFARER_ILLNESS_REPORT = '/company/seafarer-illness-report';
export const SEAFARER_TOP_FOUR_RANK_CONTRACT_REPORT = '/company/top-four-seafarer-rank-contract'


export const SEAFARER = '/company/seafarers'
export const ADD_NEW_SEAFARER = '/company/add-new-seafarer'
export const ADD_TO_VESSEL = '/company/add-to-vessel/'
export const EDIT_TO_VESSEL = '/company/edit-to-vessel/'
export const CREW_EDIT_ID = '/company/crew-profile/edit/'
export const SEAFARER_AGREEMENT = '/company/seafarer/agreement/'
export const SEAFARER_AGREEMENT_EXTENSION = '/company/seafarer/agreement-extension/'
export const SEAFARER_CHECKLIST = '/company/seafarer/checklist/'
export const COMPANY_POLICY = '/company/company-policy/'
export const SEAPORT_IMMIGRATION = '/company/seaport-immigration/'
export const APPLICATION_FORM = '/company/application-form/'
export const SIGN_ON_LETTER = '/company/sign-on-letter/'
export const  TERMS_CONDITION = '/company/terms-condition/'
export const  TERMS_CONDITION_CREATE = '/company/terms-condition/create/'
export const  TERMS_CONDITION_SHOW = '/company/terms-condition/show/'

export const PRE_EMPLOYMENT_MEDICAL = '/company/pre-employment-medical/'
export const PRE_EMPLOYMENT_MEDICAL_CREATE = '/company/pre-employment-medical/create/'
export const PRE_EMPLOYMENT_MEDICAL_EDIT = '/company/pre-employment-medical/edit/'
export const PRE_EMPLOYMENT_MEDICAL_SHOW = '/company/pre-employment-medical/show/'
export const MEDICAL_HISTORY_QUESTIONNAIRE = '/company/medical-history-questionnaire/'
export const MEDICAL_HISTORY_QUESTIONNAIRE_CREATE = '/company/medical-history-questionnaire/create/'
export const MEDICAL_HISTORY_QUESTIONNAIRE_EDIT = '/company/medical-history-questionnaire/edit/'
export const MEDICAL_HISTORY_QUESTIONNAIRE_SHOW = '/company/medical-history-questionnaire/show/'


export const SEAFARER_DOCUMENTS = '/company/seafarer/documents/'
export const ADD_NEW_SEAFARER_DOCUMENTS = '/company/seafarer/add-new-documents/'
export const EDIT_SEAFARER_DOCUMENTS = '/company/seafarer/edit-documents/'

export const PENDING_DOCUMENTS = '/company/pending-documents'

export const COMPANY_USER_LIST = '/company/company-user-list'
export const COMPANY_USER_LOGIN_HISTORY = '/company/company-user/login-history'
export const CREATE_COMPANY_USER = '/company/create-company-user'
export const EDIT_COMPANY_USER = '/company/edit-company-user/'
export const EDIT_COMPANY_USER_PASSWORD = '/company/edit-company-user-password/'

export const COMPANY_PERMISSION = '/company/permission'
export const COMPANY_PERMISSION_ADD = '/company/permission/add'
export const COMPANY_PERMISSION_EDIT = '/company/permission/edit/'
export const COMPANY_PERMISSION_SHOW = '/company/permission/show/'

export const RANK_PERMISSION = '/company/rank-permission'
export const RANK_PERMISSION_ADD = '/company/rank-permission/add'
export const RANK_PERMISSION_EDIT = '/company/rank-permission/edit/'


export const JOB_LIST = '/company/job-list'
export const ADD_NEW_JOB = '/company/add-new-job'
export const EDIT_JOB = '/company/edit-job/'
export const APPLIED_JOB = '/company/applied-job/'
export const SHORTLISTED_JOB = '/company/shortlisted-job/'

export const BUDGET_HEAD_LIST = '/company/budget-head'
export const ADD_BUDGET_HEAD = '/company/budget-head/add'
export const EDIT_BUDGET_HEAD = '/company/budget-head/edit/'
export const ADD_BUDGET_SUB_HEAD = '/company/budget-sub-head/add/'

export const FLEET_BUDGET = '/company/fleet-budget'
export const INDIVIDUAL_VESSEL_BUDGET = '/company/individual-vessel-budget'
export const PORTAGE_BILL = '/company/portage-bill'
export const TOP_FOUR_PORTAGE_BILL = '/company/top-four-portage-bill'
export const BASIC_WAGES = '/company/balance-wages'
export const TOP_FOUR_OFFICER_SALARY = '/company/top-four-officer-salary'

export const SALARY_POLICY = '/company/salary-policy'
export const SALARY_POLICY_CREATE = '/company/salary-policy/create'
export const PARTICULARS = '/company/account/particulars'
export const PARTICULARS_CREATE = '/company/account/particulars/create'
export const PARTICULARS_EDIT = '/company/account/particulars/edit/'

export const PARTICULARS_TRANSACTION = '/company/account/particulars/transaction'
export const PARTICULARS_TRANSACTION_CREATE = '/company/account/particulars/transaction/create'
export const PARTICULARS_TRANSACTION_EDIT = '/company/account/particulars/transaction/edit/'

export const HIRED_CREWS = '/company/hired-crews'
export const EMPLOYMENT_CONTRACT = '/company/employment-contract/'
export const VESSEL = '/company/vessel'
export const ADD_NEW_VESSEL = '/company/vessel/create'
export const EDIT_VESSEL = '/company/vessel/edit/'
export const VESSEL_CREW_LIST = '/company/vessel/crews/'
export const VESSEL_CREW_PLANNER = '/company/vessel/crews-planner/'
export const VESSEL_CREW_PLANNER_REPLACE = '/company/vessel/crews-planner-replace/'
export const VESSEL_CREW_PLANNER_REPLACE_EXTERNAL_POOL = '/company/vessel/crews-planner-replace/external-pool/'
export const VESSEL_CREW_PLANNER_REPLACE_TANKER = '/company/vessel/crews-planner-replace-tanker/'
export const VESSEL_CREW_PLANNER_REPLACE_TANKER_EXTERNAL_POOL = '/company/vessel/crews-planner-replace-tanker-external-pool/'
export const VESSEL_BUDGET = '/company/vessel/budget/'
export const VESSEL_BUDGET_SHOW = '/company/vessel/budget/show/'
export const VESSEL_BUDGET_EDIT = '/company/vessel/budget/edit/'

export const SEAFARER_TRASH = '/company/seafarer-trash'

export const SALARY_TREND = '/company/salary-trend'
export const SUPPLY_DEMAND = '/company/supply-demand'
export const AUTO_SUPPLY_DEMAND = '/company/auto-supply-demand'

export const COMPANY_MODULE = '/company/company-module'
export const COMPANY_MODULE_ADD = '/company/company-module-add'
export const COMPANY_MODULE_EDIT = '/company/company-module-edit/'

export const RANK_GROUP = '/company/rank-group'
export const RANK_GROUP_ADD = '/company/rank-group/add'
export const RANK_GROUP_EDIT = '/company/rank-group/edit/'

export const USER_PROFILE = '/company/user/profile/'

export const UPLOAD_PERFORMANCE = '/company/upload-performance/'
export const UPDATE_READINESS = '/company/update-readiness/'

export const UPDATE_PERSONAL_INFO = '/company/profile/personal-info/'

export const USER_PASSPORT = '/company/profile/passport/';
export const USER_PASSPORT_EDIT = '/company/profile/passport-edit/';
export const USER_VISA = '/company/profile/visa/';
export const USER_VISA_EDIT = '/company/profile/visa-edit/';

export const ADD_NEW_EDUCATION = '/company/profile/education-add/'
export const EDUCATION_EDIT = '/company/profile/education-edit/';

export const ADD_NEW_CERTIFICATION = '/company/profile/certification-add/'
export const CERTIFICATION_EDIT = '/company/profile/certification-edit/';
export const ADD_NEW_EXPERIENCE = '/company/profile/experience-add/'
export const EXPERIENCE_EDIT = '/company/profile/experience-edit/';

export const ADD_NEW_FAMILY = '/company/profile/family-add/'
export const FAMILY_EDIT = '/company/profile/family-edit/';

export const MEDICAL_INFORMATION = '/company/profile/medical-information/'
export const TRAINING_INFORMATION = '/company/profile/training-information/'
export const OTHERS_INFORMATION = '/company/profile/others-information/'
export const BANK_INFORMATION = '/company/profile/bank-information/'



export const UPLOAD_DOCUMENT = '/company/upload-document'
export const ADD_COMPANY_DOCUMENT = '/company/add-company-document'


export const RANK = '/company/rank';
export const ADD_RANK = '/company/rank/add';
export const EDIT_RANK = '/company/rank/edit/';

export const VESSEL_TYPE = '/company/vessel-type';
export const VESSEL_TYPE_ADD = '/company/vessel-type/add';
export const VESSEL_TYPE_EDIT = '/company/vessel-type/edit/';

export const SMS_LOG = '/company/sms-log';


export const PORT = '/company/port';
export const PORT_ADD = '/company/port/add';
export const PORT_EDIT = '/company/port/edit/';

export const GUEST_SEAFARER_LIST = '/company/guest/seafarer-list';
export const PORT_HEALTH_DOCUMENT = '/company/guest/port-health-document/';


export const CHAT = '/company/chat';
export const INBOX = '/company/conversation/';
export const NEW_INBOX = '/company/new-conversation/';



