import React, {useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import AddEducationForm from "../../../../Components/Profile/Education/AddEducationForm";

const CreateEducation = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disable,setDisable] = useState(false);
    const [form,setForm] = useState({
        user_id:id,
        degree:"",
        institute:"",
        country_id:"",
        passing_year:"",
        duration:"",
        result:"",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.post('/company/education', form)
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.USER_PROFILE}${id}`, {state: {type: 'education'}});
                },1000);
                setDisable(false)

            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }



    return (
        <>
            <Breadcrumb title='Add New Education' prev={`${COMPANYROUTE.USER_PROFILE}${id}`} state="education"    />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-6 col-12">
                        <form onSubmit={onSubmit}>
                            <AddEducationForm form={form} setForm={setForm} disable={disable} />
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CreateEducation;