import React, {useEffect, useState} from 'react';
import '../../styles/chat.css'
import ConversationList from "../../../Components/Chat/ConversationList";
import axiosClient from "../../../axios-client";
import * as UserRoute from "../../../constants/UserRoute";
import SearchInput from "../../../Components/Chat/SearchInput";
import SearchUserList from "../../../Components/Chat/SearchUserList";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import SeafarerChatSidebar from "../../../Components/Chat/SeafarerChatSidebar";

const Chat = () => {
    const [conversation,setConversation] = useState([]);
    const [searchExist,setSearchExist] = useState(false)
    const getData = () => {
        axiosClient.get(`/seafarer/chat` )
            .then(({data}) => {
                setConversation(data?.data)
            }).catch((e) => {
            console.log(e)
        })
    }
    useEffect(() => {
        getData()
    },[])

    useEffect(() => {

    },[searchExist])




    return (
        <div id="container">
            <SeafarerChatSidebar searchExist={searchExist} setSearchExist={setSearchExist} conversation={conversation} />
            <main>
                <header>
                    <div>
                        <h2>Welcome to {process.env.REACT_APP_NAME}</h2>
                    </div>
                </header>

            </main>
        </div>
    );
};

export default Chat;