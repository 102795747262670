import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import axiosClient from "../../../../axios-client";

const Index = () => {
    const [reportData,setReportData] = useState([])

    const {user_id} = useParams();
    const navigate = useNavigate();


    const getReportData = () => {
        axiosClient.get('/company/medical-history-questionnaire/'+user_id)
            .then(({data}) => {
                console.log(data)
                if(data  == 0){
                    toast.error('Seafarer  not found')
                    navigate(COMPANYROUTE.SEAFARER)
                }
                setReportData(data?.data)
            }).catch((e) => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete this history?`)){
            return false;
        }
        axiosClient.delete(`/company/medical-history-questionnaire/${data}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData()
            })
    }

    return (
        <>
            <Breadcrumb title='Medical History Quesestionnaire' addnew={true} route={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE_CREATE}${user_id}`}  />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">


                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Name</th>
                                            <th>CDC</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                          reportData.length > 0 &&  reportData.map((data,index) => {
                                                return (
                                                    <tr>
                                                        <td>{index+1}</td>
                                                        <td>{data?.name}</td>
                                                        <td>{data?.cdc}</td>
                                                        <td>{data?.date}</td>
                                                        <td>
                                                            <Link to={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE_EDIT}${data.id}`} className='btn btn-sm btn-primary mr-2'><i className="fa fa-pencil-alt"></i></Link>
                                                            <Link to={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE_SHOW}${data.id}`} className='btn btn-sm btn-info mr-2'><i className="fa fa-eye"></i></Link>
                                                             <button onClick={() => onDelete(data.id)} className='btn btn-sm btn-danger'><i className="fa fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }



                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Index;