import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {Link, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import CrewRemoveModal from "../../../../Components/Company/CrewList/CrewRemoveModal";
import Pagination from "react-js-pagination";
import {VESSEL_CREW_PLANNER_REPLACE} from "../../../../constants/companyRoutes";
import UpdateChecklistModal from "../../../../Components/Company/CrewPlanner/UpdateChecklistModal";
import CrewPlanerCompanyModal from "../../../../Components/Company/CrewPlanner/CrewPlanerCompanyModal";
import {toast} from "react-toastify";
import CrewPlannerListModal from "../../../../Components/Company/CrewPlanner/CrewPlannerListModal";
const CrewPlanner = () => {
    const {id} = useParams();
    const [reportData,setReportData] = useState([]);
    const [vesselName,setVesselName] = useState('')
    const [vesselId,setVesselId] = useState('')

    const [modal,setModal] = useState(false);
    const [tankerCrewReplaceModal,setTankerCrewReplaceModal] = useState(false);
    const [tankerData,setTankerData] = useState([]);
    const [rankId,setRankId] = useState('');




    const getReportData = () => {
        axiosClient.get('/company/vessel-crew-planner/' + id )
            .then(({data}) => {
                setVesselName(data?.vessel_name)
                setVesselId(data?.vessel_id)
                setReportData(data?.data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])


    const handleTankerReplace = (id) => {
            axiosClient.get('/company/get-company-data')
            .then(({data}) => {
                setTankerCrewReplaceModal(true)
                setTankerData(data?.data)
                setRankId(id)
            }).catch(() => {
             toast.error('Something went wrong')
        })
    }

    const handleModal = () => {
         setModal(true)
    }








    return (
        <>
            <Breadcrumb title={`${vesselName} Crews  Planner `}   />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-end ">
                                        <button onClick={() => handleModal()} className='btn btn-primary mr-2'>Crews Planner </button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Name</th>
                                            <th>Nationality</th>
                                            <th>CDC No</th>
                                            <th>Joining Date</th>
                                            <th>Contact Period(+/-1 Month)</th>
                                            <th>Due Date</th>
                                            <th>Extended Date</th>
                                            <th>Days to Due Date</th>
                                            <th>Days to Extended Date</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>


                                        {
                                            reportData?.length > 0  ? reportData?.map((item,index) => {
                                                return(
                                                    <tr key={index+1}>
                                                        <td>{item?.rank}</td>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.nationality}</td>
                                                        <td>{item?.cdc_no}</td>
                                                        <td>{item?.joiningDate}</td>
                                                        <td>{item?.contractMonth}</td>
                                                        <td>{item?.dueDate}</td>
                                                        <td>{item?.extendedDueDate}</td>
                                                        <td>{item?.daysDueDate}</td>
                                                        <td>{item?.daysExtendedDueDate}</td>
                                                        <td>
                                                            {
                                                                item?.category_id  ?
                                                                    item?.tanker === true ?
                                                                          item?.replaceDays >= 60 ?
                                                                                <button className="btn   btn-sm btn-success text-white" onClick={() => handleTankerReplace(item?.category_id)}>Replace</button>
                                                                          :  item?.replaceDays <= 60 && item?.replaceDays >= 30 ?
                                                                                <button className="btn   btn-sm btn-primary"  onClick={() => handleTankerReplace(item?.category_id)}  >Replace</button>
                                                                           : item?.replaceDays <= 30 && item?.replaceDays >= 0 ?
                                                                                      <button className="btn    btn-sm btn-warning"   onClick={() => handleTankerReplace(item?.category_id)} >Replace</button>
                                                                           : item?.replaceDays < 0 ?
                                                                                        <button className="btn   btn-sm btn-danger"  onClick={() => handleTankerReplace(item?.category_id)} >Replace</button>
                                                                                      : ''
                                                                           :
                                                                        item?.replaceDays >= 60 ?
                                                                            <Link className="btn   btn-sm btn-success text-white" to={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE}${vesselId}/${item?.category_id}`}>Replace</Link>
                                                                            :  item?.replaceDays <= 60 && item?.replaceDays >= 30 ?
                                                                                <Link className="btn   btn-sm btn-primary" to={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE}${vesselId}/${item?.category_id}`}>Replace</Link>
                                                                                : item?.replaceDays <= 30 && item?.replaceDays >= 0 ?
                                                                                    <Link className="btn   btn-sm btn-warning" to={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE}${vesselId}/${item?.category_id}`}>Replace</Link>
                                                                                    : item?.replaceDays < 0 ?
                                                                                        <Link className="btn   btn-sm btn-danger " to={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE}${vesselId}/${item?.category_id}`}>Replace</Link>
                                                                                        : ''
                                                                    : 'Replace Not Found'
                                                            }

                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                tankerCrewReplaceModal === true && <CrewPlanerCompanyModal data={tankerData} rank={rankId} hide={() => setTankerCrewReplaceModal(false)}   />
            }
            {
                modal === true && <CrewPlannerListModal vessel={vesselName} data={reportData} hide={() => setModal(false)} />
            }

        </>
    );
};

export default CrewPlanner;