import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import Pagination from "react-js-pagination";
const SmsLog = () => {
    const [reportData,setReportData] = useState([]);
    const getReportData = ( pageNumber = 1) => {
        axiosClient.get(`/company/sms-log?page=${pageNumber}` )
            .then(({data}) => {
                console.log(data?.data)
                setReportData(data?.data)
            }).catch((e) => {
            console.log(e)
        })
    }
    useEffect(() => {
        getReportData()
    },[])

    return (
        <>
            <Breadcrumb title='SMS Log'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body  "  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Company User</th>
                                            <th>User</th>
                                            <th>Mobile</th>
                                            <th>Text</th>
                                            <th>Created At</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {reportData?.data?.length > 0 ?  reportData?.data?.map((data,index) => (
                                            <tr  key={index} >
                                                <td>{data?.user_name}</td>
                                                <td>{data?.company_name}</td>
                                                <td>{data?.mobile}</td>
                                                <td>{data?.text}</td>
                                                <td>{data?.created_at}</td>
                                            </tr>
                                        )) : <tr><td colSpan={5} className='text-center'>No Data Found</td></tr>}
                                        </tbody>
                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">
                                        <Pagination
                                            activePage={reportData?.current_page ? reportData?.current_page : 0}
                                            itemsCountPerPage={reportData?.per_page ? reportData?.per_page : 0 }
                                            totalItemsCount={reportData?.total ? reportData?.total : 0}
                                            onChange={(pageNumber) => {
                                                getReportData(pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SmsLog;