import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";
import {toast, ToastContainer} from "react-toastify";

const UpdateReadiness = ({readiness_date}) => {
    const {id} = useParams();
    const location = useLocation();
    const date = new Date();
    const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const navigate = useNavigate();
    const [availableDate,setAvailableDate] = useState(new Date(currentDate));
    const onSubmit = (e) => {
        e.preventDefault();

        let evDate = "";
        if (availableDate === "" || availableDate === null) {
            toast.error('The Readiness Date field is required.');
            return;
        }else{
              evDate =  new Date(availableDate).toLocaleDateString();
        }
        axiosClient.post('/company/update-availability',{
            crew_id:id,
            availability_date:evDate,
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.USER_PROFILE}${id}`,{ state: { type:'personalInfo' } })

                },1000);

            }).catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    toast.error(response.data?.errors?.availability_date[0]);
                }
            }
        })


    }


    useEffect(() => {
        if(location.state?.readiness_date !== null){
            let date = new Date(location.state?.readiness_date);
            setAvailableDate(date);
        }
    },[])

    return (
        <div>
            <Breadcrumb title='Update Readiness' prev={`${COMPANYROUTE.USER_PROFILE}${id}`}  state="personalInfo"    />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-12 col-md-6">

                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="rank">Readiness Date</label>
                                        <DatePicker
                                            selected={availableDate}
                                            dateFormat='dd-MM-yyyy'
                                            className="form-control"
                                            onChange={(date) => setAvailableDate(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                </div>


                                <div className="col-6 float-left">
                                     <button type="submit" className="btn btn-success btn-block  ">Save</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default UpdateReadiness;