import React, {useState} from 'react';
import axiosClient from "../../axios-client";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../constants/companyRoutes";
import * as UserRoute from "../../constants/UserRoute";

const SearchUserList = ({searchUserList,userType}) => {

    let inbox_url = '';
    if(userType === 'company'){
          inbox_url = COMPANYROUTE.NEW_INBOX
    } else{
          inbox_url = UserRoute.NEW_INBOX
    }


    return (
        <ul className="chat-sidebar">
            {
                searchUserList?.length > 0 ?  searchUserList?.map((data,index) => (
                    <li key={index}>
                        <Link to={ `${inbox_url}${userType === 'company' ? data?.user_id : data?.id}`}>
                            <img src={data?.image_url} alt="" />
                            <div>
                                <h2>{data?.name}</h2>
                                <h3>
                                    <span className="status orange"></span>
                                    {data?.rank}
                                    {
                                        data?.cdc_number !== '' ? <span className="cdc-number">({data?.cdc_number})</span> :  data?.last_message
                                    }

                                </h3>
                            </div>
                        </Link>
                    </li>    )) : <li className="text-center"> No User Found</li>
            }
        </ul>
    )


};

export default SearchUserList;