import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {COMPANY_PERMISSION} from "../../../constants/companyRoutes";

const Show = () => {
    const {id} = useParams();
    const [data,setData] = useState([]);
    const [module,setModule] = useState([]);
    const getData = () => {
        axiosClient.get('/company/company-permission/create')
            .then(({data}) => {
                setData(data)
            }).catch(() => {
        })
    }

    const getEditData = () => {
        axiosClient.get('/company/company-permission/'+id)
            .then(({data}) => {
                let filterData = data?.data?.map((item) => item?.module_access === '1' && item?.module_id)
                filterData = filterData.filter((item) => item !== false)
                setModule(filterData)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getData()
        getEditData()
    },[])




    return (
        <div>
            <Breadcrumb title='Permission User Module' prev={COMPANYROUTE.COMPANY_PERMISSION}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                    <div className="card-body">
                                        <div className=" col-12">
                                            <div className="row">

                                                <div className="col-12 ">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Module Name</th>
                                                                <th>Permission</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            data?.modulesData?.length > 0 &&
                                                            data?.modulesData?.map((item,index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item?.name}</td>
                                                                    <td>
                                                                        {
                                                                            module.includes(item?.id) ? 'Yes' : 'No'
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>

                </div>
            </section>
        </div>

    );
};

export default Show;