import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {Link, useNavigate, useParams} from "react-router-dom";
import Logo from "../../Common/logo";
import {now} from "@amcharts/amcharts4/.internal/core/utils/Time";

const CrewListModal = ({data,vesselData,portArrival,portDeparture,hide,parentHide}) => {


    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }
    let modalCustomSize = {
        maxWidth: '1500px',
    }

    const hideModal = () => {
        hide(false)
        parentHide(false)
    }


    return (
        <div className="modal show fade "  style={modelStyle} >
            <div className="modal-dialog modal-xl" style={modalCustomSize}>
                <div className="modal-content" style={{overflowY:'scroll', height:'800px'}}>
                    <div className="modal-body">
                        <div className="card-body">
                            <div className="justify-content-center d-flex">
                                <div className="d-flex">
                                    <div className="logo">
                                        <Logo />
                                    </div>
                                    <div className="header text">
                                        <h5 className="text-center"> Brave Royal Ship Management Ltd. </h5>
                                        <h6 className="text-center">Imo Crew List</h6>
                                    </div>
                                </div>

                            </div>
                            <table  className="table table-bordered table-hover" cellSpacing="0" width="100%">

                                <tbody>
                                <tr>
                                    <td className="full-border">
                                        <p><i> Name of Ship </i></p>
                                        <p className="center-align"><strong> {vesselData?.vessel_name} </strong></p>
                                    </td>
                                    <td className="full-border">
                                        <p><i> Type of Ship </i></p>
                                        <p className="center-align">
                                            <strong>  {vesselData?.vessel_type} </strong>
                                        </p>
                                    </td>
                                    <td className="full-border">
                                        <p><i> Call Sign </i></p>
                                        <p className="center-align"><strong> 3ERX5 </strong></p>
                                    </td>
                                    <td className="full-border">
                                        <p><i> IMO Number </i></p>
                                        <p className="center-align"><strong>    {vesselData?.imo_number} </strong></p>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="full-border">
                                        <p><i> Port of Arrival </i></p>
                                        <p className="center-align">{portArrival}</p>
                                    </td>
                                    <td className="full-border">
                                        <p><i> Date </i></p>
                                        <p className="center-align"><strong> {new Date().toLocaleDateString()}  </strong></p>
                                    </td>
                                    <td className="full-border">
                                        <p><i> Flag State of Ship </i></p>
                                        <p className="center-align"><strong>   {vesselData?.imo_number}</strong></p>
                                    </td>
                                    <td className="full-border">
                                        <p><i> Port of Departure </i></p>
                                        <p className="center-align">{portDeparture}</p>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                            <table  className="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Surname</th>
                                    <th>Sex</th>
                                    <th>Rank</th>
                                    <th>Nationality</th>
                                    <th>Date Of Birth</th>
                                    <th>Place of Birth</th>
                                    <th>Passport Number</th>
                                    <th>Passport Expiry Date</th>
                                    <th>Date of Embarkation</th>
                                    <th>Place of Embarkation</th>

                                </tr>
                                </thead>
                                <tbody>


                                {
                                    data?.length > 0  ? data?.map((item,index) => {
                                        return(
                                            <tr key={index+1}>
                                                <td>{index+1}</td>
                                                <td>{item?.sur_name}</td>
                                                <td>{item?.sex}</td>
                                                <td>{item?.rank}</td>
                                                <td>{item?.country_name}</td>
                                                <td>{item?.dob}</td>
                                                <td>{item?.dob_place}</td>
                                                <td>{item?.passport}</td>
                                                <td>{item?.passport_expiry_date}</td>
                                                <td>{item?.joining_date}</td>
                                                <td> {item?.port_name} , {item?.nationality}</td>

                                            </tr>
                                        )
                                    }) : ''
                                }



                                </tbody>

                            </table>

                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrewListModal;