import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
const SeafarerEditId = () => {
    const {id} = useParams();
    const navigate = useNavigate()
    const [progress, setProgress] = useState(10);
    const [form,setForm] = useState({
          user_id:id,
          mobile:'',
          password:'',
          password_confirmation:'',
    })
    const getData = () => {
        axiosClient.get('company/seafarer/edit-password/' + id)
            .then(({data}) => {
                setProgress(100)
                setForm(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/seafarer/update-password',form)
            .then(({data}) => {

                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.SEAFARER}`);
                },1000);

            }).catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }

    useEffect(() => {
        getData()
    },[])
    return (
        <div>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Update Password' prev={COMPANYROUTE.SEAFARER}    />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-primary">

                                <form onSubmit={onSubmit}>
                                    <div className="card-body">
                                        <div className="form-group">

                                            <label htmlFor="first_name">Name </label>
                                            <input type="text" name="name" className="form-control" readOnly value={form?.name} id="first_name"
                                                   placeholder="Enter First Name" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="mobile">Mobile Number</label>
                                            <input type="text" onChange={e => setForm({...form,mobile:e.target.value})} name="mobile" className="form-control" value={form?.mobile} id="mobile"
                                                   placeholder="Enter Mobile Number" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" onChange={e => setForm({...form,password:e.target.value})} name="password" className="form-control" id="password"
                                                   placeholder="Enter Password" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Confirm Password</label>
                                            <input type="password" onChange={e => setForm({...form,password_confirmation:e.target.value})} name="password" className="form-control" id="password"
                                                   placeholder="Enter Password" />
                                        </div>

                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
};

export default SeafarerEditId;