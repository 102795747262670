import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";

const ExperienceForm = ({form,setForm,disable}) => {
    const [ranks,setRanks] = useState([]);
    const [vesselType,setVesselType] = useState([]);
    const getRankData = () => {
        axiosClient.get('/get-ranks')
            .then(({data}) => {
                setRanks(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const getVesselTypeData = () => {
        axiosClient.get('/get-vessel-types')
            .then(({data}) => {
                setVesselType(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    useEffect(() => {
        getRankData();
        getVesselTypeData()
    },[])

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Vessel</label>
                            <input onChange={e => setForm({...form,vessel_name:e.target.value})} value={form?.vessel_name} className='form-control'   type="text"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Manning Company  </label>
                            <input className='form-control ' onChange={e => setForm({...form,manning_company:e.target.value})}  value={form?.manning_company}  type="text"/>
                        </div>
                    </div>

                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Shipping Company </label>
                            <input className='form-control'  onChange={e => setForm({...form,shipping_company:e.target.value})}  value={form?.shipping_company} type="text"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Flag </label>
                            <input className='form-control' onChange={e => setForm({...form,flag:e.target.value})}  value={form?.flag}  type="text"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Vessel Type </label>
                            <select style={{maxWidth: '100%'}}
                                    onChange={e => setForm({...form,vessel_type_id:e.target.value})}
                                    value={form?.vessel_type_id}
                                    className="form-control"
                            >
                                <option  value="" selected>Select Type</option>
                                {vesselType.map((data,index) => (
                                    <option key={index}  value={data?.vessel_type_id}>{data?.vessel_type_name}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">DWT </label>
                            <input className='form-control' onChange={e => setForm({...form,dwt:e.target.value})}  value={form?.dwt} type="number"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">GRT </label>
                            <input className='form-control' onChange={e => setForm({...form,grt:e.target.value})}  value={form?.grt} type="number"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Engine Type </label>
                            <input className='form-control' onChange={e => setForm({...form,engine_type:e.target.value})}   value={form?.engine_type} type="text"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">BHP </label>
                            <input className='form-control'  onChange={e => setForm({...form,bhp:e.target.value})}  value={form?.bhp} type="number"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Cargo Crane </label>
                            <input className='form-control' onChange={e => setForm({...form,cargo_crane:e.target.value})}  value={form?.cargo_crane} type="text"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Rank </label>
                            <select style={{maxWidth: '100%'}}
                                    onChange={e => setForm({...form,category_id:e.target.value})}
                                    value={form?.category_id}
                                    className="form-control"
                            >
                                <option  value="" selected>Select Rank</option>
                                {ranks.map((rank,index) => (
                                    <option key={index}  value={rank?.category_id}>{rank?.category_name}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Sign On Date </label>
                            <DatePicker
                                selected={form?.from ? new Date(new Date(form.from)) : ''}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={e => setForm({...form,from:e})}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Sign Off Date </label>
                            <DatePicker
                                selected={form?.to ? new Date(new Date(form.to)) : ''}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={e => setForm({...form,to:e})}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                    </div>

                    <div className="col-md-12 col-12">
                        <button type="submit" className="btn btn-info">{
                            disable ? 'Saving...' : 'Save'
                        }</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ExperienceForm;