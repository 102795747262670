import React, {useEffect, useState} from 'react';

import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import DatePicker from "react-datepicker";

const Create = () => {
    const navigate = useNavigate();
    const [disabled,setDisabled] = useState(false);
    const {id} = useParams();
    const [form,setForm] = useState({
        id:id,
        user_id:'',
        date:'',
        medical_history_questionare:'',
        physical_exam:'',
        dental_exam:'',
        psychological_test:'',
        visual_test:'',
        color_vision:'',
        audiometry:'',
        cheast_xray:'',
        ecg:'',
        urine_analysis:'',
        fecalysis:'',
        complete_blood_count:'',
        vdrl:'',
        stress_test:'',
        diabetes:'',
        spirometry:'',
        fail_exam_with_reason:'',
        medical_name:'',
        medical_address:'',
        medical_contact:'',
        physician_degree:'',
        physician_licensing:'',
        date_issue_physician_licensing:'',
        exam_date:'',
        physician_comment:'',
        remarks:'',
    });


    const getData = () => {
        axiosClient.get('/company/pre-employment-medical-examination/edit/'+id)
            .then(({data}) => {
                console.log(data)
                if(data  === 0){
                    toast.error('No data found')
                    navigate(-1)
                }
                setForm(data?.data)
            }).catch((e) => {
        })
    }

    useEffect(() => {
        getData()
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        axiosClient.post('/company/pre-employment-medical-examination/update', form)
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                navigate(`${COMPANYROUTE.PRE_EMPLOYMENT_MEDICAL}${form?.user_id}`);
            }).catch(err => {

            setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    return (
        <div>
            <Breadcrumb title='Edit Pre Employment Medical'  backButton={true}   />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Date</label>
                                                    <DatePicker
                                                        selected={form?.date ? new Date(new Date(form.date)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,date:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="medical_history_questionare">Medical History Questionnaire</label>
                                                    <input type="text"
                                                              value={form.medical_history_questionare}
                                                                onChange={(e) => setForm({...form,medical_history_questionare:e.target.value})}
                                                           name="mobile" className="form-control" id="medical_history_questionare"
                                                          />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="physical_exam">Physical Examination</label>
                                                    <input type="text"
                                                           value={form.physical_exam}
                                                           onChange={(e) => setForm({...form,physical_exam:e.target.value})}
                                                           name="mobile" className="form-control" id="physical_exam"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="dental_exam">Dental Examination</label>
                                                    <input type="text"
                                                           value={form.dental_exam}
                                                           onChange={(e) => setForm({...form,dental_exam:e.target.value})}
                                                           name="dental_exam" className="form-control" id="dental_exam"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="psychological_test">Psychological Test</label>
                                                    <input type="text"
                                                           value={form.psychological_test}
                                                           onChange={(e) => setForm({...form,psychological_test:e.target.value})}
                                                           name="psychological_test" className="form-control" id="psychological_test"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="visual_test">Visual Test (STCW Code, Section A-1/9)</label>
                                                    <input type="text"
                                                           value={form.visual_test}
                                                           onChange={(e) => setForm({...form,visual_test:e.target.value})}
                                                           name="visual_test" className="form-control" id="visual_test"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">Color Vision(STCW Code, Section A-1/9)</label>
                                                    <input type="text"
                                                           value={form.color_vision}
                                                           onChange={(e) => setForm({...form,color_vision:e.target.value})}
                                                           name="mobile" className="form-control" id="mobile"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="audiometry">Audiometry(STCW Code, Section A-1/9)</label>
                                                    <input type="text"
                                                           value={form.audiometry}
                                                           onChange={(e) => setForm({...form,audiometry:e.target.value})}
                                                           name="mobile" className="form-control" id="audiometry"
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="cheast_xray">Chest X-Ray</label>
                                                    <input type="text"
                                                           value={form.cheast_xray}
                                                           onChange={(e) => setForm({...form,cheast_xray:e.target.value})}
                                                           name="cheast_xray" className="form-control" id="cheast_xray"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">ECG</label>
                                                    <input type="text"
                                                           value={form.ecg}
                                                           onChange={(e) => setForm({...form,ecg:e.target.value})}
                                                           name="ecg" className="form-control" id="ecg"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">Urine Analysis</label>
                                                    <input type="text"
                                                           value={form.urine_analysis}
                                                           onChange={(e) => setForm({...form,urine_analysis:e.target.value})}
                                                           name="urine_analysis" className="form-control" id="urine_analysis"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="fecalysis">Fecalysis (Food Service/Handlers only)</label>
                                                    <input type="text"
                                                           value={form.fecalysis}
                                                           onChange={(e) => setForm({...form,fecalysis:e.target.value})}
                                                           name="fecalysis" className="form-control" id="fecalysis"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="spirometry">Spirometry</label>
                                                    <input type="text"
                                                           value={form.spirometry}
                                                           onChange={(e) => setForm({...form,spirometry:e.target.value})}
                                                           name="spirometry" className="form-control" id="spirometry"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="complete_blood_count">Complete Blood Count</label>
                                                    <input type="text"
                                                           value={form.complete_blood_count}
                                                           onChange={(e) => setForm({...form,complete_blood_count:e.target.value})}
                                                           name="complete_blood_count" className="form-control" id="complete_blood_count"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">VDRL</label>
                                                    <input type="text"
                                                           value={form.vdrl}
                                                           onChange={(e) => setForm({...form,vdrl:e.target.value})}
                                                           name="vdrl" className="form-control" id="vdrl"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">Stress Test</label>
                                                    <input type="text"
                                                           value={form.stress_test}
                                                           onChange={(e) => setForm({...form,stress_test:e.target.value})}
                                                           name="ecg" className="form-control" id="ecg"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">Diabetes</label>
                                                    <input type="text"
                                                           value={form.diabetes}
                                                           onChange={(e) => setForm({...form,diabetes:e.target.value})}
                                                           name="diabetes" className="form-control" id="diabetes"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="fail_exam_with_reason">If failed in any above mentioned examination, please provide reasons with examination number</label>
                                                    <input type="text"
                                                           value={form.fail_exam_with_reason}
                                                           onChange={(e) => setForm({...form,fail_exam_with_reason:e.target.value})}
                                                           name="fail_exam_with_reason" className="form-control" id="fail_exam_with_reason"
                                                    />
                                                </div>
                                            </div>





                                        </div>

                                        <h6>Medical Information</h6>

                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="medical_name">Name of Medical Clinic:</label>
                                                    <input type="text"
                                                           value={form.medical_name}
                                                           onChange={(e) => setForm({...form,medical_name:e.target.value})}
                                                           name="medical_name" className="form-control" id="medical_name"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="medical_address">Address of Medical Clinic:</label>
                                                    <input type="text"
                                                           value={form.medical_address}
                                                           onChange={(e) => setForm({...form,medical_address:e.target.value})}
                                                           name="medical_address" className="form-control" id="medical_address"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="medical_contact">Contact Phone:</label>
                                                    <input type="text"
                                                           value={form.medical_contact}
                                                           onChange={(e) => setForm({...form,medical_contact:e.target.value})}
                                                           name="medical_contact" className="form-control" id="medical_contact"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="physician_degree">Name and Degree of Physician:</label>
                                                    <input type="text"
                                                           value={form.physician_degree}
                                                           onChange={(e) => setForm({...form,physician_degree:e.target.value})}
                                                           name="physician_degree" className="form-control" id="physician_degree"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="physician_licensing">Name of Physician's Licensing:</label>
                                                    <input type="text"
                                                           value={form.physician_licensing}
                                                           onChange={(e) => setForm({...form,physician_licensing:e.target.value})}
                                                           name="physician_licensing" className="form-control" id="physician_licensing"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Date of Issue of Physician's License</label>
                                                    <DatePicker
                                                        selected={form?.date_issue_physician_licensing ? new Date(new Date(form.date_issue_physician_licensing)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,date_issue_physician_licensing:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exam_date">Date of Examination</label>
                                                    <DatePicker
                                                        selected={form?.exam_date ? new Date(new Date(form.exam_date)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,exam_date:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="physician_comment">Physician's Comment:</label>
                                                    <input type="text"
                                                           value={form.physician_comment}
                                                           onChange={(e) => setForm({...form,physician_comment:e.target.value})}
                                                           name="physician_comment" className="form-control" id="physician_comment"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="remarks">Remarks:</label>
                                                    <input type="text"
                                                           value={form.remarks}
                                                           onChange={(e) => setForm({...form,remarks:e.target.value})}
                                                           name="remarks" className="form-control" id="remarks"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">{
                                            disabled ? 'Save.....' : 'Save'
                                        }</button>
                                    </div>
                                </form>
                            </div>

                </div>
            </section>
        </div>

    );
};

export default Create;