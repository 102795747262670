import React, {useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";

const CreateParticularItem = () => {
    const navigate = useNavigate();

    const [type, setType] = useState('');
    const [particular,setParticular] = useState('');


    const onSubmit = (e) => {
        e.preventDefault();

        axiosClient.post('/company/accounts/particulars', {
            type,
            particular
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.PARTICULARS}`);
                },1000);

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    return (
        <div>
            <Breadcrumb title='Create Earning Deduction' prev={COMPANYROUTE.PARTICULARS}    />
            <section className="content">
                <div className="container-fluid">

                    <div className="card card-primary">

                        <form onSubmit={onSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="first_name">Choose Type </label>
                                            <select name=""
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}
                                                    className="form-control" id="">
                                                <option value="">Choose</option>
                                                <option value="earning">Earning</option>
                                                <option value="deduction">Deduction</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="particular_type">Particular Type</label>
                                            <input
                                                value={particular}
                                                onChange={(e) => setParticular(e.target.value)}
                                                type="text" name="particular_type" className="form-control" id="particular_type"
                                                   placeholder="Enter Particular Type" />
                                        </div>
                                    </div>



                                </div>


                            </div>

                            <div className="card-footer">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default CreateParticularItem;