import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useReducer, useState} from "react";
import axiosClient from "../../../axios-client";
import './styles/style.css'
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";
import LoadingBar from 'react-top-loading-bar';
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {Link, useParams} from "react-router-dom";
import fileDownload from 'js-file-download';
import {ACTION_TYPES, INITIAL_STATE, JobReducer} from "../../../reducers/Company/JobReducer";
import {SHORTLISTED_JOB} from "../../../constants/companyRoutes";

const JobList = () => {
    const [progress, setProgress] = useState(10);

    const [state,dispatch] = useReducer(JobReducer,INITIAL_STATE);

    const getReportData = (pageNumber = 1) => {
        setProgress(50)
        dispatch({type:ACTION_TYPES.GET_JOB_DATA});
        axiosClient.get(`/company/job/?page=${pageNumber}`)
            .then(({data}) => {
                dispatch({type:ACTION_TYPES.GET_JOB_DATA_SUCCESS,payload:data.data});
                setProgress(100);

            }).catch(() => {
            dispatch({type:ACTION_TYPES.GET_JOB_DATA_ERROR});

        })
    }

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete this job post?`)){
            return false;
        }
        axiosClient.delete(`/company/job/${data?.job_id}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData(state.pageNumber);
            })
    }



    useEffect(() => {
        getReportData(1)
    },[])









    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Job List'    addnew={true}  route={COMPANYROUTE.ADD_NEW_JOB}     />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className=" table-responsive new-card-body"  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Title</th>
                                            <th>Company</th>
                                            <th>Vessel Type</th>
                                            <th>Vacancy</th>
                                            <th>Posting Date</th>
                                            <th>Deadline</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {state.loading ? "Loading" : ""}

                                        {state?.data?.data?.length > 0 ?  state?.data?.data?.map((data,index) => (
                                            <tr key={index}>
                                                <td>{data?.title}</td>
                                                <td>{data?.comapny_name}</td>
                                                <td>{data?.vessel_type}</td>
                                                <td>{data?.no_of_vacancy}</td>
                                                <td>{data?.posting_date}</td>
                                                <td>{data?.deadline}</td>
                                                <td>
                                                    <Link to={`${COMPANYROUTE.SHORTLISTED_JOB}${data?.job_id}`}    className="btn btn-success btn-sm ml-1">Shortlist</Link>
                                                    <Link to={`${COMPANYROUTE.APPLIED_JOB}${data?.job_id}`}    className="btn btn-primary btn-sm ml-1">Applied</Link>
                                                    <Link to={`${COMPANYROUTE.EDIT_JOB}${data?.job_id}`} className="btn btn-info btn-sm ml-1"><i className="fas fa-pencil-alt"></i></Link>
                                                     <button  onClick={e => onDelete(data)}   className="btn btn-danger btn-sm ml-1 "  ><i className="fas fa-trash"></i></button>

                                                </td>


                                            </tr>
                                        )) : <tr><td colSpan={4} className='text-center'>No Data Found</td></tr>}

                                        {state.error ? <tr><td colSpan={13} className='text-center text text-danger'>Something Went Wrong!</td></tr> : '' }

                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">

                                        <Pagination
                                            activePage={state?.data?.current_page ? state?.data?.current_page : 0}
                                            itemsCountPerPage={state?.data?.per_page ? state?.data?.per_page : 0 }
                                            totalItemsCount={state?.data?.total ? state?.data?.total : 0}
                                            onChange={(pageNumber) => {
                                                getReportData(pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
};

export default JobList;