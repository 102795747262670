export const INITIAL_STATE = {
    loading:false,
    error:false,
    data:{},
}

export const ACTION_TYPES = {
    GET_DOCUMENT_DATA: 'GET_DOCUMENT_DATA',
    GET_DOCUMENT_DATA_SUCCESS: 'GET_DOCUMENT_DATA_SUCCESS',
    GET_DOCUMENT_DATA_ERROR: 'GET_DOCUMENT_DATA_ERROR'
}

export const documentReducer = (state,action) => {
     switch (action.type) {
          case ACTION_TYPES.GET_DOCUMENT_DATA:
                return {
                 loading:true,
                 error:false,
                }
          case ACTION_TYPES.GET_DOCUMENT_DATA_SUCCESS:
                return {
                 ...state,
                 loading:false,
                 data:action.payload
                }
          case  ACTION_TYPES.GET_DOCUMENT_DATA_ERROR:
                return {
                 loading:false,
                 error:true,
                }
          default:
                return state
     }

}