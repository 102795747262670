import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import axiosClient from "../../../axios-client";
import React, {useEffect, useState} from "react";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportContractDurationHook from "../../../hooks/UseExportContractDurationHook";


const SeafarerContractDuration = () => {
    const [vessel,setVessel] = useState([]);
    const [data,setData] = useState([]);

    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessel(data.data)
            }).catch(() => {

        })
    }
    const getData = () => {
        axiosClient.post('/company/report/seafarer-contact-duration')
            .then(({data}) => {
                setData(data.data)
            }).catch((e) => {

        })
    }
    useEffect(() => {
         getVesselData()
         getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-contact-duration/print',
    });


    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-contact-duration/export')
            .then(({data}) => {
                UseExportContractDurationHook( 'xlsx',data?.data,vessel,'Seafarer Contract Duration Report','seafarer-contract-duration-report')
            }).catch((e) => {
            console.log(e)
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-contact-duration/export')
            .then(({data}) => {
                UseExportContractDurationHook( 'csv',data?.data,vessel,'Seafarer Contract Duration Report','seafarer-contract-duration-report')
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Seafarer Contract Duration Reports'    />

            <section className="contents">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                       <div className="d-flex justify-content-end  ">
                                            <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                            <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                            <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>
                                       </div>
                                </div>
                                <div className="card-body">
                                    <table  className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Rank</th>
                                            {vessel.length > 0 && vessel.map((data,index) => (
                                                <th key={index}>{data?.vessel_name}</th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            data.length > 0 ? data.map((data,index) => (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{data?.rank}</td>
                                                    {
                                                        data.vessel.length > 0 ? data?.vessel?.map((ves,i) => (
                                                            //vessel id exist not show
                                                             <td key={i}  dangerouslySetInnerHTML={{ __html: ves }} />
                                                        )): ''
                                                    }
                                                </tr>
                                            )) : ''
                                        }


                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerContractDuration;