import React from 'react';

const DocumentPrintTable = ({data}) => {
    return (
        <table id="" className="table table-bordered table-hover seafarer-list ">
            <thead >
            <tr>
                <th>Seafarer Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>CDC NO</th>
                <th>Vessel Name</th>
                <th>Document Name</th>
                <th>Expiry Date</th>

            </tr>
            </thead>
            <tbody>


            {
                data?.data?.length > 0 && data?.data?.map((data,index) => {
                    return (
                        <tr key={index}>
                            <td>{data?.name}</td>
                            <td>{data?.email}</td>
                            <td>{data?.mobile}</td>
                            <td>{data?.cdc_no}</td>
                            <td>{data?.vessel_name}</td>
                            <td>{data?.info_type}</td>
                            <td>{data?.expiry_date} ({data?.remaining_days})</td>
                        </tr>
                    )
                })
            }

            </tbody>

        </table>
    );
};

export default DocumentPrintTable;