import React, {useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

const EditCompanyUserPassword = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disabled,setDisabled] = useState(false);
    const [form,setForm] = useState({
        password:'',
        password_confirmation:'',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        axiosClient.put('/company/company-users/update-password/'+id, form)
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                navigate(`${COMPANYROUTE.COMPANY_USER_LIST}`);
            }).catch(err => {

            setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }



    return (
        <div>
            <Breadcrumb title='Update Password' prev={COMPANYROUTE.COMPANY_USER_LIST}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="password">Password</label>
                                                    <input type="password"
                                                           value={form.password}
                                                           onChange={(e) => setForm({...form,password:e.target.value})}
                                                           name="password" className="form-control" id="password"
                                                           placeholder="Enter Password" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="password">Confirm Password</label>
                                                    <input type="password"
                                                           value={form.password_confirmation}
                                                           onChange={(e) => setForm({...form,password_confirmation:e.target.value})}
                                                           name="password" className="form-control" id="password"
                                                           placeholder="Enter Password" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">{
                                            disabled ? 'Update.....' : 'Update'
                                        }</button>
                                    </div>
                                </form>
                            </div>

                </div>
            </section>
        </div>

    );
};

export default EditCompanyUserPassword;