import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";

am4core.useTheme(am4themes_animated);


const SeafarerContractChart = ({vessels}) => {
    const [seafarerContractData,setSeafarerContractData] = useState([]);
    const [seafarerContractLabel,setSeafarerContractLabel] = useState([]);
    const [vesselSelect,setVesselSelect] = useState('');


    const getTopFourContractData = (vessel = 1) => {
        axiosClient.post('/company/chart/top-four-seafarer-contract',{
            vessel_id: vessel,
        })
            .then(({data}) => {
                setSeafarerContractData(data.data[0])
                setSeafarerContractLabel(data.data[1])
            }).catch(() => {
        })
    }

    useEffect(() => {
        getTopFourContractData(vesselSelect)
    },[vesselSelect])



    useLayoutEffect(() => {
        let x = am4core.create("seafarerContractCHartDiv", am4charts.PieChart);
        x.innerRadius = am4core.percent(40);
        x.logo.disabled = true;


        x.data = [{
            "title": "Master (" + seafarerContractLabel[0]?.first_name + " " + seafarerContractLabel[0]?.last_name + ")",
            "value": seafarerContractData[0],
            "color": am4core.color("#00a65a")
        },{
            "title": "Chief Engineer (" + seafarerContractLabel[1]?.first_name + " " + seafarerContractLabel[1]?.last_name + ")",
            "value": seafarerContractData[1],
            "color": am4core.color("#17a2b8")
        },{
            "title": "Chief Officer (" + seafarerContractLabel[2]?.first_name + " " + seafarerContractLabel[2]?.last_name + ")",
            "value": seafarerContractData[2],
            "color": am4core.color("#ffc107")
        },{
            "title": "2nd Engineer (" + seafarerContractLabel[3]?.first_name + " " + seafarerContractLabel[3]?.last_name + ")",
            "value": seafarerContractData[3],
            "color": am4core.color("#dd4b39")
        },
        ];



        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "title";
        pieSeries.slices.template.propertyFields.fill = "color";

        pieSeries.slices.template.tooltipText = "{category}:{value}";

        //pie series label disable if value is 0
        pieSeries.labels.template.adapter.add("text", function(text, target) {
            return target.dataItem.value > 0 ? text : "";
        });


        let label = pieSeries.labels.template;
        label.text = "{category}:{value}";
        label.radius = am4core.percent(-50);

        x.legend = new am4charts.Legend();
        pieSeries.legendSettings.valueText = " ";
        x.legend.itemContainers.template.maxWidth = 120;




        return () => {
            x.dispose();
        };
    }, [seafarerContractData,seafarerContractLabel]);

    return (
        <>
            <div className="card card-info">
                <div className="card-header">
                    <h3 className="card-title">Top 4 Seafarer No Of Contract</h3>

                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus"></i>
                        </button>

                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <select style={{maxWidth: '100%',background:'navy',color:'white'}}
                                    onChange={e => setVesselSelect(e.target.value)}
                                    value={vesselSelect}
                                    className="form-control"
                            >
                                {vessels.map((vessel,index) => (
                                    <option key={index} value={vessel?.vessel_id}>{vessel?.vessel_name}</option>
                                ))}

                            </select>
                        </div>

                    </div>

                    <div className="chart">
                        <div id="seafarerContractCHartDiv" style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default SeafarerContractChart;

