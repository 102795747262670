import React, {useState} from 'react';
import {toast} from "react-toastify";
import CrewSmsSentModal from "./CrewSmsSentModal";

const CrewListSmsModal = ({data = [],hide}) => {

    const [mobileArray,setMobileArray] = useState([])
    const [bulkSelect,setBulkSelect] = useState(false)

    const [modal,setModal] = useState(false)

    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }



    const handleChange = (e) => {
        let value = e.target.value;
        let checked = e.target.checked;
        if(checked){
            setMobileArray([...mobileArray,value])
        }else{
            setMobileArray(mobileArray.filter(item => item !== value))
        }
    }

    const bulkSelectChange = (e) => {
        let checked = e.target.checked;
        if(checked){
            setMobileArray(data.map(item => item?.email))
            setBulkSelect(true)
        }else{
            setMobileArray([])
            setBulkSelect(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(mobileArray.length <= 0){
            toast.error('Please Select Email')
        }else{
            setModal(true)
        }
    }





    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{overflowY:'scroll', height:'600px'}}>
                        <div className="modal-header">
                            <h5 className="modal-title">REPLACE CREWS SMS LIST</h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th><input type="checkbox" onChange={bulkSelectChange}  className="form-control"/></th>
                                            <th>Name </th>
                                            <th>Email</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data?.length > 0 && data.map((item,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td><input type="checkbox" onChange={handleChange}
                                                                   checked={bulkSelect === true ? true : mobileArray.includes(item?.mobile)}
                                                                   value={item?.mobile}
                                                                   className="form-control"/></td>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.mobile}</td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                                <button type="submit" className="btn btn-success"   >Send Email</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                modal && <CrewSmsSentModal mobileArray={mobileArray} hide={() => setModal(false)} parentHide={hide} />
            }
        </>
    );
};

export default CrewListSmsModal;