import React, {useEffect, useState} from 'react';
import Logo from "../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import './styles/checklist.css'
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {Link, useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../hooks/usePrintWindow";
const SeaportImmigration = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [data, setData] = useState({})

    const navigate = useNavigate();

    const getData = () => {

        axiosClient.get('/company/seaport-immigration/'+id)
            .then(({data}) => {
                if(data.data  == false){
                    toast.error('Seafarer  not found')
                    navigate(COMPANYROUTE.SEAFARER)
                }
                setData(data.data)
                setProgress(100)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/seaport-immigration/print/' + id,
    });
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Seaport Immigration'  prev={COMPANYROUTE.SEAFARER}    />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-10 offset-md-1">
                                    <div className="checklist-panel">


                                 <div className="justify-content-center d-flex mb-2">
                                     <div className="d-flex">
                                        <div className="logo justify-content-start">
                                            <Logo />
                                        </div>
                                        <div className="header-text">
                                            <h5 className="text-center company-name mt-3">{data?.company_name}</h5>
                                            <h6 className="text-center company-name mt-3">Seaport Immigration</h6>
                                        </div>
                                    </div>
                                </div>
                                        <div className="content-body">
                                          <table className="table table-bordered">
                                               <thead>
                                                   <tr>
                                                       <th> Your reference</th>
                                                       <th>Your letter of</th>
                                                       <th>Our reference</th>
                                                       <th>Extension</th>
                                                       <th>Date</th>
                                                   </tr>
                                               </thead>
                                              <tbody>
                                                   <tr>
                                                       <td></td>
                                                       <td></td>
                                                       <td>SPD/2023/3074</td>
                                                       <td></td>
                                                       <td></td>
                                                   </tr>
                                              </tbody>
                                          </table>

                                          <div className="seaport-immigration">
                                              <h6 >The Immigration Officer</h6>
                                              <p >Seaport Immigration</p>
                                              <p  >CMP, Chattogram. </p>
                                          </div>


                                            <div  >
                                                <p  >Subject: <strong>Request for joining endorsement in the passport.
                                                </strong></p>
                                            </div>


                                            <div className="mb-3">
                                                Dear Sir,
                                                  <br/> <br/>
                                                The following person singed on in our vessel {data?.vessel}   at Chattogram.
                                            </div>


                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th> SL</th>
                                                    <th>Name</th>
                                                    <th>Rank</th>
                                                    <th>Passport No</th>
                                                    <th>Date Sign Of</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>01</td>
                                                    <td>{data?.name}</td>
                                                    <td>{data?.rank}</td>
                                                    <td>{data?.passport}</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <p>We therefore request you to kindly endorse him departure in Chattogram in his passport</p>

                                            <p>Thanking you</p>



                                      </div>

                                 </div>


                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default SeaportImmigration;