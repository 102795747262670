import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axiosClient from "../../../axios-client";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";

const Create = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [name,setName] = useState('');
    const [status,setStatus] = useState('');
    const [disabled,setDisabled] = useState(false);

    const getData = () => {
        axiosClient.get('/company/port/'+id+'/edit')
            .then(({data}) => {
                setName(data?.data?.name)
                setStatus(data?.data?.status)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getData()
    },[])


    const onSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        axiosClient.put('/company/port/'+id, {
            name: name,
            status: status
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(-1);
                },1000);
                setDisabled(false)

            }).catch(err => {
            const response = err.response;
            setDisabled(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    return (
        <div>
            <Breadcrumb title='Edit Port' prev={COMPANYROUTE.PORT}    />
            <section className="content">
                <div className="container-fluid">

                    <div className="card card-primary">

                        <form onSubmit={onSubmit}>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="particular_type">Port Name</label>
                                            <input
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text" name="particular_type" className="form-control" id="particular_type"
                                               />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="password">Choose Status</label>
                                            <select className="form-control"
                                                    value={status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <option value="">Choose Status</option>
                                                <option value="1">Active</option>
                                                <option value="0">InActive</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>


                            </div>

                            <div className="card-footer">
                                <button type="submit" disabled={disabled} className="btn btn-success">{
                                    disabled ? 'Loading...' : 'Save'
                                }</button>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default Create;