
const ExcelJS = require('exceljs');



   const UseExportHook = (fileExtension,data,vessel,title,file_name) => {
       const workBook = new ExcelJS.Workbook();

        const sheet = workBook.addWorksheet(title);
        sheet.properties.defaultRowHeight = 30;

        sheet.getRow(1).font = {
            family: 2,
            size: 10,
            bold: true,
        };


        sheet.columns = [
            { header: 'SL', key: 'sl', width: 5, height: 20 },
            { header: 'Rank', key: 'rank', width: 32,height: 20 },
            ...vessel.map((column) => ({
                header: column.vessel_name,
                key: column.vessel_id,
                width: 32,
                height: 20,
             })),
            ];
       data?.forEach((row, rowIndex) => {
           const rowData = {
               sl: rowIndex + 1,
               rank: row[0].rank,
           };
           row.slice(1).forEach((item) => {
               rowData[item.vessel_id] = item.rank_current_duration;
           });

           sheet.addRow(rowData);
       });


       const writeBufferPromise =
            fileExtension === 'xlsx' ? workBook.xlsx.writeBuffer() : workBook.csv.writeBuffer();

        writeBufferPromise.then((data) => {
            const blob = new Blob([data], {
                type:
                    fileExtension === 'xlsx'
                        ? 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet'
                        : 'application/vnd.openxmlformats-officedocument.spreadsheet.csv',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `${file_name}.${fileExtension}`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

export default UseExportHook;