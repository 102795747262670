import React, {useEffect} from 'react';
import axiosClient from "../../../../axios-client";
import {useStateContext} from "../../../../context/ContextProvider";


const Header = () => {
    const {user,setUser,setToken,setUserType,setUserId} =  useStateContext()

    const onLogout = (e) => {
        e.preventDefault();

        axiosClient.post('/company/logout',{
            user_id:localStorage.getItem('USER_ID')
        })
            .then(() => {
                setUser({})
                setUserId(null)
                setToken(null)
                setUserType(null)
                localStorage.clear()

            })
    }

    useEffect(() => {

    },[user])
    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="src/Components/Company/Layout/Company/Header#" role="button"><i
                        className="fas fa-bars"></i></a>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#">
                        <i className="far fa-user"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">


                        <div className="dropdown-divider"></div>
                        <a href="#" onClick={onLogout} className="dropdown-item dropdown-footer">Logout </a>
                        <div className="dropdown-divider"></div>
                    </div>
                </li>
            </ul>
        </nav>
    );
};

export default Header;