import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import DatePicker from "react-datepicker";
import AllRank from "../../../Components/Company/Common/AllRank";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";
import Pagination from "react-js-pagination";


const SeafarerNewPromotion = () => {
    const [rankSelect,setRankSelect] = useState('');
    const [reportData,setReportData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));

    const getReportData = (rank = 0,dateFromData = null,dateToData = null,pageNumber = 1) => {
        axiosClient.post('/company/report/seafarer-new-promotion',{
            rank_id: rank,
            date_from: dateFromData,
            date_to: dateToData,
            page: pageNumber
        })
            .then(({data}) => {
                console.log(data)
                setReportData(data)
            }).catch((e) => {
            console.log(e)
        })
    }







    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getReportData(rankSelect,dateFromData,dateToData,1)

    },[rankSelect,dateFrom,dateTo])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-new-promotion/print',
        payload: { rank_id: rankSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString() },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Name', key: 'name', width: 20 },
        { header: 'CDC No', key: 'cdc', width: 20 },
        { header: 'Vessel', key: 'vessel', width: 20 },
        { header: 'Rank', key: 'rank', width: 20 },
        { header: 'Promotion Date', key: 'embarkation', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-new-promotion/export',{
            rank_id: rankSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Seafarer New Promotion Report','seafarer-new-promotion-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-new-promotion/export',{
            rank_id: rankSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Seafarer New Promotion Reports','seafarer-new-promotion-report',exportColumns)
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Seafarer New Promotion Reports'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-12 col-md-9">
                                                <div className="row">
                                                    <div className='col-12 col-md-4'>
                                                        <AllRank rankSelect={rankSelect} setRankSelect={setRankSelect} />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <DatePicker
                                                            selected={dateFrom}
                                                            dateFormat='dd-MM-yyyy'
                                                            className="form-control"
                                                            onChange={(date) => setDateFrom(date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <DatePicker
                                                            selected={dateTo}
                                                            dateFormat='dd-MM-yyyy'
                                                            className="form-control"
                                                            onChange={(date) => setDateTo(date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="float-right">
                                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                    <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                    <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <div className="card-body  "  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Seafarer Name</th>
                                            <th>CDC No</th>
                                            <th>Vessel</th>
                                            <th>Rank</th>
                                            <th>Promotion Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>


                                        {reportData?.data?.length > 0 ?  reportData?.data?.map((data,index) => (

                                            <tr  key={index} >
                                                <td>{data?.name}</td>
                                                <td>{data?.cdc}</td>
                                                <td>{data?.vessel}</td>
                                                <td>{data?.rank}</td>
                                                <td>{data?.embarkation}</td>

                                            </tr>
                                        )) : <tr><td colSpan={5} className='text-center'>No Data Found</td></tr>}



                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">




                                        <Pagination
                                            activePage={reportData?.pagination?.current_page ? reportData?.pagination?.current_page : 0}
                                            itemsCountPerPage={reportData?.pagination?.per_page ? reportData?.pagination?.per_page : 0 }
                                            totalItemsCount={reportData?.pagination?.total ? reportData?.pagination?.total : 0}
                                            onChange={(pageNumber) => {
                                                let dateFromData = new Date(dateFrom).toLocaleDateString();
                                                let dateToData = new Date(dateTo).toLocaleDateString();
                                                 getReportData(rankSelect,dateFromData,dateToData,pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerNewPromotion;