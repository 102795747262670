import React, {useEffect, useReducer, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import axiosClient from "../../../../axios-client";
import {SalaryPolicyYearReducer,ACTION_TYPES,INITIAL_STATE} from "../../../../reducers/Company/SalaryPolicyYearReducer";
import {Link} from "react-router-dom";

const SalaryPolicy = () => {
    const [progress, setProgress] = useState(10);

    const [state,dispatch] = useReducer(SalaryPolicyYearReducer,INITIAL_STATE);

    const getReportData = (pageNumber = 1) => {
        setProgress(50)
        dispatch({type:ACTION_TYPES.GET_DATA});
        axiosClient.get(`/company/accounts/salary-policy/?page=${pageNumber}`)
            .then(({data}) => {
                dispatch({type:ACTION_TYPES.GET_DATA_SUCCESS,payload:data.data});
                setProgress(100);

            }).catch(() => {
            dispatch({type:ACTION_TYPES.GET_DATA_ERROR});

        })
    }



    useEffect(() => {
        getReportData(1)
    },[])
    return (
        <>
            <Breadcrumb title='Salary Policy Year' addnew={true} route={COMPANYROUTE.SALARY_POLICY_CREATE}    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">


                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>Year</th>
                                            <th>Position</th>
                                            <th>Contract</th>
                                            <th>Seniority</th>
                                            <th>Basic</th>
                                            <th>O/T</th>
                                            <th>Leave Pay</th>
                                            <th>Sub Allow</th>
                                            <th>Uniform Allow</th>
                                            <th>Owner Allow</th>
                                            <th>Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {state.loading ? "Loading" : ""}

                                        {state?.data?.data?.length > 0 ?  state?.data?.data?.map((data,index) => (
                                            <tr key={index}>
                                                <td>{data?.policy_year}</td>
                                                <td>{data?.rank}</td>
                                                <td>{data?.contract}</td>
                                                <td>{data?.seniority}</td>
                                                <td>{data?.basic_salary}</td>
                                                <td>{data?.overtime}</td>
                                                <td>{data?.leave_pay}</td>
                                                <td>{data?.sub_allow}</td>
                                                <td>{data?.uniform_allow}</td>
                                                <td>{data?.owners_allow}</td>
                                                <td>{data?.total}</td>

                                            </tr>
                                        )) : <tr><td colSpan={4} className='text-center'>No Data Found</td></tr>}

                                        {state.error ? <tr><td colSpan={13} className='text-center text text-danger'>Something Went Wrong!</td></tr> : '' }


                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SalaryPolicy;