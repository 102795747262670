import React, {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";

const Landing = () => {
    const navigate = useNavigate();

    useEffect(() => {
         navigate('/login')
    },[])

    return (
        <>
             {/*<Link to={'/company/dashboard'}>Dashboard</Link>*/}
             <Link to={'/login'}>Login</Link>
        </>
    );
};

export default Landing;