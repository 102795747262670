import React from 'react';

const OtherInfoForm = ({form,setForm,handleInputChange,disable}) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="col-md-6 col-12">
                    <p>♦ Have you ever been charged in court for any offence? </p>
                    <div className="d-flex ">
                        <div className="form-group mx-2">
                            <input name="court_offence" onChange={e => setForm({...form,court_offence:e.target.value})} checked={form?.court_offence === "Yes"}   type="radio" id="court_offence_yes"  value="Yes" />
                            <label htmlFor="court_offence_yes" > <small className="ml-1">Yes</small></label>
                        </div>
                        <div className="form-group mx-2">
                            <input name="court_offence" onChange={e => setForm({...form,court_offence:e.target.value})} checked={form?.court_offence === "No"}    type="radio" id="court_offence_no"  value="No" />
                            <label htmlFor="court_offence_no" > <small className="ml-1">No</small></label>
                        </div>
                        <div className="form-group mx-2">
                            <input name="court_offence" onChange={e => setForm({...form,court_offence:e.target.value})} checked={form?.court_offence === "Not"}     type="radio" id="court_offence_not"  value="Not" />
                            <label htmlFor="court_offence_not" > <small className="ml-1">Not Applicable</small></label>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-12">
                    <p>♦ Have you been involved in any Maritime Incident in your career? </p>
                    <div className="d-flex ">
                        <div className="form-group mx-2">
                            <input name="maritime_incident" onChange={e => setForm({...form,maritime_incident:e.target.value})} checked={form?.maritime_incident === "Yes"}   type="radio" id="maritime_incident_yes"  value="Yes" />
                            <label htmlFor="maritime_incident_yes" > <small className="ml-1">Yes</small></label>
                        </div>
                        <div className="form-group mx-2">
                            <input name="maritime_incident" onChange={e => setForm({...form,maritime_incident:e.target.value})} checked={form?.maritime_incident === "No"}    type="radio" id="maritime_incident_no"  value="No" />
                            <label htmlFor="maritime_incident_no" > <small className="ml-1">No</small></label>
                        </div>
                        <div className="form-group mx-2">
                            <input name="maritime_incident" onChange={e => setForm({...form,maritime_incident:e.target.value})} checked={form?.maritime_incident === "Not"}     type="radio" id="maritime_incident_not"  value="Not" />
                            <label htmlFor="maritime_incident_not" > <small className="ml-1">Not Applicable</small></label>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦  Port State Control Inspection (PSC)</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="psc" onChange={e => setForm({...form,psc:e.target.value})}  checked={form?.psc === "Yes"}    type="radio" id="psc_yes"  value="Yes" />
                                <label htmlFor="psc_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="psc" onChange={e => setForm({...form,psc:e.target.value})}   checked={form?.psc === "No"}    type="radio" id="psc_no"  value="No" />
                                <label htmlFor="psc_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="psc" onChange={e => setForm({...form,psc:e.target.value})}   checked={form?.psc === "Not"}      type="radio" id="psc_not"  value="Not" />
                                <label htmlFor="psc_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.psc === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-12">

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('AMSA(Australia)')}   type="checkbox" id="amsa_austrialia"  value="AMSA(Australia)" />
                                <label htmlFor="amsa_austrialia" > <small className="mx-2">AMSA(Australia)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('USCG(USA)')}   type="checkbox" id="USCG"  value="USCG(USA)" />
                                <label htmlFor="USCG" > <small className="mx-2">USCG(USA)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('Europe and the North Atlantic(Paris MOU)')}  type="checkbox" id="MOU"  value="Europe and the North Atlantic(Paris MOU)" />
                                <label htmlFor="MOU" > <small className="mx-2">Europe and the North Atlantic(Paris MOU)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('Asia and the Pacific(Tokyo Mou)')}  type="checkbox" id="asia-mou"  value="Asia and the Pacific(Tokyo Mou)" />
                                <label htmlFor="asia-mou" > <small className="mx-2">Asia and the Pacific(Tokyo Mou)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('Latin America(Acuerdo de Vina del Mar)')}  type="checkbox" id="latin-america"  value="Latin America(Acuerdo de Vina del Mar)" />
                                <label htmlFor="latin-america" > <small className="mx-2">Latin America(Acuerdo de Vina del Mar)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('Caribbean(Caribbean MOU)')}  type="checkbox" id="Caribbean"  value="Caribbean(Caribbean MOU)" />
                                <label htmlFor="Caribbean" > <small className="mx-2">Caribbean(Caribbean MOU)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('West and Central Africa(Abuja MOU)')}  type="checkbox" id="CentralAfrica"  value="West and Central Africa(Abuja MOU)" />
                                <label htmlFor="CentralAfrica" > <small className="mx-2">West and Central Africa(Abuja MOU)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('Black Sea Region(Black Sea MOU)')}  type="checkbox" id="black-sea-mou"  value="Black Sea Region(Black Sea MOU)" />
                                <label htmlFor="black-sea-mou" > <small className="mx-2">Black Sea Region(Black Sea MOU)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('Mediterranean(Mediterranean MOU)')}  type="checkbox" id="Mediterranean"  value="Mediterranean(Mediterranean MOU)" />
                                <label htmlFor="Mediterranean" > <small className="mx-2">Mediterranean(Mediterranean MOU)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('Indian Ocean(Indian Ocean MOU)')}  type="checkbox" id="indian-ocean"  value="Indian Ocean(Indian Ocean MOU)" />
                                <label htmlFor="indian-ocean" > <small className="mx-2">Indian Ocean(Indian Ocean MOU)</small></label>

                                <input name="psc_details" onChange={event => handleInputChange(event)} checked={form.psc_details.includes('Arab States of the Gulf(GCC MOU or Riyadh MOU)')}  type="checkbox" id="arab-state-mou"  value="Arab States of the Gulf(GCC MOU or Riyadh MOU)" />
                                <label htmlFor="arab-state-mou" > <small className="mx-2">Arab States of the Gulf(GCC MOU or Riyadh MOU)</small></label>


                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Flag State Inspection (FSI)</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="fsi" onChange={e => setForm({...form,fsi:e.target.value})}  checked={form?.fsi === "Yes"}     type="radio" id="fsi_yes"  value="Yes" />
                                <label htmlFor="fsi_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="fsi" onChange={e => setForm({...form,fsi:e.target.value})}  checked={form?.fsi === "No"}     type="radio" id="fsi_no"  value="No" />
                                <label htmlFor="fsi_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="fsi" onChange={e => setForm({...form,fsi:e.target.value})}  checked={form?.fsi === "Not"}       type="radio" id="fsi_not"  value="Not" />
                                <label htmlFor="fsi_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.fsi === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <input name="fsi_details" onChange={event => handleInputChange(event)}  checked={form.fsi_details.includes('Singapore')}    type="checkbox" id="Singapore"  value="Singapore" />
                                <label htmlFor="Singapore" > <small className="mx-2">Singapore</small></label>

                                <input name="fsi_details" onChange={event => handleInputChange(event)}   checked={form.fsi_details.includes('Marshall Island')}  type="checkbox" id="Marshall-Island"  value="Marshall Island" />
                                <label htmlFor="Marshall-Island" > <small className="mx-2">Marshall Island</small></label>

                                <input name="fsi_details" onChange={event => handleInputChange(event)}   checked={form.fsi_details.includes('Panama')} type="checkbox" id="Panama"  value="Panama" />
                                <label htmlFor="Panama" > <small className="mx-2">Panama</small></label>

                                <input name="fsi_details" onChange={event => handleInputChange(event)}   checked={form.fsi_details.includes('Malta')} type="checkbox" id="Malta"  value="Malta" />
                                <label htmlFor="Malta" > <small className="mx-2">Malta</small></label>

                                <input name="fsi_details" onChange={event => handleInputChange(event)}  checked={form.fsi_details.includes('Korea')}  type="checkbox" id="Korea"  value="Korea" />
                                <label htmlFor="Korea" > <small className="mx-2">Korea</small></label>

                                <input name="fsi_details" onChange={event => handleInputChange(event)}   checked={form.fsi_details.includes('India')} type="checkbox" id="India"  value="India" />
                                <label htmlFor="India" > <small className="mx-2">India</small></label>

                                <input name="fsi_details" onChange={event => handleInputChange(event)}   checked={form.fsi_details.includes('Vanuato')} type="checkbox" id="Vanuato"  value="Vanuato" />
                                <label htmlFor="Vanuato" > <small className="mx-2">Vanuato</small></label>

                                <input name="fsi_details"  onChange={event => handleInputChange(event)}  checked={form.fsi_details.includes('Ceyman Island')} type="checkbox" id="Ceyman-Island"  value="Ceyman Island" />
                                <label htmlFor="Ceyman-Island" > <small className="mx-2">Ceyman Island</small></label>
                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Sire Inspection (SI)</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="si" onChange={e => setForm({...form,si:e.target.value})}  checked={form?.si === "Yes"}    type="radio" id="si_yes"  value="Yes" />
                                <label htmlFor="si_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="si" onChange={e => setForm({...form,si:e.target.value})}  checked={form?.si === "No"}     type="radio" id="si_no"  value="No" />
                                <label htmlFor="si_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="si" onChange={e => setForm({...form,si:e.target.value})}  checked={form?.si === "Not"}      type="radio" id="si_not"  value="Not" />
                                <label htmlFor="si_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.si === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <input name="si_details" onChange={event => handleInputChange(event)}  checked={form.si_details.includes('Shell')}   type="checkbox" id="Shell"  value="Shell" />
                                <label htmlFor="Shell" > <small className="mx-2">Shell</small></label>

                                <input name="si_details" onChange={event => handleInputChange(event)} checked={form.si_details.includes('BP')} type="checkbox" id="BP"  value="BP" />
                                <label htmlFor="BP" > <small className="mx-2">BP</small></label>

                                <input name="si_details" onChange={event => handleInputChange(event)}  checked={form.si_details.includes('Chevron')} type="checkbox" id="Chevron"  value="Chevron" />
                                <label htmlFor="Chevron" > <small className="mx-2">Chevron</small></label>

                                <input name="si_details"  onChange={event => handleInputChange(event)}  checked={form.si_details.includes('Exxonmobile')}  type="checkbox" id="Exxonmobile"  value="Exxonmobile" />
                                <label htmlFor="Exxonmobile" > <small className="mx-2">Exxonmobile</small></label>

                                <input name="si_details"  onChange={event => handleInputChange(event)}  checked={form.si_details.includes('Statoil')} type="checkbox" id="Statoil"  value="Statoil" />
                                <label htmlFor="Statoil" > <small className="mx-2">Statoil</small></label>

                                <input name="si_details" onChange={event => handleInputChange(event)}   checked={form.si_details.includes('Idemitsu')} type="checkbox" id="Idemitsu"  value="Idemitsu" />
                                <label htmlFor="Idemitsu" > <small className="mx-2">Idemitsu</small></label>

                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦  CDI Inspection</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="cdi" onChange={e => setForm({...form,cdi:e.target.value})}  checked={form?.cdi === "Yes"}    type="radio" id="cdi_yes"  value="Yes" />
                                <label htmlFor="cdi_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="cdi" onChange={e => setForm({...form,cdi:e.target.value})}  checked={form?.cdi === "No"}     type="radio" id="cdi_no"  value="No" />
                                <label htmlFor="cdi_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="cdi" onChange={e => setForm({...form,cdi:e.target.value})}   checked={form?.cdi === "Not"}     type="radio" id="cdi_not"  value="Not" />
                                <label htmlFor="cdi_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Navigation Experience</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="navigation" onChange={e => setForm({...form,navigation:e.target.value})}  checked={form?.navigation === "Yes"}    type="radio" id="navigation_yes"  value="Yes" />
                                <label htmlFor="navigation_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="navigation" onChange={e => setForm({...form,navigation:e.target.value})}  checked={form?.navigation === "No"}     type="radio" id="navigation_no"  value="No" />
                                <label htmlFor="navigation_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="navigation" onChange={e => setForm({...form,navigation:e.target.value})}  checked={form?.navigation === "Not"}      type="radio" id="navigation_not"  value="Not" />
                                <label htmlFor="navigation_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.navigation === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <input name="ne_details" onChange={event => handleInputChange(event)}   checked={form.ne_details.includes('EUROPE')}   type="checkbox" id="EUROPE"  value="EUROPE" />
                                <label htmlFor="EUROPE" > <small className="mx-2">EUROPE</small></label>

                                <input name="ne_details" onChange={event => handleInputChange(event)} checked={form.ne_details.includes('FAR EAST ASIA')}   type="checkbox" id="FAR-EAST-ASIA"  value="FAR EAST ASIA" />
                                <label htmlFor="FAR-EAST-ASIA" > <small className="mx-2">FAR EAST ASIA</small></label>

                                <input name="ne_details" onChange={event => handleInputChange(event)} checked={form.ne_details.includes('INDIAN SUB CONTINENT')}   type="checkbox" id="INDIAN-SUB-CONTINENT"  value="INDIAN SUB CONTINENT" />
                                <label htmlFor="INDIAN-SUB-CONTINENT" > <small className="mx-2">INDIAN SUB CONTINENT</small></label>

                                <input name="ne_details"  onChange={event => handleInputChange(event)} checked={form.ne_details.includes('MIDDLE EAST')}   type="checkbox" id="MIDDLE-EAST"  value="MIDDLE EAST" />
                                <label htmlFor="MIDDLE-EAST" > <small className="mx-2">MIDDLE EAST</small></label>

                                <input name="ne_details" onChange={event => handleInputChange(event)}  checked={form.ne_details.includes('SOUTH AMERICA')}   type="checkbox" id="SOUTH-AMERICA"  value="SOUTH AMERICA" />
                                <label htmlFor="SOUTH-AMERICA" > <small className="mx-2">SOUTH AMERICA</small></label>

                                <input name="ne_details"  onChange={event => handleInputChange(event)}  checked={form.ne_details.includes('USA')}  type="checkbox" id="USA1"  value="USA" />
                                <label htmlFor="USA1" > <small className="mx-2">USA</small></label>

                                <input name="ne_details" onChange={event => handleInputChange(event)}  checked={form.ne_details.includes('CENTRAL AMERICA')}   type="checkbox" id="CENTRAL-AMERICA"  value="CENTRAL AMERICA" />
                                <label htmlFor="CENTRAL-AMERICA" > <small className="mx-2">CENTRAL AMERICA</small></label>

                                <input name="ne_details" onChange={event => handleInputChange(event)} checked={form.ne_details.includes('WORLD WIDE')}    type="checkbox" id="WORLD-WIDE"  value="WORLD WIDE" />
                                <label htmlFor="WORLD-WIDE" > <small className="mx-2">WORLD WIDE</small></label>

                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Rank Experience</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="rank_exp" onChange={e => setForm({...form,rank_exp:e.target.value})}  checked={form?.rank_exp === "Yes"}    type="radio" id="rank_exp_yes"  value="Yes" />
                                <label htmlFor="rank_exp_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="rank_exp" onChange={e => setForm({...form,rank_exp:e.target.value})}  checked={form?.rank_exp === "No"}     type="radio" id="rank_exp_no"  value="No" />
                                <label htmlFor="rank_exp_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="rank_exp" onChange={e => setForm({...form,rank_exp:e.target.value})}  checked={form?.rank_exp === "Not"}      type="radio" id="rank_exp_not"  value="Not" />
                                <label htmlFor="rank_exp_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.rank_exp === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <label htmlFor="rank_exp_details" > <small className="mx-2">♦ Total / seatime bases rank experience years/month)</small></label>
                                <input name="rank_exp_details" onChange={e => setForm({...form,re_details:e.target.value})} value={form.re_details} type="text" className="form-control" id="rank_exp_details"   />

                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ Ship Type  Sailing Record</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="sailing_record" onChange={e => setForm({...form,sailing_record:e.target.value})}  checked={form?.sailing_record === "Yes"}    type="radio" id="sailing_record_yes"  value="Yes" />
                                <label htmlFor="sailing_record_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="sailing_record" onChange={e => setForm({...form,sailing_record:e.target.value})}  checked={form?.sailing_record === "No"}     type="radio" id="sailing_record_no"  value="No" />
                                <label htmlFor="sailing_record_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="sailing_record" onChange={e => setForm({...form,sailing_record:e.target.value})}  checked={form?.sailing_record === "Not"}      type="radio" id="sailing_record_not"  value="Not" />
                                <label htmlFor="sailing_record_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                {form?.sailing_record === 'Yes' ? (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <input name="stsr_details" onChange={event => handleInputChange(event)}  checked={form.stsr_details.includes('Product Tanker')}    type="checkbox" id="Product-Tanker"  value="Product Tanker" />
                                <label htmlFor="Product-Tanker" > <small className="mx-2">Product Tanker</small></label>

                                <input name="stsr_details" onChange={event => handleInputChange(event)} checked={form.stsr_details.includes('LPG')}  type="checkbox" id="LPG"  value="LPG" />
                                <label htmlFor="LPG" > <small className="mx-2">LPG</small></label>

                                <input name="stsr_details" onChange={event => handleInputChange(event)} checked={form.stsr_details.includes('LNG')}   type="checkbox" id="LNG"  value="LNG" />
                                <label htmlFor="LNG" > <small className="mx-2">LNG</small></label>

                                <input name="stsr_details"  onChange={event => handleInputChange(event)} checked={form.stsr_details.includes('Crude Carrier')}   type="checkbox" id="Crude-Carrier"  value="Crude Carrier" />
                                <label htmlFor="Crude-Carrier" > <small className="mx-2">Crude Carrier</small></label>

                                <input name="stsr_details" onChange={event => handleInputChange(event)} checked={form.stsr_details.includes('Chemical Tanker')}    type="checkbox" id="Chemical-Tanker"  value="Chemical Tanker" />
                                <label htmlFor="Chemical-Tanker" > <small className="mx-2">Chemical Tanker</small></label>

                                <input name="stsr_details" onChange={event => handleInputChange(event)} checked={form.stsr_details.includes('Bulk Carriers')}   type="checkbox" id="Bulk-Carriers"  value="Bulk Carriers" />
                                <label htmlFor="Bulk-Carriers" > <small className="mx-2">Bulk Carriers</small></label>

                                <input name="stsr_details" onChange={event => handleInputChange(event)} checked={form.stsr_details.includes('PCTC')}   type="checkbox" id="PCTC"  value="PCTC" />
                                <label htmlFor="PCTC" > <small className="mx-2">PCTC</small></label>

                                <input name="stsr_details" onChange={event => handleInputChange(event)} checked={form.stsr_details.includes('Log Carrier')}   type="checkbox" id="Log-Carrier"  value="Log Carrier" />
                                <label htmlFor="Log-Carrier" > <small className="mx-2">Log Carrier</small></label>

                                <input name="stsr_details" onChange={event => handleInputChange(event)} checked={form.stsr_details.includes('Wood Chip Carrier')}   type="checkbox" id="Wood-Chip-Carrier"  value="Wood Chip Carrier" />
                                <label htmlFor="Wood-Chip-Carrier" > <small className="mx-2">Wood Chip Carrier</small></label>

                            </div>
                        </div>
                    </>
                ) : ''}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦  Experience in Inert Gas Tanker ?</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="gas_tanker" onChange={e => setForm({...form,gas_tanker:e.target.value})}  checked={form?.gas_tanker === "Yes"}    type="radio" id="gas_tanker_yes"  value="Yes" />
                                <label htmlFor="gas_tanker_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="gas_tanker" onChange={e => setForm({...form,gas_tanker:e.target.value})}  checked={form?.gas_tanker === "No"}     type="radio" id="gas_tanker_no"  value="No" />
                                <label htmlFor="gas_tanker_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="gas_tanker" onChange={e => setForm({...form,gas_tanker:e.target.value})}  checked={form?.gas_tanker === "Not"}      type="radio" id="gas_tanker_not"  value="Not" />
                                <label htmlFor="gas_tanker_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦  Experience in USCG( TVEL/COC) ?</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="uscg" onChange={e => setForm({...form,uscg:e.target.value})}  checked={form?.uscg === "Yes"}    type="radio" id="uscg_yes"  value="Yes" />
                                <label htmlFor="uscg_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="uscg" onChange={e => setForm({...form,uscg:e.target.value})}  checked={form?.uscg === "No"}     type="radio" id="uscg_no"  value="No" />
                                <label htmlFor="uscg_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="uscg" onChange={e => setForm({...form,uscg:e.target.value})}  checked={form?.uscg === "Not"}      type="radio" id="uscg_not"  value="Not" />
                                <label htmlFor="uscg_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦  Experience on STS Operation ?</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="sts" onChange={e => setForm({...form,sts:e.target.value})}  checked={form?.sts === "Yes"}    type="radio" id="sts_yes"  value="Yes" />
                                <label htmlFor="sts_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="sts" onChange={e => setForm({...form,sts:e.target.value})}  checked={form?.sts === "No"}     type="radio" id="sts_no"  value="No" />
                                <label htmlFor="sts_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="sts" onChange={e => setForm({...form,sts:e.target.value})}  checked={form?.sts === "Not"}      type="radio" id="sts_not"  value="Not" />
                                <label htmlFor="sts_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <p>♦ Types of Cargo Carried</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="cargo_carried" onChange={e => setForm({...form,cargo_carried:e.target.value})} value={form.cargo_carried} className="form-control"  type="text" id="cargo_carried"   />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦  Radio Qualification</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="radio_qua" onChange={e => setForm({...form,radio_qua:e.target.value})}  checked={form?.radio_qua === "Yes"}    type="radio" id="radio_qua_yes"  value="Yes" />
                                <label htmlFor="radio_qua_yes" > <small className="ml-1">Yes</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="radio_qua" onChange={e => setForm({...form,radio_qua:e.target.value})}  checked={form?.radio_qua === "No"}     type="radio" id="radio_qua_no"  value="No" />
                                <label htmlFor="radio_qua_no" > <small className="ml-1">No</small></label>
                            </div>
                            <div className="form-group mx-2">
                                <input name="radio_qua" onChange={e => setForm({...form,radio_qua:e.target.value})}  checked={form?.radio_qua === "Not"}      type="radio" id="radio_qua_not"  value="Not" />
                                <label htmlFor="radio_qua_not" > <small className="ml-1">Not Applicable</small></label>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6 col-12">
                        <p>♦ English Proficiency Level</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <select style={{maxWidth: '100%'}}
                                        onChange={e => setForm({...form,english_pro:e.target.value})}
                                        value={form.english_pro}
                                        className="form-control"
                                >
                                    <option value="">Choose</option>
                                    <option value="Poor">Poor </option>
                                    <option value="Fair">Fair </option>
                                    <option value="Good">Good</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <p>♦ Marlin Test Result- in percentage</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="marlin_result" onChange={e => setForm({...form,marlin_result:e.target.value})} value={form.marlin_result} className="form-control"  type="number" id="marlin_result"    />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>♦  Name of last Shipping Company Served</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="last_company" onChange={e => setForm({...form,last_company:e.target.value})} value={form.last_company}  className="form-control"  type="text" id="last_company"   />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>♦ Reason for leaving last Company</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="leaving_reason" onChange={e => setForm({...form,reason_leaving:e.target.value})} value={form.reason_leaving}  className="form-control"  type="text" id="leaving_reason"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>♦ Nationalities Sailed</p>
                        <div className="d-flex ">
                            <div className="form-group mx-2">
                                <input name="nationalities" onChange={e => setForm({...form,nationalities:e.target.value})} value={form.nationalities}  className="form-control"  type="text" id="nationalities"    />
                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-12 mt-2">
                    <button type="submit" disabled={disable} className="btn btn-info">{
                        disable ? 'Saving...' : 'Save'
                    }</button>
                </div>

            </div>
        </div>

    );
};

export default OtherInfoForm;