import React, {useEffect, useLayoutEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const AutoSupplyDemand = () => {
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(),1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth(),1));
    const [vessel,setVessel] = useState([]);
    const [vesselId,setVesselId] = useState(0);

    const [analyticsData,setAnalyticsData] = useState([]);


    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessel(data.data)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getVesselData();
    },[])

    const getData = (dateFrom = '',dateTo = '',vesselId = 0) => {
        axiosClient.post('/company/auto-supply-demand',{
            vessel_id:vesselId,
            date_from: dateFrom,
            date_to: dateTo,
        })
            .then(({data}) => {
                setAnalyticsData(data?.data)
            }).catch(() => {

        })
    }


    useEffect(() => {
        let dateFromData = dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1) + '-' + dateFrom.getDate();
        let dateToData = dateTo.getFullYear() + '-' + (dateTo.getMonth() + 1) + '-' + dateTo.getDate()
        getData(dateFromData,dateToData,vesselId);
    },[dateFrom,dateTo,vesselId])

    const chartData =
        analyticsData?.length > 0  &&   analyticsData?.map((data,index) => (
            {
                "name": data?.rank,
                "supply": data?.supply,
                "demand": data?.demand,
            })
        )


    useLayoutEffect(() => {
        let chart = am4core.create("supplyDemandChart",  am4charts.XYChart);
        chart.logo.disabled = true;

        chart.data =  chartData

        // Create axes
        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;


        var  valueAxis = chart.xAxes.push(new am4charts.ValueAxis());

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "supply";
        series.dataFields.categoryY = "name";
        series.name = "Supply";
        series.tooltipText = "{name} Total Supply: [bold]{valueX}[/]";
        series.columns.template.fill = am4core.color("#17a2b8");
        series.columns.template.width = am4core.percent(20);


        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueX = "demand";
        series2.dataFields.categoryY = "name";
        series2.name = "Demand";
        series2.tooltipText = "{name} Total Demand : [bold]{valueX}[/]";
        series2.columns.template.fill = am4core.color("#ffc107");
        series2.columns.template.width = am4core.percent(20);


        chart.cursor = new am4charts.XYCursor();

        chart.legend = new am4charts.Legend();



        return () => {
            chart.dispose();
        };
    }, [analyticsData]);



    return (
       <>
           <Breadcrumb title='Auto Supply Demand'      />

           <section className="content">
               <div className="container-fluid">
                   <div className="row">
                       <div className="col-12">
                           <div className="card">
                               <div className="card-header">
                                   <div className='row'>
                                       <div className="col-12">
                                           <div className="row">
                                               <div className='col-12 col-md-4'>
                                                   <div className="form-group">
                                                       <select style={{maxWidth: '100%'}}
                                                               className="form-control"
                                                               value={vesselId}
                                                               onChange={(e) => setVesselId(e.target.value)}
                                                       >
                                                           <option  value="0" selected>All Vessel</option>
                                                           {vessel.map((data,index) => (
                                                               <option key={index}    value={data?.vessel_id}>{data?.vessel_name}</option>
                                                           ))}

                                                       </select>
                                                   </div>
                                               </div>

                                               <div className='col-12 col-md-4'>
                                                   <DatePicker
                                                       selected={dateFrom}
                                                       dateFormat='dd-MM-yyyy'
                                                       className="form-control"
                                                       onChange={(date) => setDateFrom(date)}
                                                       showMonthDropdown
                                                       showYearDropdown
                                                   />
                                               </div>
                                               <div className='col-12 col-md-4'>
                                                   <DatePicker
                                                       selected={dateTo}
                                                       dateFormat='dd-MM-yyyy'
                                                       className="form-control"
                                                       onChange={(date) => setDateTo(date)}
                                                       showMonthDropdown
                                                       showYearDropdown
                                                   />
                                               </div>
                                           </div>
                                       </div>

                                   </div>
                               </div>

                               <div className="card-body ">

                                   <div className="row">
                                       <div className="col-md-6 col-12">
                                           <div className="chart">
                                               <div id="supplyDemandChart" style={{ width: "100%", height: "1200px" }}></div>
                                           </div>
                                       </div>
                                       <div className="col-md-6 col-12">
                                           <table id="" className="table table-bordered table-hover">
                                               <thead>
                                               <tr>
                                                   <th>#</th>
                                                   <th>Rank </th>
                                                   <th>Demand</th>
                                                   <th>Supply</th>
                                               </tr>
                                               </thead>
                                               <tbody>


                                               {
                                                   analyticsData?.length > 0  &&   analyticsData?.map((data,index) => (
                                                       <tr key={index}>
                                                           <td>{index + 1}</td>
                                                           <td>{data?.rank}</td>
                                                           <td>{data?.demand}</td>
                                                           <td>{data?.supply}</td>
                                                       </tr>
                                                   ))
                                               }
                                               <tr>
                                                    <td colSpan={2}>Total</td>
                                                       <td>{analyticsData?.reduce((a, b) => a + (b['demand'] || 0), 0)}</td>
                                                       <td>{analyticsData?.reduce((a, b) => a + (b['supply'] || 0), 0)}</td>
                                               </tr>

                                               </tbody>

                                           </table>
                                       </div>
                                   </div>


                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
       </>
    );
};

export default AutoSupplyDemand;