import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";

am4core.useTheme(am4themes_animated);
const SeafarerDormantChart = ({ranks}) => {
    const [data,setData] = useState([]);
    const [rankSelect,setRankSelect] = useState('');

    const getTurnOverData = (rank_id = '') => {
        axiosClient.post('/company/chart/dormant',{
            rank_id: rank_id,
        })
            .then(({data}) => {
                setData(data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getTurnOverData(rankSelect)
    },[rankSelect])

    useLayoutEffect(() => {
        let x = am4core.create("SeafarerDormantChartDiv", am4charts.PieChart);
        x.innerRadius = am4core.percent(40);
        x.logo.disabled = true;


        x.data = [{
            "title": "Master",
            "value": data?.masterData?.length,
            "color": am4core.color("#00a65a")
        },{
            "title": "Chief Officer",
            "value": data?.chiefOfficerData?.length,
            "color": am4core.color("#17a2b8")
        },{
            "title": "Chief Engineer",
            "value": data?.chiefEngineerData?.length,
            "color": am4core.color("#ffc107")
        },{
            "title": "Second Engineer",
            "value": data?.secondEngineerData?.length,
            "color": am4core.color("#dd4b39")
        },
        ];



        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "title";
        pieSeries.slices.template.propertyFields.fill = "color";

        pieSeries.slices.template.tooltipText = "{category}:{value}";

        //pie series label disable if value is 0
        pieSeries.labels.template.adapter.add("text", function(text, target) {
            return target.dataItem.value > 0 ? text : "";
        });

        let label = pieSeries.labels.template;
        label.text = "{category}:{value}";

        x.legend = new am4charts.Legend();
        pieSeries.legendSettings.valueText = " ";
        x.legend.itemContainers.template.maxWidth = 120;




        return () => {
            x.dispose();
        };
    }, [data]);

    return (
        <>
            <div className="card card-secondary">
                <div className="card-header">
                    <h3 className="card-title">Dormant </h3>

                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus"></i>
                        </button>

                    </div>
                </div>
                <div className="card-body">
                    <div className="row">

                        <div className="col-md-3 mt-1">
                            <select style={{maxWidth: '100%',background:'navy',color:'white'}}
                                    onChange={e => setRankSelect(e.target.value)}
                                    value={rankSelect}
                                    className="form-control"
                            >
                                <option  value="0" selected>All Ranks</option>
                                {ranks.map((rank,index) => (
                                    <option key={index} value={rank?.category_id}>{rank?.category_name}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                    <div className="chart">
                        <div id="SeafarerDormantChartDiv" style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default SeafarerDormantChart;

