import React, {useEffect, useState} from 'react';
import Logo from "../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import './styles/checklist.css'
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {Link, useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../hooks/usePrintWindow";
const SignOnLetter = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [data, setData] = useState({})

    const navigate = useNavigate();

    const getData = () => {

        axiosClient.get('/company/sign-on-letter/'+id)
            .then(({data}) => {
                if(data.data  == false){
                    toast.error('Seafarer  not found')
                    navigate(COMPANYROUTE.SEAFARER)
                }
                console.log(data)
                setData(data.data)
                setProgress(100)
            }).catch((e) => {
            console.log(e)

        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/sign-on-letter/print/' + id,
    });
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Sign On Letter '  prev={COMPANYROUTE.SEAFARER}    />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-10 offset-md-1">
                                    <div className="checklist-panel">


                                 <div className="justify-content-center d-flex mb-2">
                                     <div className="d-flex">
                                        <div className="logo justify-content-start">
                                            <Logo />
                                        </div>
                                        <div className="header-text">
                                            <h5 className="text-center company-name mt-3">{data?.company_name}</h5>
                                            <h6 className="text-center company-name mt-3">Sign On Letter</h6>
                                        </div>
                                    </div>
                                </div>
                                        <div className="content-body">
                                          <table className="table table-bordered">
                                               <thead>
                                                   <tr>
                                                       <th> Your reference</th>
                                                       <th>Your letter of</th>
                                                       <th>Our reference</th>
                                                       <th>Extension</th>
                                                       <th>Date</th>
                                                   </tr>
                                               </thead>
                                              <tbody>
                                                   <tr>
                                                       <td></td>
                                                       <td></td>
                                                       <td>SPD/2023/3074</td>
                                                       <td></td>
                                                       <td></td>
                                                   </tr>
                                              </tbody>
                                          </table>

                                          <div className="seaport-immigration">
                                              <h6 >The Shipping Master </h6>
                                              <p>  Govt. Shipping Office</p>
                                              <p> CGO Building </p>
                                              <p>  Agrabad, Chittagong.</p>
                                          </div>



                                            <div  >
                                                <p  >Subject: <strong> Request for signing on & Issuance of Immigration letter of Vessel –  {data?.vessel}
                                                </strong></p>
                                            </div>


                                            <div className="mb-3">

                                                <p>Dear Sir,
                                                    <br />
                                                        We shall be highly obliged if you kindly arrange to sign on the article of agreement & issue immigration letter in favour of the following seafarer / seafarers. Who is/are going to join vessel-  {data.vessel}  at Chittagong port, on within  . We confirm you that we kept and maintain all crew documentation & its validity. </p>

                                            </div>


                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th> SL</th>
                                                    <th>Name</th>
                                                    <th>Rank</th>
                                                    <th>CDC</th>
                                                    <th>Salary</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>01</td>
                                                    <td>{data?.name}</td>
                                                    <td>{data?.rank}</td>
                                                    <td>{data?.cdc}</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>


                                            <table className="table table-bordered">
                                                <tr>
                                                    <td>
                                                        NAME & ADDRESS OF MANAGER  :
                                                    </td>

                                                    <td>
                                                        M/S. {data?.company_name} <br/>
                                                        “Kabir Manzil” (3rd Floor), Sheikh Mujib Road, <br/>
                                                        Agrabad C/A,  Chattogram-4100, Bangladesh. <br/>
                                                        Mobile Number-+88031-2513791-4, <br/>
                                                        E-Mail :spd@brsml.com
                                                    </td>
                                                </tr>
                                            </table>

                                            <table className="table table-bordered">
                                                <tr>
                                                    <td>
                                                        Ship Details  :
                                                    </td>

                                                    <td>
                                                         Name : {data?.vessel}
                                                         Flag : {data?.flag_state} <br/>
                                                         Call Sign: .....
                                                         IMO No: {data?.imo_number} <br/>
                                                         GRT: {data?.grt}
                                                         DWT: {data?.dwt} <br/>
                                                    </td>
                                                </tr>
                                            </table>

                                            <p>We are assuring your good self that the said appointment of the seafarer / seafarers is / are genuine and In future if anything found fake/wrong regarding the engagement, we will be fully responsible and liable. We are also assuring you to obey any decision taken by the concerned authority regarding the matter in future</p>

                                            <p>Your Faithfully</p>

                                            <p> Marine Superintendent </p>



                                      </div>

                                 </div>


                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default SignOnLetter;