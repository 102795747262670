import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import * as COMPANYROUTE from '../../../../constants/companyRoutes'

import axiosClient from "../../../../axios-client";
import {useStateContext} from "../../../../context/ContextProvider";
import {SEAFARER_DORMANT} from "../../../../constants/companyRoutes";


const Sidebar = () => {
    const [permission, setPermission] = useState([]);
    const [superAdmin, setSuperAdmin] = useState(false);
    const {role} =  useStateContext()



    const getData = () => {
        axiosClient.get('/company/permission-check')
            .then(({data}) => {
                if(data?.data == true){
                    setSuperAdmin(true)
                }else{
                    setPermission(data?.data);
                }

            }).catch((e) => {
        })
    }

    useEffect(() => {
        getData()
    }, [])

    // Define an object to store the mapping of module IDs to permission states
    const modulePermissionMap = {
        2: 'signonOffReportPermission',
        3: 'seafarerContractReportPermission',
        4: 'supplyDemandReportPermission',
        5: 'seafarerLeftReportPermission',
        7: 'seafarerContractDurationReportPermission',
        8: 'seafarerTurnoverReportPermission',
        9: 'promotionReportPermission',
        10: 'emergencyReportPermission',
        11: 'illnessReportPermission',
        12:'seafarerPermission',
        18:'pendingDocumentPermission',
        20:'companyUserPermission',
        24:'systemModulePermission',
        27:'userModulePermission',
        34:'jobModulePermission',
        36:'uploadDocumentPermission',
        39:'budgetHeadPermission',
        41:'fleetBudgetPermission',
        42:'individualVesselBudgetPermission',
        43:'portageBillPermission',
        44:'basicWagesPermission',
        45:'salaryPolicyPermission',
        46:'earnDeducItemPermission',
        47:'earnDeducEntryPermission',
        48:'hiredCrewsPermission',
        49:'vesselPermission',
        57:'supplyDemandPermission',
        58:'salaryTrendPermission',
        70:'settingPermission',
    };

// Create a new object to store the permission states
    const permissionStates = {};

// Loop through the permission array and populate the permission states object
    if (superAdmin === false && permission) {
        permission.forEach(item => {
            if (modulePermissionMap[item?.module_id]) {
                permissionStates[modulePermissionMap[item?.module_id]] = true;
            }
        });
    } else {
        // If superAdmin, set all permission states to true
        for (const key in modulePermissionMap) {
            permissionStates[modulePermissionMap[key]] = true;
        }
    }

// Destructure the permission states object to individual variables
    const {
        seafarerPermission,
        signonOffReportPermission,
        seafarerContractReportPermission,
        supplyDemandReportPermission,
        seafarerLeftReportPermission,
        seafarerContractDurationReportPermission,
        seafarerTurnoverReportPermission,
        promotionReportPermission,
        emergencyReportPermission,
        illnessReportPermission,
        pendingDocumentPermission,
        companyUserPermission,
        systemModulePermission,
        userModulePermission,
        jobModulePermission,
        uploadDocumentPermission,
        budgetHeadPermission,
        fleetBudgetPermission,
        individualVesselBudgetPermission,
        portageBillPermission,
        basicWagesPermission,
        salaryPolicyPermission,
        earnDeducItemPermission,
        earnDeducEntryPermission,
        hiredCrewsPermission,
        vesselPermission,
        supplyDemandPermission,
        salaryTrendPermission,
        settingPermission
    } = permissionStates;




    let location = window.location.pathname;
    const managementReportMenuOpen =  location == COMPANYROUTE.SIGN_ON_OFF ||
        location == COMPANYROUTE.SIGN_OFF ||
        location == COMPANYROUTE.NO_OF_CONTRACT ||
        location == COMPANYROUTE.SUPPLY_DEMAND_REPORT ||
        location == COMPANYROUTE.SEAFARER_LEFT_REPORT ||
        location == COMPANYROUTE.SEAFARER_CONTRACT_DURATION_REPORT ||
        location == COMPANYROUTE.SEAFARER_INDIVIDUAL_CONTRACT_DURATION_REPORT ||
        location == COMPANYROUTE.SEAFARER_TURNOVER_REPORT ||
        location == COMPANYROUTE.SEAFARER_NEW_PROMOTION_REPORT ||
        location == COMPANYROUTE.SEAFARER_EMERGENCY_SIGN_OFF_REPORT  ||
        location == COMPANYROUTE.SEAFARER_CONTRACT_EXTENSION ||
        location == COMPANYROUTE.SEAFARER_ILLNESS_REPORT ||
        location == COMPANYROUTE.SEAFARER_TOP_FOUR_RANK_CONTRACT_REPORT;

    const companyUserMenuOpen = location == COMPANYROUTE.COMPANY_USER_LIST || location == COMPANYROUTE.COMPANY_USER_LOGIN_HISTORY;

    const accountMenuOpen = location == COMPANYROUTE.BUDGET_HEAD_LIST ||
        location == COMPANYROUTE.FLEET_BUDGET ||
        location == COMPANYROUTE.INDIVIDUAL_VESSEL_BUDGET ||
        location == COMPANYROUTE.PORTAGE_BILL ||
        location == COMPANYROUTE.BASIC_WAGES ||
        location == COMPANYROUTE.SALARY_POLICY ||
        location == COMPANYROUTE.TOP_FOUR_OFFICER_SALARY ||
        location == COMPANYROUTE.PARTICULARS ||
        location == COMPANYROUTE.PARTICULARS_TRANSACTION;

    const analyticsMenuOpen = location == COMPANYROUTE.SALARY_TREND || location == COMPANYROUTE.SUPPLY_DEMAND;

    const settingsMenuOpen = location == COMPANYROUTE.RANK ||
        location == COMPANYROUTE.VESSEL_TYPE ||
        location == COMPANYROUTE.PORT ||
        location == COMPANYROUTE.SMS_LOG;

    const menuDisplayNoneStyle = { display: 'none' };




    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">

            {/*<a href="src/Components/Company/Layout/Company/Sidebar#" className="brand-link">*/}
            {/*    <img src="/asset/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"*/}
            {/*         className="brand-image img-circle elevation-3"   />*/}
            {/*        <span className="brand-text font-weight-light">AdminLTE 3</span>*/}
            {/*</a>*/}

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        {/*<img src="/asset/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />*/}
                    </div>
                    <div className="info">
                        <Link  className="d-block" to={COMPANYROUTE.DASHBOARD}>DEMO</Link>
                    </div>
                </div>


                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li className="nav-item ">
                            <Link to={COMPANYROUTE.DASHBOARD} className={`nav-link ${location == COMPANYROUTE.DASHBOARD ? 'active' : ''}`}>
                                <i className="nav-icon fa fa-tachometer-alt"></i>
                                <p>
                                    Dashboard
                                </p>
                            </Link>
                        </li>

                        {
                            (signonOffReportPermission ||
                            seafarerContractReportPermission ||
                            supplyDemandReportPermission ||
                            seafarerLeftReportPermission ||
                            seafarerContractDurationReportPermission ||
                            seafarerTurnoverReportPermission ||
                            promotionReportPermission || emergencyReportPermission
                            || illnessReportPermission )  ?

                            <li className={`nav-item ${
                                     managementReportMenuOpen
                                    ? 'menu-open menu-is-opening ' : ''}`}>
                                <a href='#'  className={`nav-link ${
                                    managementReportMenuOpen
                                        ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-chart-area"></i>
                                    <p>
                                        Management Report
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview" style={menuDisplayNoneStyle}  >
                                      {
                                        signonOffReportPermission &&
                                          <>
                                            <li className="nav-item ">
                                                <Link to={COMPANYROUTE.SIGN_ON_OFF} className={`nav-link ${location == COMPANYROUTE.SIGN_ON_OFF ? 'active' : ''}`}>
                                                    <i className="fa  fa-sign-in-alt"></i>
                                                    <small className='px-2'> Sign On/Off</small>
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to={COMPANYROUTE.SIGN_OFF} className={`nav-link ${location == COMPANYROUTE.SIGN_OFF ? 'active' : ''}`}>
                                                    <i className="far fa-bell-slash"></i>
                                                    <small className='px-2'>Sign Off</small>
                                                </Link>
                                            </li>

                                              <li className="nav-item">
                                                  <Link to={COMPANYROUTE.SEAFARER_DORMANT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_DORMANT ? 'active' : ''}`}>
                                                      <i className="fas fa-snowflake"></i>
                                                      <small className='px-2'>Dormant</small>
                                                  </Link>
                                              </li>
                                        </>
                                     }
                                    {
                                        seafarerContractReportPermission &&
                                        <li className="nav-item">
                                            <Link to={COMPANYROUTE.NO_OF_CONTRACT} className={`nav-link ${location == COMPANYROUTE.NO_OF_CONTRACT ? 'active' : ''}`}>
                                                <i className="fas fa-sun"></i>
                                                <small className='px-2'> No. Of Contract</small>
                                            </Link>
                                        </li>
                                    }

                                    {
                                        supplyDemandReportPermission &&
                                        <li className="nav-item">
                                            <Link to={COMPANYROUTE.SUPPLY_DEMAND_REPORT} className={`nav-link ${location == COMPANYROUTE.SUPPLY_DEMAND_REPORT ? 'active' : ''}`}>
                                                <i className="fas fa-snowflake"></i>
                                                <small className='px-2'> Supply Demand</small>
                                            </Link>
                                        </li>
                                    }


                                    {
                                        seafarerLeftReportPermission &&
                                        <li className="nav-item">
                                            <Link to={COMPANYROUTE.SEAFARER_LEFT_REPORT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_LEFT_REPORT ? 'active' : ''}`}>
                                                <i className="fas fa-fire-alt"></i>
                                                <small className='px-2'> Seafarer Left</small>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        seafarerContractDurationReportPermission &&
                                        <>
                                            <li className="nav-item">
                                                <Link to={COMPANYROUTE.SEAFARER_CONTRACT_DURATION_REPORT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_CONTRACT_DURATION_REPORT ? 'active' : ''}`}>
                                                    <i className="far fa-calendar-alt"></i>
                                                    <small className='px-2'>Duration</small>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={COMPANYROUTE.SEAFARER_INDIVIDUAL_CONTRACT_DURATION_REPORT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_INDIVIDUAL_CONTRACT_DURATION_REPORT ? 'active' : ''}`}>
                                                    <i className="far fa-calendar-alt"></i>
                                                    <small className='px-2'>Individual Duration</small>
                                                </Link>
                                            </li>
                                        </>
                                   }
                                    {
                                        seafarerTurnoverReportPermission &&
                                        <li className="nav-item">
                                            <Link to={COMPANYROUTE.SEAFARER_TURNOVER_REPORT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_TURNOVER_REPORT ? 'active' : ''}`}>
                                                <i className="fab fa-tumblr"></i>
                                                <small className='px-2'>Turnover</small>
                                            </Link>
                                        </li>
                                    }

                                    {
                                        promotionReportPermission &&
                                        <>
                                            <li className="nav-item">
                                                <Link to={COMPANYROUTE.SEAFARER_NEW_PROMOTION_REPORT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_NEW_PROMOTION_REPORT ? 'active' : ''}`}>
                                                    <i className="fas fa-caret-square-up"></i>
                                                    <small className='px-2'>New Promotion</small>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={COMPANYROUTE.SEAFARER_CONTRACT_EXTENSION} className={`nav-link ${location == COMPANYROUTE.SEAFARER_CONTRACT_EXTENSION ? 'active' : ''}`}>
                                                    <i className="fas fa-snowflake"></i>
                                                    <small className='px-2'>Contract Extension</small>
                                                </Link>
                                            </li>
                                        </>

                                    }
                                    {
                                        emergencyReportPermission &&
                                        <li className="nav-item">
                                            <Link to={COMPANYROUTE.SEAFARER_EMERGENCY_SIGN_OFF_REPORT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_EMERGENCY_SIGN_OFF_REPORT ? 'active' : ''}`}>
                                                <i className="fas fa-window-close"></i>
                                                <small className='px-2'>Emergency Sign Off</small>
                                            </Link>
                                        </li>
                                    }

                                    {
                                        illnessReportPermission &&
                                        <li className="nav-item">
                                            <Link to={COMPANYROUTE.SEAFARER_ILLNESS_REPORT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_ILLNESS_REPORT ? 'active' : ''}`}>
                                                <i className="fas fa-life-ring"></i>
                                                <small className='px-2'>Illness</small>
                                            </Link>
                                        </li>
                                    }

                                    <li className="nav-item">
                                        <Link to={COMPANYROUTE.SEAFARER_TOP_FOUR_RANK_CONTRACT_REPORT} className={`nav-link ${location == COMPANYROUTE.SEAFARER_TOP_FOUR_RANK_CONTRACT_REPORT ? 'active' : ''}`}>
                                            <i className="fas fa-random"></i>
                                            <small className='px-2'>Top Four Contract</small>
                                        </Link>
                                    </li>



                                </ul>
                            </li> : ''
                        }





                        {
                            seafarerPermission &&
                                <li className="nav-item ">
                                    <Link to={COMPANYROUTE.SEAFARER} className={`nav-link ${location == COMPANYROUTE.SEAFARER ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-anchor"></i>
                                        <p>
                                            Seafarers
                                        </p>
                                    </Link>
                                </li>
                        }



                        {/*{*/}
                        {/*    pendingDocumentPermission &&*/}
                        {/*    <li className="nav-item ">*/}
                        {/*        <Link to={COMPANYROUTE.PENDING_DOCUMENTS} className={`nav-link ${location == COMPANYROUTE.PENDING_DOCUMENTS ? 'active' : ''}`}>*/}
                        {/*            <i className="nav-icon fas fa-paste"></i>*/}
                        {/*            <p>*/}
                        {/*                Pending Document*/}
                        {/*            </p>*/}
                        {/*        </Link>*/}
                        {/*    </li>*/}
                        {/*}*/}



                        {
                            companyUserPermission &&

                            <li className={`nav-item ${
                                companyUserMenuOpen
                                    ? 'menu-open menu-is-opening ' : ''}`}>
                                <a href='#'  className={`nav-link ${
                                    companyUserMenuOpen
                                        ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-chart-area"></i>
                                    <p>
                                        Company User
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.COMPANY_USER_LIST} className={`nav-link ${location == COMPANYROUTE.COMPANY_USER_LIST ? 'active' : ''}`}>
                                            <i className="fas fa-users"></i>
                                            <small className='px-2'> User List</small>
                                        </Link>
                                    </li>
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.COMPANY_USER_LOGIN_HISTORY} className={`nav-link ${location == COMPANYROUTE.COMPANY_USER_LOGIN_HISTORY ? 'active' : ''}`}>
                                            <i className="fas fa-list"></i>
                                            <small className='px-2'> Login History</small>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        }

                        {
                            systemModulePermission &&
                            <li className="nav-item ">
                                <Link to={COMPANYROUTE.COMPANY_MODULE} className={`nav-link ${location == COMPANYROUTE.COMPANY_MODULE ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-file-archive"></i>
                                    <p>
                                        System Module
                                    </p>
                                </Link>
                            </li>
                        }




                        {
                            userModulePermission &&
                            <li className="nav-item ">
                                <Link to={COMPANYROUTE.COMPANY_PERMISSION} className={`nav-link ${location == COMPANYROUTE.COMPANY_PERMISSION ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-list"></i>
                                    <p>
                                        User Permission
                                    </p>
                                </Link>
                            </li>
                        }


                        {
                            jobModulePermission &&
                            <li className="nav-item ">
                                <Link to={COMPANYROUTE.JOB_LIST} className={`nav-link ${location == COMPANYROUTE.JOB_LIST ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-suitcase"></i>
                                    <p>
                                        Job
                                    </p>
                                </Link>
                            </li>
                        }

                        {
                            uploadDocumentPermission &&
                            <li className="nav-item ">
                                <Link to={COMPANYROUTE.UPLOAD_DOCUMENT} className={`nav-link ${location == COMPANYROUTE.UPLOAD_DOCUMENT ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-file"></i>
                                    <p>
                                        Upload Document
                                    </p>
                                </Link>
                            </li>
                        }


                        {
                            (budgetHeadPermission || fleetBudgetPermission ||
                            individualVesselBudgetPermission ||
                            portageBillPermission ||
                            basicWagesPermission ||
                            salaryPolicyPermission ||
                            earnDeducItemPermission ||
                            earnDeducEntryPermission) ?
                        <li className={`nav-item  ${
                            accountMenuOpen
                                ? 'menu-open menu-is-opening ' : ''}`}>
                            <a href='#'  className={`nav-link ${
                                accountMenuOpen
                                    ? 'active' : ''}`}>
                                <i className="nav-icon fas fa-file-invoice"></i>
                                <p>
                                   Account
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                {
                                    budgetHeadPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.BUDGET_HEAD_LIST} className={`nav-link ${location == COMPANYROUTE.BUDGET_HEAD_LIST ? 'active' : ''}`}>
                                            <i className="fas fa-clipboard-list"></i>
                                            <small className='px-2'>Budget Head</small>
                                        </Link>
                                    </li>
                                }
                                {
                                    fleetBudgetPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.FLEET_BUDGET} className={`nav-link ${location == COMPANYROUTE.FLEET_BUDGET ? 'active' : ''}`}>
                                            <i className="fas fa-clipboard-list"></i>
                                            <small className='px-2'>Fleet Budget</small>
                                        </Link>
                                    </li>
                                }

                                {
                                    individualVesselBudgetPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.INDIVIDUAL_VESSEL_BUDGET} className={`nav-link ${location == COMPANYROUTE.INDIVIDUAL_VESSEL_BUDGET ? 'active' : ''}`}>
                                            <i className="fas fa-clipboard-list"></i>
                                            <small className='px-2'>Individual Vessel</small>
                                        </Link>
                                    </li>
                                }

                                {
                                    portageBillPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.PORTAGE_BILL} className={`nav-link ${location == COMPANYROUTE.PORTAGE_BILL ? 'active' : ''}`}>
                                            <i className="fab fa-paypal"></i>
                                            <small className='px-2'>Portage Bill</small>
                                        </Link>
                                    </li>
                                }

                                {
                                    basicWagesPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.BASIC_WAGES} className={`nav-link ${location == COMPANYROUTE.BASIC_WAGES ? 'active' : ''}`}>
                                            <i className="fas fa-balance-scale"></i>
                                            <small className='px-2'>Balance Wages</small>
                                        </Link>
                                    </li>
                                }
                                {
                                    salaryPolicyPermission &&
                                   <>
                                       <li className="nav-item ">
                                           <Link to={COMPANYROUTE.SALARY_POLICY} className={`nav-link ${location == COMPANYROUTE.SALARY_POLICY ? 'active' : ''}`}>
                                               <i className="fas fa-dollar-sign"></i>
                                               <small className='px-2'>Salary Policy</small>
                                           </Link>
                                       </li>
                                       <li className="nav-item ">
                                           <Link to={COMPANYROUTE.TOP_FOUR_OFFICER_SALARY} className={`nav-link ${location == COMPANYROUTE.TOP_FOUR_OFFICER_SALARY ? 'active' : ''}`}>
                                               <i className="fas fa-dollar-sign"></i>
                                               <small className='px-2'>Top Four Salary</small>
                                           </Link>
                                       </li>

                                   </>
                                }
                                {
                                    earnDeducItemPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.PARTICULARS} className={`nav-link ${location == COMPANYROUTE.PARTICULARS ? 'active' : ''}`}>
                                            <i className="fas fa-atom"></i>
                                            <small className='px-2'>Earn/Deduc Item</small>
                                        </Link>
                                    </li>
                                }

                                {
                                    earnDeducEntryPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.PARTICULARS_TRANSACTION} className={`nav-link ${location == COMPANYROUTE.PARTICULARS_TRANSACTION ? 'active' : ''}`}>
                                            <i className="fas fa-dollar-sign"></i>
                                            <small className='px-2'>Earn/Deduc Entry</small>
                                        </Link>
                                    </li>
                                }


                            </ul>
                        </li>
                         : ' ' }

                        {
                            hiredCrewsPermission &&
                            <li className="nav-item ">
                                <Link to={COMPANYROUTE.HIRED_CREWS} className={`nav-link ${location == COMPANYROUTE.HIRED_CREWS ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-users"></i>
                                    <p>
                                        Hired Crews
                                    </p>
                                </Link>
                            </li>
                        }

                        {
                            vesselPermission &&
                            <li className="nav-item ">
                                <Link to={COMPANYROUTE.VESSEL} className={`nav-link ${location == COMPANYROUTE.VESSEL ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-ship"></i>
                                    <p>
                                        Vessel
                                    </p>
                                </Link>
                            </li>
                        }


                        {
                            superAdmin === true &&
                            <li className="nav-item ">
                                <Link to={COMPANYROUTE.SEAFARER_TRASH} className={`nav-link ${location == COMPANYROUTE.SEAFARER_TRASH ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-trash"></i>
                                    <p>
                                        Seafarer Trash
                                    </p>
                                </Link>
                            </li>
                        }






                        {
                            (salaryTrendPermission || supplyDemandPermission) ?


                        <li className={`nav-item ${
                            analyticsMenuOpen
                                ? 'menu-open menu-is-opening ' : ''}`}>
                            <a href='#'  className={`nav-link ${
                                analyticsMenuOpen
                                    ? 'active' : ''}`}>
                                <i className="nav-icon fas fa-chart-bar"></i>
                                <p>
                                    Analytics
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                {
                                    salaryTrendPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.SALARY_TREND} className={`nav-link ${location == COMPANYROUTE.SALARY_TREND ? 'active' : ''}`}>
                                            <i className="fas fa-chart-line"></i>
                                            <small className='px-2'>Salary Trend</small>
                                        </Link>
                                    </li>
                                }
                                {
                                    supplyDemandPermission &&
                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.SUPPLY_DEMAND} className={`nav-link ${location == COMPANYROUTE.SUPPLY_DEMAND ? 'active' : ''}`}>
                                            <i className="fas fa-list"></i>
                                            <small className='px-2'>Supply Demand</small>
                                        </Link>
                                    </li>
                                }

                            </ul>
                        </li>
                                : ' ' }



                        {
                            settingPermission &&
                            <li className={`nav-item ${
                                settingsMenuOpen
                                    ? 'menu-open menu-is-opening ' : ''}`}>
                                <a href='#'  className={`nav-link ${
                                    settingsMenuOpen
                                        ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-cogs"></i>
                                    <p>
                                        Setting
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                        <li className="nav-item ">
                                            <Link to={COMPANYROUTE.RANK} className={`nav-link ${location == COMPANYROUTE.RANK ? 'active' : ''}`}>
                                                <i className="fas fa-share-square"></i>
                                                <small className='px-2'>Rank</small>
                                            </Link>
                                        </li>

                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.VESSEL_TYPE} className={`nav-link ${location == COMPANYROUTE.VESSEL_TYPE ? 'active' : ''}`}>
                                            <i className="fas fa-code-branch"></i>
                                            <small className='px-2'>Vessel Type</small>
                                        </Link>
                                    </li>

                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.SMS_LOG} className={`nav-link ${location == COMPANYROUTE.SMS_LOG ? 'active' : ''}`}>
                                            <i className="fas fa-envelope"></i>
                                            <small className='px-2'>SMS Log</small>
                                        </Link>
                                    </li>

                                    <li className="nav-item ">
                                        <Link to={COMPANYROUTE.PORT} className={`nav-link ${location == COMPANYROUTE.PORT ? 'active' : ''}`}>
                                            <i className="fas fa-palette"></i>
                                            <small className='px-2'>Port</small>
                                        </Link>
                                    </li>

                                </ul>
                            </li>
                        }

                        {
                            role == 4 &&
                            <li className="nav-item ">
                                <Link to={COMPANYROUTE.GUEST_SEAFARER_LIST} className={`nav-link ${location == COMPANYROUTE.GUEST_SEAFARER_LIST ? 'active' : ''}`}>
                                    <i className="nav-icon fas fa-anchor"></i>
                                    <p>
                                        Seafarers
                                    </p>
                                </Link>
                            </li>
                        }

                    </ul>
                </nav>

            </div>
        </aside>
    );
};

export default Sidebar;