import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import axiosClient from "../../../axios-client";
import {useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";

const EmploymentContract = () => {
    const {id} = useParams();
    const [data,setData] = useState([])
    const [modal,setModal] = useState(false)
    const [imagePreview,setImagePreview] = useState(null)

    const [form,setForm] = useState({
        contact_duration: '',
        commencement_date: '',
        basic_wage: '',
        overtime:'',
        leave_wage:'',
        signature_file:null,
        old_signature_file:null,
    });

    const getReportData = (pageNumber = 1) => {
        axiosClient.get('/company/employment-contract/'+id)
            .then(({data}) => {
                console.log(data)
                setData(data?.data)
                setForm({
                    ...form,
                    contact_duration: data?.data?.contact_duration,
                    commencement_date: data?.data?.commencement_date,
                    basic_wage: data?.data?.basic_wages,
                    overtime:data?.data?.overtime,
                    leave_wage:data?.data?.leave_wage,
                    signature_file:null,
                    old_signature_file:data?.data?.signature,
                })
            }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {
        getReportData()
    },[])

    const handleUpdateModal = () => {
        setModal(true)
    }

    const handleUpdateModalClose = () => {
        setModal(false)
    }
    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const onSubmit = (e) => {
        e.preventDefault()
        axiosClient.post('/company/hire/update-employment-contract',{
            contact_duration: form.contact_duration,
            commencement_date: form.commencement_date,
            basic_wage: form.basic_wage,
            overtime:form.overtime,
            leave_wage:form.leave_wage,
            signature_file:form.signature_file,
            old_signature_file:form.old_signature_file,
            id:parseInt(id),
        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                toast.success(data.message);
                getReportData()
                setModal(false)
            }).catch(err => {
            const response = err.response;
            console.log(response)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    const handleChangeProfileImage = (e) => {
        if (e.target.files) {
            setForm({
                ...form,
                signature_file:e.target.files[0]
            })
            setImagePreview(window.URL.createObjectURL(e.target.files[0]))
        }
    }

    return (
        <>
            <Breadcrumb title='Employment Contract'    />
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="float-right">
                                <button onClick={() => handleUpdateModal()} className='btn btn-info mr-2'><i className="fa fa-plus-circle"></i> Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="bg-cyan text-center p-3">
                                <h5>  Employment Contract</h5>
                            </div>
                            <div className="card">
                            <div className="card-body  p-3">
                                 <p>This employment Contract is entered into between the Seaman and the Agent/Owner of the vessel: <strong>{data?.vessel_name}</strong> (as agent only on behalf of vessel's owners).</p>
                             </div>
                            </div>
                            <div className="card">
                                <h6 className="pl-3 pt-3">Detail Of Seafarer</h6>
                                <div className="card-body  ">
                                          <div className="col-12">
                                              <table className="table table-bordered">
                                                  <tbody>
                                                  <tr>
                                                      <td><strong>Surname: </strong> {data?.surname}</td>
                                                      <td><strong>Given Name: </strong> {data?.name} </td>
                                                  </tr>
                                                  <tr>
                                                      <td><strong>Full Home Address: </strong> {data?.address}</td>
                                                      <td><strong>Position: </strong> {data?.position}  </td>
                                                  </tr>
                                                  <tr>
                                                      <td><strong>Date of Joining: </strong> {data?.date_of_joining}</td>
                                                      <td><strong>Home Port / Home Town Airport: </strong>   </td>
                                                  </tr>
                                                  <tr>
                                                      <td><strong>Nationality: </strong> {data?.nationality}</td>
                                                      <td><strong>Birth Place: </strong> {data?.birth_place}  </td>
                                                  </tr>
                                                  <tr>
                                                      <td><strong>Passport No: </strong> {data?.passport_no}</td>
                                                      <td><strong>Date of Birth: </strong> {data?.date_of_birth}  </td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                      </div>
                                </div>
                            </div>
                            <div className="card">
                                <h6 className="pl-3 pt-3">Detail Of Employeer</h6>
                                <div className="card-body  ">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td><strong>Surname: </strong>{data?.name} {data?.surname}</td>
                                                <td><strong>Full Style Address: </strong> {data?.address} </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <h6 className="pl-3 pt-3">Detail Of Vessel</h6>
                                <div className="card-body  ">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td><strong>Name: </strong>{data?.vessel_name} </td>
                                                <td><strong>IMO Number: </strong> {data?.imo_number} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Official No: </strong> </td>
                                                <td><strong>Flag: </strong> {data?.flag_state} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Port Of Registry: </strong> {data?.flag_state} </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className="card">
                                <h6 className="pl-3 pt-3">Details of Terms of Contract</h6>
                                <div className="card-body  ">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td><strong>Contract Duration: </strong>{data?.contact_duration} </td>
                                                <td><strong>Commencement date: </strong> {data?.commencement_date} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Basic wage/month: </strong>{data?.basic_wages} </td>
                                                <td><strong>Overtime: </strong> {data?.overtime} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Leave Wage: </strong> {data?.leave_wage} </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                        <p> SMOU/SOS CBA to be incorporated into and form part of this contract</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="pl-3 pt-3">
                                    <h6>Confirmation of the Contract:</h6>
                                    <p>The below signatories confirm their understanding and acceptance of this</p>
                                </div>
                                <div className="card-body  ">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Signature of Employer:</strong><br/><br/>
                                                    <img src={data?.signature} width="100px" height="100px" alt=""/>
                                                        <p>-------------------------------------------</p>
                                                        <p>
                                                            XYZ Company
                                                            <br />
                                                                As agents only
                                                                <br />
                                                                    for and on behalf of owners of the vessel
                                                        </p>
                                                </td>
                                                <td>
                                                    Signature of Seaman:
                                                    <strong>(The undersigned has been given<br /> the opportunity to review and seek advice on the terms<br /> & conditions in the agreement and freely accepts them<br /> before
                                                        signing): </strong><br />
                                                    <img src={data?.emp_signature} width="100px" height="100px" alt=""/>
                                                    <p>-------------------------------------------</p>
                                                    <p>
                                                        Name:
                                                        <br />
                                                            Position:
                                                    </p>
                                                </td>
                                            </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className="card">
                                <h6 className="pl-3 pt-3">Next of Kin(NOK) of Seafarer:</h6>
                                <div className="card-body  ">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td><strong>NOK's Name: </strong>{data?.kin_name} </td>
                                                <td><strong>Relationship: </strong> {data?.relation} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Contact Address: </strong>{data?.kin_address} </td>
                                                <td><strong>Email: </strong> {data?.kin_email} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Telephone: </strong> {data?.kin_telephone} </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <h6 className="pl-3 pt-3">Bank Account Details::</h6>
                                <div className="card-body  ">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th colSpan="2"><strong>a) Seafarer</strong></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td><strong>Beneficiary Name: </strong>{data?.emp_beneficiary_name} </td>
                                                <td><strong>Bank Name: </strong> {data?.emp_bank_name} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Account no: </strong>{data?.emp_account_number} </td>
                                                <td><strong>Branch Name: </strong> {data?.emp_branch_name} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Bank Address: </strong> {data?.emp_branch_name} </td>
                                                <td><strong>Swift Code: </strong> {data?.emp_swift_code} </td>
                                            </tr>
                                          </tbody>
                                            <thead>
                                            <tr>
                                                <th colSpan="2"><strong>b) NOK or nominated</strong></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td><strong>Beneficiary Name: </strong>{data?.kin_beneficiary_name} </td>
                                                <td><strong>Bank Name: </strong> {data?.kin_bank_name} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Account no: </strong>{data?.kin_account_number} </td>
                                                <td><strong>Branch Name: </strong> {data?.kin_branch_name} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Bank Address: </strong> {data?.kin_branch_name} </td>
                                                <td><strong>Swift Code: </strong> {data?.kin_swift_code} </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="pl-3 pt-3">
                                    <h6>Confirmation of the Agreement:</h6>
                                    <p>I have examined and agreed to the terms and conditions of employment as stated above and entered into this voluntarily. I hereby confirm that I have NOT paid any charges to any recruitment
                                        agency for gaining this employment</p>
                                </div>
                                <div className="card-body  ">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Signature of Employer:</strong><br/><br/>
                                                    <img src={data?.signature} width="100px" height="100px" alt=""/>
                                                    <p>-------------------------------------------</p>
                                                    <p>
                                                        XYZ Company
                                                        <br />
                                                        As agents only
                                                        <br />
                                                        for and on behalf of owners of the vessel
                                                    </p>
                                                </td>
                                                <td>
                                                    Signature of Seaman:
                                                    <strong>(The undersigned has been given<br /> the opportunity to review and seek advice on the terms<br /> & conditions in the agreement and freely accepts them<br /> before
                                                        signing): </strong><br />
                                                    <img src={data?.emp_signature} width="100px" height="100px" alt=""/>
                                                    <p>-------------------------------------------</p>
                                                    <p>
                                                        Name:{data?.name} {data?.surname}
                                                        <br />
                                                        Position:
                                                    </p>
                                                </td>
                                            </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </section>

            {
                modal &&
                <div className="modal show fade "  style={modelStyle} >
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content"  >
                            <div className="modal-header">
                                <h5 className="modal-title">Update Employment Contract </h5>
                            </div>
                            <form  onSubmit={onSubmit}>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Contact Duration</label>
                                                <input  onChange={e => setForm({...form,contact_duration:e.target.value})}
                                                        value={form.contact_duration}
                                                        type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Commencement date </label>
                                                <DatePicker
                                                    selected={form?.commencement_date ? new Date(new Date(form.commencement_date)) : ''}
                                                    dateFormat='dd-MM-yyyy'
                                                    className="form-control"
                                                    onChange={e => setForm({...form,commencement_date:e})}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Basic wage/month</label>
                                                <input  onChange={e => setForm({...form,basic_wage:e.target.value})}
                                                        value={form.basic_wage}
                                                        type="text" className="form-control"/>
                                            </div>
                                        </div>


                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Overtime</label>
                                                <input  onChange={e => setForm({...form,overtime:e.target.value})}
                                                        value={form.overtime}
                                                        type="text" className="form-control"/>
                                            </div>
                                        </div>



                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Leave Wage</label>
                                                <input  onChange={e => setForm({...form,leave_wage:e.target.value})}
                                                        value={form.leave_wage}
                                                        type="text" className="form-control"/>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            {imagePreview !== null  ?
                                                <img className="profile-user-img img-fluid  "
                                                     src={imagePreview}
                                                     alt="Signature" />
                                                :
                                                <img className="profile-user-img img-fluid  "
                                                     src={data?.signature}
                                                     alt="Signature" />
                                            }
                                            <div className="form-group">
                                                    <label className="btn btn-sm btn-info" htmlFor="profile_image"> Change</label>
                                                    <input onChange={handleChangeProfileImage} type="file" id="profile_image" className="d-none" name="profile_image"/>

                                            </div>
                                        </div>

                                    </div>





                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-danger" onClick={handleUpdateModalClose}  >Close</button>
                                    <button type="submit" className="btn btn-success">Submit</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
      </>
    );
};

export default EmploymentContract;