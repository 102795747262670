import React, { useState} from 'react';
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";

const DocumentRenewalModal = ({data,hide}) => {
    let messageData = data?.name + " please proceed to renew your (" + data?.info_type +  ")  expiry dates is (" + data?.expiry_date + ") and upload new copy in your profile";
    const [message,setMessage] = useState(messageData)
    const [attachment,setAttachment] = useState(null)
    const [email,setEmail] = useState(data?.email)

    const [disable,setDisable] = useState(false)


    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisable(true)

        axiosClient.post('/company/send-mail', {
            subject:'Document renew',
            message:message,
            email_file:attachment,
            email:email
        } , {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                setDisable(false)
                if(data === 1){
                    toast.success('Email Sent Successfully')
                }else if(data === 0){
                    toast.error('Email Not Sent')
                }
                hide(false)
            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }


    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content"  >

                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="">To</label>
                                    <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Message</label>
                                    <textarea value={message} onChange={e => setMessage(e.target.value)} className="form-control" name="" id="" cols="30" rows="10"></textarea>
                                </div>
                                <div className="form-group">
                                    <label className="btn btn-info" htmlFor="attachement">Attachment</label>
                                    <p>{attachment?.name ?? ''}</p>
                                    <input type="file" onChange={e => setAttachment(e.target.files[0])}  id="attachement" accept="image/jpeg,image/jpg,image/png, .pdf, .doc, .docx" className="form-control d-none"/>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                                <button type="submit" disabled={disable} className="btn btn-success">{
                                    disable ? 'Sending...' : 'Send'
                                }</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
};

export default DocumentRenewalModal;