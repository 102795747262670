import React, {useState} from 'react';
import {userSearch} from "./userSearch";

const SearchInput = ({setSearchExist,setSearchUserList,searchUrl}) => {

    const handleSearch = (value) => {
        userSearch(value, setSearchExist, setSearchUserList,searchUrl);
    }

    const [searchText, setSearchText] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        handleSearch(value);
    };

    return (
            <div>

                        <input
                            type="text"
                            onChange={handleChange}
                            value={searchText}
                            className="form-control"
                            placeholder="Search Here"
                        />

            </div>

        );

        };

export default SearchInput;