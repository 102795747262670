import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";


const SeafarerEmergencySignOff = () => {
    const [reportData,setReportData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));

    const getReportData = (dateFromData = null,dateToData = null) => {
        axiosClient.post('/company/report/seafarer-emergency-sign-off',{
            date_from: dateFromData,
            date_to: dateToData
        })
            .then(({data}) => {
                setReportData(data.data)
            }).catch(() => {
        })
    }

    const columns = [

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Rank',
            selector: row => row.rank ,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'CDC No.',
            selector: row => row.cdc,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Country',
            selector: row => row.country_name,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Deviation Port',
            selector: row => row.deviation_port_name,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Deviation Position',
            selector: row => row.deviation_position,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Deviation Date',
            selector: row => row.deviation_date,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Arrival Date',
            selector: row => row.arrival_date,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Departure Date',
            selector: row => row.departure_date,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'On Hire Date',
            selector: row => row.hire_date,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Remarks',
            selector: row => row.remarks,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Total Time Lost',
            selector: row => row.time_lost,
            sortable: true,
            className: 'header-row',
        },



    ];






    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getReportData(dateFromData,dateToData)

    },[dateFrom,dateTo])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-emergency-sign-off/print',
        payload: { date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString() },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Name', key: 'name', width: 20 },
        { header: 'Rank', key: 'rank', width: 20 },
        { header: 'CDC No', key: 'cdc', width: 20 },
        { header: 'Country', key: 'country_name', width: 20 },
        { header: 'Deviation Port', key: 'deviation_port_name', width: 20 },
        { header: 'Deviation Position', key: 'deviation_position', width: 20 },
        { header: 'Deviation Date', key: 'deviation_date', width: 20 },
        { header: 'Arrival Date', key: 'arrival_date', width: 20 },
        { header: 'Departure Date', key: 'departure_date', width: 20 },
        { header: 'On Hire Date', key: 'hire_date', width: 20 },
        { header: 'Remarks', key: 'remarks', width: 20 },
        { header: 'Total Time Lost', key: 'time_lost', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-emergency-sign-off/export',{
              date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Seafarer New Promotion Report','seafarer-emergency-sign-off-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-emergency-sign-off/export',{
             date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Seafarer Emergency Sign Off Reports','seafarer-emergency-sign-off-report',exportColumns)
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Seafarer Emergency Sign Off Reports'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateFrom}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateFrom(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateTo}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateTo(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="float-right">
                                                <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body ">

                                    <DataTable
                                        columns={columns}
                                        data={reportData}
                                        pagination={true}
                                        paginationPerPage={30}
                                        paginationRowsPerPageOptions={[30, 50, 100,500]}
                                        fixedHeader={true}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerEmergencySignOff;