import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";

const CrewPerformanceModal = ({data,hide}) => {

    const navigate = useNavigate();


    const [reportData,setReportData] = useState([]);




    const getData = () => {
        axiosClient.get('/company/crew-performance-review/'+data)
            .then(({data}) => {
                setReportData(data?.data)
            }).catch(() => {
        })
    }


    useEffect(() => {
        getData()
    },[])

    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }


    return (
        <div className="modal show fade "  style={modelStyle} >
            <div className="modal-dialog modal-xl">
                <div className="modal-content" style={{overflowY:'scroll', height:'600px'}}>
                    <div className="modal-header">
                        <h5 className="modal-title">ON-SIGNER PERFORMANCE REVIEW </h5>
                    </div>
                    <div className="modal-body">
                        <div className="card-body">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>#SL</th>
                                    <th>Title</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    reportData?.length > 0 && reportData.map((item,index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{item?.certification_title}</td>
                                                <td>
                                                    <a href={item?.document_url} target="_blank"   className="btn btn-sm btn-info">view</a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrewPerformanceModal;