import {useEffect} from "react";

const UseDatePicker = () => {
    useEffect(() => {
        //data table
        const datepicker = document.createElement('script');
        datepicker.src = `../../js/datepicker.js`;
        datepicker.async = true;
        document.body.appendChild(datepicker)
    },[])
};

export default UseDatePicker;