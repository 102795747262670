import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import AllRank from "../../../Components/Company/Common/AllRank";
import * as COMPANYROUTE from '../../../constants/companyRoutes'
import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import axiosClient from "../../../axios-client";
import './styles/seafarers.css'
import Pagination from "react-js-pagination";
import {ACTION_TYPES, INITIAL_STATE, seafarerReducer} from "../../../reducers/Company/SeafarerReducer";
import {toast} from "react-toastify";
import LeftRehireModal from "./LeftRehireModal";
import LoadingBar from 'react-top-loading-bar';
import {SEAPORT_IMMIGRATION} from "../../../constants/companyRoutes";


const Seafarer = () => {
    const [progress, setProgress] = useState(10);
    const [vesselSelect,setVesselSelect] = useState('');
    const [rankSelect,setRankSelect] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [modal,showModal] = useState(false);
    const [modalData,setModalData] = useState({});

    const [state,dispatch] = useReducer(seafarerReducer,INITIAL_STATE);

    const getReportData = (pageNumber = 1,vessel= 0,rank = 0,searchQuery = '') => {
        setProgress(50)
        dispatch({type:ACTION_TYPES.GET_SEAFARER_DATA});
        axiosClient.get(`/company/seafarers?page=${pageNumber}`,{
            params: {
                vessel_id: vessel,
                rank_id: rank,
                search: searchQuery
            }
        })
            .then(({data}) => {
                dispatch({type:ACTION_TYPES.GET_SEAFARER_DATA_SUCCESS,payload:data.data});
                setProgress(100);

            }).catch(() => {
            dispatch({type:ACTION_TYPES.GET_SEAFARER_DATA_ERROR});

        })
    }

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete ${data?.name}?`)){
            return false;
        }
        axiosClient.delete(`/company/seafarer/seafarer-delete/${data?.user_id}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData(state.pageNumber,state.vessel,state.rank,state.searchQuery);
            })
    }

    const unlockProfile = (data) => {
        if(!window.confirm(`Are you sure you want to unlock profile  ${data?.name}?`)){
            return false;
        }
        axiosClient.delete(`/company/crew-unlock-profile/${data?.user_id}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData(state.pageNumber,state.vessel,state.rank,state.searchQuery);
            })
    }


    useEffect(() => {
        getReportData(1,vesselSelect,rankSelect,searchQuery)
    },[vesselSelect,rankSelect,searchQuery])


    const handleModal = (data) => {
       showModal(true)
       setModalData(data)
    }





    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Seafarer' addnew={true}  route={COMPANYROUTE.ADD_NEW_SEAFARER}    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-4'>
                                                    <AllRank rankSelect={rankSelect} setRankSelect={setRankSelect} />
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <AllVessel vesselSelect={vesselSelect} setVesselSelect={setVesselSelect} />
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <input className='form-control' placeholder="Others" onChange={(e) => setSearchQuery(e.target.value)} type="text"/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body  new-card-body"  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Name</th>
                                            <th>Unique Id</th>
                                            <th>Vessel</th>
                                            <th>Rank</th>
                                            <th>Experience</th>
                                            <th>Country</th>
                                            <th>Email</th>
                                            <th>Availability Date</th>
                                            <th>Mobile</th>
                                            <th>Left Contract No</th>
                                            <th>Re Hire</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {state.loading ? "Loading" : ""}

                                        {state?.data?.data?.length > 0 ?  state?.data?.data.map((data,index) => (
                                        <tr  className={` ${data?.crews_count > 0 ? '' : 'green-lighteen'}`} key={index} >
                                            <td>{data?.name}</td>
                                            <td>{data?.cdc}</td>
                                            <td>{data?.vessel}</td>
                                            <td>{data?.rank}</td>
                                            <td>{data?.experience}</td>
                                            <td>{data?.country}</td>
                                            <td>{data?.email}</td>
                                            <td>{data?.availableDate}</td>
                                            <td>{data?.mobile}</td>
                                            <td>{data?.leftContact}</td>
                                            <td><span className={`text ${data?.reHire  == 'Beneficial Terminations (Under Performance)' ? 'text-danger' : 'text-info'}`}  >{data?.reHire}</span></td>
                                            <td><span className={`badge ${data?.status  == 'Leave' ? 'badge-danger' : 'badge-success'}`}  >{data?.status == 'Leave' ? 'Leave' : 'On Board'}</span></td>

                                            <td>
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-info">Action</button>
                                                    <button type="button"
                                                            className="btn btn-info dropdown-toggle dropdown-icon"
                                                            data-toggle="dropdown">
                                                        <span className="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <div className="dropdown-menu" role="menu">
                                                        {
                                                            data?.reHire === 'Beneficial Terminations (Under Performance)' ? (
                                                                <>
                                                                    <button  onClick={e => unlockProfile(data)}   className="dropdown-item text-sm"  >Unlock Profile</button>

                                                                </>
                                                            ) : <>
                                                                {
                                                                    data?.status === 'Leave' && <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.ADD_TO_VESSEL}${data?.user_id}`}>Add To Vessel</Link>
                                                                }
                                                                {
                                                                    data?.status === 'Current' && (
                                                                        <>
                                                                            <button type="button"  className="dropdown-item text-sm"
                                                                                     onClick={() => handleModal(data?.user_id)}
                                                                            >
                                                                                Left/Rehire
                                                                            </button>
                                                                            <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.EDIT_TO_VESSEL}${data?.user_id}`}>Edit</Link>
                                                                            <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.SEAFARER_AGREEMENT}${data?.user_id}`}>Agreement</Link>
                                                                            {
                                                                                data?.agreement_extension === true &&   <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.SEAFARER_AGREEMENT_EXTENSION}${data?.user_id}`}>Agreement Extension</Link>
                                                                            }



                                                                        </>
                                                                    )
                                                                }
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.USER_PROFILE}${data?.user_id}`} state={{type:'personalInfo'}}>Profile</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.SEAFARER_DOCUMENTS}${data?.user_id}`}>Documents</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.CREW_EDIT_ID}${data?.user_id}`}>Edit Id</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.SEAFARER_CHECKLIST}${data?.user_id}`}>Checklist</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.COMPANY_POLICY}${data?.user_id}`}>Company Policy</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.SEAPORT_IMMIGRATION}${data?.user_id}`}>Seaport Immigration</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.SIGN_ON_LETTER}${data?.user_id}`}>Sign On Letter</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.APPLICATION_FORM}${data?.user_id}`}>Application Form</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.TERMS_CONDITION}${data?.user_id}`}>Terms Condition</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.PRE_EMPLOYMENT_MEDICAL}${data?.user_id}`}>Pre Employment Medical</Link>
                                                                <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.MEDICAL_HISTORY_QUESTIONNAIRE}${data?.user_id}`}>Medical History Questionnaire</Link>
                                                                <button  onClick={e => onDelete(data)}   className="dropdown-item text-sm"  >Delete</button>
                                                            </>
                                                        }



                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                            )) : <tr><td colSpan={13} className='text-center'>No Data Found</td></tr>}

                                        {state.error ? <tr><td colSpan={13} className='text-center text text-danger'>Something Went Wrong!</td></tr> : '' }

                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">




                                    <Pagination
                                        activePage={state?.data?.current_page ? state?.data?.current_page : 0}
                                        itemsCountPerPage={state?.data?.per_page ? state?.data?.per_page : 0 }
                                        totalItemsCount={state?.data?.total ? state?.data?.total : 0}
                                        onChange={(pageNumber) => {
                                            getReportData(pageNumber,vesselSelect,rankSelect,searchQuery)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"

                                    />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {
                modal === true && <LeftRehireModal data={modalData} hide={() => showModal(false)} />
            }

        </>
    );
};

export default Seafarer;