import React from 'react';
import {Link, useParams} from "react-router-dom";
import * as UserRoute from "../../../constants/UserRoute";
import DocumentVisaList from "../../Profile/DocumentVisa/DocumentVisaList";

const DocumentVisa = ({passportData,visaData}) => {
    return (
        <>
            <div className="row">
                <Link  className="btn btn-success btn-sm mr-2" to={UserRoute.USER_PASSPORT} state={{type:'Passport',title:"Add Passport",document_title:"Passport No"}}>PASSPORT</Link>
                <Link  className="btn btn-warning btn-sm mr-2" to={UserRoute.USER_PASSPORT} state={{type:'Seaman Passport',title:"Add Seaman Id",document_title:"Seaman Id No"}}>SEAMAN ID</Link>
                <Link  className="btn btn-danger btn-sm mr-2" to={UserRoute.USER_PASSPORT} state={{type:'Continuous Discharge Certificate(CDC)',title:"Add CDC NO",document_title:"CDC No"}}>CDC</Link>
                <Link  className="btn btn-info btn-sm  mr-2" to={UserRoute.USER_VISA}>VISA</Link>
            </div>

            <DocumentVisaList passportData={passportData} visaData={visaData} passportEditRoute={UserRoute.USER_PASSPORT_EDIT} visaEditRoute={UserRoute.USER_VISA_EDIT}/>

        </>
    );
};

export default DocumentVisa;