import React from 'react';
import {Link, useParams} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import OtherInfoData from "../../Profile/OtherInfo/OtherInfoData";

const Others = ({medicalData,surgeryData,medicalVaccinationData,othersTrainingData,othersData,bankData}) => {

    const {id} = useParams();
    return (
        <>
            <div className="row">
                <Link  className="btn btn-success btn-sm mr-2" to={`${COMPANYROUTE.MEDICAL_INFORMATION}${id}`}>Medical Information</Link>
                <Link  className="btn btn-warning btn-sm mr-2" to={`${COMPANYROUTE.TRAINING_INFORMATION}${id}`}>Training</Link>
                <Link  className="btn btn-danger btn-sm mr-2" to={`${COMPANYROUTE.OTHERS_INFORMATION}${id}`}>Others</Link>
                <Link  className="btn btn-info btn-sm mr-2" to={`${COMPANYROUTE.BANK_INFORMATION}${id}`}>Bank</Link>
            </div>
            <OtherInfoData
                medicalData={medicalData}
                surgeryData={surgeryData}
                medicalVaccinationData={medicalVaccinationData}
                othersTrainingData={othersTrainingData}
                othersData={othersData}
                bankData={bankData}
            />

        </>
    );
};

export default Others;