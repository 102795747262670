export const INITIAL_STATE = {
    loading:false,
    error:false,
    data:{},
    appliedJobData:{},
    shortlistData:{},
}

export const ACTION_TYPES = {
    GET_JOB_DATA: 'GET_JOB_DATA',
    GET_JOB_DATA_SUCCESS: 'GET_JOB_DATA_SUCCESS',
    GET_JOB_DATA_ERROR: 'GET_JOB_DATA_ERROR',
    GET_APPLIED_JOB_DATA_SUCCESS: 'GET_APPLIED_JOB_DATA_SUCCESS',
    GET_SHORTLIST_DATA_SUCCESS: 'GET_SHORTLIST_DATA_SUCCESS',
}

export const JobReducer = (state,action) => {
     switch (action.type) {
          case ACTION_TYPES.GET_JOB_DATA:
                return {
                 loading:true,
                 error:false,
                }
          case ACTION_TYPES.GET_JOB_DATA_SUCCESS:
                return {
                 ...state,
                 loading:false,
                 data:action.payload
                }
          case  ACTION_TYPES.GET_JOB_DATA_ERROR:
                return {
                 loading:false,
                 error:true,
                }
         case ACTION_TYPES.GET_APPLIED_JOB_DATA_SUCCESS:
             return {
                 ...state,
                 loading:false,
                 appliedJobData:action.payload
             }
         case ACTION_TYPES.GET_SHORTLIST_DATA_SUCCESS:
             return {
                 ...state,
                 loading:false,
                 shortlistData:action.payload
             }
          default:
                return state
     }

}