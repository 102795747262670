import axiosClient from "../axios-client";
import {useState} from "react";

const usePrintWindow = ({ url, payload = null }) => {
    const [loading, setLoading] = useState(false);

    const handlePrint = () => {
        setLoading(true);
        axiosClient.post(url, payload)
            .then(({ data }) => {
                console.log(data)
                const printLayout = document.createElement('div');
                printLayout.innerHTML = data?.data;
                const contentToHide = document.querySelector('.content-wrapper');
                const contentToFooterHide = document.querySelector('.main-footer');
                if (contentToHide) {
                    contentToHide.style.display = 'none';
                }
                if (contentToFooterHide) {
                    contentToFooterHide.style.display = 'none';
                }


                var pageBreak = printLayout.querySelectorAll('.pageBreak');


                 if(pageBreak != null && pageBreak != undefined && pageBreak != ''){
                    for(var i = 0; i < pageBreak.length - 1; i++){
                        console.log(i)
                        pageBreak[i].style.pageBreakAfter = 'always';
                    }
                 }





                document.body.appendChild(printLayout);
                const images = printLayout.querySelectorAll('img');
                let numLoaded = 0;
                const numImages = images.length;
                const checkAllLoaded = () => {
                    numLoaded++;
                    if (numLoaded === numImages) {
                        window.print();
                        document.body.removeChild(printLayout);
                        if (contentToHide) {
                            contentToHide.style.display = 'block';
                        }
                        if (contentToFooterHide) {
                            contentToFooterHide.style.display = 'block';
                        }
                        setLoading(false);
                    }
                };
                images.forEach((image) => {
                    image.onload = checkAllLoaded;
                });
            }).catch((e) => {
            // Handle errors
            console.log(e)
            setLoading(false);
        });
    };

    return { handlePrint, loading };
};


export default usePrintWindow;
