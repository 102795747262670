import React from 'react';
import {Link} from "react-router-dom";

const FamilyList = ({familyData,onDelete,route}) => {
    return (
        <table className="table table-bordered ">
            <thead>
            <tr>
                <th>Relationship</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Date of Birth</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {familyData && familyData.length > 0 ? familyData.map((data,index) => (
                <tr key={index}>
                    <td>{data?.relationship}</td>
                    <td>{data?.firstname}</td>
                    <td>{data?.lastname}</td>
                    <td>{data?.dob}</td>
                    <td>
                        <Link to={`${route}${data?.family_id}`} className="btn btn-info btn-sm mr-2"><i className="fas fa-pencil-alt"></i></Link>
                        <button onClick={e => onDelete(data)}  className="btn btn-danger btn-sm"><i className="fas fa-trash"></i></button>
                    </td>
                </tr>
            )) : "" }

            </tbody>
        </table>

    );
};

export default FamilyList;