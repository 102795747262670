import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import {useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import AddDocumentForm from "../../../Components/Document/AddDocumentForm";

const AddDocument = () => {
     const {id} = useParams();
     const [loading,setLoading] = useState(false);
    const [progress, setProgress] = useState(100);
     const navigate = useNavigate()

    const [form,setForm] = useState({
        user_id:id,
        document_type:'',
        title:'',
        doc_file:null,
        document_lists:'',
    })


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axiosClient.post('/company/seafarers/documents/store',form,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                toast.success(data.message);
                setLoading(false);
                setTimeout(() => {
                    navigate(-1);
                },1000);

            }).catch(err => {
            setLoading(false);
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }








    return (
        <div>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Add New Seafarer Document' backButton={true}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={onSubmit}>
                                    <AddDocumentForm form={form} setForm={setForm} loading={loading} />
                                </form>
                            </div>

                </div>
            </section>
        </div>
    );
};

export default AddDocument;