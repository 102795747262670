import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";


const SupplyDemandReport = () => {
    const [vesselSelect,setVesselSelect] = useState('');
    const [reportData,setReportData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));

    const getReportData = (vessel = 0,dateFromData = null,dateToData = null) => {
        axiosClient.post('/company/report/seafarer-supply-demand',{
            vessel_id: vessel,
            date_from: dateFromData,
            date_to: dateToData
        })
            .then(({data}) => {
                setReportData(data.data)
            }).catch(() => {
        })
    }

    const columns = [

        {
            name: 'Rank',
            selector: row => row.rank_name,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Total Supply',
            selector: row => row.total_supply,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Total Demand',
            selector: row => row.total_demand,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Surplus',
            selector: row => row.total_demand > row.total_supply ? row.total_demand - row.total_supply : '',
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Deflict',
            selector: row => row.total_supply > row.total_demand ? row.total_supply - row.total_demand : '',
            sortable: true,
            className: 'header-row',
        },
    ];




    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getReportData(vesselSelect,dateFromData,dateToData)

    },[vesselSelect,dateFrom,dateTo])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-supply-demand/print',
        payload: { vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString() },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Rank', key: 'rank_name', width: 20 },
        { header: 'Total Supply', key: 'total_supply', width: 20 },
        { header: 'Total Demand', key: 'total_demand', width: 20 },
        { header: 'Surplus', key: 'surplus', width: 20 },
        { header: 'Deflict', key: 'deflict', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-supply-demand/export',{
            vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Seafarer Supply Demand Reports','no-of-contract-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-supply-demand/export',{
            vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Seafarer Supply Demand Reports','no-off-contract-report',exportColumns)
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Seafarer Supply Demand Reports'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-12 col-md-9">
                                                <div className="row">
                                                    <div className='col-12 col-md-4'>
                                                        <AllVessel vesselSelect={vesselSelect} setVesselSelect={setVesselSelect} />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <DatePicker
                                                            selected={dateFrom}
                                                            dateFormat='dd-MM-yyyy'
                                                            className="form-control"
                                                            onChange={(date) => setDateFrom(date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <DatePicker
                                                            selected={dateTo}
                                                            dateFormat='dd-MM-yyyy'
                                                            className="form-control"
                                                            onChange={(date) => setDateTo(date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="float-right">
                                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                    <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                    <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        columns={columns}
                                        data={reportData}
                                        pagination={true}
                                        paginationPerPage={30}
                                        paginationRowsPerPageOptions={[30, 50, 100,500]}
                                        fixedHeader={true}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SupplyDemandReport;