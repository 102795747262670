import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import {useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';

const EditToVessel = () => {
     const {id} = useParams();
     const navigate = useNavigate()
    const [progress, setProgress] = useState(10);
    const [ranks,setRanks] = useState([]);
    const [vessel,setVessel] = useState([]);
    const [country,setCountry] = useState([]);
    const [port,setPort] = useState([]);
    const [form,setForm] = useState({
        crews_id:'',
        emp_id:id,
        country_id:'',
        category_id:'',
        vessel_id:'',
        port_id:'',
        promotion:'',
        signing:'',
        embarkation:'',
        joining_date:'',
        expiry_date:'',
        contract_month:'',
        contract_plus:'',
        contract_minus:'',
        basic_wages:'',
        leave_pay:'',
        overtime:'',
        tanker_allowance:'',
        other_allowance:'',
        medical_details:'',

        extended_from_date:'',
        extended_to_date:'',
        contract_extension_month:'',
        contract_extension_month_plus:'',
        contract_extension_month_minus:'',
        extended_remarks:'',
        extended_basic_wages:'',
        extended_leave_pay:'',
        extended_overtime:'',
        extended_tanker_allowance:'',
        extended_other_allowance:'',


        on_board_promotion_date:'',
        salary_effective_date:'',
        on_board_rank:'',
        on_board_basic_wages:'',
        on_board_leave_pay:'',
        on_board_overtime:'',
        on_board_tanker_allowance:'',
        on_board_other_allowance:'',
        on_board_remarks:'',

    })
    const [illnessCheck,setIllnessCheck] = useState(form?.medical_details != null || form?.medical_details !== '');
    const [contractExtension,setContractExtension] = useState(false);
    const [salaryRevised,setSalaryRevised] = useState(false);
    const [onboardPromotion,setOnBoardPromotion] = useState(false);


    const getRankData = () => {
        axiosClient.get('/company/get-ranks')
            .then(({data}) => {
                setRanks(data.data)
                setProgress(30)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const getCountryData = () => {
        axiosClient.get('/company/get-country')
            .then(({data}) => {
                setCountry(data.data)
                setProgress(50)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessel(data.data)
                setProgress(70)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const getPortData = () => {
        axiosClient.get('/company/get-port')
            .then(({data}) => {
                setPort(data.data)
                setProgress(80)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const getData = () => {
        axiosClient.get('/company/seafarers/edit-to-vessel/'+id)
            .then(({data}) => {
                setForm({
                    crews_id:data.data?.crews_id,
                    emp_id:data.data?.emp_id,
                    country_id:data.data?.country_id,
                    category_id:data.data?.category_id,
                    vessel_id:data.data?.vessel_id,
                    port_id:data.data?.port_id,
                    promotion:data.data?.promotion,
                    signing:data.data?.signing,
                    embarkation:data.data?.embarkation,
                    joining_date:data.data?.joining_date,
                    expiry_date:data.data?.expiry_date,
                    contract_month:data.data?.contract_month,
                    contract_plus:data.data?.contract_plus,
                    contract_minus:data.data?.contract_minus,
                    basic_wages:data.data?.basic_wages,
                    leave_pay:data.data?.leave_pay,
                    overtime:data.data?.overtime,
                    tanker_allowance:data.data?.tanker_allowance,
                    other_allowance:data.data?.other_allowance,
                    medical_details:data.data?.medical_details,

                    extended_from_date:data?.contractExtensionData?.date_from,
                    extended_to_date:data?.contractExtensionData?.date_to,
                    contract_extension_month:data?.contractExtensionData?.contract_month,
                    contract_extension_month_plus:data?.contractExtensionData?.contract_plus,
                    contract_extension_month_minus:data?.contractExtensionData?.contract_minus,
                    extended_remarks:data?.contractExtensionData?.remarks,
                    extended_basic_wages:data?.contractExtensionData?.basic_wages,
                    extended_leave_pay:data?.contractExtensionData?.leave_pay,
                    extended_overtime:data?.contractExtensionData?.overtime,
                    extended_tanker_allowance:data?.contractExtensionData?.tanker_allowance,
                    extended_other_allowance:data?.contractExtensionData?.other_allowance,

                    on_board_promotion_date:data?.onBoardPromotion?.promtion_date,
                    salary_effective_date:data?.onBoardPromotion?.salary_effective_date,
                    on_board_rank:data?.onBoardPromotion?.category_id,
                    on_board_basic_wages:data?.onBoardPromotion?.basic_wages,
                    on_board_leave_pay:data?.onBoardPromotion?.leave_pay,
                    on_board_overtime:data?.onBoardPromotion?.overtime,
                    on_board_tanker_allowance:data?.onBoardPromotion?.tanker_allowance,
                    on_board_other_allowance:data?.onBoardPromotion?.other_allowance,
                    on_board_remarks:data?.onBoardPromotion?.remarks,

                })
                setContractExtension(data?.contractExtensionData !== null)
                setOnBoardPromotion(data?.onBoardPromotion !== null)
                setSalaryRevised(data?.contractExtensionData?.salary_revised === 'yes')

                setProgress(100)
            }).catch((err) => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.SEAFARER}`);
                },1000);
            }
        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/seafarers/update-to-vessel',{
            ...form,
            salary_revised:salaryRevised,
            contract_extension:contractExtension,
            on_board_promotion:onboardPromotion,
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.SEAFARER}`);
                },1000);

            }).catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }


    useEffect(() => {
        getRankData()
        getCountryData()
        getVesselData()
        getPortData()
        getData()
    },[])

    return (
        <div>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Update Seafarer Joining' prev={COMPANYROUTE.SEAFARER}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={onSubmit}>
                                    <div className="card-body">
                                        <fieldset>
                                            <legend>Joining Details:</legend>
                                        <div className="row">

                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="vessel">Choose Vessel </label>
                                                    <select style={{maxWidth: '100%'}}
                                                            onChange={e => setForm({...form,vessel_id:e.target.value})}
                                                            value={form.vessel_id}
                                                            className="form-control"
                                                    >
                                                        <option  value="" selected>Select Vessel</option>
                                                        {vessel.map((data,index) => (
                                                            <option key={index}    value={data?.vessel_id}>{data?.vessel_name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Choose Rank </label>
                                                    <select style={{maxWidth: '100%'}}
                                                            onChange={e => setForm({...form,category_id:e.target.value})}
                                                            value={form.category_id}
                                                            className="form-control"
                                                    >
                                                        <option  value="" selected>Select Rank</option>
                                                        {ranks.map((data,index) => (
                                                            <option key={index}    value={data?.category_id}>{data?.category_name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="mother_name">Select Country</label>
                                                        <select style={{maxWidth: '100%'}}
                                                                onChange={e => setForm({...form,country_id:e.target.value})}
                                                                value={form?.country_id}
                                                                className="form-control"
                                                        >
                                                            <option  value="" selected>Select Country</option>
                                                            {country.map((item,index) => (
                                                                <option key={index}   value={item?.country_id}>{item?.country_name}</option>
                                                            ))}

                                                        </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Choose Port </label>
                                                    <select style={{maxWidth: '100%'}}
                                                            onChange={e => setForm({...form,port_id:e.target.value})}
                                                            value={form?.port_id}
                                                            className="form-control"
                                                    >
                                                        <option  value="" selected>Select Port</option>
                                                        {port.map((item,index) => (
                                                            <option key={index}   value={item?.id}>{item?.name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Choose Promotion </label>
                                                    <select className='form-control'
                                                            onChange={e => setForm({...form,promotion:e.target.value})}
                                                            value={form.promotion}
                                                    >
                                                        <option value="">Select Promotion</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Contract Signing Date</label>
                                                    <DatePicker
                                                        selected={form?.signing ? new Date(new Date(form.signing)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,signing:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Departure Date</label>
                                                    <DatePicker
                                                        selected={form?.embarkation ? new Date(new Date(form.embarkation)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,embarkation:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Joining  Date</label>
                                                    <DatePicker
                                                        selected={form?.joining_date ? new Date(new Date(form.joining_date)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,joining_date:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Expiry Date (Contract Duration Due Date)</label>
                                                    <DatePicker
                                                        selected={form?.expiry_date ? new Date(new Date(form.expiry_date)) : ''}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,expiry_date:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Contract Month</label>
                                                    <input onChange={e => setForm({...form,contract_month:e.target.value})} value={form?.contract_month} className='form-control' placeholder="Enter Contract Month" type="text"/>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Contract (+)</label>
                                                    <input className='form-control' onChange={e => setForm({...form,contract_plus:e.target.value})} value={form?.contract_plus} placeholder="Enter Contract Month (+)" type="text"/>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="rank">Contract Month (-)</label>
                                                    <input className='form-control' onChange={e => setForm({...form,contract_minus:e.target.value})} value={form?.contract_minus} placeholder="Enter Contract Month (-)" type="text"/>
                                                </div>
                                            </div>

                                        </div>
                                        </fieldset>

                                        <fieldset>
                                            <legend>Payment Details:</legend>
                                            <div className="row">

                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Basic Wages</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,basic_wages:e.target.value})} value={form?.basic_wages} placeholder="Enter Basic Wages" type="number"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Leave Pay</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,leave_pay:e.target.value})} value={form?.leave_pay} placeholder="Enter Leave Pay" type="number"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Fixed Overtime</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,overtime:e.target.value})} value={form?.overtime} placeholder="Enter Fixed Overtime" type="number"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Tanker Allowance</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,tanker_allowance:e.target.value})} value={form?.tanker_allowance} placeholder="Enter Tanker Allowance" type="number"/>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="rank">Other Allowance</label>
                                                        <input className='form-control ' onChange={e => setForm({...form,other_allowance:e.target.value})} value={form?.other_allowance}  placeholder="Enter Other Allowance" type="number"/>
                                                    </div>
                                                </div>
                                                <div className=" col-12">
                                                    <div className="form-group">
                                                        <p>
                                                            <input type="checkbox" onChange={(e) => setIllnessCheck(e.target.checked) } checked={illnessCheck}   name="illness" className="filled-in"
                                                                   id="illnessCheckbox" />
                                                                <label className="text-black ml-2" htmlFor="illnessCheckbox"> Any Illness During Leave</label>
                                                        </p>



                                                        {
                                                            illnessCheck  ? (
                                                                <div id="illnessDiv"  >
                                                                    <label className="text-black" htmlFor="medical_details">Medical
                                                                        History Details</label>
                                                                    <textarea name="medical_details" onChange={e => setForm({...form,medical_details:e.target.value})} className="form-control" id="medical_details"
                                                                              placeholder="Enter details" value={form?.medical_details}></textarea>
                                                                </div>
                                                            ) : ''
                                                        }

                                                    </div>
                                                </div>

                                                <div className=" col-12">
                                                    <div className="form-group">
                                                        <p>
                                                            <input type="checkbox" onChange={(e) => setContractExtension(e.target.checked) } checked={contractExtension}   name="illness" className="filled-in"
                                                                   id="illnessCheckbox" />
                                                            <label className="text-black ml-2" htmlFor="illnessCheckbox"> Contract Extension</label>
                                                        </p>



                                                        {
                                                            contractExtension  ? (
                                                                <div className="row">
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Date From</label>
                                                                            <DatePicker
                                                                                selected={form?.extended_from_date ? new Date(new Date(form.extended_from_date)) : ''}
                                                                                dateFormat='dd-MM-yyyy'
                                                                                className="form-control"
                                                                                onChange={e => setForm({...form,extended_from_date:e})}
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Extension Due Date</label>
                                                                            <DatePicker
                                                                                selected={form?.extended_to_date ? new Date(new Date(form.extended_to_date)) : ''}
                                                                                dateFormat='dd-MM-yyyy'
                                                                                className="form-control"
                                                                                onChange={e => setForm({...form,extended_to_date:e})}
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Contract Extension Month</label>
                                                                            <input onChange={e => setForm({...form,contract_extension_month:e.target.value})} value={form?.contract_extension_month} className='form-control' placeholder="Enter Contract Month" type="text"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Contract Extension Month (+)</label>
                                                                            <input className='form-control' onChange={e => setForm({...form,contract_extension_month_plus:e.target.value})} value={form?.contract_extension_month_plus} placeholder="Enter Contract Month (+)" type="text"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Contract Extension Month (-)</label>
                                                                            <input className='form-control' onChange={e => setForm({...form,contract_extension_month_minus:e.target.value})} value={form?.contract_extension_month_minus} placeholder="Enter Contract Month (-)" type="text"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Remarks</label>
                                                                            <input className='form-control' onChange={e => setForm({...form,extended_remarks:e.target.value})} value={form?.extended_remarks} placeholder="Enter Remarks" type="text"/>
                                                                        </div>
                                                                    </div>

                                                                   <div className="col-12">
                                                                       <p>
                                                                           <input type="checkbox" onChange={(e) => setSalaryRevised(e.target.checked) } checked={salaryRevised}    className="filled-in"
                                                                                  id="illnessCheckbox" />
                                                                           <label className="text-black ml-2" htmlFor="illnessCheckbox">Any Change in Salary</label>
                                                                       </p>
                                                                   </div>

                                                                    {
                                                                        salaryRevised ? (
                                                                            <>

                                                                                <div className="col-md-4 col-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="rank">Basic Wages</label>
                                                                                        <input className='form-control ' onChange={e => setForm({...form,extended_basic_wages:e.target.value})} value={form?.extended_basic_wages} placeholder="Enter Basic Wages" type="number"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="rank">Leave Pay</label>
                                                                                        <input className='form-control ' onChange={e => setForm({...form,extended_leave_pay:e.target.value})} value={form?.extended_leave_pay} placeholder="Enter Leave Pay" type="number"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="rank">Fixed Overtime</label>
                                                                                        <input className='form-control ' onChange={e => setForm({...form,extended_overtime:e.target.value})} value={form?.extended_overtime} placeholder="Enter Fixed Overtime" type="number"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="rank">Tanker Allowance</label>
                                                                                        <input className='form-control ' onChange={e => setForm({...form,extended_tanker_allowance:e.target.value})} value={form?.extended_tanker_allowance} placeholder="Enter Tanker Allowance" type="number"/>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4 col-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="rank">Other Allowance</label>
                                                                                        <input className='form-control ' onChange={e => setForm({...form,extended_other_allowance:e.target.value})} value={form?.extended_other_allowance}  placeholder="Enter Other Allowance" type="number"/>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : ''
                                                                    }

                                                                </div>
                                                            ) : ''
                                                        }

                                                    </div>
                                                </div>

                                                <div className=" col-12">
                                                    <div className="form-group">
                                                        <p>
                                                            <input type="checkbox" onChange={(e) => setOnBoardPromotion(e.target.checked) } checked={onboardPromotion}   name="illness" className="filled-in"
                                                                   id="illnessCheckbox" />
                                                            <label className="text-black ml-2" htmlFor="illnessCheckbox"> On Board Promotion</label>
                                                        </p>



                                                        {
                                                            onboardPromotion  ? (
                                                                <div className="row">
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">On Board Promotion Date</label>
                                                                            <DatePicker
                                                                                selected={form?.on_board_promotion_date ? new Date(new Date(form.on_board_promotion_date)) : ''}
                                                                                dateFormat='dd-MM-yyyy'
                                                                                className="form-control"
                                                                                onChange={e => setForm({...form,on_board_promotion_date:e})}
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Salary Effective Date</label>
                                                                            <DatePicker
                                                                                selected={form?.salary_effective_date ? new Date(new Date(form.salary_effective_date)) : ''}
                                                                                dateFormat='dd-MM-yyyy'
                                                                                className="form-control"
                                                                                onChange={e => setForm({...form,salary_effective_date:e})}
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Choose Rank </label>
                                                                            <select style={{maxWidth: '100%'}}
                                                                                    onChange={e => setForm({...form,on_board_rank:e.target.value})}
                                                                                    value={form.on_board_rank}
                                                                                    className="form-control"
                                                                            >
                                                                                <option  value="" selected>Select Rank</option>
                                                                                {ranks.map((data,index) => (
                                                                                    <option key={index}    value={data?.category_id}>{data?.category_name}</option>
                                                                                ))}

                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Basic Wages</label>
                                                                            <input className='form-control ' onChange={e => setForm({...form,on_board_basic_wages:e.target.value})} value={form?.on_board_basic_wages} placeholder="Enter Basic Wages" type="number"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Leave Pay</label>
                                                                            <input className='form-control ' onChange={e => setForm({...form,on_board_leave_pay:e.target.value})} value={form?.on_board_leave_pay} placeholder="Enter Leave Pay" type="number"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Fixed Overtime</label>
                                                                            <input className='form-control ' onChange={e => setForm({...form,on_board_overtime:e.target.value})} value={form?.on_board_overtime} placeholder="Enter Fixed Overtime" type="number"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Tanker Allowance</label>
                                                                            <input className='form-control ' onChange={e => setForm({...form,on_board_tanker_allowance:e.target.value})} value={form?.on_board_tanker_allowance} placeholder="Enter Tanker Allowance" type="number"/>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Other Allowance</label>
                                                                            <input className='form-control ' onChange={e => setForm({...form,on_board_other_allowance:e.target.value})} value={form?.on_board_other_allowance}  placeholder="Enter Other Allowance" type="number"/>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4 col-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="rank">Remarks</label>
                                                                            <input className='form-control' onChange={e => setForm({...form,on_board_remarks:e.target.value})} value={form?.on_board_remarks} placeholder="Remarks" type="text"/>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            ) : ''
                                                        }

                                                    </div>
                                                </div>



                                            </div>
                                        </fieldset>




                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>

                </div>
            </section>
        </div>
    );
};

export default EditToVessel;