import React, {useEffect, useState} from 'react';
import './notification.css'
import axiosClient from "../../../axios-client";
import DocumentRenewalModal from "../documentRenewal/DocumentRenewalModal";
import DocumentRenewalSearchModal from "../documentRenewal/DocumentRenewalSearchModal";
// import loadingImage from "./images/loading.webp";
const DocumentRenewal60Days = () => {
    const [notificationData,setNotificationData] = useState([]);
    const [notificationDataCount,setNotificationDataCount] = useState(0);
    const [emailModal,setEmailModal] = useState(false)
    const [emailModalData,setEmailModalData] = useState("")

    const [printModal,setPrintModal] = useState(false)
    const [printModalData,setPrintModalData] = useState("")

    const [visiable,setVisiable] = useState(false)

    const getNotificationData = () => {
        axiosClient.get('/company/notification/document-renewal')
            .then(({data}) => {
                console.log(data?.data)
                setNotificationData(data.data.document_data)
                setNotificationDataCount(data.data.document_count)
                setPrintModalData(data.data.document_exist)
                setVisiable(true)
            }).catch((e) => {
            console.log(e)
        })
    }

    const handleEmailModal = () => {
         setEmailModal(true)
    }

    const handlePrintModal = () => {
            setPrintModal(true)
    }

    useEffect(() => {
        getNotificationData()
    },[])
    return (
         <>
             {visiable ?
                 <div className="card-body">
                 <div className="d-flex justify-content-center">
                    <button onClick={() => handlePrintModal()} className="btn btn-sm btn-primary   text-center"><i className="fa fa-print"></i> Print</button>
                </div>
                 <div id="accordion">
                     <div className="card card-success">
                         <div className="card-header">
                             <h4 className="card-title w-100">
                                 <a className="d-block w-100 collapsed" data-toggle="collapse"
                                                        href="#documentRenewal60Days" aria-expanded="false">
                                     Document Renewal ({notificationDataCount ?? 0}) </a>
                             </h4>
                         </div>



                         <div id="documentRenewal60Days" className="collapse notification-content" data-parent="#accordion"  >
                             {notificationData.length > 0 ? notificationData.map((data,index) => {
                                 return (
                                     <div className="card-body notification" key={index}>
                                         <div className="row">
                                             <div className="col-8">
                                                 <h6>{data?.name}  <small>({data?.info_type})</small></h6>
                                                 <p><strong>Expiry Date :</strong> {data?.expiry_date} </p>
                                                 <p><strong>CDC NO :</strong> {data?.cdc_no}</p>
                                                 <p><strong>Vessel Name :</strong>
                                                     {data?.status == 'Leave' ?  <span className="badge badge-danger">Leave</span> : data?.vessel_name}
                                                 </p>
                                             </div>
                                             <div className="col-4 ">
                                                 <a href="#" className="btn btn-sm btn-info  mr-2 my-2 text-center">SMS</a>
                                                 <button onClick={() => handleEmailModal(data)} className="btn btn-sm btn-primary   text-center"> Email</button>
                                             </div>

                                         </div>
                                     </div>
                                 ) }) : <div className="card-body notification">
                                 <div className="row">
                                     <div className="col-12 ">
                                         <h6 className="text-center">No Data Found</h6>
                                     </div>
                                 </div>
                             </div>}

                         </div>
                     </div>

                 </div>
             </div>
                 : <div className="card-body text-center">
                     <img src="/images/loading.webp" className="img-fluid" height="120" width="120" alt=""/>
                 </div>
             }

             {
                  emailModal &&  <DocumentRenewalModal data={emailModalData} hide={() => setEmailModal(false)} />
             }

             {
                 printModal &&  <DocumentRenewalSearchModal data={printModalData} hide={() => setPrintModal(false)} />
             }
         </>
    );
};

export default DocumentRenewal60Days;