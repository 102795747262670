import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
const AddNewSeafarer = () => {
    const [progress, setProgress] = useState(10);
    const navigate = useNavigate();
    const [form,setForm] = useState({
        first_name:'',
        last_name:'',
        email:'',
        mobile:'',
        password:'',
        password_confirmation:''
    })

    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/seafarer/store',form)
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.SEAFARER}`);
                },1000);

            }).catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }
    useEffect(() => {
        setProgress(100);
    },[])
    return (
        <div>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Add New Seafarer' prev={COMPANYROUTE.SEAFARER}    />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-primary">

                                <form onSubmit={onSubmit}>
                                    <div className="card-body">
                                        <div className="form-group">

                                            <label htmlFor="first_name">First Name </label>
                                            <input type="text" name="first_name" onChange={e => setForm({...form,first_name:e.target.value})} className="form-control" id="first_name"
                                                   placeholder="Enter First Name" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="last_name">Last Name</label>
                                            <input type="text" name="last_name"   onChange={e => setForm({...form,last_name:e.target.value})} className="form-control" id="first_name"
                                                   placeholder="Enter Last Name" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" name="email"  onChange={e => setForm({...form,email:e.target.value})} className="form-control" id="email"
                                                   placeholder="Enter Email" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="mobile">Mobile Number</label>
                                            <input type="text" name="mobile"  onChange={e => setForm({...form,mobile:e.target.value})} className="form-control" id="mobile"
                                                   placeholder="Enter Mobile Number" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" name="password"  onChange={e => setForm({...form,password:e.target.value})} className="form-control" id="password"
                                                   placeholder="Enter Password" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Confirm Password</label>
                                            <input type="password" name="password"  onChange={e => setForm({...form,password_confirmation:e.target.value})} className="form-control" id="password"
                                                   placeholder="Enter Password" />
                                        </div>


                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                  </div>
                </div>
            </section>
        </div>
    );
};

export default AddNewSeafarer;