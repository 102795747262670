import React from 'react';

const AddDocumentForm = ({form,setForm,loading}) => {
    return (
        <>
            <div className="card-body">

                <label><small>Select Document Type</small></label>
                <div className="d-flex ">
                    <div className="form-group mx-2">
                        <input name="certificate_document" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '1'}   type="radio" id="certificate_document"  value="1" />
                        <label htmlFor="certificate_document" > <small className="ml-1">Stcw Document</small></label>
                    </div>
                    <div className="form-group mx-2">
                        <input name="training_document" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '2'}   type="radio" id="training_document"  value="2" />
                        <label htmlFor="training_document" > <small className="ml-1">Training Certificate</small></label>
                    </div>
                    <div className="form-group mx-2">
                        <input name="passport_document" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '3'}   type="radio" id="passport_document"  value="3" />
                        <label htmlFor="passport_document" > <small className="ml-1">Passport & Visas</small></label>
                    </div>
                    <div className="form-group mx-2">
                        <input name="medical_document" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '4'}   type="radio" id="medical_document"  value="4" />
                        <label htmlFor="medical_document" > <small className="ml-1">Company Pre Joining Medical</small></label>
                    </div>
                    <div className="form-group mx-2">
                        <input name="port_health_document" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '9'}   type="radio" id="port_health_document"  value="9" />
                        <label htmlFor="port_health_document" > <small className="ml-1">Port Health Medical</small></label>
                    </div>
                    <div className="form-group mx-2">
                        <input name="CDC" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '5'}   type="radio" id="CDC"  value="5" />
                        <label htmlFor="CDC" > <small className="ml-1">CDC</small></label>
                    </div>
                    <div className="form-group mx-2">
                        <input name="Education" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '6'}   type="radio" id="Education"  value="6" />
                        <label htmlFor="Education" > <small className="ml-1">Education</small></label>
                    </div>
                    <div className="form-group mx-2">
                        <input name="Experience" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '7'}   type="radio" id="Experience"  value="7" />
                        <label htmlFor="Experience" > <small className="ml-1">Experience</small></label>
                    </div>
                    <div className="form-group mx-2">
                        <input name="Others" onChange={e => setForm({...form,document_type:e.target.value})} checked={form.document_type === '8'}   type="radio" id="Others"  value="8" />
                        <label htmlFor="Others" > <small className="ml-1">Others</small></label>
                    </div>

                </div>

                <div className="row">
                    {form.document_type === '1' && (
                        <div className="col-md-6 col-12">
                            <div className="form-group mx-2">
                                <label htmlFor="title" > <small className="ml-1">STCW Certificates/Documents</small></label>
                                <select name="" id=""
                                        className="form-control"
                                        onChange={e => setForm({...form,document_lists:e.target.value})}
                                >
                                    <option value="">Choose your document</option>
                                    <option value="Certificate of Competency (COC)">Certificate of Competency (COC)</option>
                                    <option value="Flag State Endorsement (COC)">Flag State Endorsement (COC)</option>
                                    <option value="GMDSS- General Operator Certificate (GOC)">GMDSS- General Operator Certificate (GOC)</option>
                                    <option value="Flag State Endorsement (GOC)">Flag State Endorsement (GOC)</option>
                                    <option value="Tanker Endorsement by Flag State">Tanker Endorsement by Flag State</option>
                                </select>

                            </div>
                        </div>
                    )}
                    {form.document_type === '2' && (
                        <div className="col-md-6 col-12">
                            <div className="form-group mx-2">
                                <label htmlFor="title" > <small className="ml-1">Training Certificates</small></label>
                                <select name="" id=""
                                        className="form-control"
                                        onChange={e => setForm({...form,document_lists:e.target.value})}
                                >
                                    <option value="">Choose your document</option>
                                    <option value="Basic Safety Training">Basic Safety Training</option>
                                    <option value="Advanced Fire Fighting">Advanced Fire Fighting</option>
                                    <option value="Basic Fire Fighting">Basic Fire Fighting</option>
                                    <option value="Medical Care">Medical Care</option>
                                    <option value="Medical Frist Aid">Medical Frist Aid</option>
                                    <option value="Proficiency in Survival Craft and Rescue Boat">Proficiency in Survival Craft and Rescue Boat</option>
                                    <option value="Personal Safety and Social Responsibility">Personal Safety and Social Responsibility</option>
                                    <option value="Ship Security Officer">Ship Security Officer</option>
                                    <option value="Ship Security Awareness Certificate">Ship Security Awareness Certificate</option>
                                    <option value="Shipboard Safety Officer Course">Shipboard Safety Officer Course</option>
                                    <option value="Ship’s Cook Certificate">Ship’s Cook Certificate</option>
                                    <option value="Basic Tanker Familiarization Course (Oil/Gas/Chemical)">Basic Tanker Familiarization Course (Oil/Gas/Chemical)</option>
                                    <option value="Advanced Tanker Training (Oil/Gas/Chemical)">Advanced Tanker Training (Oil/Gas/Chemical)</option>
                                    <option value="ECDIS Generic">ECDIS Generic</option>
                                    <option value="ECDIS Type Specific">ECDIS Type Specific</option>
                                    <option value="Bridge Resources Management">Bridge Resources Management</option>
                                    <option value="Bridge Team Management">Bridge Team Management</option>
                                    <option value="Ship Handling Simulator course">Ship Handling Simulator course</option>
                                    <option value="Radar/ARPA">Radar/ARPA</option>
                                    <option value="ME Engine Course">ME Engine Course</option>
                                    <option value="Engine Resources Management">Engine Resources Management</option>
                                    <option value="Risk Assessment Course">Risk Assessment Course</option>
                                    <option value="Incident Investigation Course">Incident Investigation Course</option>
                                    <option value="Crude Oil Washing">Crude Oil Washing</option>
                                    <option value="Marine Environment Protection">Marine Environment Protection</option>
                                    <option value="High Voltage (Management Level)">High Voltage (Management Level)</option>
                                    <option value="Engine Handing Simulator">Engine Handing Simulator</option>
                                    <option value="Efficient Deck Hand (EDH)">Efficient Deck Hand (EDH)</option>
                                    <option value="Navigational Aid and Electronic Navigation System">Navigational Aid and Electronic Navigation System</option>
                                    <option value="Operational Use of Electronic Chart Display and Information System">Operational Use of Electronic Chart Display and Information System</option>
                                    <option value="Radar Navigation (Management Level)">Radar Navigation (Management Level)</option>
                                    <option value="Seafarer with Designated Security Duties">Seafarer with Designated Security Duties</option>
                                    <option value="Personal Survival Techniques">Personal Survival Techniques</option>
                                    <option value="High Voltage System">High Voltage System</option>
                                    <option value="Seafarer with designated security duties (DSD)">Seafarer with designated security duties (DSD)</option>
                                    <option value="Elementary First Aid">Elementary First Aid</option>
                                    <option value="Personal Survival Techniques (PST)">Personal Survival Techniques (PST)</option>
                                    <option value="Ship Security Awareness Certificate (Security Awareness)">Ship Security Awareness Certificate (Security Awareness)</option>
                                    <option value="Fire Prevention & Fire Fighting">Fire Prevention & Fire Fighting</option>
                                    <option value="High Voltage System (Operation Level)">High Voltage System (Operation Level)</option>
                                    <option value="Rating Forming Part of a Engine Watch">Rating Forming Part of a Engine Watch</option>
                                    <option value="Rating Forming Part of a Navigational Watch">Rating Forming Part of a Navigational Watch</option>
                                    <option value="Rating as able Seafarer Deck">Rating as able Seafarer Deck</option>
                                    <option value="Fire Prevention And Fire Fighting" >Fire Prevention And Fire Fighting</option>
                                    <option value="Radar Navigation (Management Level)"  >Radar Navigation (Management Level)</option>
                                    <option value="Radar Navigation (Operational Level)"   >Radar Navigation (Operational Level)</option>
                                    <option value="Rating As Able Seafarer Engine">Rating As Able Seafarer Engine</option>
                                </select>

                            </div>
                        </div>
                    )}
                    {form.document_type === '3' && (
                        <div className="col-md-6 col-12">
                            <div className="form-group mx-2">
                                <label htmlFor="title" > <small className="ml-1">Passport and Visas</small></label>
                                <select name="" id=""
                                        className="form-control"
                                        onChange={e => setForm({...form,document_lists:e.target.value})}
                                >
                                    <option value="">Choose your document</option>
                                    <option value="National Passport">National Passport</option>
                                    <option value="Seafarer Passport">Seafarer Passport</option>
                                    <option value="US Visa C1/D">US Visa C1/D</option>
                                    <option value="Schengen Visa">Schengen Visa</option>
                                    <option value="Australian MCV Visa">Australian MCV Visa</option>
                                </select>

                            </div>
                        </div>
                    )}
                    {form.document_type === '4' && (
                        <div className="col-md-6 col-12">
                            <div className="form-group mx-2">
                                <label htmlFor="title" > <small className="ml-1">Medical</small></label>
                                <select name="" id=""
                                        className="form-control"
                                        onChange={e => setForm({...form,document_lists:e.target.value})}
                                >
                                    <option value="">Choose your document</option>
                                    <option value="Medical Certificate">Company Pre Joining Medical</option>
                                </select>

                            </div>
                        </div>
                    )}
                    {form.document_type === '8' && (
                        <div className="col-md-6 col-12">
                            <div className="form-group mx-2">
                                <label htmlFor="title" > <small className="ml-1">Others Documents</small></label>
                                <select name="" id=""
                                        className="form-control"
                                        onChange={e => setForm({...form,document_lists:e.target.value})}
                                >
                                    <option value="">Choose your document</option>
                                    <option value="Confidential Report">Confidential Report</option>
                                    <option value="Check list for Sea-Staff joining vessel">Check list for Sea-Staff joining vessel</option>
                                    <option value="Next of Kin list">Next of Kin list</option>
                                    <option value="Covid Certificate">Covid Certificate</option>
                                    <option value="Covid Test Report">Covid Test Report</option>
                                    <option value="SID">SID</option>
                                    <option value="Bank Details">Bank Details</option>
                                    <option value="Sign on letter">Sign on letter</option>
                                    <option value="Seafarer Employment Agreement">Seafarer Employment Agreement</option>
                                    <option value="Terms and Conditions of service">Terms and Conditions of service</option>
                                    <option value="Income Tax">Income Tax</option>
                                    <option value="NID">NID</option>
                                    <option value="Birth Certificate">Birth Certificate</option>
                                </select>

                            </div>
                        </div>
                    )}

                </div>


                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-group mx-2">
                            <label htmlFor="title" > <small className="ml-1">Document Title</small></label>
                            <input name="title" className="form-control" onChange={e => setForm({...form,title:e.target.value})}     type="text" id="title"   />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-group mx-2">
                            <label htmlFor="file" > <small className="ml-1">Document File</small></label>
                            <input name="file" className="form-control" onChange={e => setForm({...form,doc_file:e.target.files[0]})}     type="file" id="file"   />
                        </div>
                    </div>
                </div>





            </div>

            <div className="card-footer">
                {
                    loading ? <button className="btn btn-success" type="button" disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</button> :
                        <button className="btn btn-success" type="submit">Save <i className="fas fa-paper-plane"></i></button>
                }

            </div>
        </>
    );
};

export default AddDocumentForm;