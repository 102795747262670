import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import EditEducationForm from "../../../../Components/Profile/Education/EditEducationForm";

const EditEducation = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disable,setDisable] = useState([]);
    const [form,setForm] = useState({
        user_id:"",
        degree:"",
        institute:"",
        country_id:"",
        passing_year:"",
        duration:"",
        result:"",
    });


    const getData = () => {
        axiosClient.get('/company/education/'+id+'/edit')
            .then(({data}) => {
                setForm(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.put('/company/education/'+id, form)
            .then(({data}) => {
                setDisable(false)
                toast.success(data.message);
                navigate(-1)
            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {

                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    useEffect(() => {
        getData()
    },[])

    return (
        <>
            <Breadcrumb title='Edit Education' backButton={true}    />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-6 col-12">
                        <form onSubmit={onSubmit}>
                            <EditEducationForm form={form} setForm={setForm} disable={disable} />

                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditEducation;