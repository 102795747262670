import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import {useNavigate, useParams,} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import '../styles/style.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";


const EditBudgetHead = () => {
    const navigate = useNavigate();
    const {id} = useParams();

    const [budgetHead, setBudgetHead] = useState('');

    const getData = () => {
        axiosClient.get('/company/budget-head/'+id+'/edit')
            .then(({data}) => {
                setBudgetHead(data?.data?.name)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }


    useEffect(() => {
        getData()
    },[])


    const onSubmit = (e) => {
        e.preventDefault();

        axiosClient.put(`/company/budget-head/${id}`,  {
            budget_head: budgetHead,
        })
            .then(({data}) => {
                toast.success(data.message);
                  setTimeout(() => {
                    navigate(`${COMPANYROUTE.BUDGET_HEAD_LIST}`);
                  },1000);

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }




    return (
        <>
            <Breadcrumb title='Edit Budget Head'  backButton={true}    />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-12">
                        <form onSubmit={onSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="rank">Budget Head Title </label>
                                                <input type="text" value={budgetHead} onChange={(e) => setBudgetHead(e.target.value)} className="form-control"/>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <button type="submit" className="btn btn-info">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditBudgetHead;