import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../constants/companyRoutes";
import React from "react";

const ConversationList = ({conversation,inbox_url}) => {

    return (
        <ul className="chat-sidebar">
             {
                  conversation?.length > 0 ?  conversation?.map((data,index) => (
                    <li key={index}>
                        <Link to={`${inbox_url}${data?.slug}`}>
                        <img src={data?.image_url} alt="" />
                        <div>
                            <h2>{data?.name}</h2>
                            <h3>
                                <span className="status orange"></span>
                                {data?.rank}
                                {
                                    data?.cdc_number !== '' ? <span className="cdc-number">({data?.cdc_number})</span> :  data?.last_message
                                }

                            </h3>
                        </div>
                        </Link>
                    </li>    )) : <li className="text-center"> No Conversation Found</li>
                 }
        </ul>
    );

    // return (
    //     <div className="conversation">
    //
    //         <ul className="contacts-list">
    //             {
    //                 conversation?.length > 0 ?  conversation?.map((data,index) => (
    //                     <li key={index}>
    //                         <Link to={`${inbox_url}${data?.slug}`}>
    //                             <img className="contacts-list-img" src={data?.image_url} />
    //                             <div className="contacts-list-info">
    //                                           <span className="contacts-list-name">
    //                                             {data?.name}
    //                                               <small className="contacts-list-date float-right">{data?.last_message_time}</small>
    //                                           </span>
    //                                 <span className="contacts-list-msg">{data?.last_message}</span>
    //                             </div>
    //                         </Link>
    //                     </li>
    //                 )) : <li className="text-center"> No Conversation Found</li>
    //             }
    //
    //         </ul>
    //     </div>
    // );
};

export default ConversationList;