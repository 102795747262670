import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import './styles/style.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState,convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import ContentState from "draft-js/lib/ContentState";

const EditJob = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [rank,setRank] = useState([]);
    const [vesselType,setVesselType] = useState([]);
    const [data,setData] = useState({});

    const [form,setForm] = useState({
        job_id:id,
        title:"",
        vessel_id:"",
        deadline:"",
        no_of_vacancy:"",
        job_nature:"",
        description:EditorState.createEmpty(),
        requirements:EditorState.createEmpty(),
        qualification:EditorState.createEmpty(),
        instruction:EditorState.createEmpty(),
        other_benefits:'',
        gender:'',
        min_age:'',
        max_age:'',
        max_experience:'',
        min_experience:'',
        salary:'',
        min_salary:'',
        max_salary:'',
        status:'',
        job_file:null,

    });
    const getRank = () => {
        axiosClient.get('/company/get-ranks')
            .then(({data}) => {
                setRank(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    const getVesselType = () => {
        axiosClient.get('/company/get-vessel-types')
            .then(({data}) => {
                setVesselType(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const getData = () => {
        axiosClient.get('/company/job/'+id+'/edit')
            .then(({data}) => {
                const description = data.data.description;
                const contentBlock = htmlToDraft(description);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const descriptionState = EditorState.createWithContent(contentState);

                const requirements = data.data.requirements;
                const contentBlock2 = htmlToDraft(requirements);
                const contentState2 = ContentState.createFromBlockArray(contentBlock2.contentBlocks);
                const requirementsState = EditorState.createWithContent(contentState2);

                const qualification = data.data.qualification;
                const contentBlock3 = htmlToDraft(qualification);
                const contentState3 = ContentState.createFromBlockArray(contentBlock3.contentBlocks);
                const qualificationState = EditorState.createWithContent(contentState3);

                const instruction = data.data.instruction;
                const contentBlock4 = htmlToDraft(instruction);
                const contentState4 = ContentState.createFromBlockArray(contentBlock4.contentBlocks);
                const instructionState = EditorState.createWithContent(contentState4);


                setForm({
                    job_id:data.data.job_id,
                    title:data.data.title,
                    vessel_id:data.data.vessel_id,
                    deadline:data.data.deadline,
                    no_of_vacancy:data.data.no_of_vacancy,
                    job_nature:data.data.job_nature,
                    description:data.data?.description
                        ? descriptionState
                        : EditorState.createEmpty(),
                    requirements: data.data?.requirements
                        ? requirementsState
                        : EditorState.createEmpty(),
                    qualification: data.data?.qualification
                        ? qualificationState
                        : EditorState.createEmpty(),
                    instruction: data.data?.instruction
                        ? instructionState
                        : EditorState.createEmpty(),
                    other_benefits:data.data.other_benefits,
                    gender: data.data.gender,
                    salary: data.data.salary,
                    status: data.data.status,
                })
            }).catch((e) => {


        })
    }







    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post(`/company/job/update`,  form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                toast.success(data.message);
                  setTimeout(() => {
                    navigate(`${COMPANYROUTE.JOB_LIST}`);
                  },1000);

            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    useEffect(() => {
        const desState = form?.description.getCurrentContent();
        const desContent = convertToRaw(desState);
        const descriptionData = draftToHtml(desContent)

        const reqState = form?.requirements.getCurrentContent();
        const reqContent = convertToRaw(reqState);
        const requirementsData = draftToHtml(reqContent)

        const qualState = form?.qualification.getCurrentContent();
        const qualContent = convertToRaw(qualState);
        const qualificationData = draftToHtml(qualContent)

        const insState = form?.instruction.getCurrentContent();
        const insContent = convertToRaw(insState);
        const instructionData = draftToHtml(insContent)

        setForm({...form,descriptionData:descriptionData,requirementsData:requirementsData,qualificationData:qualificationData,instructionData:instructionData})


    },[form?.description,form?.requirements,form?.qualification,form?.instruction])

    useEffect(() => {
        getRank()
        getVesselType()
        getData()
    },[])






    return (
        <>
            <Breadcrumb title='Edit Job'  backButton={true}    />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-12">
                        <form onSubmit={onSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="rank">Job Title </label>
                                                <select style={{maxWidth: '100%'}}
                                                        onChange={e => setForm({...form,title:e.target.value})}
                                                        value={form?.title}
                                                        className="form-control"
                                                >
                                                    <option  value="" selected>Select Job Type</option>
                                                    {rank.map((item,index) => (
                                                        <option key={index}   value={item?.category_name}>{item?.category_name}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="rank">Vessel Type </label>
                                                <select style={{maxWidth: '100%'}}
                                                        onChange={e => setForm({...form,vessel_id:e.target.value})}
                                                        value={form?.vessel_id}
                                                        className="form-control"
                                                >
                                                    <option  value="" selected>Select Vessel Type</option>
                                                    {vesselType.map((item,index) => (
                                                        <option key={index}   value={item?.vessel_type_id}>{item?.vessel_type_name}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="rank">Application Deadline</label>
                                                <DatePicker
                                                    selected={form?.deadline ? new Date(new Date(form.deadline)) : ''}
                                                    dateFormat='dd-MM-yyyy'
                                                    className="form-control"
                                                    onChange={e => setForm({...form,deadline:e})}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="rank">Job Vacancies  </label>
                                                <input className='form-control '  value={form?.no_of_vacancy} onChange={e => setForm({...form,no_of_vacancy:e.target.value})}  type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-12 ">
                                            <div className="form-group">
                                                <label htmlFor="rank">Job Type  </label>
                                                <div className="d-flex ">
                                                    <div className="form-group mx-2">
                                                        <input name="job_nature" onChange={e => setForm({...form,job_nature:e.target.value})} checked={form.job_nature === 'Full Time'}   type="radio" id="full_time"  value="Full Time" />
                                                        <label htmlFor="full_time" > <small className="ml-1">Full Time</small></label>
                                                    </div>
                                                    <div className="form-group mx-2">
                                                        <input name="job_nature" onChange={e => setForm({...form,job_nature:e.target.value})}   checked={form.job_nature === 'Part Time'}  type="radio" id="part_time"  value="Part Time" />
                                                        <label htmlFor="part_time" > <small className="ml-1">Part Time</small></label>
                                                    </div>
                                                    <div className="form-group mx-2">
                                                        <input name="job_nature" onChange={e => setForm({...form,job_nature:e.target.value})}  checked={form.job_nature === 'Contractual'}    type="radio" id="Contractual"  value="Contractual" />
                                                        <label htmlFor="Contractual" > <small className="ml-1">Contractual</small></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="desc">Description </label>
                                                <Editor
                                                    editorState={form?.description}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={e => setForm({...form,description:e})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="desc">Requirements </label>
                                                <Editor
                                                    editorState={form?.requirements}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={e => setForm({...form,requirements:e})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="desc">Qualification </label>
                                                <Editor
                                                    editorState={form?.qualification}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={e => setForm({...form,qualification:e})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="desc">Instruction </label>
                                                <Editor
                                                    editorState={form?.instruction}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={e => setForm({...form,instruction:e})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="desc">Benefits </label>
                                                 <textarea value={form?.other_benefits} onChange={e => setForm({...form,other_benefits:e.target.value})} className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div className="form-group">
                                                <label htmlFor="rank">Gender </label>
                                                <div className="d-flex ">
                                                    <div className="form-group mx-2">
                                                        <input name="gender" onChange={e => setForm({...form,gender:e.target.value})} checked={form.gender === 'Male'}   type="radio" id="Male"  value="Male" />
                                                        <label htmlFor="Male" > <small className="ml-1">Male</small></label>
                                                    </div>
                                                    <div className="form-group mx-2">
                                                        <input name="gender" onChange={e => setForm({...form,gender:e.target.value})}   checked={form.gender === 'Female'}  type="radio" id="Female"  value="Female" />
                                                        <label htmlFor="Female" > <small className="ml-1">Female</small></label>
                                                    </div>
                                                    <div className="form-group mx-2">
                                                        <input name="gender" onChange={e => setForm({...form,gender:e.target.value})}  checked={form.gender === 'All'}    type="radio" id="All"  value="All" />
                                                        <label htmlFor="All" > <small className="ml-1">All</small></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="desc">Age Limit </label>
                                                 <div className="row">
                                                     <div className="col-12 col-md-6">
                                                         <label htmlFor="desc"><small>Minimum Age Limit</small></label>
                                                         <select style={{maxWidth: '100%'}}
                                                                 onChange={e => setForm({...form,min_age:e.target.value})}
                                                                 className="form-control"
                                                         >
                                                             <option  value="">Any  </option>
                                                             {Array.from(Array(63).keys(),x => x+18).map((item,index) => (
                                                                 <option key={index} value={item}>{item}</option>
                                                             ))}
                                                         </select>
                                                     </div>
                                                     <div className="col-12 col-md-6">
                                                         <label htmlFor="desc"><small>Maximum Age Limit</small></label>
                                                         <select style={{maxWidth: '100%'}}
                                                                 onChange={e => setForm({...form,max_age:e.target.value})}
                                                                 className="form-control"
                                                         >
                                                             <option  value="">Any  </option>
                                                             {Array.from(Array(63).keys(),x => x+18).map((item,index) => (
                                                                 <option key={index} value={item}>{item}</option>
                                                             ))}
                                                         </select>
                                                     </div>
                                                 </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="desc">Experience </label>
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="desc"><small>Minimum Experience</small></label>
                                                        <select style={{maxWidth: '100%'}}
                                                                onChange={e => setForm({...form,min_experience:e.target.value})}
                                                                className="form-control"
                                                        >
                                                            <option  value="">Any  </option>
                                                            {Array.from(Array(40).keys(),x => x+1).map((item,index) => (
                                                                <option key={index} value={item}>{item}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="desc"><small>Maximum Experience</small></label>
                                                        <select style={{maxWidth: '100%'}}
                                                                onChange={e => setForm({...form,max_experience:e.target.value})}
                                                                className="form-control"
                                                        >
                                                            <option  value="">Any  </option>
                                                            {Array.from(Array(40).keys(),x => x+1).map((item,index) => (
                                                                <option key={index} value={item}>{item}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 ">
                                            <div className="form-group">
                                                <label htmlFor="rank">Salary </label>
                                                <div className="d-flex ">
                                                    <div className="form-group mx-2">
                                                        <input name="salary" onChange={e => setForm({...form,salary:e.target.value})} checked={form.salary === 'Negotiable'}   type="radio" id="Negotiable"  value="Negotiable" />
                                                        <label htmlFor="Negotiable" > <small className="ml-1">Negotiable</small></label>
                                                    </div>
                                                    <div className="form-group mx-2">
                                                        <input name="salary" onChange={e => setForm({...form,salary:e.target.value})}   checked={form.salary === ''}  type="radio" id="not_mention"  value="" />
                                                        <label htmlFor="not_mention" > <small className="ml-1">Don't want to mention</small></label>
                                                    </div>
                                                    <div className="form-group mx-2">
                                                        <input name="salary" onChange={e => setForm({...form,salary:e.target.value})}  checked={form.salary !== '' && form.salary !== 'Negotiable'}    type="radio" id="salaryrange"  value="0" />
                                                        <label htmlFor="salaryrange" > <small className="ml-1">Salary Range</small></label>
                                                    </div>
                                                </div>
                                                {form.salary === '0' || form.salary !== '' && form.salary !== 'Negotiable' ? (
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6">
                                                                    <input type="number" className="form-control"    onChange={e => setForm({...form,min_salary:e.target.value})} />
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <input type="number" className="form-control"    onChange={e => setForm({...form,max_salary:e.target.value})} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                ) : ''}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="desc"><small>Publication Status</small></label>
                                            <select style={{maxWidth: '100%'}}
                                                    onChange={e => setForm({...form,status:e.target.value})}
                                                    className="form-control"
                                            >
                                                <option  value="">Select </option>
                                                <option value="1">Publish</option>
                                                <option value="0">Unpublish</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mx-2">
                                                <label htmlFor="file" > <small className="ml-1">Document File</small></label>
                                                <input name="file" className="form-control" onChange={e => setForm({...form,job_file:e.target.files[0]})}     type="file" id="file"   />
                                            </div>
                                        </div>






                                        <div className="col-12">
                                            <button type="submit" className="btn btn-info">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditJob;