import {useEffect} from "react";


const UseDatatable = () => {
    useEffect(() => {
        //data table
        const dataTable = document.createElement('script');
        dataTable.src = `../../js/datatable.js`;
        dataTable.async = true;
        document.body.appendChild(dataTable)
    },[])
};

export default UseDatatable;