import React from 'react';

const UserInfo = ({userData}) => {
    return (
        <>
            <img src={userData?.image_url} alt="" />
            <div>
                <h2>{userData?.name}</h2>
                <h3>
                    {userData?.rank}
                    {
                        userData?.cdc_number !== '' ? <span className="cdc-number">({userData?.cdc_number})</span> : ''
                    }
                </h3>
            </div>
        </>
    );
};

export default UserInfo;