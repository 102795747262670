import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import axiosClient from "../../../axios-client";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";

const IndividualVesselBudget = () => {
    const [reportData,setReportData] = useState([]);


    const getReportData = () => {
        axiosClient.get('/company/individual-vessel-budget')
            .then(({data}) => {
                setReportData(data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/individual-vessel-budget/print',
    });

    const columns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Vessel', key: 'vessel_name', width: 20 },
        { header: 'Title', key: 'title', width: 20 },
        { header: 'Budget', key: 'budget', width: 20 },
        { header: 'Expense', key: 'expense', width: 20 },
        { header: 'Variance', key: 'variance', width: 20 },
        { header: 'Remarks', key: 'remarks', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/individual-vessel-budget/export')
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Individual Vessel Budget','individual-vessel--budget',columns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/individual-vessel-budget/export' )
            .then(({data}) => {
                UseExportHook( 'csv',data,'Individual Vessel Budget','individual-vessel--budget',columns)
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Individual Vessel Budget'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-md-8 col-12">

                                        </div>
                                        <div className="col-md-4 col-12 ">
                                            <div className="float-right">
                                                <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Vessel </th>
                                            <th>Title</th>
                                            <th>Year</th>
                                            <th>Budget </th>
                                            <th>Expense </th>
                                            <th>Variance </th>
                                            <th>Reamrks </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData?.data?.length > 0  ? reportData?.data?.map((item,index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{item?.vessel_name}</td>
                                                        <td>{item?.title}</td>
                                                        <td>{item?.budget_year}</td>
                                                        <td>{item?.budget}</td>
                                                        <td>{item?.expense}</td>
                                                        <td>{item?.variance}</td>
                                                        <td> </td>
                                                    </tr>
                                                )
                                            }) : ''
                                        }



                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default IndividualVesselBudget;