import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {Link} from "react-router-dom";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";

const VesselList = () => {
    const [reportData,setReportData] = useState([]);


    const getReportData = (dateFromData = null) => {
        axiosClient.get('/company/vessel')
            .then(({data}) => {
                setReportData(data?.data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete this job post?`)){
            return false;
        }
        axiosClient.delete(`/company/vessel/${data?.vessel_id}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData();
            })
    }

    return (
        <>
            <Breadcrumb title='Vessel' addnew={true} route={COMPANYROUTE.ADD_NEW_VESSEL}    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">


                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Vessel Image</th>
                                            <th>Vessel Name</th>
                                            <th>Vessel Type</th>
                                            <th>DWT</th>
                                            <th>GRT</th>
                                            <th>Engine Type</th>
                                            <th>BHP</th>
                                            <th>IMO Number</th>
                                            <th>Flag State</th>
                                            <th>Lifeboat Capacity</th>
                                            <th>Total Crew</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>


                                        {
                                            reportData?.length > 0  ? reportData?.map((item,index) => {
                                                return(
                                                    <tr key={index+1}>
                                                        <td>{index+1}</td>
                                                        <td><img src={item?.image}  width="100px" height="100px" alt=""/></td>
                                                        <td>{item?.vessel_name}</td>
                                                        <td>{item?.vessel_type_name}</td>
                                                        <td>{item?.dwt_number}</td>
                                                        <td>{item?.grt_number}</td>
                                                        <td>{item?.engine_type}</td>
                                                        <td>{item?.bhp_number}</td>
                                                        <td>{item?.imo_number}</td>
                                                        <td>{item?.flag_state}</td>
                                                        <td>{item?.capacity}</td>
                                                        <td>{item?.total_crews}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-info">Action</button>
                                                                <button type="button"
                                                                        className="btn btn-info dropdown-toggle dropdown-icon"
                                                                        data-toggle="dropdown">
                                                                    <span className="sr-only">Toggle Dropdown</span>
                                                                </button>
                                                                <div className="dropdown-menu" role="menu">
                                                                    <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.EDIT_VESSEL}${item?.vessel_id}`}>EDIT</Link>
                                                                    <button  onClick={e => onDelete(item)}   className="dropdown-item text-sm"  >Delete</button>
                                                                    <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.VESSEL_CREW_LIST}${item?.vessel_id}`}>Crew List</Link>
                                                                    <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.VESSEL_CREW_PLANNER}${item?.vessel_id}`}>Crew Planner</Link>
                                                                    <Link className="dropdown-item text-sm" to={`${COMPANYROUTE.VESSEL_BUDGET}${item?.vessel_id}`}>Budget</Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''
                                        }



                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VesselList;