import {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";

const AllRank = ({rankSelect,setRankSelect}) => {

       const [ranks,setRanks] = useState([]);

    const getRankData = () => {
        axiosClient.get('/company/get-top-four-ranks')
            .then(({data}) => {
                setRanks(data.data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getRankData()
    },[])

    return (
        <div className='form-group'>
            <select style={{maxWidth: '100%'}}
                    onChange={e => setRankSelect(e.target.value)}
                    value={rankSelect}
                    className="form-control"
            >
                <option  value="0" selected>All Ranks</option>
                {ranks.map((rank,index) => (
                    <option key={index} value={rank?.category_id}>{rank?.category_name}</option>
                ))}

            </select>
        </div>
    );
};

export default AllRank;