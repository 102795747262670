import React from 'react';
import {Link} from "react-router-dom";
import {useStateContext} from "../../context/ContextProvider";

const DocumentList = ({state,onDownload,onDelete,EditRoute}) => {
    const {userType} =  useStateContext()

    console.log(userType)

    return (
        <table id="" className="table table-bordered table-hover seafarer-list ">
            <thead >
            <tr>
                <th>Document type</th>
                <th>Document title</th>
                <th>File name</th>
                <th>Document File</th>
            </tr>
            </thead>
            <tbody>
            {state.loading ? "Loading" : ""}

            {state?.data?.length > 0 ?  state?.data?.map((data,index) => (
                        userType === 'seafarer' && data?.certification_title === 'Confidential Report' ? '' :
                            <>
                                <tr key={index}>
                                    <td>{data?.document_type}</td>
                                    <td>{data?.certification_title}</td>
                                    <td>{data?.title}</td>
                                    <td>
                                        <Link to={`${EditRoute}${data?.emp_doc_id}`} className="btn btn-info btn-sm ml-1"><i className="fas fa-pencil-alt"></i></Link>
                                        <a href={data?.doc_url} target="_blank" className="btn btn-primary btn-sm ml-1"><i className="fas fa-eye"></i></a>
                                        {/*<button  onClick={e => onDownload(data)}   className="btn btn-warning btn-sm ml-1 text-white"  ><i className="fas fa-download"></i></button>*/}
                                        <button  onClick={e => onDelete(data)}   className="btn btn-danger btn-sm ml-1 "  ><i className="fas fa-trash"></i></button>

                                    </td>


                                </tr>
                            </>
            )) : <tr><td colSpan={4} className='text-center'>No Data Found</td></tr>}

            {state.error ? <tr><td colSpan={13} className='text-center text text-danger'>Something Went Wrong!</td></tr> : '' }

            </tbody>

        </table>

    );
};

export default DocumentList;