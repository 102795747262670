import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import TrainingInfoForm from "../../../../Components/Profile/OtherInfo/TrainingInfoForm";

const TrainingInfo = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disable,setDisable] = useState(false)
    const [form,setForm] = useState({
        user_id:id,
        others_training_id:0,
        ecdis:'',
        ecdis_type:'',
        ecdis_details:'',
        bridge_status:'',
        bridge_issue:'',
        bridge_expiry:'',
        bridge_resource:'',
        bridge_resource_issue:'',
        bridge_resource_expiry:'',
        ship_handling:'',
        cargo_handling:'',
    });

    const getData = () => {
        axiosClient.get('/company/training-information/'+id)
            .then(({data}) => {
                 if (data.data !== null) {
                     setForm(data.data)
                 }
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }


    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.post('/company/save-training-information', form)
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(`${COMPANYROUTE.USER_PROFILE}${id}`, {state: {type: 'others'}});
                },1000);
                setDisable(false)

            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

      useEffect(() => {
        getData();
      },[])

    return (
        <>
            <Breadcrumb title='Training Information' prev={`${COMPANYROUTE.USER_PROFILE}${id}`} state="others"   />
            <section className="content">
                <div className="container-fluid">
                    <div className=" col-12">
                        <form onSubmit={onSubmit}>
                            <TrainingInfoForm form={form} setForm={setForm} disable={disable} />
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TrainingInfo;