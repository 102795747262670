
import React, {useEffect, useReducer, useState} from "react";
import '../styles/style.css'
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";
import LoadingBar from 'react-top-loading-bar';
import {Link} from "react-router-dom";
import {
    ACTION_TYPES,
    BudgetHeadReducer,
    INITIAL_STATE,
} from "../../../../reducers/Company/BudgetHeadReducer";
import axiosClient from "../../../../axios-client";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";

const BudgetHead = () => {
    const [progress, setProgress] = useState(10);

    const [state,dispatch] = useReducer(BudgetHeadReducer,INITIAL_STATE);

    const getReportData = (pageNumber = 1) => {
        setProgress(50)
        dispatch({type:ACTION_TYPES.GET_BUDGET_HEAD_DATA});
        axiosClient.get(`/company/budget-head/?page=${pageNumber}`)
            .then(({data}) => {
                dispatch({type:ACTION_TYPES.GET_BUDGET_HEAD_DATA_SUCCESS,payload:data.data});
                setProgress(100);

            }).catch(() => {
            dispatch({type:ACTION_TYPES.GET_BUDGET_HEAD_DATA_ERROR});

        })
    }

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete this job post?`)){
            return false;
        }
        axiosClient.delete(`/company/budget-head/${data?.budget_head_id}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData(state.pageNumber);
            })
    }



    useEffect(() => {
        getReportData(1)
    },[])









    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Budget Head'    addnew={true}  route={COMPANYROUTE.ADD_BUDGET_HEAD}     />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className=" table-responsive new-card-body"  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Budget Head</th>
                                            <th>Action</th>
                                            <th>Budget Sub Head</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {state.loading ? "Loading" : ""}

                                        {state?.data?.data?.length > 0 ?  state?.data?.data?.map((data,index) => (
                                            <tr key={index}>
                                                <td>{data?.name}</td>
                                                <td>
                                                    <Link to={`${COMPANYROUTE.ADD_BUDGET_SUB_HEAD}${data?.budget_head_id}`}    className="btn btn-primary btn-sm ml-1"><i className="fa fa-plus-circle"></i></Link>
                                                    <Link to={`${COMPANYROUTE.EDIT_BUDGET_HEAD}${data?.budget_head_id}`} className="btn btn-info btn-sm ml-1"><i className="fas fa-pencil-alt"></i></Link>
                                                    <button  onClick={e => onDelete(data)}   className="btn btn-danger btn-sm ml-1 "  ><i className="fas fa-trash"></i></button>
                                                </td>
                                                <td>
                                                    <table className="w-100" >
                                                        {
                                                            data?.child.length > 0 && data?.child.map((sub_data,index) => (
                                                                <tr key={index}>
                                                                    <td >{sub_data?.name}</td>
                                                                    <td >
                                                                        <Link to={`${COMPANYROUTE.EDIT_BUDGET_HEAD}${sub_data?.budget_head_id}`} className="btn btn-info btn-sm ml-1"><i className="fas fa-pencil-alt"></i></Link>
                                                                        <button  onClick={e => onDelete(sub_data)}   className="btn btn-danger btn-sm ml-1 "  ><i className="fas fa-trash"></i></button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </table>
                                                </td>


                                            </tr>
                                        )) : <tr><td colSpan={4} className='text-center'>No Data Found</td></tr>}

                                        {state.error ? <tr><td colSpan={13} className='text-center text text-danger'>Something Went Wrong!</td></tr> : '' }

                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">

                                        <Pagination
                                            activePage={state?.data?.current_page ? state?.data?.current_page : 0}
                                            itemsCountPerPage={state?.data?.per_page ? state?.data?.per_page : 0 }
                                            totalItemsCount={state?.data?.total ? state?.data?.total : 0}
                                            onChange={(pageNumber) => {
                                                getReportData(pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
};

export default BudgetHead;