import {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";

const AllVessel = ({vesselSelect,setVesselSelect}) => {

    const [vessels,setVessels] = useState([]);

    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessels(data.data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getVesselData()
    },[])
    return (
        <div className='form-group'>
            <select style={{maxWidth: '100%'}}
                    onChange={e => setVesselSelect(e.target.value)}
                    value={vesselSelect}
                    className="form-control"
            >
                <option  value="0" selected>All Vessel</option>
                {vessels.map((vessel,index) => (
                    <option key={index} value={vessel?.vessel_id}>{vessel?.vessel_name}</option>
                ))}

            </select>
        </div>
    );
};

export default AllVessel;