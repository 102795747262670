import React from 'react';
import {Link, useParams} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import FamilyList from "../../Profile/Family/FamilyList";

const Family = ({familyData}) => {
    const {id} = useParams();

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete?`)){
            return false;
        }
        axiosClient.delete(`/company/family/${data?.family_id}`)
            .then((data) => {
                toast.success(data.data.message);
                setTimeout(() => {
                    window.location.reload();
                },1000)
            })
    }

    return (
        <>
            <div className="col-12 ">
                <div className="float-right">
                    <Link to={`${COMPANYROUTE.ADD_NEW_FAMILY}${id}`}  className="btn btn-success mb-2">Add New <i className="fa fa-plus-circle"></i></Link>
                </div>
            </div>

            <FamilyList familyData={familyData} onDelete={onDelete} route={COMPANYROUTE.FAMILY_EDIT} />
        </>
    );
};

export default Family;