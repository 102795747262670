import {Link, useNavigate} from "react-router-dom";
import * as COMPANYROUTE from '../../../constants/companyRoutes'
import React from "react";

const Breadcrumb = ({title,addnew,route,prev,backButton = false,state = null}) => {
    const navigate = useNavigate();
    return (
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6 d-flex">
                        {
                            backButton ? (
                                <a className="btn btn-outline-light mr-2"  onClick={() => navigate(-1)}> <i className="fas fa-arrow-left"></i></a>
                            ) : null
                        }
                        {
                            prev ? (
                                <Link className="btn btn-outline-light mr-2" state={{type:state}} to={prev}>
                                    <i className="fas fa-arrow-left"></i>
                                </Link>
                            ) : null
                        }

                        <h5 className="m-0">{title}</h5>
                        {
                            addnew ?  <Link to={route} className="btn btn-success btn-sm ml-2 p-2" title={'ADD New'}>Add New </Link>  : null
                        }
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link  to={COMPANYROUTE.DASHBOARD}>Home</Link></li>
                            <li className="breadcrumb-item active">{title} </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumb;