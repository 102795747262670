import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axiosClient from "../../../axios-client";

am4core.useTheme(am4themes_animated);
const DurationComparison = ({vessels}) => {
    const [contractDuration,setContractDuration] = useState([]);
    const [currentDuration,setCurrentDuration] = useState([]);
    const [durationContractLabel,setDurationContractLabel] = useState([]);
    const [vesselSelect,setVesselSelect] = useState('');


    const getTopFourContractData = (vessel = 1) => {
        axiosClient.post('/company/chart/duration-comparison',{
            vessel_id: vessel,
        })
            .then(({data}) => {
                setContractDuration(data.data[0])
                setCurrentDuration(data.data[1])
                setDurationContractLabel(data.data[2])
            }).catch(() => {
        })
    }

    useEffect(() => {
        getTopFourContractData(vesselSelect)
    },[vesselSelect])

    useLayoutEffect(() => {
        let chart = am4core.create("DurationComparisonChart",  am4charts.XYChart);
        chart.logo.disabled = true;
        chart.data = [{
            "name": "Master",
            "toolTip": "Master (" + durationContractLabel[0]?.first_name + " " + durationContractLabel[0]?.last_name + ")",
            "contract": contractDuration[0],
            "current": currentDuration[0],
        },{
            "name": "Chief Engineer",
            "toolTip": "Chief Engineer (" + durationContractLabel[1]?.first_name + " " + durationContractLabel[1]?.last_name + ")",
            "contract": contractDuration[1],
            "current": currentDuration[1],
        },{
            "name": "Chief Officer",
            "toolTip": "Chief Officer (" + durationContractLabel[2]?.first_name + " " + durationContractLabel[2]?.last_name + ")",
            "contract": contractDuration[2],
            "current": currentDuration[2],
        },{
            "name": "2nd Engineer",
            "toolTip": "2nd Engineer (" + durationContractLabel[3]?.first_name + " " + durationContractLabel[3]?.last_name + ")",
            "contract": contractDuration[3],
            "current": currentDuration[3],
        } ];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;

        var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "[bold] Duration [/]";

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "contract";
        series.dataFields.categoryX = "name";
        series.name = "Duration as per Contract";
        series.tooltipText = "Duration as per Contract: [bold]{valueY}[/]\n {toolTip} ";
        series.columns.template.fill = am4core.color("#00a65a");


        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "current";
        series2.dataFields.categoryX = "name";
        series2.name = "Current Duration On Board";
        series2.tooltipText = "Current Duration On Board : [bold]{valueY}[/] \n {toolTip}";
        series2.columns.template.fill = am4core.color("#dd4b39");

        chart.cursor = new am4charts.XYCursor();

        chart.legend = new am4charts.Legend();



        return () => {
            chart.dispose();
        };
    }, [contractDuration,currentDuration,durationContractLabel]);

    return (
        <>
            <div className="card card-primary">
                <div className="card-header">
                    <h3 className="card-title">Duration Comparison Chart </h3>

                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <select style={{maxWidth: '100%',background:'navy',color:'white'}}
                                    onChange={e => setVesselSelect(e.target.value)}
                                    value={vesselSelect}
                                    className="form-control"
                            >
                                {vessels.map((vessel,index) => (
                                    <option key={index} value={vessel?.vessel_id}>{vessel?.vessel_name}</option>
                                ))}

                            </select>
                        </div>

                    </div>
                    <div className="chart">
                        <div id="DurationComparisonChart" style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default DurationComparison;

