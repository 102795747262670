import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import useDatePicker from "../../../hooks/useDatePicker";
import axiosClient from "../../../axios-client";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
am4core.useTheme(am4themes_animated);
const SignOnOffChart = ({vessels,ranks}) => {
    const [signOnOffData,setSignOnOffData] = useState([]);
    const [vesselSelect,setVesselSelect] = useState('');
    const [rankSelect,setRankSelect] = useState('');
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));



    const getSignOnOffChartData = (vessel = 0,rank = 0,dateFromData = null,dateToData = null) => {
        axiosClient.post('/company/chart/sign-on-off',{
            vessel_id: vessel,
            category_id: rank,
            date_from: dateFromData,
            date_to: dateToData
        })
            .then(({data}) => {
                setSignOnOffData(data.data)
            }).catch(() => {
        })
    }

   useEffect(() => {
       let dateFromData = new Date(dateFrom).toLocaleDateString();
       let dateToData = new Date(dateTo).toLocaleDateString();
       getSignOnOffChartData(vesselSelect,rankSelect,dateFromData,dateToData)
   },[vesselSelect,rankSelect,dateFrom,dateTo])

    useLayoutEffect(() => {
        let x = am4core.create("signOnOffChartDiv", am4charts.PieChart);
        x.logo.disabled = true;

        x.innerRadius = am4core.percent(40);

        x.data = [{
            "label": "Sign On",
            "value": signOnOffData[0],
            "color": am4core.color("#00a65a")
        }, {
            "label": "Sign Off",
            "value":signOnOffData[1],
            "color": am4core.color("#dd4b39")
        }];




        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "label";
        pieSeries.slices.template.propertyFields.fill = "color";

        pieSeries.slices.template.tooltipText = "{category}:{value}";

        //pie series label disable if value is 0
        pieSeries.labels.template.adapter.add("text", function(text, target) {
            return target.dataItem.value > 0 ? text : "";
        });

        let label = pieSeries.labels.template;
        label.text = "{category}:{value}";

        x.legend = new am4charts.Legend();
        pieSeries.legendSettings.valueText = " ";
        x.legend.itemContainers.template.maxWidth = 100;





        return () => {
            x.dispose();
        };
    }, [signOnOffData]);

    return (
        <>
            <div className="card card-success">
                <div className="card-header">
                    <h3 className="card-title">Monthly Sign On Off Chart</h3>

                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus"></i>
                        </button>

                    </div>
                </div>
                <div className="card-body">
                        <div className="row">
                            <div className="col-md-3 mt-1">
                                <select style={{maxWidth: '100%',background:'navy',color:'white'}}
                                    onChange={e => setVesselSelect(e.target.value)}
                                    value={vesselSelect}
                                    className="form-control"
                                >
                                    <option  value="0" selected>All Vessel</option>
                                    {vessels.map((vessel,index) => (
                                        <option key={index} value={vessel?.vessel_id}>{vessel?.vessel_name}</option>
                                    ))}

                                </select>
                            </div>
                            <div className="col-md-3 mt-1">
                                <DatePicker
                                    selected={dateFrom}
                                    dateFormat='dd-MM-yyyy'
                                    className="form-control"
                                    onChange={(date) => setDateFrom(date)}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                            <div className="col-md-3  mt-1">
                                <DatePicker
                                    selected={dateTo}
                                    dateFormat='dd-MM-yyyy'
                                    className="form-control"
                                    onChange={(date) => setDateTo(date)}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                            <div className="col-md-3 mt-1">
                                <select style={{maxWidth: '100%',background:'navy',color:'white'}}
                                        onChange={e => setRankSelect(e.target.value)}
                                    value={rankSelect}
                                    className="form-control"
                                >
                                    <option  value="0" selected>All Ranks</option>
                                    {ranks.map((rank,index) => (
                                        <option key={index} value={rank?.category_id}>{rank?.category_name}</option>
                                    ))}

                                </select>
                            </div>
                        </div>

                    <div className="chart">
                        <div id="signOnOffChartDiv" style={{ width: "100%", height: "500px" }}></div>

                    </div>
                </div>
            </div>
        </>
    )

};

export default SignOnOffChart;

