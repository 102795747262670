import React, {useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import {useNavigate, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as UserRoute from "../../../constants/UserRoute";

const UploadPerformance = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [evaluationDate,setEvaluationDate] = useState("");
    const [file,setFile] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        let evDate = "";
        if (evaluationDate === "" || evaluationDate === null) {
            toast.error('Please select evaluation date');
            return;
        }else{
              evDate =  new Date(evaluationDate).toLocaleDateString();
        }
        if(file === ""){
            toast.error('Please   select performance file');
            return;
        }

        axiosClient.post('/seafarer/performance-evaluation-update',{
            doc_file:file,
            evaluation_date:evDate,
        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(UserRoute.USER_PROFILE, { state: { type:'personalInfo' } });
                },1500);
            }).catch(err => {
            const response = err.response;

            if(response && response.status === 422){
                if(response.data?.errors){
                    toast.error(response.data?.errors?.doc_file[0]);
                    toast.error(response.data?.errors?.evaluation_date[0]);
                }
            }
        })


    }

    return (
        <div>
            <Breadcrumb title='Upload Performance' prev={UserRoute.USER_PROFILE}   state="personalInfo"    />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-12 col-md-6">

                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="rank">Evaluation Date</label>
                                        <DatePicker
                                            selected={evaluationDate}
                                            dateFormat='dd-MM-yyyy'
                                            className="form-control"
                                            onChange={(date) => setEvaluationDate(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="upload_file" className="btn btn-info">Upload Performance File</label>
                                    <input type="file" onChange={(e) => setFile(e.target.files[0])} className="d-none" id="upload_file" name="performance_file"/>
                                </div>

                                <div className="col-6 float-left">
                                     <button type="submit" className="btn btn-success btn-block  ">Save</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default UploadPerformance;