import React, {useEffect, useState} from 'react';
import Logo from "../../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import './../styles/checklist.css'
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import {Link, useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../../hooks/usePrintWindow";
const CompanyPolicy = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [data, setData] = useState({})

    const navigate = useNavigate();

    const getData = () => {

        axiosClient.get('/company/medical-history-questionnaire/show/'+id)
            .then(({data}) => {
                console.log(data)
                if(data  == 0){
                    toast.error('Pre Employee Medical not found')
                    navigate(-1)
                }
                setData(data.data)
                setProgress(100)
            }).catch(() => {


        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/medical-history-questionnaire/print/' + id,
    });
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Medical History Questionnarie'   backButton={true}    />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-10 offset-md-1">
                                    <div className="checklist-panel">


                                        <div className="justify-content-center d-flex mb-2">
                                    <div className="d-flex">
                                        <div className="logo">
                                            <Logo />
                                        </div>
                                        <div className="header-text">
                                            <h5 className="text-center company-name">{data?.company_name}</h5>
                                            <h6 className="text-center title">Medical History Questionnarie</h6>

                                        </div>
                                    </div>
                                </div>
                                        <div className="content-body">
                                            <div className="row">
                                                <div className="col-md-8 col-12">
                                                    <table className="table table-bordered">
                                                          <tr>
                                                              <td><strong>Name:</strong> {data?.name}</td>
                                                              <td ><strong> Date Of Birth:</strong> {data?.dob}</td>
                                                              <td><strong>  Job Title: </strong>  {data?.rank}</td>
                                                          </tr>
                                                        <tr>
                                                            <td colSpan='2'><strong>Address:</strong> {data?.address}</td>
                                                            <td><strong> Seaman Certificate No: </strong> {data?.certificate_no}</td>
                                                        </tr>
                                                        <tr>
                                                            <td  colSpan={2}><strong>  Employer </strong> {data?.company_short_name}</td>
                                                            <td ><strong>Phone:</strong>  {data?.mobile}</td>

                                                        </tr>
                                                        <tr>
                                                            <td><strong>  In Emergency Notify:</strong> {data?.kin_name}   </td>
                                                            <td><strong>Relationship:</strong> {data?.relation}   </td>
                                                            <td><strong>Phone:</strong>{data?.kin_telephone}   </td>

                                                        </tr>
                                                        <tr>
                                                            <td><strong>  Personal Physician or Clinic:</strong> {data?.physician_name}   </td>
                                                            <td><strong> Physician Address:</strong> {data?.physician_address}   </td>
                                                            <td><strong> Physician Phone:</strong> {data?.physician_phone}   </td>

                                                        </tr>


                                                    </table>

                                                    <table className="table table-bordered">
                                                        <tr>
                                                            <td><strong>   Ultrasound of Liver & Kidney:</strong> {data?.ultrasound}    </td>
                                                            <td><strong>    Hepatitis B:</strong> {data?.hepatitis_b}    </td>
                                                            <td><strong>   HIV:</strong>  {data?.hiv}   </td>
                                                            <td><strong>  SGPT&SGOT:</strong>{data?.sgpt_sgot}   </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>   Fasting Blood Suger:</strong> {data?.blood_sugar}  </td>
                                                            <td><strong>    Allergies:</strong>  {data?.allergies}   </td>
                                                            <td><strong>   HbA1c:</strong>   {data?.hba1c}  </td>
                                                            <td><strong> Alchohol/Drug Test:</strong> {data?.alcohol_drug_test}    </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className="col-md-4 col-12 justify-content-center">
                                                    <img src={data?.image_url} width="120" height="120" alt=""/>
                                                    <p>Signature</p>
                                                    <img src={data?.signature_url} width="120" height="60" alt=""/>
                                                     <p>Date: {data?.date}</p>
                                                </div>

                                                <div className="col-md-7 col-12">
                                                    <h6> Family History Has any one in your family ever had:</h6>
                                                    <table className="table table-bordered">
                                                        <tr>
                                                            <td>Diabetes: {data?.family_diabetes}  {data?.family_diabetes_details}</td>
                                                            <td>Heart  Disease: {data?.family_heart_disease}  {data?.family_heart_disease_details}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>High Blood Pressure: {data?.family_high_blood_pressure}  {data?.family_high_blood_pressure_details}</td>
                                                            <td>Cancer: {data?.family_cancer}  {data?.family_cancer_details}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mental  Illness: {data?.family_mental_illness}  {data?.family_mental_illness_details}</td>
                                                            <td>Epilepsy  /Seizure: {data?.family_epilepsy_seizure}  {data?.family_epilepsy_seizure_details}</td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={2}>Any other major conditions?: {data?.any_other_major_condition}  {data?.any_other_major_condition_details}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>Males Only</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>Penile Discharge: {data?.prostate_problem} {data?.prostate_problem_details}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>Prostate Problems: {data?.penile_discharge} {data?.penile_discharge_details}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>Testicular  Lumps: {data?.testicular_lumps} {data?.testicular_lumps_details}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>Are you currently under a doctor's care?: {data?.any_doctor_care} </td>
                                                        </tr>
                                                        {
                                                            data?.any_doctor_care == 'Yes' && (
                                                               <>
                                                                   <tr>
                                                                       <td colSpan={2}>what problem(s)?: {data?.whats_problem} </td>
                                                                   </tr>
                                                                   <tr>
                                                                       <td>Physician(s) Name: {data?.doctor_care_physician_name} </td>
                                                                       <td>Physician(s) Address: {data?.doctor_care_physician_address} </td>
                                                                   </tr>
                                                               </>
                                                            )
                                                        }

                                                        <tr>
                                                            <td colSpan={2}>History of surgeries / hospitalizations: {data?.history_of_surgery} </td>
                                                        </tr>
                                                        {
                                                            data?.history_of_surgery == 'Yes' && (
                                                                <>
                                                                    <tr>
                                                                        <td>Date: {data?.surgery_date} </td>
                                                                        <td>Details: {data?.surgery_details} </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }

                                                        <tr>
                                                            <td colSpan={2}>Are you presently on any medication: {data?.presentyly_any_medication} </td>
                                                        </tr>
                                                        {
                                                            data?.presentyly_any_medication == 'Yes' && (
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={2}>Details: {data?.presentyly_any_medication_details} </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }

                                                        <tr>
                                                            <td colSpan={2}>Do you or Did you smoke?: {data?.do_smoke} </td>
                                                        </tr>
                                                        {
                                                            data?.do_smoke == 'Yes' && (
                                                                <>
                                                                    <tr>
                                                                        <td>How Long?: {data?.how_long} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Packs per day?: {data?.packer_per_day} </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }

                                                        <tr>
                                                            <td colSpan={2}>Do you use alcoholic beverages?: {data?.alcholic_bevarage} </td>
                                                        </tr>

                                                        {
                                                            data?.alcholic_bevarage == 'Yes' && (
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={2}>How Long?: {data?.alcholic_bevarage_details} </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }

                                                        <tr>
                                                            <td colSpan={2}>Do you use or   take any drug?: {data?.any_drug} </td>
                                                        </tr>

                                                        {
                                                            data?.any_drug == 'Yes' && (
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={2} >Details: {data?.any_drug_details} </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }

                                                        <tr>
                                                            <td colSpan={2}>Health Condition: {data?.health_condition} </td>
                                                        </tr>



                                                    </table>
                                                </div>
                                                <div className="col-md-5 col-12">
                                                    <h6>Do you have or have recelved treatment for the following: </h6>
                                                    <table className="table table-bordered">
                                                        <tr>
                                                            <td>Diabetes:{data?.diabetes}</td>
                                                            <td>Heart Trouble:{data?.heart_trouble}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>High Blood Pressure:{data?.high_blood_pressure}</td>
                                                            <td>Shortness of Breath:{data?.shortness_breath}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chest Pain:{data?.chest_pain}</td>
                                                            <td>Chronic Cough:{data?.chronic_cough}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Asthma:{data?.asthma}</td>
                                                            <td>Tuberculosis:{data?.tuberculosis}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rheumatic Fever:{data?.rheumatic_fever}</td>
                                                            <td>Frequent Headaches:{data?.frequent_headaches}</td>
                                                        </tr>
                                                        <tr>
                                                        <td>Vision Problems:{data?.vision_problem}</td>
                                                        <td>20/20 Vision:{data?.twenty_twenty_vision}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hearing Problems:{data?.hearing_problem}</td>
                                                            <td>Epilepsy:{data?.epilepsy}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Jaundice or Hepatitis:{data?.jaundice}</td>
                                                            <td>Dizziness:{data?.dizziness}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Back Problems:{data?.back_problems}</td>
                                                            <td>Slipped Disc:{data?.slipped_disc}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Wrist Problems:{data?.wrist_problem}</td>
                                                            <td>Fractured Vertebrae:{data?.fractured_vertebrae}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Arthritis/Gout:{data?.arthritis}</td>
                                                            <td>Kidney Problems:{data?.kidney_problem}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Cancer/Tumor:{data?.cancer_tumor}</td>
                                                            <td>Rashor Skin Problem:{data?.rashor_skin_problem}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hernia/Hydrocele:{data?.hernia}</td>
                                                            <td>VaricoseVeins:{data?.varicose_veins}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Drug Problems:{data?.drug_problem}</td>
                                                            <td>Mental Breakdown:{data?.mental_breakdown}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>Psychological Impairment, Beperssionor Mental Illness:{data?.psychological_impairment}</td>

                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>Sexually Transmitted Disease:{data?.sexually_transmitted}</td>

                                                        </tr>

                                                    </table>


                                                </div>



                                            </div>

                                            <hr/>
                                            <div className="col-12">
                                                <p className="text-center"> I  hereby Declare that I have made full   disclosure of all of my medical history to the Doctor Clinic. I am aware that their formation supplied by forms the
                                                    basis upon which I will be offered employment as a Seafarer. I understand that in the event of any misrepresentation
                                                    either by statement or omission I will lose the right to benefit from sick pay and/or compensation which would
                                                    otherwise be due under the employers and/or the Owners and/or Insurance of the Vessel or their authorized
                                                    representatives.
                                                </p>
                                            </div>

                                      </div>

                                        <div className="d-flex justify-content-end">
                                            <p className="pt-3">
                                                Seafarer Signature:
                                            </p>
                                           <img src={data?.signature_url} width="120" height="60" alt=""/>
                                        </div>

                                 </div>


                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default CompanyPolicy;