import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import Logo from "../../../Components/Common/logo";
import DatePicker from "react-datepicker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";

const TopFourOfficerSalary = () => {
    var date = new Date();
    const [reportData,setReportData] = useState([]);

    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(),1));


    const [vessel,setVessel] = useState([]);
    const [vesselId,setVesselId] = useState(0);

    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessel(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    useEffect(() => {
        getVesselData();
    },[])

    const getData = (vesselId = 0, dateFrom = '') => {
        axiosClient.post('/company/accounts/top-four-salary',{
            vessel_id: vesselId,
            date_from: dateFrom
        })
            .then(({data}) => {
                setReportData(data)
            }).catch(() => {

        })
    }


    useEffect(() => {
        let dateFromData = dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1);
        getData(vesselId,dateFromData);
    },[vesselId,dateFrom])



    return (
        <>
            <Breadcrumb title='Top Four Officer Salary'      />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-4'>
                                                    <div className="form-group">
                                                        <select style={{maxWidth: '100%'}}
                                                                className="form-control"
                                                                value={vesselId}
                                                                onChange={(e) => setVesselId(e.target.value)}
                                                        >
                                                            <option value={0}>Select Vessel</option>
                                                            {vessel.map((data,index) => (
                                                                <option key={index}    value={data?.vessel_id}>{data?.vessel_name}</option>
                                                            ))}

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateFrom}
                                                        dateFormat='MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateFrom(date)}
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-1">
                                            <button className='btn btn-block  btn-success'>Print</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-8 offset-md-2">

                                <div className="card-body border ">

                                    <div className="justify-content-center d-flex">
                                        <div className="d-flex">
                                            <div className="logo">
                                                <Logo />
                                            </div>
                                            <div className="header text">
                                                <h5 className="text-center">Brave Royal Ship Management Ltd.</h5>
                                            </div>
                                        </div>

                                    </div>
                                    <hr/>

                                    <div className="row d-flex">
                                        <div className="mr-5">
                                            <h6>Ref:</h6>
                                            <h6>Date:</h6>
                                        </div>
                                        <div >
                                            <h6>SRSL/SALARY/FTM/  {reportData?.headerData?.report_date}</h6>
                                            <h6>{  new Date().toLocaleDateString() }</h6>
                                        </div>
                                    </div>

                                    <div className="justify-content-center text-center  mb-5">
                                        <h6 style={{textDecoration:'underline'}}>OFFICER SALARY FOR THE MONTH OF  {reportData?.headerData?.report_date} </h6>
                                        <h6 style={{textDecoration:'underline'}}> {reportData?.headerData?.vessel_name}</h6>
                                    </div>

                                    {
                                        reportData?.data?.length > 0 && reportData?.data?.map((data,index) => (
                                            <>
                                                <div className="row d-flex">
                                                    <div className="mr-5">
                                                        <p><strong> {index+1} ) USD</strong></p>
                                                        <p>NAME:</p>
                                                        <p>RANK:</p>
                                                        <p>A\C:</p>
                                                        <p>BRANCH:</p>
                                                        <p>SWIFT CODE:</p>
                                                    </div>
                                                    <div>
                                                        <p><strong> ${data?.amount_dollar} {data?.amount_word.toUpperCase()}</strong></p>
                                                        <p>{data?.name}</p>
                                                        <p>{data?.rank}</p>
                                                        <p>{data?.bank_account}</p>
                                                        <p>{data?.bank_name}</p>
                                                        <p>{data?.routing_number}</p>
                                                    </div>
                                                </div>
                                                <hr/>
                                            </>
                                        ))

                                    }




                                    <div className="justify-content-center text-center">
                                        <p>{reportData?.footerData?.address ?? 'Kabir Manzil (3rd Floor) Sheikh Mujib Road, Agrabad C/A, Chattogram-4100, Bangladesh'}</p>
                                        <p>Phone:: +880 31 2513791-4, 711501-5, 721898,716211 Fax:+88 031-2523811,710542 Email:  {reportData?.footerData?.gm_email_info ?? 'info@brsml.com' } </p>
                                    </div>



                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TopFourOfficerSalary;