import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import {useNavigate} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import BankInfoForm from "../../../../Components/Profile/OtherInfo/BankInfoForm";
import * as UserRoute from "../../../../constants/UserRoute";

const BankInfo = () => {
    const navigate = useNavigate();
    const [disable,setDisable] = useState(false);
    const [form,setForm] = useState({
        user_id:"",
        id:0,
        bank_name:'',
        account_name:'',
        account_number:'',
        routing_number:'',
        address:'',
        account_type:'',
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.post('/seafarer/save-bank-information', form)
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(UserRoute.USER_PROFILE, { state: { type:'others' } });
                },1000);
                setDisable(false)

            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    const getData = () => {
        axiosClient.get('/seafarer/bank-information')
            .then(({data}) => {
                if (data.data !== null) {
                    setForm(data.data)
                }
            }).catch(() => {


        })
    }
    useEffect(() => {
        getData()
    },[])
    return (
        <>
            <Breadcrumb title='Bank Information' prev={UserRoute.USER_PROFILE} state="others"   />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-6 col-12">
                        <form onSubmit={onSubmit}>
                            <BankInfoForm form={form} setForm={setForm} disable={disable} />
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BankInfo;