import { useRef, useState} from "react";
import axiosClient from "../../axios-client";
import {useStateContext} from "../../context/ContextProvider";
import {Navigate} from "react-router-dom";
import * as COMPANYROUTE from "../../constants/companyRoutes";
import * as UserRoute from "../../constants/UserRoute";


const Login = () => {

    const mobileRef = useRef();
    const passwordRef = useRef();
    const [errors,setErrors] = useState(null)
    const {setUser,setToken,setUserType,setUserId,setRole} = useStateContext();

    const onSubmit = (e) => {
        e.preventDefault();
        const payload = {
            mobile:mobileRef.current.value,
            password:passwordRef.current.value,
        }

        setErrors({})
        axiosClient.post('/login',payload)
            .then(({data}) => {
                setUser(data.user)
                setUserId(data.user.user_id)
                setUserId(data.user.user_id)
                setRole(data.user?.role_id)
                setToken(data.accessToken)
                setUserType(data.user.user_type)
                setErrors('')
            }).catch(err => {

            const response = err.response;
            console.log(response)
            if(response && response.status === 422){
                if(response.data.message){
                    setErrors(response.data.message)
                }
            }
        })
    }
    const {token,userType} = useStateContext()
    if(token){
        if(userType === 'company'){
            return <Navigate to={COMPANYROUTE.DASHBOARD} />
        }else if(userType === 'seafarer'){
            return <Navigate to={UserRoute.DASHBOARD} />
        }else{
        }

    }


    return (
          <div className="login-page">
              <div className="login-box">
                  <div className="login-logo">
                      <a href="/"><b>Global Maricruit </b></a>
                  </div>
                  <div className="card">
                      <div className="card-body login-card-body">
                          <p className="login-box-msg">Sign in to start your session</p>
                          {
                                errors &&  (
                                    <div className="alert alert-danger">
                                        {JSON.stringify(errors)}
                                    </div>
                                )
                          }

                          <form  onSubmit={onSubmit}>
                              <div className="input-group mb-3">
                                  <input  ref={mobileRef} type="mobile" name="mobile"   className="form-control" placeholder="Mobile" />
                                  <div className="input-group-append">
                                      <div className="input-group-text">
                                          <span className="fas fa-envelope"></span>
                                      </div>
                                  </div>
                              </div>
                              <div className="input-group mb-3">
                                  <input  ref={passwordRef} type="password" name="password"   className="form-control" placeholder="Password" />
                                  <div className="input-group-append">
                                      <div className="input-group-text">
                                          <span className="fas fa-lock"></span>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-4">
                                      <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                  </div>
                              </div>
                          </form>

                      </div>
                  </div>
              </div>
          </div>

    );
};

export default Login;