import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";

const Index = () => {
    const [reportData,setReportData] = useState([])


    const getReportData = (pageNumber = 1) => {
        axiosClient.get(`/company/guest/seafarer-list?page=${pageNumber}`)
            .then(({data}) => {
                setReportData(data?.data)
            }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {
        getReportData(1)
    },[])



    return (
        <>
            <Breadcrumb title='Seafarer  List'   />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">


                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Seafarer Name</th>
                                            <th>CDC</th>
                                            <th>Vessel</th>
                                            <th>Rank</th>
                                            <th>Action </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData?.data?.length > 0 && reportData?.data?.map((data,index) => {
                                                return (
                                                    <tr>
                                                        <td>{index+1}</td>
                                                        <td>{data?.name}</td>
                                                        <td>{data.cdc_no}</td>
                                                        <td>
                                                            {
                                                                data?.status == 'Leave' ?  <span className="badge badge-danger">Leave</span> :  data?.current_vessel
                                                            }
                                                        </td>
                                                        <td>{data.current_rank}</td>

                                                        <td>
                                                            <Link to={`${COMPANYROUTE.PORT_HEALTH_DOCUMENT}${data.user_id}`} className='btn btn-sm btn-info mr-2'>View</Link>
                                                               </td>
                                                    </tr>
                                                )
                                            })
                                        }



                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">


                                        <Pagination
                                            activePage={reportData?.current_page ? reportData?.current_page : 0}
                                            itemsCountPerPage={reportData?.per_page ? reportData?.per_page : 0 }
                                            totalItemsCount={reportData?.total ? reportData?.total : 0}
                                            onChange={(pageNumber) => {
                                                getReportData(pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Index;