import React from 'react';
import DatePicker from "react-datepicker";

const CertificationForm = ({form,setForm,disable}) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Stcw Certification/ Training Title</label>
                            <select
                                onChange={e => setForm({...form,certificate_title:e.target.value})}
                                value={form?.certificate_title}
                                name="" id="" className="form-control">
                                <option value="">Select certification</option>
                                <option value="Certificate of Competency (COC)">  Certificate of Competency (COC) </option>
                                <option value="Flag State Endorsement (COC)">Flag  State Endorsement (COC) </option>
                                <option value="GMDSS- General Operator Certificate (GOC)"> GMDSS- General Operator Certificate (GOC) </option>
                                <option value="Flag State Endorsement (GOC)">Flag State Endorsement (GOC) </option>
                                <option value="Tanker Endorsement by Flag State"> Tanker Endorsement by Flag State </option>
                                <option value="Basic Safety Training">Basic Safety Training </option>
                                <option value="Advanced Fire Fighting">Advanced Fire Fighting   </option>
                                <option value="Basic Fire Fighting">Basic Fire Fighting </option>
                                <option value="Medical Care">Medical Care</option>
                                <option value="Medical Frist Aid">Medical Frist Aid  </option>
                                <option value="Proficiency in Survival Craft and Rescue Boat"> Proficiency in Survival Craft and Rescue Boat </option>
                                <option value="Personal Safety and Social Responsibility"> Personal Safety and Social Responsibility  </option>
                                <option value="Ship Security Officer">Ship Security  Officer </option>
                                <option value="Ship Security Awareness Certificate"> Ship Security Awareness Certificate </option>
                                <option value="Shipboard Safety Officer Course"> Shipboard Safety Officer Course </option>
                                <option value="Ship’s Cook Certificate">Ship’s Cook Certificate </option>
                                <option value="Basic Tanker Familiarization Course (Oil/Gas/Chemical)"> Basic Tanker Familiarization Course  (Oil/Gas/Chemical) </option>
                                <option value="Advanced Tanker Training (Oil/Gas/Chemical)"> Advanced Tanker Training (Oil/Gas/Chemical) </option>
                                <option value="ECDIS Generic">ECDIS Generic</option>
                                <option value="ECDIS Type Specific">ECDIS Type Specific </option>
                                <option value="Bridge Resources Management">Bridge  Resources Management </option>
                                <option value="Bridge Team Management">Bridge Team Management </option>
                                <option value="Ship Handling Simulator course">Ship  Handling Simulator course </option>
                                <option value="Radar/ARPA">Radar/ARPA</option>
                                <option value="ME Engine Course">ME Engine Course </option>
                                <option value="Engine Resources Management">Engine  Resources Management </option>
                                <option value="Risk Assessment Course">Risk Assessment Course </option>
                                <option value="Incident Investigation Course"> Incident Investigation Course </option>
                                <option value="Crude Oil Washing">Crude Oil Washing  </option>
                                <option value="Personal Survival Techniques">Personal Survival Techniques</option>
                                <option value="Seafarer with Designated Security Duties">Seafarer with Designated Security Duties</option>
                                <option value="Elementary First AID">Elementary First AID</option>
                                <option value="Efficient Deck Hand">Efficient Deck Hand</option>
                                <option value="Navigational Aids and Electronic Navigation Systems">Navigational Aids and Electronic Navigation Systems</option>
                                <option value="Operational Use of Electronic Chart Display and Information Systems">Operational Use of Electronic Chart Display and Information Systems</option>
                                <option value="Rating Forming Part of a Navigational Watch">Rating Forming Part of a Navigational Watch</option>
                                <option value="ECDIS- Electronic Chart Display and Information System">ECDIS- Electronic Chart Display and Information System</option>
                                <option value="High Voltage System (Operation Level)">High Voltage System (Operation Level)</option>
                                <option value="High Voltage (Management Level)">High Voltage (Management Level)</option>
                                <option value="Rating Forming Part of a Engine Watch">Rating Forming Part of a Engine Watch</option>
                                <option value="Rating Forming Part of a Navigational Watch">Rating Forming Part of a Navigational Watch</option>
                                <option value="Rating as able Seafarer Deck">Rating as able Seafarer Deck</option>
                                <option value="Fire Prevention And Fire Fighting" >Fire Prevention And Fire Fighting</option>
                                <option value="Radar Navigation (Management Level)"  >Radar Navigation (Management Level)</option>
                                <option value="Radar Navigation (Operational Level)"   >Radar Navigation (Operational Level)</option>
                                <option value="Rating As Able Seafarer Engine">Rating As Able Seafarer Engine</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Training Type  </label>
                            <select
                                onChange={e => setForm({...form,type:e.target.value})}
                                value={form?.type} className="form-control" id="">
                                <option value="">Select Training Type</option>
                                <option value="Basic">Basic</option>
                                <option value="Advanced">Advanced</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Stcw Grade  </label>
                            <input className='form-control  '    value={form?.grade} onChange={e => setForm({...form,grade:e.target.value})} placeholder="Enter Stcw Grade" type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="certificate_number">Certificate Number</label>
                            <input className='form-control'   value={form?.number} onChange={e => setForm({...form,number:e.target.value})} placeholder="Enter Certificate number" type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Flag State Endorsement</label>
                            <select
                                onChange={e => setForm({...form,flag_state:e.target.value})}
                                name=""   value={form?.flag_state} className="form-control" id="">
                                <option value="">Select Flag State Endorsement</option>
                                <option value="Yes">Yes</option>
                                <option value="Not Applicable">Not Applicable </option>
                                <option value="Applied">Applied</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="issued_date">Issued Date</label>
                            <DatePicker
                                selected={form?.issued ? new Date(new Date(form.issued)) : ''}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={e => setForm({...form,issued:e})}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="expiry_date">Expiry Date</label>
                            <DatePicker
                                selected={form?.valid ? new Date(new Date(form.valid)) : ''}
                                dateFormat='dd-MM-yyyy'
                                className="form-control"
                                onChange={e => setForm({...form,valid:e})}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="issued_at">Issued At</label>
                            <select
                                onChange={e => setForm({...form,issued_at:e.target.value})}
                                value={form?.issued_at} className="form-control" id="">
                                <option value="">Choose one </option>
                                <option value="Atlantic Maritime Training Institute"> Atlantic Maritime Training Institute </option>
                                <option value="Seamen's Training Centre">Seamen's Training Centre </option>
                                <option value="International Institute of Maritime Technology"> International Institute of Maritime Technology </option>
                                <option value="Bangladesh Marine Academy">Bangladesh Marine Academy </option>
                                <option value="Bangladesh Marine Fisheries Academy"> Bangladesh Marine Fisheries Academy </option>
                                <option value="National Maritime Institute">National Maritime Institute </option>
                                <option value="Bangladesh Maritime Training Institute (BMTI)"> Bangladesh Maritime Training Institute (BMTI) </option>
                                <option value="MAS Maritime Academy">MAS Maritime Academy </option>
                                <option value="International Maritime Academy (IMA)"> International Maritime Academy (IMA) </option>
                                <option value="Ocean Maritime Academy">Ocean Maritime Academy </option>
                                <option value="International Maritime Training Academy (IMTA)"> International Maritime Training Academy (IMTA) </option>
                                <option value="Western Maritime Academy">Western Maritime Academy </option>
                                <option value="Oriental Global International Maritime Academy (OGIMA)"> Oriental Global International Maritime Academy (OGIMA) </option>
                                <option value="Department of Shipping, Bangladesh"> Department of Shipping, Bangladesh </option>
                                <option value="Maritime Institute of Science & Technology"> Maritime Institute of Science & Technology </option>
                                <option value="Bay Maritime Institution">Bay Maritime Institution </option>
                                <option value="United Maritime Academy">United Maritime Academy</option>
                                <option value="National Marine Academy of Bangladesh">National Marine Academy of Bangladesh</option>
                                <option value="Shah Marine And Business Institute">Shah Marine And Business Institute</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-12">
                        <button className="btn btn-info ">{
                            disable ? 'Saving' : 'Save'
                        }</button>
                    </div>


                </div>
            </div>
        </div>

    );
};

export default CertificationForm;