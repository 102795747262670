import Breadcrumb from "../../Components/Company/Common/Breadcrumb";
import SignOnOffChart from "../../Components/Company/Chart/SignOnOffChart";
import SupplyDemandChart from "../../Components/Company/Chart/SupplyDemandChart";
import SeafarerContractChart from "../../Components/Company/Chart/SeafarerContractChart";
import DurationComparison from "../../Components/Company/Chart/DurationComparison";
import SalaryTrendChart from "../../Components/Company/Chart/SalaryTrendChart";
import SeafarerTurnoverChart from "../../Components/Company/Chart/SeafarerTurnoverChart";
import CrewChangeDue60Days from "../../Components/Company/Notification/CrewChangeDue60Days";
import CrewChangeDue30Days from "../../Components/Company/Notification/CrewChangeDue30Days";
import CrewChangeOverDue from "../../Components/Company/Notification/CrewChangeOverDue";
import DocumentRenewal60Days from "../../Components/Company/Notification/DocumentRenewal60Days";
import DocumentRenewal30Days from "../../Components/Company/Notification/DocumentRenewal30Days";
import DocumentRenewalOverdue from "../../Components/Company/Notification/DocumentRenewalOverdue";
import axiosClient from "../../axios-client";
import {useEffect, useState} from "react";
import SeafarerDormantChart from "../../Components/Company/Chart/SeafarerDormantChart";
import CrewExtension from "../../Components/Company/Notification/CrewExtension";


const Dashboard = () => {
    const [dashboardData,setDashboardData] = useState([]);
    const [vessels,setVessels] = useState([]);
    const [ranks,setRanks] = useState([]);
    const [topFourRank,setTopFourRank] = useState([]);

    const [permission,setPermission] = useState([]);
    const [superAdmin,setSuperAdmin] = useState(false);


    const getData = () => {
        axiosClient.get('/company/permission-check')
            .then(({data}) => {
                if(data?.data == true){
                    setSuperAdmin(true)
                }
                setPermission(data?.data);
            }).catch((e) => {

        })
    }

    useEffect(() => {
        getData()
    },[])

    let dashboard
    if (superAdmin == false) {
        let dashboardFilter = permission?.filter((item) => item?.module_id == 1);
        dashboard = dashboardFilter?.length > 0 ? true : false;
    }else{
        dashboard = true;
    }

    useEffect(() => {
        getDashboardData()
        getVesselData()
        getRanksData()
        getTopFourRanksData()
    },[])

    const getDashboardData = () => {
        axiosClient.get('/company/dashboard')
            .then(({data}) => {
                setDashboardData(data.data)
            }).catch(() => {
        })
    }


    const getVesselData = () => {
        axiosClient.get('/company/get-vessels')
            .then(({data}) => {
                setVessels(data.data)
            }).catch(() => {
        })
    }
    const getRanksData = () => {
        axiosClient.get('/company/get-ranks')
            .then(({data}) => {
                setRanks(data.data)
            }).catch(() => {
        })
    }

    const getTopFourRanksData = () => {
        axiosClient.get('/company/get-top-four-ranks')
            .then(({data}) => {
                setTopFourRank(data.data)
            }).catch(() => {
        })
    }



    return (
        <>   
            <Breadcrumb title='Dashboard'   />

            <>
                {
                    dashboard == true ?
                     <>
                         <section className="content">
                             <div className="container-fluid">
                                 <div className="row">
                                     <div className="col-md-3 col-12">
                                         <div className="small-box bg-info">
                                             <div className="inner">
                                                 <h3>{dashboardData?.total_seafarers}</h3>

                                                 <p>Total Seafarers</p>
                                             </div>
                                             <div className="icon">
                                                 <i className="fa fa-users"></i>
                                             </div>
                                             <a href="src/pages/Company/Dashboard#" className="small-box-footer">More info <i
                                                 className="fas fa-arrow-circle-right"></i></a>
                                         </div>
                                     </div>
                                     <div className="col-md-3 col-12">
                                         <div className="small-box bg-success">
                                             <div className="inner">
                                                 <h3>{dashboardData?.total_posted_job}</h3>

                                                 <p>Total Job</p>
                                             </div>
                                             <div className="icon">
                                                 <i className="fa fa-suitcase"></i>
                                             </div>
                                             <a href="src/pages/Company/Dashboard#" className="small-box-footer">More info <i
                                                 className="fas fa-arrow-circle-right"></i></a>
                                         </div>
                                     </div>
                                     <div className="col-md-3 col-12">
                                         <div className="small-box bg-warning">
                                             <div className="inner">
                                                 <h3>{dashboardData?.total_budget ?? 0}</h3>
                                                 <p>Total Budget</p>
                                             </div>
                                             <div className="icon">
                                                 <i className="fa fa-dollar-sign"></i>
                                             </div>
                                             <p href="#" className="small-box-footer">{dashboardData?.budget_start_year} - {dashboardData?.current_budget_year} Budget Year</p>
                                         </div>
                                     </div>
                                     <div className="col-md-3 col-12">
                                         <div className="small-box bg-danger">
                                             <div className="inner">
                                                 <h3>{dashboardData?.total_expense ?? 0}</h3>

                                                 <p>Total Expense</p>
                                             </div>
                                             <div className="icon">
                                                 <i className="fa fa-dollar-sign"></i>
                                             </div>
                                             <p href="#" className="small-box-footer">{dashboardData?.budget_start_year} - {dashboardData?.current_budget_year} Expense Year</p>

                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </section>

                         <div className="container-fluid">
                             <div className="row">
                                 <div className="col-lg-6 col-12">
                                     <SignOnOffChart  vessels={vessels} ranks={ranks}   />
                                 </div>

                                 <div className="col-lg-6 col-12">
                                     <SeafarerContractChart vessels={vessels} />
                                 </div>

                                 <div className="col-lg-6 col-12">
                                     <SupplyDemandChart vessels={vessels} ranks={ranks}  />
                                 </div>
                                 <div className="col-lg-6 col-12">
                                     <DurationComparison  vessels={vessels} />
                                 </div>

                                 <div className="col-lg-6 col-12">
                                     <SalaryTrendChart vessels={vessels} />
                                 </div>

                                 <div className="col-lg-6 col-12">
                                     <SeafarerDormantChart ranks={topFourRank} />
                                 </div>
                             </div>


                             <div className="row">
                                 <div className="col-lg-4 col-md-6">
                                     <CrewChangeDue60Days />
                                 </div>
                                 <div className="col-lg-4 col-md-6">
                                     <CrewChangeDue30Days />
                                 </div>
                                 <div className="col-lg-4 col-md-6">
                                     <CrewChangeOverDue />
                                 </div>
                                 <div className="col-lg-4 col-md-6">
                                     <DocumentRenewal60Days />
                                 </div>
                                 <div className="col-lg-4 col-md-6">
                                     <DocumentRenewal30Days />
                                 </div>
                                 <div className="col-lg-4 col-md-6">
                                     <DocumentRenewalOverdue />
                                 </div>
                                 <div className="col-lg-4 offset-lg-4 col-md-6">
                                     <CrewExtension />
                                 </div>
                             </div>
                         </div>
                     </>


                    : <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">
                                        <h5 className="card-title">Welcome to Dashboard</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

           </>

        </>

    );
};

export default Dashboard;