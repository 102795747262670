import React, {useState} from 'react';
import SearchInput from "./SearchInput";
import SearchUserList from "./SearchUserList";
import ConversationList from "./ConversationList";
import * as UserRoute from "../../constants/UserRoute";

const SeafarerChatSidebar = ({searchExist,setSearchExist,conversation}) => {
    const [searchUserList,setSearchUserList] = useState([])
    let searchUrl =  '/seafarer/chat/search-user';
    return (
        <aside >
            <header>
                <SearchInput setSearchExist={setSearchExist} setSearchUserList={setSearchUserList} searchUrl={searchUrl} />
            </header>
            {
                searchExist ? <SearchUserList searchUserList={searchUserList} /> : <ConversationList conversation={conversation} inbox_url={UserRoute.INBOX}/>
            }
        </aside>
    );
};

export default SeafarerChatSidebar;