import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {Link, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import CrewRemoveModal from "../../../../Components/Company/CrewList/CrewRemoveModal";
import CrewPerfomanceModal from "../../../../Components/Company/CrewPlanner/CrewPerfomanceModal";
import ContractPeriodModal from "../../../../Components/Company/CrewPlanner/ContractPeriodModal";
import CrewListEmailModal from "../../../../Components/Company/CrewPlanner/CrewListEmailModal";
import CrewListSmsModal from "../../../../Components/Company/CrewPlanner/CrewListSmsModal";
import CrewHireModal from "../../../../Components/Company/CrewPlanner/CrewHireModal";
import UpdateChecklistModal from "../../../../Components/Company/CrewPlanner/UpdateChecklistModal";
import {VESSEL_CREW_PLANNER_REPLACE_TANKER_EXTERNAL_POOL} from "../../../../constants/companyRoutes";
const CrewPlannerReplaceTanker = () => {
    const {vessel_id,category_id,company}  = useParams();

    const [reportData,setReportData] = useState([]);
    const [vesselName,setVesselName] = useState('')

    const [emailModal,setEmailModal] = useState(false);
    const [smsModal,setSmsModal] = useState(false);






    const getReportData = () => {
        axiosClient.get('/company/tanker-internal-crew-planners/' + vessel_id + '/' + category_id + '/' + company)
            .then(({data}) => {
                console.log(data)
                setVesselName(data?.vessel_name)
                setReportData(data?.data)
            }).catch((e) => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])




    const handleEmailModal = () => {
        setEmailModal(true)
    }

    const handleSmsModal = () => {
        setSmsModal(true)
    }




    return (
        <>
            <Breadcrumb title={`${vesselName} Available Crews `} backButton={true}  />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-end ">
                                        <Link to={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE_TANKER_EXTERNAL_POOL}${vessel_id}/${category_id}/${company}`} className='btn btn-success mr-2'>Check External Pool</Link>
                                        <button onClick={() => handleEmailModal()} className='btn btn-info mr-2'>Email</button>
                                        <button onClick={() => handleSmsModal()} className='btn btn-primary mr-2'>SMS</button>
                                    </div>
                                </div>

                                <div className="card-body">
                                            <table id="data-table" className="table table-bordered table-hover">
                                                <thead className='bg-info'>
                                                <tr>
                                                    <th>Crew</th>
                                                    <th>Rank</th>
                                                    <th>Nationality</th>
                                                    <th>Date of Readiness</th>
                                                    <th>Years in Rank</th>
                                                    <th>Years with Operator</th>
                                                    <th>Years on this type of tanker</th>
                                                    <th>Years on all types of tanker</th>
                                                    <th>Oil major company</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>


                                                {
                                                    reportData?.length > 0  ? reportData?.map((item,index) => {
                                                        return(
                                                            <tr  style={{
                                                                 background: item?.color
                                                            }}
                                                                 key={index+1}>
                                                                <td>{item?.name}</td>
                                                                <td>{item?.rank}</td>
                                                                <td>{item?.nationality}</td>
                                                                <td>{item?.readiness}</td>
                                                                <td>{item?.rank_year}</td>
                                                                <td>{item?.operator_year}</td>
                                                                <td>{item?.tanker_year}</td>
                                                                <td>{item?.all_tanker_year}</td>
                                                                <td>{item?.color == '#a2d9ce' ?  item?.matrix_company : ''}</td>
                                                                <td>
                                                                    <Link className="btn btn-primary  btn-sm mr-2" to={`${COMPANYROUTE.USER_PROFILE}${item?.emp_id}`}>Profile</Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : ''
                                                }
                                                </tbody>
                                            </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {
                emailModal === true && <CrewListEmailModal data={reportData} hide={() => setEmailModal(false)}   />
            }

            {
                smsModal === true && <CrewListSmsModal data={reportData} hide={() => setSmsModal(false)}   />
            }



        </>
    );
};

export default CrewPlannerReplaceTanker;