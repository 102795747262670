import React, {useState} from 'react';
import SearchInput from "./SearchInput";
import SearchUserList from "./SearchUserList";
import ConversationList from "./ConversationList";
import * as COMPANYROUTE from "../../constants/companyRoutes";

const CompanyChatSidebar = ({searchExist,setSearchExist,conversation}) => {
    const [searchUserList,setSearchUserList] = useState([])

    let searchUrl =  '/company/chat/search-user';
    return (
        <aside>
            <header>
                <SearchInput setSearchExist={setSearchExist} setSearchUserList={setSearchUserList} searchUrl={searchUrl} />
            </header>
            {
                searchExist ? <SearchUserList searchUserList={searchUserList} userType="company" /> : <ConversationList conversation={conversation} inbox_url={COMPANYROUTE.INBOX}/>
            }
        </aside>
    );
};

export default CompanyChatSidebar;