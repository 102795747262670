import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

const EditCompanyUser = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disabled,setDisabled] = useState(false);
    const [form,setForm] = useState({
        first_name:'',
        last_name:'',
        email:'',
        mobile:'',
        role:'',
        start_working_hours:'',
        end_working_hours:'',
        status:'',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        axiosClient.put('/company/company-users/'+id, form)
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                navigate(`${COMPANYROUTE.COMPANY_USER_LIST}`);
            }).catch(err => {

            setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    const getData = () => {
        axiosClient.get('/company/company-users/' + id + '/edit')
            .then(({data}) => {
                setForm({
                    first_name:data?.data?.first_name,
                    last_name:data?.data?.last_name,
                    email:data?.data?.email,
                    mobile:data?.data?.mobile,
                    role:data?.data?.role_id,
                    start_working_hours:data?.data?.start_working_hours,
                    end_working_hours:data?.data?.end_working_hours,
                    status:data?.data?.status,
                })
            }).catch(() => {
        })
    }

    useEffect(() => {
        getData()
    },[])

    return (
        <div>
            <Breadcrumb title='Edit Company User' prev={COMPANYROUTE.COMPANY_USER_LIST}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">First Name </label>
                                                    <input type="text"
                                                           value={form.first_name}
                                                              onChange={(e) => setForm({...form,first_name:e.target.value})}
                                                           name="first_name" className="form-control" id="first_name"
                                                           placeholder="Enter First Name" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="last_name">Last Name</label>
                                                    <input type="text"
                                                           value={form.last_name}
                                                           onChange={(e) => setForm({...form,last_name:e.target.value})}
                                                           name="last_name" className="form-control" id="first_name"
                                                           placeholder="Enter Last Name" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <input type="text"
                                                              value={form.email}
                                                              onChange={(e) => setForm({...form,email:e.target.value})}
                                                           name="email" className="form-control" id="email"
                                                           placeholder="Enter Email" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">Mobile Number</label>
                                                    <input type="text"
                                                              value={form.mobile}
                                                                onChange={(e) => setForm({...form,mobile:e.target.value})}
                                                           name="mobile" className="form-control" id="mobile"
                                                           placeholder="Enter Mobile Number" />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="password">Choose Role</label>
                                                    <select className="form-control"
                                                                value={form.role}
                                                                onChange={(e) => setForm({...form,role:e.target.value})}
                                                          >
                                                        <option value="">Choose Role</option>
                                                        <option value="1">Super Admin</option>
                                                        <option value="2">General Admin</option>
                                                        <option value="4">Privileged User</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="password">Start Work</label>
                                                    <select className="form-control"
                                                                value={form.start_working_hours}
                                                                onChange={(e) => setForm({...form,start_working_hours:e.target.value})}
                                                            name="" id="">
                                                        <option value="">Choose Start Work</option>
                                                        <option value="00:00">00:00</option>
                                                        <option value="00:30">00:30</option>
                                                        <option value="01:00">01:00</option>
                                                        <option value="01:30">01:30</option>
                                                        <option value="02:00">02:00</option>
                                                        <option value="02:30">02:30</option>
                                                        <option value="03:00">03:00</option>
                                                        <option value="03:30">03:30</option>
                                                        <option value="04:00">04:00</option>
                                                        <option value="04:30">04:30</option>
                                                        <option value="05:00">05:00</option>
                                                        <option value="05:30">05:30</option>
                                                        <option value="06:00">06:00</option>
                                                        <option value="06:30">06:30</option>
                                                        <option value="07:00">07:00</option>
                                                        <option value="07:30">07:30</option>
                                                        <option value="08:00">08:00</option>
                                                        <option value="08:30">08:30</option>
                                                        <option value="09:00">09:00</option>
                                                        <option value="09:30">09:30</option>
                                                        <option value="10:00">10:00</option>
                                                        <option value="10:30">10:30</option>
                                                        <option value="11:00">11:00</option>
                                                        <option value="11:30">11:30</option>
                                                        <option value="12:00">12:00</option>
                                                        <option value="12:30">12:30</option>
                                                        <option value="13:00">13:00</option>
                                                        <option value="13:30">13:30</option>
                                                        <option value="14:00">14:00</option>
                                                        <option value="14:30">14:30</option>
                                                        <option value="15:00">15:00</option>
                                                        <option value="15:30">15:30</option>
                                                        <option value="16:00">16:00</option>
                                                        <option value="16:30">16:30</option>
                                                        <option value="17:00">17:00</option>
                                                        <option value="17:30">17:30</option>
                                                        <option value="18:00">18:00</option>
                                                        <option value="18:30">18:30</option>
                                                        <option value="19:00">19:00</option>
                                                        <option value="19:30">19:30</option>
                                                        <option value="20:00">20:00</option>
                                                        <option value="20:30">20:30</option>
                                                        <option value="21:00">21:00</option>
                                                        <option value="21:30">21:30</option>
                                                        <option value="22:00">22:00</option>
                                                        <option value="22:30">22:30</option>
                                                        <option value="23:00">23:00</option>
                                                        <option value="23:30">23:30</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="password">End Work</label>
                                                    <select className="form-control" name=""
                                                                value={form.end_working_hours}
                                                                onChange={(e) => setForm({...form,end_working_hours:e.target.value})}
                                                            id="">
                                                        <option value="">Choose End Work</option>
                                                        <option value="00:00">00:00</option>
                                                        <option value="00:30">00:30</option>
                                                        <option value="01:00">01:00</option>
                                                        <option value="01:30">01:30</option>
                                                        <option value="02:00">02:00</option>
                                                        <option value="02:30">02:30</option>
                                                        <option value="03:00">03:00</option>
                                                        <option value="03:30">03:30</option>
                                                        <option value="04:00">04:00</option>
                                                        <option value="04:30">04:30</option>
                                                        <option value="05:00">05:00</option>
                                                        <option value="05:30">05:30</option>
                                                        <option value="06:00">06:00</option>
                                                        <option value="06:30">06:30</option>
                                                        <option value="07:00">07:00</option>
                                                        <option value="07:30">07:30</option>
                                                        <option value="08:00">08:00</option>
                                                        <option value="08:30">08:30</option>
                                                        <option value="09:00">09:00</option>
                                                        <option value="09:30">09:30</option>
                                                        <option value="10:00">10:00</option>
                                                        <option value="10:30">10:30</option>
                                                        <option value="11:00">11:00</option>
                                                        <option value="11:30">11:30</option>
                                                        <option value="12:00">12:00</option>
                                                        <option value="12:30">12:30</option>
                                                        <option value="13:00">13:00</option>
                                                        <option value="13:30">13:30</option>
                                                        <option value="14:00">14:00</option>
                                                        <option value="14:30">14:30</option>
                                                        <option value="15:00">15:00</option>
                                                        <option value="15:30">15:30</option>
                                                        <option value="16:00">16:00</option>
                                                        <option value="16:30">16:30</option>
                                                        <option value="17:00">17:00</option>
                                                        <option value="17:30">17:30</option>
                                                        <option value="18:00">18:00</option>
                                                        <option value="18:30">18:30</option>
                                                        <option value="19:00">19:00</option>
                                                        <option value="19:30">19:30</option>
                                                        <option value="20:00">20:00</option>
                                                        <option value="20:30">20:30</option>
                                                        <option value="21:00">21:00</option>
                                                        <option value="21:30">21:30</option>
                                                        <option value="22:00">22:00</option>
                                                        <option value="22:30">22:30</option>
                                                        <option value="23:00">23:00</option>
                                                        <option value="23:30">23:30</option>
                                                    </select>

                                                </div>
                                            </div>


                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="password">Choose Status</label>
                                                <select className="form-control"
                                                        value={form.status}
                                                        onChange={(e) => setForm({...form,status:e.target.value})}
                                                >
                                                    <option value="">Choose Status</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">InActive</option>
                                                </select>
                                            </div>
                                        </div>

                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">{
                                            disabled ? 'Update.....' : 'Update'
                                        }</button>
                                    </div>
                                </form>
                            </div>

                </div>
            </section>
        </div>

    );
};

export default EditCompanyUser;