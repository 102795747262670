import React from 'react';
import Logo from "./logo";
import FormatDate from "../../hooks/FormatDate";
const formattedDate = FormatDate();

const PrintHeader = ({title = ''}) => {
    return (
        <div className="d-flex justify-content-center mb-2 "  >
            <Logo img={process.env.REACT_APP_LOGO}/>
            <div className="mr-2 ml-2 text-center">
                <h6>{process.env.REACT_APP_NAME}</h6>
                <h6>{title}</h6>
                <p>{formattedDate}</p>
            </div>
        </div>
    );
};

export default PrintHeader;