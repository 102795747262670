import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component';

import './report.css';
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";
import AllRank from "../../../Components/Company/Common/AllRank";
import Pagination from "react-js-pagination";
const DormantReport = () => {
    const [vesselSelect,setVesselSelect] = useState('');
    const [rankSelect,setRankSelect] = useState('');

    const [reportData,setReportData] = useState([]);
    var date = new Date();

    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(),date.getDate()));
    const [data, setData] = useState(reportData);

    const getReportData = (vessel = 0,rankSelect = 0,dateFromData = null,pageNumber = 1) => {
        axiosClient.post('/company/report/dormant',{
            vessel_id: vessel,
            rank_id: rankSelect,
            date_from: dateFromData,
            page: pageNumber
        })
            .then(({data}) => {
                console.log(data)
                setReportData(data)
            }).catch((e) => {
            console.log(e)
        })
    }


    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        getReportData(vesselSelect,rankSelect,dateFromData,1)

    },[vesselSelect,rankSelect,dateFrom])

    useEffect(() => {
        setData(reportData)
    },[reportData])


    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/dormant/print',
        payload: { vessel_id: vesselSelect,rank_id:rankSelect, date_from: new Date(dateFrom).toLocaleDateString() },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Last Vessel Name', key: 'vessel_name', width: 20 },
        { header: 'Seafarer Name', key: 'user_name', width: 20 },
        { header: 'CDC No', key: 'cdc', width: 20 },
        { header: 'Rank', key: 'rank', width: 20 },
        { header: 'Contract Due Date/Sign Off Date', key: 'expiry_date', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/dormant/export',{
            vessel_id: vesselSelect,rank_id:rankSelect, date_from: new Date(dateFrom).toLocaleDateString()
        })
            .then(({data}) => {
                console.log(data)
                UseExportHook( 'xlsx',data,'Dormant Report','dormant-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/dormant/export',{
            vessel_id: vesselSelect,rank_id:rankSelect, date_from: new Date(dateFrom).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Dormant Report','dormant-report',exportColumns)
            }).catch(() => {
        })
    };


    return (
        <>
            <Breadcrumb title='Dormant Report'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-4'>
                                                    <AllVessel vesselSelect={vesselSelect} setVesselSelect={setVesselSelect} />
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <AllRank rankSelect={rankSelect} setRankSelect={setRankSelect} />
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateFrom}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateFrom(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-3">
                                            <div className="float-right">
                                                <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body  "  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Last Vessel Name</th>
                                            <th>Seafarer Name</th>
                                            <th>CDC No</th>
                                            <th>Rank</th>
                                            <th>Contract Due Date/Sign Off Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>


                                        {reportData?.data?.length > 0 ?  reportData?.data?.map((data,index) => (

                                            <tr  key={index} >
                                                <td>{data?.vessel_name}</td>
                                                <td>{data?.user_name}</td>
                                                <td>{data?.cdc}</td>
                                                <td>{data?.rank}</td>
                                                <td>{data?.expiry_date}</td>

                                            </tr>
                                        )) : <tr><td colSpan={5} className='text-center'>No Data Found</td></tr>}



                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">




                                        <Pagination
                                            activePage={reportData?.pagination?.current_page ? reportData?.pagination?.current_page : 0}
                                            itemsCountPerPage={reportData?.pagination?.per_page ? reportData?.pagination?.per_page : 0 }
                                            totalItemsCount={reportData?.pagination?.total ? reportData?.pagination?.total : 0}
                                            onChange={(pageNumber) => {
                                                let dateFromData = new Date(dateFrom).toLocaleDateString();
                                                getReportData(vesselSelect,rankSelect,dateFromData,pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DormantReport;