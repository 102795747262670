import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import Logo from "../../../Components/Common/logo";
import TopFourRank from "../../../Components/Company/Common/TopFourRank";
import DatePicker from "react-datepicker";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";
import UseTurnoverExportHook from "../../../hooks/UseTurnoverExportHook";
import AllRank from "../../../Components/Company/Common/AllRank";


const SeafarerTurnOver = () => {
    const [rankSelect,setRankSelect] = useState('');
    const [turnoverData,setTurnoverData] = useState([]);
    const [terminationData,setTerminationData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));

    const getReportData = (rank = 0,dateFromData = null,dateToData = null) => {
        axiosClient.post('/company/report/seafarer-turnover',{
            rank_id: rank,
            date_from: dateFromData,
            date_to: dateToData
        })
            .then(({data}) => {
                setTurnoverData(data.turnover)
                setTerminationData(data.termination)
            }).catch(() => {
        })
    }



    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getReportData(rankSelect,dateFromData,dateToData)

    },[rankSelect,dateFrom,dateTo])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-turnover/print',
        payload: { date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString() },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Name', key: 'name', width: 20 },
        { header: 'CDC No', key: 'cdc', width: 20 },
        { header: 'Rank', key: 'rank', width: 20 },
        { header: 'Vessel', key: 'vessel', width: 20 },
        { header: 'Left after few contract', key: 'contact_no', width: 20 },
        { header: 'Termination', key: 'termination', width: 20 },
        { header: 'Reason', key: 'reason', width: 20 },
        { header: 'Remarks', key: 'remarks', width: 20 },
    ];

    let from_date =  new Date(dateFrom).toDateString().split(' ').slice(1).join(' ')
    let to_date =  new Date(dateTo).toDateString().split(' ').slice(1).join(' ')

    const exportColumns1 = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Rank', key: 'rank', width: 20 },
        { header: from_date, key: 'prev_crews', width: 20 },
        { header: 'IN', key: 'in_crews', width: 20 },
        { header: 'Out', key: 'out_crews', width: 20 },
        { header: to_date, key: 'current_crews', width: 20 },
        { header: 'Turn Over', key: 'turnover', width: 20 },
        { header: 'Retention', key: 'retention', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-turnover/export',{
            date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseTurnoverExportHook( 'xlsx',data,'Seafarer Turnover Reports','seafarer-turnover-report',exportColumns,exportColumns1)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-turnover/export',{
            date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseTurnoverExportHook( 'csv',data,'Seafarer Turnover Reports','seafarer-turnover-report',exportColumns,exportColumns1)
            }).catch(() => {
        })
    };


    return (
        <>
            <Breadcrumb title='Seafarer Turnover Reports'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-4'>
                                                    <AllRank rankSelect={rankSelect} setRankSelect={setRankSelect} />

                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateFrom}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateFrom(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <DatePicker
                                                        selected={dateTo}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={(date) => setDateTo(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="float-right">
                                                <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body ">

                                  <div className="justify-content-center d-flex">
                                      <div className="d-flex">
                                          <div className="logo">
                                              <Logo />
                                          </div>
                                          <div className="header text">
                                              <h5 className="text-center">Brave Royal Ship Management Limited</h5>
                                              <h6 className="text-center">Seafarer Turnover Report</h6>
                                              <p className="text-center">{ new Date(dateFrom).toDateString().split(' ').slice(1).join(' ')} to { new Date(dateTo).toDateString().split(' ').slice(1).join(' ')}</p>
                                          </div>
                                      </div>

                                  </div>

                                    <table id="" className="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>CDC Number</th>
                                            <th>Rank</th>
                                            <th>Vessel</th>
                                            <th>Left after few contract</th>
                                            <th>Termination</th>
                                            <th>Reason</th>
                                            <th>Remarks</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {terminationData.length > 0 ? terminationData.map((data,index) => (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{data?.name}</td>
                                                <td>{data?.cdc}</td>
                                                <td>{data?.rank}</td>
                                                <td>{data?.vessel}</td>
                                                <td>{data?.contact_no}</td>
                                                <td>{data?.termination}</td>
                                                <td>{data?.reason}</td>
                                                <td>{data?.remarks}</td>

                                            </tr>
                                    )) : '' }

                                        </tbody>

                                    </table>
                                    <table id="" className="table table-bordered  mt-5">
                                        <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Rank</th>
                                            <th>{ new Date(dateFrom).toDateString().split(' ').slice(1).join(' ')}</th>
                                            <th>IN</th>
                                            <th>Out</th>
                                            <th>{ new Date(dateTo).toDateString().split(' ').slice(1).join(' ')}</th>
                                            <th>Turn Over</th>
                                            <th>Retention</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {turnoverData.length > 0 ? turnoverData.map((data,index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{data?.rank}</td>
                                            <td>{data?.prev_crews}</td>
                                            <td>{data?.in_crews}</td>
                                            <td>{data?.out_crews}</td>
                                            <td>{data?.current_crews}</td>
                                            <td>{data?.turnover}</td>
                                            <td>{data?.retention}</td>
                                        </tr>
                                        )) : '' }

                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerTurnOver;