import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import CrewListModal from "./CrewListModal";

const PortArrivalModal = ({data,vesselData,hide}) => {
    const [portArrival,setPortArrival] = useState("")
    const [portDeparture,setPortDeparture] = useState("")

    const [modal,setModal] = useState(false)


    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(portArrival === "" || portDeparture === ""){
            toast.error("Please enter all fields")
        }
        else{
            setModal(true)
        }



    }


    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-md">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title">ENTER CONTRACT PERIOD </h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="">Port Arrival</label>
                                    <input value={portArrival} required onChange={(e) => setPortArrival(e.target.value) } type="number" className="form-control"/>
                                </div>

                            <div className="form-group">
                                <label htmlFor="">Enter Departure</label>
                                <input value={portDeparture} required onChange={(e) => setPortDeparture(e.target.value) } type="number" className="form-control"/>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                            <button type="submit" className="btn btn-success">Submit</button>

                        </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                modal === true ? <CrewListModal hide={setModal} portArrival={portArrival} portDeparture={portDeparture} data={data} vesselData={vesselData} parentHide={hide} /> : null
            }

        </>
    );
};

export default PortArrivalModal;