import React, {useEffect, useState} from 'react';
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";

const AddEducationForm = ({form,setForm,disable}) => {
    const [country,setCountry] = useState([]);
    const getCountryData = () => {
        axiosClient.get('/get-country')
            .then(({data}) => {
                setCountry(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }
    useEffect(() => {
        getCountryData()
    },[])


    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1950; year--) {
        years.push(year);
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Degree Certification</label>
                            <input  onChange={e => setForm({...form,degree:e.target.value})} className='form-control'  type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Institute  </label>
                            <input className='form-control '  onChange={e => setForm({...form,institute:e.target.value})}  type="text"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Country </label>
                            <select style={{maxWidth: '100%'}}
                                    onChange={e => setForm({...form,country_id:e.target.value})}
                                    value={form.country_id}
                                    className="form-control"
                            >
                                <option  value="" selected>Select Country</option>
                                {country.map((item,index) => (
                                    <option key={index}   value={item?.country_id}>{item?.country_name}</option>
                                ))}

                            </select>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Passing Year  </label>
                            <select style={{maxWidth: '100%'}}
                                    onChange={e => setForm({...form,passing_year:e.target.value})}
                                    className="form-control"
                            >
                                <option  value="">Select Years</option>
                                {years.map((item,index) => (
                                    <option key={index}   value={item}>{item}</option>
                                ))}
                            </select>

                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Duration(Year) </label>
                            <select style={{maxWidth: '100%'}}
                                    onChange={e => setForm({...form,duration:e.target.value})}
                                    className="form-control"
                            >
                                <option  value="">Select  </option>
                                {Array.from(Array(10).keys(),x => x+1).map((item,index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="rank">Result  </label>
                            <input className='form-control '  onChange={e => setForm({...form,result:e.target.value})}  type="text"/>
                        </div>
                    </div>


                    <div className="col-12">
                        <button type="submit" className="btn btn-info">{
                            disable ? 'Saving....' : 'Save'
                        }</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AddEducationForm;