import React from 'react';
import {Link} from "react-router-dom";
import OtherInfoData from "../../Profile/OtherInfo/OtherInfoData";
import * as UserRoute from "../../../constants/UserRoute";

const Others = ({medicalData,surgeryData,medicalVaccinationData,othersTrainingData,othersData,bankData}) => {

    return (
        <>
            <div className="row">
                <Link  className="btn btn-success btn-sm mr-2" to={UserRoute.MEDICAL_INFORMATION}>Medical Information</Link>
                <Link  className="btn btn-warning btn-sm mr-2" to={UserRoute.TRAINING_INFORMATION}>Training</Link>
                <Link  className="btn btn-danger btn-sm mr-2" to={UserRoute.OTHERS_INFORMATION}>Others</Link>
                <Link  className="btn btn-info btn-sm mr-2" to={UserRoute.BANK_INFORMATION}>Bank</Link>
            </div>

            <OtherInfoData
                medicalData={medicalData}
                surgeryData={surgeryData}
                medicalVaccinationData={medicalVaccinationData}
                othersTrainingData={othersTrainingData}
                othersData={othersData}
                bankData={bankData}
            />




        </>
    );
};

export default Others;