import React, { useState} from 'react';
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

const CrewEmailSentModal = ({mobileArray,hide,parentHide}) => {

    const {vessel_id,category_id} = useParams();

    const [message,setMessage] = useState('')

    const [disable,setDisable] = useState(false)


    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisable(true)

        axiosClient.post('/company/invite-crew-replace-sms', {
            crews_mobile:mobileArray,
            email_vessel: parseInt(vessel_id),
            email_rank: parseInt(category_id),
            message:message,
        })
            .then(({data}) => {
                setDisable(false)
               if(data === 1){
                     toast.success('SMS Sent Successfully')
               }else if(data === 0){
                     toast.error('SMS Not Sent')
               }
               hide(false)
               parentHide(false)
            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }


    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content"  >

                        <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="">Mobile No</label>
                                    <input value={mobileArray} type="text" className="form-control"/>
                                </div>
                            <div className="form-group">
                                <label htmlFor="">Message</label>
                                <textarea value={message} onChange={e => setMessage(e.target.value)} className="form-control" name="" id="" cols="30" rows="10"></textarea>
                            </div>

                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                            <button type="submit" disabled={disable} className="btn btn-success">{
                                disable ? 'Sending...' : 'Send'
                            }</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CrewEmailSentModal;