import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useReducer, useState} from "react";
import axiosClient from "../../../axios-client";
import './styles/document.css'
import {toast} from "react-toastify";
import LoadingBar from 'react-top-loading-bar';
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {documentReducer,INITIAL_STATE,ACTION_TYPES} from "../../../reducers/Company/DocumentReducer";
import { useParams} from "react-router-dom";
import DocumentList from "../../../Components/Document/DocumentList";

const Document = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);

    const [state,dispatch] = useReducer(documentReducer,INITIAL_STATE);

    const getReportData = () => {
        setProgress(50)
        dispatch({type:ACTION_TYPES.GET_DOCUMENT_DATA});
        axiosClient.get(`/company/seafarers/documents/${id}`)
            .then(({data}) => {
                dispatch({type:ACTION_TYPES.GET_DOCUMENT_DATA_SUCCESS,payload:data.data});
                setProgress(100);

            }).catch((e) => {
            console.log(e)
            dispatch({type:ACTION_TYPES.GET_DOCUMENT_DATA_ERROR});

        })
    }

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete ${data?.certification_title}?`)){
            return false;
        }
        axiosClient.delete(`/company/seafarers/documents/delete/${data?.user_id}/${data?.emp_doc_id}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData();
            })
    }
    const onDownload = (data) => {
        axiosClient.get(`/company/seafarers/documents/download/${data?.user_id}/${data?.emp_doc_id}`)
            .then((res) => {
                const url = res.data?.path;
                const filename = url.substring(url.lastIndexOf('/') + 1);
                const extension = filename.substring(filename.lastIndexOf('.') + 1);
                const title = res.data?.title.replace(/[^\w\s.-]/gi, '') + '.' + extension;
                console.log(title)
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.setAttribute('download', title);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(err => {
            const response = err.response;
            if(response.status === 422){
                toast.error("File Not Found");
            }

        })
    }


    useEffect(() => {
        getReportData()
    },[])









    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Seafarer Documents'   prev={COMPANYROUTE.SEAFARER} addnew={true}  route={`${COMPANYROUTE.ADD_NEW_SEAFARER_DOCUMENTS}${id}`}     />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className="card-body table-responsive new-card-body"  style={{paddingTop: '0px'}} >

                                    <DocumentList state={state} onDownload={onDownload} onDelete={onDelete} EditRoute={COMPANYROUTE.EDIT_SEAFARER_DOCUMENTS} />


                                    <div className="mt-2 justify-content-center d-flex">

                                    {/*<Pagination*/}
                                    {/*    activePage={state?.data?.current_page ? state?.data?.current_page : 0}*/}
                                    {/*    itemsCountPerPage={state?.data?.per_page ? state?.data?.per_page : 0 }*/}
                                    {/*    totalItemsCount={state?.data?.total ? state?.data?.total : 0}*/}
                                    {/*    onChange={(pageNumber) => {*/}
                                    {/*        getReportData(pageNumber)*/}
                                    {/*    }}*/}
                                    {/*    pageRangeDisplayed={8}*/}
                                    {/*    itemClass="page-item"*/}
                                    {/*    linkClass="page-link"*/}

                                    {/*/>*/}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
};

export default Document;