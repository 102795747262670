import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useReducer, useState} from "react";
import axiosClient from "../../../axios-client";
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";
import LoadingBar from 'react-top-loading-bar';
import DocumentList from "../../../Components/Document/DocumentList";
import * as UserRoute from "../../../constants/UserRoute";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {ADD_COMPANY_DOCUMENT} from "../../../constants/companyRoutes";

const PreJoiningDocument = () => {
    const [progress, setProgress] = useState(10);
    const [data,setData] = useState([]);


    const getReportData = () => {
        setProgress(50)
        axiosClient.get(`/seafarer/pre-joining`)
            .then(({data}) => {
                 setData(data?.data)
                setProgress(100);

            }).catch(() => {

        })
    }




    useEffect(() => {
        getReportData()
    },[])




    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Pre Joining Familiarization'        />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className="card-body table-responsive new-card-body"  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Document Name</th>
                                            <th>Document File</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {data?.length > 0 ? data?.map((data,index) => (
                                            <tr key={index}>
                                                <td>{data?.title}</td>
                                                <td>
                                                    <a href={data?.doc_file} target="_blank" className="btn btn-success btn-sm ml-1">Download</a>
                                                </td>
                                                <td>
                                                   <a href={data?.doc_file} target="_blank" className="btn btn-primary btn-sm ml-1"><i className="fas fa-eye"></i></a>

                                                </td>


                                            </tr>
                                        )) : <tr><td colSpan={3} className='text-center'>No Data Found</td></tr>}


                                        </tbody>

                                    </table>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
};

export default PreJoiningDocument;