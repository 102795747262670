import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import EditDocumentForm from "../../../Components/Document/EditDocumentForm";

const EditDocument = () => {
     const {id} = useParams();
     const [loading,setLoading] = useState(false);
     const [progress, setProgress] = useState(100);

     const navigate = useNavigate()

    const [form,setForm] = useState({
        flag:'',
        title:'',
        docs_file:null,
        certification_title:'',
    })


    const getData = () => {
        axiosClient.get('/seafarer/documents/edit/'+id)
            .then(({data}) => {
                setForm(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }



    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axiosClient.post('/seafarer/documents/update',{
            id:form.emp_doc_id,
            user_id:form.user_id,
            document_type:form.flag,
            title:form.title,
            doc_file:form.docs_file,
            document_lists:form.certification_title,
            old_doc_file:form.doc_file,

        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                toast.success(data.message);
                setLoading(false);
                setTimeout(() => {
                    navigate(-1);
                },1000);

            }).catch(err => {
            setLoading(false);
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }



   useEffect(() => {
       getData()
   },[])





    return (
        <div>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Edit Seafarer Document' backButton={true}    />
            <section className="content">
                <div className="container-fluid">
                 <div className="card card-primary">
                    <form onSubmit={onSubmit}>
                       <EditDocumentForm form={form} setForm={setForm} loading={loading} />
                     </form>
                  </div>
                </div>
            </section>
        </div>
    );
};

export default EditDocument;