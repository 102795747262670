import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import ProfileImage from "./ProfileImage";
import PersonalInfoData from "../../Profile/PersonalInfo/PersonalInfoData";


const PersonalInfo = ({user,empData}) => {
     const {id} = useParams();

    return (
        <div className="row profile">
            <div className="col-12">
                <div className="float-right">
                    <Link to={`${COMPANYROUTE.UPDATE_PERSONAL_INFO}${id}`} className="btn btn-success">Update Profile</Link>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="card   card-outline">
                    <div className="card-body box-profile">
                        <ProfileImage imagePath={empData?.profile_image} />

                        <h3 className="profile-username text-center">{user?.first_name} {user?.middle_name} {user?.last_name}</h3>

                        <p className="text-muted text-center">{empData?.category}</p>

                        <ul className="list-group list-group-unbordered mb-3">
                            <li className="list-group-item">
                                <Link className="btn btn-info" to={`${COMPANYROUTE.UPLOAD_PERFORMANCE}${id}`}>Upload Performance</Link>
                            </li>
                            <li className="list-group-item">
                                <b>Readiness: {empData?.available_date} </b> <Link state={{readiness_date:empData?.available_date}} to={`${COMPANYROUTE.UPDATE_READINESS}${id}`} className="btn btn-info btn-sm float-right">Update Readiness</Link>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
           <PersonalInfoData user={user} empData={empData} />
        </div>
    );
};

export default PersonalInfo;