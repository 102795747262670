import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";
import Pagination from "react-js-pagination";


const SeafarerContractExtension = () => {
    const [vesselSelect,setVesselSelect] = useState('');
    const [reportData,setReportData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));

    const getReportData = (vessel = 0,dateFromData = null,dateToData = null,pageNumber = 1) => {
        axiosClient.post('/company/report/seafarer-contract-extension',{
            vessel_id: vessel,
            date_from: dateFromData,
            date_to: dateToData,
            page:pageNumber
        })
            .then(({data}) => {
                setReportData(data)
            }).catch((e) => {
            console.log(e)
        })
    }




    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getReportData(vesselSelect,dateFromData,dateToData,1)

    },[vesselSelect,dateFrom,dateTo])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-contract-extension/print',
        payload: { vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString() },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Vessel Name', key: 'vessel', width: 20 },
        { header: 'Seafarer Name', key: 'name', width: 20 },
        { header: 'Rank', key: 'rank', width: 20 },
        { header: 'Contract Expiry Date', key: 'expiry', width: 20 },
        { header: 'Extended Contract Expiry Date', key: 'extend_expiry', width: 20 },
        { header: 'Previous Salary', key: 'prev_salary', width: 20 },
        { header: 'Revised Salary', key: 'revised_salary', width: 20 },
        { header: 'Remarks', key: 'remarks', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-contract-extension/export',{
            vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Seafarer Contract Extension Reports','seafarer-contract-extension-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-contract-extension/export',{
            vessel_id: vesselSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Seafarer Contract Extension Reports','seafarer-contract-extension-report',exportColumns)
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Seafarer Contract Extension Reports'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-12 col-md-9">
                                                <div className="row">
                                                    <div className='col-12 col-md-4'>
                                                        <AllVessel vesselSelect={vesselSelect} setVesselSelect={setVesselSelect} />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <DatePicker
                                                            selected={dateFrom}
                                                            dateFormat='dd-MM-yyyy'
                                                            className="form-control"
                                                            onChange={(date) => setDateFrom(date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <DatePicker
                                                            selected={dateTo}
                                                            dateFormat='dd-MM-yyyy'
                                                            className="form-control"
                                                            onChange={(date) => setDateTo(date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-3">
                                                <div className="float-right">
                                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                    <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                    <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              <div className="card-body  "  style={{paddingTop: '0px'}} >

                                        <table id="" className="table table-bordered table-hover seafarer-list ">
                                            <thead >
                                            <tr>
                                                <th>Seafarer Name</th>
                                                <th>Vessel Name</th>
                                                <th>Rank</th>
                                                <th>Contract Expiry Date</th>
                                                <th>Extended Contract Expiry Date</th>
                                                <th>Previous Salary</th>
                                                <th>Revised Salary</th>
                                                <th>Remarks</th>
                                            </tr>
                                            </thead>
                                            <tbody>


                                            {reportData?.data?.length > 0 ?  reportData?.data?.map((data,index) => (

                                                <tr  key={index} >
                                                    <td>{data?.name}</td>
                                                    <td>{data?.vessel}</td>
                                                    <td>{data?.rank}</td>
                                                    <td>{data?.expiry}</td>
                                                    <td>{data?.extend_expiry}</td>
                                                    <td>{data?.prev_salary}</td>
                                                    <td>{data?.revised_salary}</td>
                                                    <td>{data?.remarks}</td>

                                                </tr>
                                            )) : <tr><td colSpan={8} className='text-center'>No Data Found</td></tr>}



                                            </tbody>

                                        </table>

                                        <div className="mt-2 justify-content-center d-flex">




                                            <Pagination
                                                activePage={reportData?.pagination?.current_page ? reportData?.pagination?.current_page : 0}
                                                itemsCountPerPage={reportData?.pagination?.per_page ? reportData?.pagination?.per_page : 0 }
                                                totalItemsCount={reportData?.pagination?.total ? reportData?.pagination?.total : 0}
                                                onChange={(pageNumber) => {
                                                    let dateFromData = new Date(dateFrom).toLocaleDateString();
                                                    let dateToData = new Date(dateTo).toLocaleDateString();
                                                    getReportData(vesselSelect,dateFromData,dateToData,pageNumber)
                                                }}
                                                pageRangeDisplayed={8}
                                                itemClass="page-item"
                                                linkClass="page-link"

                                            />
                                        </div>

                                    </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerContractExtension;