import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {Link, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import CrewRemoveModal from "../../../../Components/Company/CrewList/CrewRemoveModal";
import CrewPerfomanceModal from "../../../../Components/Company/CrewPlanner/CrewPerfomanceModal";
import ContractPeriodModal from "../../../../Components/Company/CrewPlanner/ContractPeriodModal";
import CrewListEmailModal from "../../../../Components/Company/CrewPlanner/CrewListEmailModal";
import CrewListSmsModal from "../../../../Components/Company/CrewPlanner/CrewListSmsModal";
import CrewHireModal from "../../../../Components/Company/CrewPlanner/CrewHireModal";
import UpdateChecklistModal from "../../../../Components/Company/CrewPlanner/UpdateChecklistModal";
const CrewPlannerReplace = () => {
    const {vessel_id,category_id}  = useParams();
    const [reportData,setReportData] = useState([]);
    const [vesselName,setVesselName] = useState('')
    const [tanker,setTanker] = useState('')

    const [performanceReviewModal,setPerformanceReviewModal] = useState(false);
    const [performanceReviewModalData,setPerformanceReviewModalData] = useState({});

    const [documentReviewModal,setDocumentReviewModal] = useState(false);
    const [documentReviewModalData,setDocumentReviewModalData] = useState({});

    const [emailModal,setEmailModal] = useState(false);
    const [smsModal,setSmsModal] = useState(false);

    const [hireModal,setHireModal] = useState(false);
    const [hireModalData,setHireModalData] = useState({});

    const [updateChecklistModal,setUpdateChecklistModal] = useState(false);
    const [updateChecklistData,setUpdateChecklistData] = useState({});




    const getReportData = (pageNumber = 1) => {
        axiosClient.get('/company/crew-planners-replace/' + vessel_id + '/' + category_id +  '?page=' + pageNumber)
            .then(({data}) => {
                setTanker(data?.isTanker)
                setVesselName(data?.vessel_name)
                setReportData(data?.data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData(1)
    },[])


    const handelPerformanceReviewModal = (data) => {
        setPerformanceReviewModal(true)
        setPerformanceReviewModalData(data)
    }

    const handleDocumentReviewModal = (data) => {
        setDocumentReviewModal(true)
        setDocumentReviewModalData(data)
    }

    const handleEmailModal = () => {
        setEmailModal(true)
    }

    const handleSmsModal = () => {
        setSmsModal(true)
    }

    const handleHireModal = (data) => {
        setHireModal(true)
        setHireModalData(data)
    }

    const handleUpdateChecklistData = (data) => {
        setUpdateChecklistData(data)
        setUpdateChecklistModal(true)
    }







    return (
        <>
            <Breadcrumb title={`${vesselName} Available Crews `} backButton={true}  />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-end ">
                                        <Link to={`${COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE_EXTERNAL_POOL}${vessel_id}/${category_id}`} className='btn btn-success mr-2'>Check External Pool</Link>
                                        <button onClick={() => handleEmailModal()} className='btn btn-info mr-2'>Email</button>
                                        <button onClick={() => handleSmsModal()} className='btn btn-primary mr-2'>SMS</button>
                                    </div>
                                </div>

                                <div className="card-body">
                                <table id="data-table" className="table table-bordered table-hover">
                                                <thead className='bg-info'>
                                                <tr>
                                                    <th>Crew</th>
                                                    <th>Rank</th>
                                                    <th>Nationality</th>
                                                    <th>Date of Readiness </th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>


                                                {
                                                    reportData?.length > 0  ? reportData?.map((item,index) => {
                                                        return(
                                                            <tr key={index+1}>
                                                                <td>{item?.name}</td>
                                                                <td>{item?.rank}</td>
                                                                <td>{item?.nationality}</td>
                                                                <td>{item?.readiness}</td>
                                                                <td>
                                                                    <Link className="btn btn-primary  btn-sm mr-2" to={`${COMPANYROUTE.USER_PROFILE}${item?.emp_id}`}>Profile</Link>
                                                                    <button className="btn btn-sm btn-success mr-2" onClick={() => handleDocumentReviewModal(item?.emp_id)}  >Document Review</button>
                                                                    <button className="btn btn-sm btn-info" onClick={() => handelPerformanceReviewModal(item?.emp_id)} >Performance  Review</button>
                                                                    {
                                                                        item?.hire_id !== false && <button className="btn btn-sm btn-warning ml-2" onClick={() => handleHireModal(item?.hire_id)} >Hire</button>
                                                                    }
                                                                    {
                                                                        item?.hire_doc !== false && <button className="btn btn-sm btn-warning ml-2" onClick={() => handleUpdateChecklistData(item?.hire_doc)} >Update Checklist</button>
                                                                    }


                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : ''
                                                }

                                                </tbody>
                                            </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                performanceReviewModal === true && <CrewPerfomanceModal data={performanceReviewModalData} hide={() => setPerformanceReviewModal(false)}   />
            }
            {
                documentReviewModal === true && <ContractPeriodModal data={documentReviewModalData} hide={() => setDocumentReviewModal(false)}   />
            }

            {
                emailModal === true && <CrewListEmailModal data={reportData} hide={() => setEmailModal(false)}   />
            }

            {
                smsModal === true && <CrewListSmsModal data={reportData} hide={() => setSmsModal(false)}   />
            }

            {
                hireModal === true && <CrewHireModal data={hireModalData} hide={() => setHireModal(false)}   />
            }
            {
                updateChecklistModal === true && <UpdateChecklistModal data={updateChecklistData} hide={() => setUpdateChecklistModal(false)}   />
            }

        </>
    );
};

export default CrewPlannerReplace;