import React from 'react';

const DocumentRenewalForm = ({handleSubmit,setDocumentType,documentType,data,handleInputChange,documentList,hideModal,disable}) => {
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    <div className="d-flex justify-content-center m-1">
                        <div className="form-group mr-2">
                            <input id="all-document" name="surgery" className="mr-2" onChange={e => setDocumentType('all')}  checked={documentType === 'all'}   type="radio"   value="all" />

                            <label htmlFor="all-document"   className="text-sm" >    All Document</label>

                        </div>
                        <div className="form-group">
                            <input id="individual-document" name="surgery" className="mr-2" onChange={e => setDocumentType('individual')}  checked={documentType === 'individual'}   type="radio"   value="individual" />
                            <label htmlFor="individual-document"  className="text-sm"   >    Individual Document</label>


                        </div>
                    </div>

                    {
                        documentType === 'individual' ? (
                            <div className="   m-1">
                                <div className="row">
                                    {
                                        data?.passport?.length > 0 &&  data?.passport?.map((data,index) => {
                                            return (
                                                <div className="col-md-6 col-12" key={index}>
                                                    <div className="form-group" style={{margin:0}}>
                                                        <input
                                                            onChange={event => handleInputChange(event)}
                                                            checked={documentList.includes(data?.title)}
                                                            value={data?.title}
                                                            type="checkbox"
                                                            id={`${data?.title}-renewal`}
                                                            className="mr-2"
                                                        />
                                                        <label htmlFor={`${data?.title}-renewal`}
                                                               style={{ fontWeight: 400 }}
                                                               className="text-sm"
                                                        >
                                                            {data?.title === 'Seaman Passport' ? 'Seaman ID' : data?.title}  ({data?.count})
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        data?.visa > 0 ?  (
                                            <div className="col-md-6 col-12" >
                                                <div className="form-group" style={{margin:0}}>
                                                    <input
                                                        onChange={event => handleInputChange(event)}
                                                        checked={documentList.includes('visa')}
                                                        value="visa"
                                                        type="checkbox"
                                                        id="visa-renewal"
                                                        className="mr-2"
                                                    />
                                                    <label htmlFor="visa-renewal" style={{fontWeight:400}} className="text-sm">Visa ({data?.visa})</label>
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    {
                                        data?.certification?.length > 0 &&  data?.certification?.map((data,index) => {
                                            return (
                                                <div className="col-md-6 col-12" key={index}>
                                                    <div className="form-group" style={{margin:0}}>
                                                        <input type="checkbox"
                                                               onChange={event => handleInputChange(event)}
                                                               checked={documentList.includes(data?.title)}
                                                               value={data?.title}
                                                               id={`${data?.title}-renewal`}
                                                               className="mr-2"
                                                        />
                                                        <label htmlFor={`${data?.title}-renewal`} style={{fontWeight:400}} className="text-sm">{data?.title}  ({data?.count})</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        ) : null
                    }
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                    <button type="submit" disabled={disable} className="btn btn-success">{
                        disable ? 'Previewing...' : 'Preview'
                    }</button>
                </div>
            </form>

        </div>
    );
};

export default DocumentRenewalForm;