import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";

const VesselBudgetEdit = () => {
    const {year,id} = useParams();
    const naviagte = useNavigate();
    const [reportData,setReportData] = useState([]);
    const [vesselName,setVesselName] = useState("")

    const [budgetYear,setBudgetYear] = useState("")

    const [title,setTitle] = useState("")
    const [expenses,setExpenses] = useState([])

    const [expenseForm,setExpenseForm] = useState({})



    const getReportData = () => {
        axiosClient.get('/company/budget/edit/' + year + '/' + id)
            .then(({data}) => {
                setBudgetYear(data?.data?.budget_year)
                setTitle(data?.data?.title)
                setExpenses(data?.expenseData)

                const expenseFormData = {}
                data?.expenseData?.forEach((item) => {
                    expenseFormData[item?.budget_id] = item?.amount
                })
                setExpenseForm(expenseFormData)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])

    const handleSubmit = (e) => {
            e.preventDefault();
            axiosClient.post('/company/budget/update', {
               year: parseInt(budgetYear),
               expenses: expenseForm,
               vessel_id: parseInt(id)
            })
            .then(({data}) => {
                toast.success(data.message);
                 naviagte(`${COMPANYROUTE.VESSEL_BUDGET}${id}`)
            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }



    return (
         <>

             <Breadcrumb title={`${vesselName} Budget Edit  `}  backButton={true} />
             <section className="content">
                 <div className="container-fluid">
                     <div className="row">
                         <div className="col-12">
                             <div className="card">
                                 <div className="card-body">
                                     <form onSubmit={handleSubmit}>
                                         <div className="card-body">
                                           <div className="col-12 col-md-6">
                                               <div className="row">
                                                   <div className="col-12 ">
                                                       <div className="form-group">
                                                           <label htmlFor="vessel_type">Title </label>
                                                           <input type="text" disabled className="form-control" value={title}/>
                                                       </div>
                                                   </div>
                                                   <div className="col-12 ">
                                                       <div className="form-group">
                                                           <label htmlFor=""> Budget Year</label>
                                                           <select onChange={e => setBudgetYear(e.target.value)}
                                                                   value={budgetYear}
                                                                   className="form-control">
                                                               <option value="">Select Budget Year</option>
                                                               {Array.from(Array(21).keys(),x => x+2022).map((item,index) => (
                                                                   <option value={item}>{item}</option>
                                                               ))}
                                                           </select>
                                                       </div>
                                                   </div>
                                                   {
                                                      expenses?.length > 0 &&  expenses?.map((item,index) => (
                                                              <div className="col-12 ">
                                                                     <div className="form-group">
                                                                            <label htmlFor="vessel_type">{item?.budget_head} Amount</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={expenseForm[item?.budget_id] || ''}
                                                                                onChange={(e) =>
                                                                                    setExpenseForm({
                                                                                        ...expenseForm,
                                                                                        [item?.budget_id]: e.target.value,
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                </div>
                                                         ))
                                                   }

                                               </div>
                                           </div>


                                         </div>

                                         <div className="card-footer">
                                             <button type="submit" className="btn btn-success">Submit</button>
                                         </div>
                                     </form>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </section>



         </>
    );
};

export default VesselBudgetEdit;