import React, {useEffect, useState} from 'react';
import Logo from "../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import './styles/checklist.css'
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {Link, useNavigate, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../hooks/usePrintWindow";
const ApplicationForm = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [data, setData] = useState({})

    const navigate = useNavigate();

    const getData = () => {

        axiosClient.get('/company/application-form/'+id)
            .then(({data}) => {
                console.log(data)
                setData(data)
                setProgress(100)
            }).catch((e) => {
            console.log(e)


        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/application-form/print/' + id,
    });
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Application Form '  prev={COMPANYROUTE.SEAFARER}    />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-10 offset-md-1">
                                    <div className="checklist-panel">


                                        <div className="justify-content-center d-flex mb-2">
                                    <div className="d-flex">
                                        <div className="logo">
                                            <Logo />
                                        </div>
                                        <div className="header-text">
                                            <h5 className="text-center company-name">{data?.common_info?.company_name}</h5>
                                              <div className="d-flex justify-content-center">
                                                  <div className="header-border">
                                                      FILE NO. F– 01
                                                  </div>
                                                  <div className="header-border">
                                                      BRSML/FORM-03-R1
                                                  </div>
                                              </div>
                                            <h6 className="text-center title">Application Form</h6>

                                        </div>
                                    </div>
                                </div>
                                        <div className="content-body">

                                         <div className="row mb-2">
                                             <div className="col-md-6 col-12">
                                                 <strong>Post Applied for: </strong> {data?.empData?.rank?.category_name}
                                             </div>
                                             <div className="col-md-6 col-12">
                                                 <strong>Date:</strong>  {data?.common_info?.date}
                                             </div>
                                         </div>

                                            <table className="table table-bordered">
                                                <tr>
                                                    <td colSpan={2}><strong>Section 1:  </strong> Basic Information</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><strong>Full Name: </strong>{data?.userData?.first_name}  {data?.userData?.middle_name}  {data?.userData?.last_name}</td>
                                                </tr>
                                                <tr>
                                                    <td> <strong> Father Name:  </strong>  {data?.empData?.father_name}</td>
                                                    <td> <strong> Mother Name:  </strong>  {data?.empData?.mother_name} </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><strong>Present Address: </strong>{data?.empData?.address}   </td>
                                                </tr>

                                                <tr>
                                                    <td> <strong> Date Of Birth:  </strong>  {data?.empData?.dob}</td>
                                                    <td> <strong> Place Of Birth:  </strong>  {data?.empData?.dop} </td>
                                                </tr>

                                                <tr>
                                                    <td> <strong>Blood:  </strong>  {data?.empData?.blood}</td>
                                                    <td> <strong> Nationality:  </strong>  {data?.empData?.nationality} </td>
                                                </tr>

                                                <tr>
                                                    <td> <strong>Marital Status:  </strong>  {data?.empData?.marital_status}</td>
                                                    <td> <strong> Email:  </strong>  {data?.userData?.email} </td>
                                                </tr>
                                                <tr>
                                                    <td> <strong>Mobile:  </strong>  {data?.userData?.mobile}</td>
                                                    <td> <strong> Telephone:  </strong>  {data?.empData?.telephone} </td>
                                                </tr>
                                                <tr>
                                                    <td> <strong>NID:  </strong>  {data?.empData?.nid}</td>
                                                    <td> <strong> Kin NID:  </strong>  {data?.empData?.kin_nid} </td>
                                                </tr>
                                                <tr>
                                                    <td> <strong>Kin Name:  </strong>  {data?.empData?.kin_name}</td>
                                                    <td> <strong> Kin Relation:  </strong>  {data?.empData?.relation} </td>
                                                </tr>
                                                <tr>
                                                    <td> <strong>Kin Email:  </strong>  {data?.empData?.kin_email}</td>
                                                    <td> <strong> Kin Telephone:  </strong>  {data?.empData?.kin_telephone} </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={2}> <strong>Kin Address:  </strong>  {data?.empData?.kin_address}</td>
                                                </tr>



                                            </table>

                                            <table className="table table-bordered">
                                                <tr>
                                                    <td colSpan={7}><strong>Section 2:  </strong> Passport & CDC Information</td>
                                                </tr>
                                                    <tr>
                                                        <th>Document & Visas</th>
                                                        <th>Passport No.</th>
                                                        <th>Visa No.  </th>
                                                        <th>Issue Date </th>
                                                        <th>Expiry Date </th>
                                                        <th>Issued At </th>
                                                        <th>Country </th>
                                                    </tr>

                                                    {
                                                        data?.empPassportData?.map((item, index) => (
                                                             <tr>
                                                                  <td>{item?.info_type === 'Seaman Passport' ? 'Seaman Id' : item?.info_type}</td>
                                                                 <td>{item?.number}</td>
                                                                 <td>{item?.visa_number}</td>
                                                                 <td>{item?.issue_date}</td>
                                                                 <td>{item?.expiry_date}</td>
                                                                 <td>{item?.issued_at}</td>
                                                                 <td>{item?.country}</td>
                                                             </tr>
                                                        ))
                                                    }
                                            </table>

                                            <table className="table table-bordered">
                                                <tr>
                                                    <td colSpan={7}><strong>Section 3:  </strong> Bank Information</td>
                                                </tr>
                                                    <tr>
                                                        <th>Bank Name</th>
                                                        <th>Account Name</th>
                                                        <th>Account Number</th>
                                                        <th>Routing/Swift Number</th>
                                                        <th>Address</th>
                                                        <th>Account Type  </th>
                                                    </tr>

                                                    <tr>
                                                        <td>{data?.empBankData?.bank_name}</td>
                                                        <td>{data?.empBankData?.account_name}</td>
                                                        <td>{data?.empBankData?.account_number}</td>
                                                        <td>{data?.empBankData?.routing_number}</td>
                                                        <td>{data?.empBankData?.address}</td>
                                                        <td>{data?.empBankData?.account_type}</td>
                                                    </tr>
                                            </table>


                                            <table className="table table-bordered">
                                                <tr>
                                                    <td colSpan={4}><strong>Section 4:  </strong> Spouse & Child Information</td>
                                                </tr>
                                                <tr>
                                                    <th>Relationship</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Date of  Birth  </th>
                                                </tr>

                                                {
                                                    data?.empFamilyData?.map((item, index) => (
                                                        <tr>
                                                            <td>{item?.relationship}</td>
                                                            <td>{item?.firstname}</td>
                                                            <td>{item?.lastname}</td>
                                                            <td>{item?.dob}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>

                                            <table className="table table-bordered">
                                                <tr>
                                                    <td colSpan={6}><strong>Section 5:  </strong>Academic Qualification & Pre-Sea Training</td>
                                                </tr>
                                                <tr>
                                                    <th>Degree/Certification</th>
                                                    <th>Institute</th>
                                                    <th>Country</th>
                                                    <th>Passing Year </th>
                                                    <th>Duration</th>
                                                    <th>Result</th>
                                                </tr>

                                                {
                                                    data?.empEducationData?.map((item, index) => (
                                                        <tr>
                                                            <td>{item?.degree}</td>
                                                            <td>{item?.institute}</td>
                                                            <td>{item?.country}</td>
                                                            <td>{item?.passing_year}</td>
                                                            <td>{item?.duration}</td>
                                                            <td>{item?.result}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>


                                            <table className="table table-bordered">
                                                <tr>
                                                    <td colSpan={8}><strong>Section 6:  </strong> Certificates (Photocopies to be provided)</td>
                                                </tr>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Training</th>
                                                    <th>STCW Grade</th>
                                                    <th> Certificate Number</th>
                                                    <th>Flag  State</th>
                                                    <th>Issued</th>
                                                    <th>Expiry</th>
                                                    <th>Issued At</th>
                                                </tr>

                                                {
                                                    data?.empCertificationData?.map((item, index) => (
                                                        <tr>
                                                            <td>{item?.certificate_title}</td>
                                                            <td>{item?.type}</td>
                                                            <td>{item?.grade}</td>
                                                            <td>{item?.number}</td>
                                                            <td>{item?.flag_state}</td>
                                                            <td>{item?.issued}</td>
                                                            <td>{item?.valid}</td>
                                                            <td>{item?.issued_at}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>

                                            <table className="table table-bordered">
                                                <tr>
                                                    <td colSpan={12}><strong>Section 7:  </strong> Section 7: Record of Previous Employment</td>
                                                </tr>
                                                <tr>
                                                    <th>Vessel</th>
                                                    <th>Flag</th>
                                                    <th>Type</th>
                                                    <th>DWT</th>
                                                    <th>GRT</th>
                                                    <th>Engine Type</th>
                                                    <th>BHP</th>
                                                    <th>Cargo Crane</th>
                                                    <th>Rank</th>
                                                    <th>Sign-On Date</th>
                                                    <th>Sign-Off Date</th>
                                                    <th>Duration</th>
                                                </tr>

                                                {
                                                    data?.empExperienceData?.map((item, index) => (
                                                        <tr>
                                                            <td>{item?.vessel_name}</td>
                                                            <td>{item?.flag}</td>
                                                            <td>{item?.vessel_type}</td>
                                                            <td>{item?.dwt}</td>
                                                            <td>{item?.grt}</td>
                                                            <td>{item?.engine_type}</td>
                                                            <td>{item?.bhp}</td>
                                                            <td>{item?.cargo_crane}</td>
                                                            <td>{item?.category}</td>
                                                            <td>{item?.from}</td>
                                                            <td>{item?.to}</td>
                                                            <td>{item?.duration}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>





                                      </div>

                                 </div>



                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default ApplicationForm;