import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../../axios-client";

const VesselBudgetShow = () => {
    const {year,id} = useParams();
    const [reportData,setReportData] = useState([]);
    const [vesselName,setVesselName] = useState("")

    const [budgetYear,setBudgetYear] = useState(year)




    const getReportData = () => {
        axiosClient.get('/company/budget/show/' + budgetYear + '/' + id)
            .then(({data}) => {
                setReportData(data?.data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData()
    },[budgetYear])



    return (
         <>

             <Breadcrumb title={`${vesselName} Budget Details  `}  backButton={true} />
             <div className="content-header">
                 <div className="container-fluid">
                     <div className="row mb-2">

                         <div className="col-12 col-md-4">
                             <div className="form-group">
                                 <label htmlFor="">Select Budget Year</label>
                                 <select onChange={e => setBudgetYear(e.target.value)}
                                         value={budgetYear}
                                         className="form-control">
                                     <option value="">Select Budget Year</option>
                                     {Array.from(Array(21).keys(),x => x+2022).map((item,index) => (
                                         <option value={item}>{item}</option>
                                     ))}
                                 </select>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>

             <section className="content">
                 <div className="container-fluid">
                     <div className="row">
                         <div className="col-12">
                             <div className="card">


                                 <div className="card-body">
                                     <table id="data-table" className="table table-bordered table-hover">
                                         <thead className='bg-info'>
                                         <tr>
                                             <th>Budget Head</th>
                                             <th>Year</th>
                                             <th>Budget</th>
                                             <th>Expense</th>
                                             <th>Variance</th>
                                             <th>Budget Remarks</th>
                                             <th>Expense Remarks</th>
                                         </tr>
                                         </thead>
                                         <tbody>
                                         {
                                            reportData?.length > 0 ? reportData.map((data,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{data?.budget_head}</td>
                                                        <td>{data?.budget_year}</td>
                                                        <td>{data?.amount_format}</td>
                                                        <td>{data?.expense_amount}</td>
                                                        <td>{data?.balance}</td>
                                                        <td>{data?.remarks}</td>
                                                        <td>{data?.expense_remarks}</td>
                                                    </tr>
                                                )
                                            }) : <tr>
                                                <td colSpan={8} className='text-center'>No Data Found</td>
                                            </tr>

                                         }

                                         </tbody>

                                     </table>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </section>



         </>
    );
};

export default VesselBudgetShow;