import React, {useEffect, useState} from 'react';
import Logo from "../../../Components/Common/logo";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import './styles/agreement.css'
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import LoadingBar from 'react-top-loading-bar';
import usePrintWindow from "../../../hooks/usePrintWindow";
const Agreement = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);
    const [data,setData] = useState();
    const navigate = useNavigate();
    const getData = () => {
        axiosClient.get('/company/contract-extension-agreement/'+id)
            .then(({data}) => {
                setData(data.data)
                setProgress(100);
            }).catch((e) => {
            toast.error(e.response.data.message)
            navigate(COMPANYROUTE.SEAFARER)


        })
    }

    useEffect(() => {
        getData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/contract-extension-agreement/print/' + id,
    });

    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Seafarer Agreement Extension'  prev={COMPANYROUTE.SEAFARER}    />


            <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>

                                <div className="col-md-8 offset-md-2">
                                    <div className="agreement-panel">


                                <div className="justify-content-center d-flex">
                                    <div className="d-flex">
                                        <div className="logo">
                                            <Logo />
                                        </div>
                                        <div className="header text">
                                            <h5 className="text-center">{data?.company_name}</h5>
                                            <h6 className="text-center">SEAFARER EMPLOYMENT AGREEMENT - EXTENSION </h6>
                                            <small>This agreement (hereinafter referred to as this ‘Agreement’) is made and entered into by and between:</small>
                                        </div>
                                    </div>

                                </div>
                                <div className="justify-content-center">
                                   <div className="agreement-asection  ">
                                       <p>
                                           A. Employer: BRAVE ROYAL SHIP MANAGEMENT (BD) LIMITED. KABIR MANZIL(3RD Floor), Sheikh Mujib Road,Agrabad C/A, Chittagong -4100,Bangladesh. Phone:+88-031-2513791-4,711501-5,721898,716211 FAX:+88-031-2523811,710542, Email:info@brsml.com
                                       </p>
                                   </div>
                                    <div className="agreement-bsection">
                                         <table className="table table-bordered">
                                             <tr>
                                                 <td colSpan="3"> <strong >B. Employee: </strong></td>
                                             </tr>
                                             <tr>
                                                 <td><strong>1. Surname:</strong>{data?.surname}</td>
                                                 <td><strong>2. First and Middle name:</strong>{data?.name}</td>
                                                 <td><strong>3. Nationality:</strong>{data?.nationality}</td>
                                             </tr>
                                             <tr>
                                                 <td><strong>4. Date of Birth:</strong>{data?.date_birth}</td>
                                                 <td><strong>5. Place Of Birth:</strong>{data?.place_birth}</td>
                                                 <td><strong>6. Home Phone:</strong>{data?.phone}</td>
                                             </tr>
                                             <tr>
                                                 <td colSpan={3}><strong>7. Home address and telephone :</strong>{data?.address}</td>
                                             </tr>
                                             <tr>
                                                 <td  colSpan={3}><strong>8. Name and relationship of next of kin  :</strong>{data?.kin_name_relation}</td>
                                             </tr>
                                             <tr>
                                                 <td><strong>9. Passport No:</strong>{data?.passport_no}</td>
                                                 <td><strong>Date/Place of issue:</strong>{data?.passport_date}</td>
                                                 <td><strong>Validity:</strong>{data?.passport_validity}</td>
                                             </tr>
                                             <tr>
                                                 <td><strong>10. Seaman Book:</strong>{data?.seaman_id}</td>
                                                 <td><strong>Date/Place of issue:</strong>{data?.seaman_date}</td>
                                                 <td><strong>Validity:</strong>{data?.seaman_validity}</td>
                                             </tr>
                                             <tr>
                                                 <td><strong>11. Seaman Id:</strong>{data?.seaman_id}</td>
                                                 <td><strong>Date/Place of issue:</strong>{data?.seaman_date}</td>
                                                 <td><strong>Validity:</strong>{data?.seaman_validity}</td>
                                             </tr>
                                             <tr>
                                                 <td  colSpan={3}><strong>12. Certificates of Competency  :</strong>{data?.coc}</td>
                                             </tr>
                                             <tr>
                                                 <td colSpan={3}><strong>13. Name Of employee  :</strong>{data?.emp_name}</td>
                                             </tr>
                                             <tr>
                                                 <td><strong>14. Signing the Agreement:</strong>{data?.sign_agreement}</td>
                                                 <td><strong>Date :</strong>{data?.sign_date}</td>
                                                 <td><strong>Place:</strong>{data?.sign_place}</td>
                                             </tr>

                                         </table>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td><strong>C. Name of Vessel:</strong>{data?.vessel_name}</td>
                                                <td><strong>Flag of Vessel  :</strong>{data?.vessel_flag}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>D. Terms and Condition: (wage in USD): </strong> </td>
                                                <td><strong>Position onboard  : </strong>{data?.rank}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Basic Wages : </strong>{data?.basic_wages}</td>
                                                <td><strong>Leave Pay : </strong>{data?.leave_pay}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Fixed Over Time: (120 hours) : </strong>{data?.overtime}</td>
                                                <td><strong>Tanker Allowance: </strong>{data?.tanker_allowance}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Other Allowance: </strong>{data?.other_allowance}</td>
                                                <td><strong>Total: </strong>{data?.total}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}><strong>E. Period of Service onboard: </strong>{data?.contract_period}</td>
                                            </tr>
                                        </table>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td colSpan={3}><strong>F. Bank Information :</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Bank Name: </strong>{data?.bank_name}</td>
                                                <td><strong>Account Name: </strong>{data?.account_name}</td>
                                                <td><strong>Account Number: </strong>{data?.account_number}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Routing/Swift Number: </strong>{data?.routing_number}</td>
                                                <td><strong>Address: </strong>{data?.bank_address}</td>
                                                <td><strong>Account Type: </strong>{data?.account_type}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="agreement-footer">
                                        <p  >
                                            <small>a. This document is subject to be read along with Articles of Agreement for Bangladesh Seamen, The Bangladesh Merchant Shipping Ordinance 1983, company’s Terms and Conditions of
                                                Service or CBA, as applicable.
                                            </small>
                                        </p>

                                        <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <p>-------------------------------------------------------------- <br/>
                                                        (Company)<br/>
                                                        Name : <br/>
                                                        Position : </p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <p>I have read and accepted the terms and conditions hereby:</p>
                                                    <p>-------------------------------------------------------------- <br/>
                                                        (Seafarer)<br/>
                                                        Name : <br/>
                                                        Position : </p>
                                                </div>
                                        </div>


                                    </div>

                                </div>
                                </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>

    );
};

export default Agreement;