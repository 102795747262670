import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import axiosClient from "../../../axios-client";

const UpdateChecklistModal = ({data = [],hide}) => {

    const [dataArray,setDataArray] = useState([])
    const [bulkSelect,setBulkSelect] = useState(false)

    const [reportData,setReportData] = useState([])

    const [modal,setModal] = useState(false)
    const [disable,setDisable] = useState(false)

    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }


    const getData = (data) => {
        axiosClient.get('/company/get-hire-check-lists/' + data)
            .then(({data}) => {
                setReportData(data)
            }).catch(() => {
        })
    }


    useEffect(() => {
        getData(data)
    },[data])



    const handleChange = (e) => {
        let value = e.target.value;
        let checked = e.target.checked;
        if(checked){
            setDataArray([...dataArray,parseInt(value)])
        }else{
            setDataArray(dataArray.filter(item => item !== parseInt(value)))
        }


    }

    const bulkSelectChange = (e) => {
        let checked = e.target.checked;
        if(checked){
            setDataArray(reportData.map(item => item?.check_lists_id))

            setBulkSelect(true)
        }else{
            setDataArray([])
            setBulkSelect(false)
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(dataArray.length <= 0){
            toast.error('Please Select any option')
        }else{
            setDisable(true)

            axiosClient.post('/company/update-hire-check-lists', {
                hire_id: data,
                check_lists_id: dataArray
            })
                .then(({data}) => {
                    setDisable(false)
                    toast.success(data?.message)
                    hide(false)
                }).catch(err => {
                const response = err.response;
                if (response && response.status === 422) {
                    if (response.data?.errors) {
                        const errors = response.data.errors;
                        for (const key in errors) {
                            toast.error(errors[key][0]);
                        }
                    }
                }
            })
        }
    }


    useEffect(() => {
        const updatedArray = reportData.filter(item => item?.status === 'Yes').map(item => parseInt(item?.check_lists_id));
        setDataArray(updatedArray);
    }, [reportData]);


    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{overflowY:'scroll', height:'600px'}}>
                        <div className="modal-header">
                            <h5 className="modal-title">UPDATE CREWS CHECK LISTS</h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th><input type="checkbox" onChange={bulkSelectChange}  /></th>
                                            <th>Name </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            reportData?.length > 0 && reportData.map((item,index) => {
                                                const isChecked = bulkSelect === true ? true : dataArray.includes(parseInt(item?.check_lists_id));
                                                return (
                                                    <tr key={index}>
                                                        <td><input type="checkbox" onChange={handleChange}
                                                                   checked={isChecked}
                                                                   value={item?.check_lists_id}
                                                                   className="  "/></td>
                                                        <td>{item?.doc_type} {item?.status}  </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                                <button type="submit" disabled={disable} className="btn btn-success"   >{
                                    disable === true ? 'Submitting...' : 'Submit'
                                }</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateChecklistModal;