import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useReducer, useState} from "react";
import axiosClient from "../../../axios-client";
import './styles/style.css'
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";
import LoadingBar from 'react-top-loading-bar';
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {Link, useParams} from "react-router-dom";
import fileDownload from 'js-file-download';
import {ACTION_TYPES, INITIAL_STATE, JobReducer} from "../../../reducers/Company/JobReducer";

const ShortlistedSeafarer = () => {
    const {id} = useParams();
    const [progress, setProgress] = useState(10);

    const [state,dispatch] = useReducer(JobReducer,INITIAL_STATE);
    const [jobTitle,setJobTitle] = useState('');

    const getReportData = (pageNumber = 1) => {
        setProgress(50)
        axiosClient.get(`/company/job/shortlisted-seafarer/${id}?page=${pageNumber}`)
            .then(({data}) => {
                setJobTitle(data.jobTitle);
                dispatch({type:ACTION_TYPES.GET_SHORTLIST_DATA_SUCCESS,payload:data.appliedSeafarersData});
                setProgress(100);

            }).catch(() => {
            toast.error('Something went wrong');

        })
    }



    useEffect(() => {
        getReportData(1)
    },[])







    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title={jobTitle}     />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className=" table-responsive new-card-body"  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {state?.shortlistData?.data?.length > 0 ?  state?.shortlistData?.data?.map((data,index) => (
                                            <tr key={index}>
                                                <td>{data?.first_name + ' ' + data?.middle_name + ' ' + data?.last_name}</td>
                                                <td>{data?.email}</td>
                                                <td>{data?.mobile}</td>
                                                <td>
                                                    <Link target="_blank" className="btn btn-sm btn-info" to={`${COMPANYROUTE.USER_PROFILE}${data?.user_id}`}>Profile</Link>

                                                </td>


                                            </tr>
                                        )) : <tr><td colSpan={4} className='text-center'>No Data Found</td></tr>}


                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">

                                        <Pagination
                                            activePage={state?.shortlistData?.current_page ? state?.shortlistData?.current_page : 0}
                                            itemsCountPerPage={state?.shortlistData?.per_page ? state?.shortlistData?.per_page : 0 }
                                            totalItemsCount={state?.shortlistData?.total ? state?.shortlistData?.total : 0}
                                            onChange={(pageNumber) => {
                                                getReportData(pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
};

export default ShortlistedSeafarer;