import React, {useEffect, useReducer, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as UserRoute from "../../../constants/UserRoute";
import PersonalInfoForm from "../../../Components/Profile/PersonalInfo/PersonalInfoForm";


const PersonalInfo = () => {
    const navigate = useNavigate();
    const [disabled,setDisabled] = useState(false);
    const [user,setUser] = useState({
        first_name:"",
        middle_name:"",
        last_name:"",
        email:"",
        mobile:"",
    });
    const [empData,setEmpData] = useState({
       father_name:"",
       mother_name:"",
       category_id:"",
       nid:"",
       birth_certificate:"",
       telephone:"",
       dob:"",
       dop:"",
       gender:"",
       blood:"",
       height:"",
       weight:"",
       cover_size:"",
       shoe_size:"",
       religion:"",
       skype:"",
       marital_status:"",
       country_id:"",
       address:"",
       post_code:"",
       kin_name:"",
       kin_email:"",
       kin_telephone:"",
       relation:"",
       kin_nid:"",
       kin_address:"",
       kin_birth_certificate:"",

    });

    const getProfileData = () =>  {
        axiosClient.get('/seafarer/profile')
            .then(({data}) => {
                setUser(data.user);
                setEmpData(data.empData);

            }).catch(() => {
            toast.error("Something went wrong");


        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/seafarer/personal-info-update',{
            first_name:user.first_name,
            middle_name:user.middle_name,
            last_name:user.last_name,
            email:user.email,
            mobile:user.mobile,
            father_name:empData.father_name,
            mother_name:empData.mother_name,
            category_id:empData.category_id,
            nid:empData.nid,
            birth_certificate:empData.birth_certificate,
            telephone:empData.telephone,
            dob:empData.dob,
            dop:empData.dop,
            gender:empData.gender,
            blood:empData.blood,
            height:empData.height,
            weight:empData.weight,
            cover_size:empData.cover_size,
            shoe_size:empData.shoe_size,
            religion:empData.religion,
            skype:empData.skype,
            marital_status:empData.marital_status,
            country_id:empData.country_id,
            address:empData.address,
            post_code:empData.post_code,
            kin_name:empData.kin_name,
            kin_email:empData.kin_email,
            kin_telephone:empData.kin_telephone,
            relation:empData.relation,
            kin_nid:empData.kin_nid,
            kin_address:empData.kin_address,
            kin_birth_certificate:empData.kin_birth_certificate,
        })
            .then(({data}) => {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(UserRoute.USER_PROFILE, { state: { type:'personalInfo' } });
                },1000);

            }).catch(err => {
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }


    useEffect(() => {
        getProfileData()
    },[])



    return (
        <div>
            <Breadcrumb title='Personal Information' prev={UserRoute.USER_PROFILE} state="personalInfo"    />
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <form  onSubmit={onSubmit}>
                                <PersonalInfoForm
                                    setUser={setUser}
                                    user={user}
                                    setEmpData={setEmpData}
                                    empData={empData}
                                    disabled={disabled}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PersonalInfo;