import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import 'react-datepicker/dist/react-datepicker.css';
import axiosClient from "../../../axios-client";
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import CrewPerfomanceModal from "../../../Components/Company/CrewPlanner/CrewPerfomanceModal";
import ContractPeriodModal from "../../../Components/Company/CrewPlanner/ContractPeriodModal";
import CrewHireModal from "../../../Components/Company/CrewPlanner/CrewHireModal";
import UpdateChecklistModal from "../../../Components/Company/CrewPlanner/UpdateChecklistModal";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";

const FleetBudget = () => {
    const [reportData,setReportData] = useState([]);

    const [performanceReviewModal,setPerformanceReviewModal] = useState(false);
    const [performanceReviewModalData,setPerformanceReviewModalData] = useState({});

    const [documentReviewModal,setDocumentReviewModal] = useState(false);
    const [documentReviewModalData,setDocumentReviewModalData] = useState({});


    const [hireModal,setHireModal] = useState(false);
    const [hireModalData,setHireModalData] = useState({});

    const [updateChecklistModal,setUpdateChecklistModal] = useState(false);
    const [updateChecklistData,setUpdateChecklistData] = useState({});

    const [finalHireModal,setFinalHireModal] = useState(false);
    const [finalHireData,setFinalHireData] = useState({});

    const getReportData = (pageNumber = 1) => {
        axiosClient.get(`/company/hired-crews/?page=${pageNumber}`)
            .then(({data}) => {
                console.log(data)
                setReportData(data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData(1)
    },[])

    const handelPerformanceReviewModal = (data) => {
        setPerformanceReviewModal(true)
        setPerformanceReviewModalData(data)
    }

    const handleDocumentReviewModal = (data) => {
        setDocumentReviewModal(true)
        setDocumentReviewModalData(data)
    }

    const handleHireModal = (data) => {
        setHireModal(true)
        setHireModalData(data)
    }

    const handleUpdateChecklistData = (data) => {
        setUpdateChecklistData(data)
        setUpdateChecklistModal(true)
    }

    const handleHireFinalModal = (data) => {
        setFinalHireModal(true)
        setFinalHireData(data)
    }

    const [disable,setDisable] = useState(false)

    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const [form, setForm] = useState({
        embarkation:'',
        sign_on:'',
        sign_off:'',
    })

    const hideModal = () => {
       setFinalHireModal(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setDisable(true)

        axiosClient.post('/company/hire/employment-replace', {
            hire_id: finalHireData,
            embarkation: form.embarkation,
            sign_on: form.sign_on,
            sign_off: form.sign_off,
        })
            .then(({data}) => {
                setDisable(false)
                toast.success(data?.message)
                setFinalHireModal(false)
                getReportData()
                setForm({
                    embarkation:'',
                    sign_on:'',
                    sign_off:'',
                })

            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    return (
        <>
            <Breadcrumb title='Hired Crews'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Crew</th>
                                            <th>Rank</th>
                                            <th>Nationality</th>
                                            <th>Date of Readiness</th>
                                            <th>Action</th>
                                         </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData?.data?.data?.length > 0  ? reportData?.data?.data?.map((item,index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{item?.user_name}</td>
                                                        <td>{item?.category_name}</td>
                                                        <td>{item?.country_name}</td>
                                                        <td>{item?.readiness_date}</td>
                                                        <td>
                                                                <Link className="btn btn-primary btn-sm mr-1" to={`${COMPANYROUTE.USER_PROFILE}${item?.emp_id}`}>Profile</Link>
                                                                <button className="btn btn-sm btn-success mr-1" onClick={() => handleDocumentReviewModal(item?.emp_id)}  >Document Review</button>
                                                                <button className="btn btn-sm btn-info mr-1" onClick={() => handelPerformanceReviewModal(item?.emp_id)} >Performance  Review</button>
                                                                {
                                                                    item?.hire_status_id !== false && <button className="btn btn-sm btn-warning text-white ml-2" onClick={() => handleHireModal(item?.hire_id)} >Hire</button>
                                                                }
                                                                {
                                                                    item?.hire_doc !== false &&
                                                                    <>
                                                                        <button className="btn btn-sm btn-warning text-white mr-1  " onClick={() => handleUpdateChecklistData(item?.hire_doc)} >Update Checklist</button>
                                                                        <Link className="btn btn-secondary  btn-sm mr-1" to={`${COMPANYROUTE.EMPLOYMENT_CONTRACT}${item?.hire_id}`}>Employment Contract</Link>
                                                                        <button className="btn btn-sm btn-success text-white   mr-1" onClick={() => handleHireFinalModal(item?.hire_id)} >Final</button>
                                                                    </>

                                                                }

                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''
                                        }



                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">

                                        <Pagination
                                            activePage={reportData?.data?.current_page ? reportData?.data?.current_page : 0}
                                            itemsCountPerPage={reportData?.data?.per_page ? reportData?.data?.per_page : 0 }
                                            totalItemsCount={reportData?.data?.total ? reportData?.data?.total : 0}
                                            onChange={(pageNumber) => {
                                                getReportData(pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                performanceReviewModal === true && <CrewPerfomanceModal data={performanceReviewModalData} hide={() => setPerformanceReviewModal(false)}   />
            }
            {
                documentReviewModal === true && <ContractPeriodModal data={documentReviewModalData} hide={() => setDocumentReviewModal(false)}   />
            }

            {
                hireModal === true && <CrewHireModal data={hireModalData} hide={() => setHireModal(false)}   />
            }
            {
                updateChecklistModal === true && <UpdateChecklistModal data={updateChecklistData} hide={() => setUpdateChecklistModal(false)}   />
            }

            {
                finalHireModal === true &&
                <div className="modal show fade "  style={modelStyle} >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content"  >
                            <div className="modal-header">
                                <h5 className="modal-title">HIRED CREW</h5>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <div className="form-group">
                                                    <label htmlFor="">Departure Date</label>
                                                    <DatePicker
                                                        selected={form?.embarkation}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,embarkation:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Sign-On Date</label>
                                                    <DatePicker
                                                        selected={form?.sign_on}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,sign_on:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Sign-Off Date</label>
                                                    <DatePicker
                                                        selected={form?.sign_off}
                                                        dateFormat='dd-MM-yyyy'
                                                        className="form-control"
                                                        onChange={e => setForm({...form,sign_off:e})}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                                    <button type="submit" disabled={disable} className="btn btn-success"   >{
                                        disable === true ? 'Saving...' : 'Save'
                                    }</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            }

        </>
    );
};

export default FleetBudget;