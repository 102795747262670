import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

const Edit = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [rank,setRank] = useState([]);

    const [disabled,setDisabled] = useState(false);

    const [name,setName] = useState('');
    const [rankGroup,setRankGroup] = useState([]);

    const getRank = () => {
        axiosClient.get('/company/get-ranks')
            .then(({data}) => {
                setRank(data.data)
            }).catch(() => {

        })
    }

    useEffect(() => {
        getRank()
    },[])

    const getData = () => {
        axiosClient.get('/company/rank-group/' + id + '/edit')
            .then(({data}) => {
                setName(data.data.group_name)
                setRankGroup(data.data.rank_ids)
            }).catch(() => {
                toast.error('Something went wrong');
                navigate(`${COMPANYROUTE.RANK_GROUP}`)
        })
    }

    useEffect(() => {
        getData()
    },[])


    const handleSubmit = (e) => {
        e.preventDefault();

        setDisabled(true)
        axiosClient.put('/company/rank-group/'+id,{
            group_name: name,
            rank_group: rankGroup
        })
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
                navigate(`${COMPANYROUTE.RANK_GROUP}`);
            }).catch(err => {
            setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }
    return (
        <div>
            <Breadcrumb title='Edit Rank Group' prev={COMPANYROUTE.RANK_GROUP}    />
            <section className="content">
                <div className="container-fluid">

                    <div className="card card-primary">

                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="col-md-6 col-12">
                                    <div className="row">
                                        <div className="col-12  ">
                                            <div className="form-group">
                                                <label htmlFor="grt">Group Name</label>
                                                <input
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                    type="text"   className="form-control" id="grt"
                                                    placeholder="Enter Group Name" />
                                            </div>
                                        </div>

                                        <div className="col-12  ">
                                            <div className="form-group">
                                                <label htmlFor="grt">Rank</label>
                                                <div className="row">

                                                    {
                                                        rank.map((item,index) => {
                                                            return (
                                                                <div className="col-12 col-md-6" key={index}>
                                                                    <div key={index} className="form-check d-flex">
                                                                        <input className="form-check-input"
                                                                               onChange={e => {
                                                                                   const {checked} = e.target;
                                                                                   if (checked) {
                                                                                       setRankGroup([...rankGroup,item?.category_id.toString()])
                                                                                   } else {
                                                                                       const newRankGroup = rankGroup.filter((r) => r !== item?.category_id.toString());
                                                                                       setRankGroup(newRankGroup)
                                                                                   }
                                                                               }}
                                                                                 checked={rankGroup.includes(item?.category_id.toString())}
                                                                               type="checkbox" value="" id={item?.category_id} />
                                                                        <label className="form-check-label"  htmlFor={item?.category_id}>
                                                                            {item?.category_name}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>

                            <div className="card-footer">
                                <button type="submit" className="btn btn-success">{
                                    disabled ? 'Please Wait....' : 'Update'
                                }</button>
                            </div>
                        </form>

                    </div>

                </div>
            </section>
        </div>

    );
};

export default Edit;