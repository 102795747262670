import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import TopFourRank from "../../../Components/Company/Common/TopFourRank";
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";


const SeafarerLeftReport = () => {
    const [rankSelect,setRankSelect] = useState('');
    const [reportData,setReportData] = useState([]);
    var date = new Date();
    const [dateFrom,setDateFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateTo,setDateTo] = useState(new Date(date.getFullYear(), date.getMonth()+1, 0));

    const getReportData = (rank = 0,dateFromData = null,dateToData = null) => {
        axiosClient.post('/company/report/seafarer-left',{
            rank_id: rank,
            date_from: dateFromData,
            date_to: dateToData
        })
            .then(({data}) => {
                setReportData(data.data)
            }).catch((e) => {
            console.log(e)
        })
    }

    const columns = [

        {
            name: '#SL',
            selector: row => row.sl,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Seafarer Name',
            selector: row => row.user_name,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Rank',
            selector: row => row.rank_name,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Vessel',
            selector: row => row.vessel_name,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Leaving Contract No.',
            selector: row => row.contact_no ,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Remarks',
            selector: row => row.contract_remarks ,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'NTBR',
            selector: row => row.status ,
            sortable: true,
            className: 'header-row',
        },
        {
            name: 'Rehire Remarks',
            selector: row => row.rehire_remarks ,
            sortable: true,
            className: 'header-row',
        },

    ];






    useEffect(() => {
        let dateFromData = new Date(dateFrom).toLocaleDateString();
        let dateToData = new Date(dateTo).toLocaleDateString();
        getReportData(rankSelect,dateFromData,dateToData)

    },[rankSelect,dateFrom,dateTo])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/seafarer-left/print',
        payload: { rank_id: rankSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString() },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Seafarer Name', key: 'user_name', width: 20 },
        { header: 'Vessel', key: 'vessel_name', width: 20 },
        { header: 'Leaving Contract No', key: 'contact_no', width: 20 },
        { header: 'Remarks', key: 'contract_remarks', width: 20 },
        { header: 'NTBR', key: 'status', width: 20 },
        { header: 'Rehire Remarks', key: 'rehire_remarks', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/seafarer-left/export',{
            rank_id: rankSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Seafarer Left Report','seafarer-left-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/seafarer-left/export',{
            rank_id: rankSelect,  date_from: new Date(dateFrom).toLocaleDateString(), date_to: new Date(dateTo).toLocaleDateString()
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Seafarer Left Report','seafarer-left-report',exportColumns)
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Seafarer Left Reports'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-12 col-md-9">
                                                <div className="row">
                                                    <div className='col-12 col-md-4'>
                                                        <TopFourRank rankSelect={rankSelect} setRankSelect={setRankSelect} />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <DatePicker
                                                            selected={dateFrom}
                                                            dateFormat='dd-MM-yyyy'
                                                            className="form-control"
                                                            onChange={(date) => setDateFrom(date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-4'>
                                                        <DatePicker
                                                            selected={dateTo}
                                                            dateFormat='dd-MM-yyyy'
                                                            className="form-control"
                                                            onChange={(date) => setDateTo(date)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="float-right">
                                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                    <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                    <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        columns={columns}
                                        data={reportData}
                                        pagination={true}
                                        paginationPerPage={30}
                                        paginationRowsPerPageOptions={[30, 50, 100,500]}
                                        fixedHeader={true}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerLeftReport;