import {createContext, useContext, useState} from "react";

const StateContext = createContext({
    user:null,
    token:null,
    notifications:null,
    userType:null,
    userId:null,
    role:null,
    setUser: () => {},
    setToken: () => {},
    setNotification: () => {},
    setUserType: () => {},
    setUserId: () => {},
    setRole: () => {},
});


export const ContextProvider = ({children}) => {
    const [user,setUser] = useState({});
    const [notification,_setNotification] = useState('');
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));
    const [userType, _setUserType] = useState(localStorage.getItem('USER_TYPE'));
    const [userId, _setUserId] = useState(localStorage.getItem('USER_ID'));
    const [role,_setRole] = useState(localStorage.getItem('ROLE_ID'));
    const setNotification = (notification) => {
        _setNotification(notification);
        setTimeout(() => {
            _setNotification('');
        },5000)
    }

    const setToken = (token) => {
        _setToken(token)
        if(token){
            localStorage.setItem('ACCESS_TOKEN',token);
        }else{
            localStorage.removeItem('ACCESS_TOKEN')
        }
    }
    const setUserId = (userId) => {
        _setUserId(userId)
        if(userId){
            localStorage.setItem('USER_ID',userId);
        }else{
            localStorage.removeItem('USER_ID')
        }
    }
    const setRole = (role) => {
        _setRole(role)
        if(role){
            localStorage.setItem('ROLE_ID',role);
        }else{
            localStorage.removeItem('ROLE_ID')
        }
    }
    const setUserType = (type) => {
        _setUserType(type)
        if(type){
            localStorage.setItem('USER_TYPE',type);
        }else{
            localStorage.removeItem('USER_TYPE')
        }
    }

    return (
        <StateContext.Provider value={{
            user,
            token,
            setUser,
            setToken,
            notification,
            setNotification,
            userType,
            setUserType,
            userId,
            setUserId,
            role,
            setRole
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)

