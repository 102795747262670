import React, {useState} from 'react';
import CrewEmailSentModal from "./CrewEmailSentModal";
import {toast} from "react-toastify";
import CrewSmsSentModal from "./CrewSmsSentModal";
import axiosClient from "../../../axios-client";
import {useNavigate, useParams} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {VESSEL_CREW_PLANNER_REPLACE_TANKER} from "../../../constants/companyRoutes";

const CrewPlanerCompanyModal = ({data = [],rank,hide}) => {

    const {id} = useParams();

    const navigate = useNavigate();

    const [dataArray,setDataArray] = useState([])
    const [disable,setDisable] = useState(false)

    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }



    const handleChange = (e) => {
        let value = e.target.value;
        let checked = e.target.checked;
        if(checked){
            setDataArray([...dataArray,value])
        }else{
            setDataArray(dataArray.filter(item => item !== value))
        }
    }




    const handleSubmit = (e) => {
        e.preventDefault();
        if(dataArray.length <= 0){
            toast.error('Please Select At Least One Company')
        }else{
            setDisable(true)
             let company = dataArray.join('+')
            axiosClient.post('/company/crew-planner-replace-tanker', {
                replace_rank_id: rank,
                replace_vessel_id: id,
                company: dataArray
            })
                .then(({data}) => {
                    setDisable(false)
                    navigate(COMPANYROUTE.VESSEL_CREW_PLANNER_REPLACE_TANKER + id+ '/' + rank + '/' + company)

                }).catch(err => {
                const response = err.response;
                setDisable(false)
                if (response && response.status === 422) {
                    if (response.data?.errors) {
                        const errors = response.data.errors;
                        for (const key in errors) {
                            toast.error(errors[key][0]);
                        }
                    }
                }
            })
        }
    }





    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title">State Criteria</h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="card-body">
                                    <div className="row">

                                    {
                                        data?.length > 0 && data.map((item,index) => {
                                            return (
                                              <div className="col-md-3 col-6"  key={index}>
                                                  <div className="form-group row">
                                                      <div className="d-flex">
                                                          <input id={item?.company_id} type="checkbox" className="mr-2"   onChange={handleChange} value={item?.company_name.toLowerCase()} />
                                                          <label className="mt-1" htmlFor={item?.company_id}>{item?.company_name} </label>
                                                      </div>
                                                  </div>
                                              </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                                <button type="submit" className="btn btn-success"   >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </>
    );
};

export default CrewPlanerCompanyModal;