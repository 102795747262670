import React, {useEffect, useState} from 'react';
import './notification.css'
import axiosClient from "../../../axios-client";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {Link} from "react-router-dom";
import usePrintWindow from "../../../hooks/usePrintWindow";
const CrewChangeDueOverDue = () => {
    const [notificationData,setNotificationData] = useState([]);
    const [notificationDataCount,setNotificationDataCount] = useState(0);

    const getCrewChangeNotificationData = () => {
        axiosClient.get('/company/notification/crew-change-overdue')
            .then(({data}) => {
                setNotificationData(data.data.crew_data)
                setNotificationDataCount(data.data.crew_count)
            }).catch((e) => {
        })
    }

    useEffect(() => {
        getCrewChangeNotificationData()
    },[])

    const { handlePrint, loading } = usePrintWindow({
        url: '/company/notification/crew-change-overdue/print',
    });
    return (
            <div className="card-body">
                <div className="d-flex justify-content-center">
                    <button className='btn   btn-sm  btn-primary text-center mr-2  ' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                </div>
                <div id="accordion">
                    <div className="card card-danger">
                        <div className="card-header">
                            <h4 className="card-title w-100">
                                <a className="d-block w-100 collapsed text-white" data-toggle="collapse"
                                   href="#crewChangeDueOverDue" aria-expanded="false">
                                    Crew Change Overdue ({notificationDataCount ?? 0})
                                </a>
                            </h4>
                        </div>
                        <div id="crewChangeDueOverDue" className="collapse notification-content" data-parent="#accordion"  >
                            {notificationData.length > 0 ? notificationData.map((data,index) => {
                                return  (
                                    <div className="card-body notification" key={index}>
                                        <div className="row">
                                            <div className="col-4 ">
                                                <img  src={`${data?.image_url ? data?.image_url : '/images/demo-user.png'}`}   className="img-fluid img-rounded " alt=""/>
                                                <Link to={`${COMPANYROUTE.VESSEL_CREW_PLANNER}${data?.vessel_id}`} className="btn btn-sm btn-danger mt-2 text-center">Replace</Link>
                                            </div>
                                            <div className="col-8">
                                                <h6 className="text-center">{data?.name}</h6>
                                                <p className="text-center"><strong>CDC No:</strong> {data?.cdc_no}</p>
                                                <p className="text-center"><strong>Rank:</strong> {data?.rank}</p>
                                                <p className="text-center"><strong>Vessel:</strong>{data?.vessel}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}) : <div className="card-body notification">
                                <div className="row">
                                    <div className="col-12 ">
                                        <h6 className="text-center">No Data Found</h6>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
    );
};

export default CrewChangeDueOverDue;