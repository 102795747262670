import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../axios-client";
import AllVessel from "../../../Components/Company/Common/AllVessel";
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component';

import './report.css';
import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";
import AllRank from "../../../Components/Company/Common/AllRank";
import Pagination from "react-js-pagination";
import TopFourRank from "../../../Components/Company/Common/TopFourRank";
const SeafarerTopFourRankContractReport = () => {
    const [rankSelect,setRankSelect] = useState('');

    const [reportData,setReportData] = useState([]);


    const getReportData = ( rankSelect = 0,pageNumber = 1) => {
        axiosClient.post('/company/report/top-four-seafarer-rank-contract',{
            category_id: rankSelect,
            page: pageNumber
        })
            .then(({data}) => {
                console.log(data?.data)
                setReportData(data?.data)
            }).catch((e) => {
            console.log(e)
        })
    }


    useEffect(() => {
        getReportData(rankSelect,1)

    },[rankSelect])




    const { handlePrint, loading } = usePrintWindow({
        url: '/company/report/top-four-seafarer-rank-contract/print',
        payload: { category_id:rankSelect},
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Seafarer Name', key: 'name', width: 20 },
        { header: 'Rank', key: 'category_name', width: 20 },
        { header: 'Total Contract', key: 'contract', width: 20 },
        { header: 'Status', key: 'status', width: 20 },
    ];
    const exportExcelFile = () => {
        axiosClient.post('/company/report/top-four-seafarer-rank-contract/export',{
            category_id:rankSelect
        })
            .then(({data}) => {
                UseExportHook( 'xlsx',data,'Top Four Seafarer Rank Contract Reports','top-four-seafarer-contract-report',exportColumns)
            }).catch(() => {
        })
    };

    const exportCSVFile = () => {
        axiosClient.post('/company/report/top-four-seafarer-rank-contract/export',{
            category_id:rankSelect
        })
            .then(({data}) => {
                UseExportHook( 'csv',data,'Top Four Seafarer Rank Contract Reports','top-four-seafarer-contract-report',exportColumns)
            }).catch(() => {
        })
    };

    return (
        <>
            <Breadcrumb title='Top Four Seafarer Rank Contract Reports'    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className='col-12 col-md-4'>
                                                    <TopFourRank rankSelect={rankSelect} setRankSelect={setRankSelect} />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-3">
                                            <div className="float-right">
                                                <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile}><i className="fa fa-file-csv"></i> CSV</button>
                                                <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile}><i className="fa fa-file-excel"></i> Export</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body  "  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>Seafarer Name</th>
                                            <th>Rank</th>
                                            <th>Total Contract</th>
                                            <th>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>


                                        {reportData?.data?.length > 0 ?  reportData?.data?.map((data,index) => (

                                            <tr  key={index} >
                                                <td>{data?.name}</td>
                                                <td>{data?.category_name}</td>
                                                <td>{data?.contract}</td>
                                                <td>{data?.status}</td>
                                            </tr>
                                        )) : <tr><td colSpan={5} className='text-center'>No Data Found</td></tr>}


                                        </tbody>

                                    </table>

                                    <div className="mt-2 justify-content-center d-flex">

                                        <Pagination
                                            activePage={reportData?.current_page ? reportData?.current_page : 0}
                                            itemsCountPerPage={reportData?.per_page ? reportData?.per_page : 0 }
                                            totalItemsCount={reportData?.total ? reportData?.total : 0}
                                            onChange={(pageNumber) => {
                                                getReportData(rankSelect,pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeafarerTopFourRankContractReport;