import Breadcrumb from "../../Components/Company/Common/Breadcrumb";
import SignOnOffChart from "../../Components/Company/Chart/SignOnOffChart";
import SupplyDemandChart from "../../Components/Company/Chart/SupplyDemandChart";
import SeafarerContractChart from "../../Components/Company/Chart/SeafarerContractChart";
import DurationComparison from "../../Components/Company/Chart/DurationComparison";
import SalaryTrendChart from "../../Components/Company/Chart/SalaryTrendChart";
import SeafarerTurnoverChart from "../../Components/Company/Chart/SeafarerTurnoverChart";
import CrewChangeDue60Days from "../../Components/Company/Notification/CrewChangeDue60Days";
import CrewChangeDue30Days from "../../Components/Company/Notification/CrewChangeDue30Days";
import CrewChangeOverDue from "../../Components/Company/Notification/CrewChangeOverDue";
import DocumentRenewal60Days from "../../Components/Company/Notification/DocumentRenewal60Days";
import DocumentRenewal30Days from "../../Components/Company/Notification/DocumentRenewal30Days";
import DocumentRenewalOverdue from "../../Components/Company/Notification/DocumentRenewalOverdue";
import axiosClient from "../../axios-client";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as UserRoute from "../../constants/UserRoute";


const Dashboard = () => {

    const [userInfo,setUserInfo] = useState({})
    const [jobList,setJobList] = useState([])

    const getData = (dateFromData = null) => {
        axiosClient.get('/seafarer/dashboard')
            .then(({data}) => {
               setUserInfo(data?.userInfo)
                setJobList(data?.newJob)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getData()
    },[])



    return (
        <>   
            <Breadcrumb title='Dashboard'   />

            <>
                <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">
                                        <h5 className="card-title">Welcome to Global Maricruit</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                 <div className="card card-info card-outline">
                                      <div className="card-body">
                                          <img src={userInfo?.image} width="200" height="200" alt=""/>
                                            <div className="mt-2">
                                                <p><strong>Name:</strong> {userInfo?.name}</p>
                                                <p><strong>Rank:</strong> {userInfo?.rank}</p>
                                                <p><strong>CDC:</strong> {userInfo?.cdc}</p>
                                                <p><strong>Mobile:</strong> {userInfo?.mobile}</p>

                                            </div>
                                      </div>
                                 </div>
                            </div>
                            
                            <div className="col-md-6 col-12">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Link state={{type:'personalInfo'}} to={UserRoute.USER_PROFILE} className="btn btn-success btn-block" href=""><i className="fa fa-users"> Update Profile</i></Link>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Link to={UserRoute.PRE_JOINING_FAMILIARISATION} className="btn btn-success btn-block" href=""><i className="fa fa-chart-area"></i> Pre Joining Familiarisation</Link>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <div className="card card-info card-outline ">
                                            <div className="card-body">
                                                <h5 className="card-title mb-3">New Job</h5>
                                                <table className="table table-bordered">
                                                     <thead>
                                                         <tr>
                                                             <th>Rank</th>
                                                             <th>Image</th>
                                                             <th>Details</th>
                                                         </tr>
                                                     </thead>

                                                    <tbody>
                                                    {
                                                       jobList?.length > 0 ? jobList?.map((item,index) => {
                                                             return (
                                                                    <tr key={index}>
                                                                        <td>{item?.rank}</td>
                                                                        <td><img src={item?.image} width="50" height="50" alt=""/></td>
                                                                        <td></td>
                                                                    </tr>
                                                                )} ) : <tr>
                                                                    <td colSpan="3" className="text-center">No Job Found</td>
                                                                </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

           </>

        </>

    );
};

export default Dashboard;