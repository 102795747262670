import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";

const VesselBudget = () => {
    const {id} = useParams();
    const [reportData,setReportData] = useState([]);
    const [budgetHeadData,setBudgetHeadData] = useState([]);
    const [budgetModal,setBudgetModal] = useState(false);
    const [vesselName,setVesselName] = useState("")
    const [modifyBudgetModal,setModifyBudgetModal] = useState(false);
    const [expenseModal,setExpenseModal] = useState(false);
    const [modifyExpenseModal,setModifyExpenseModal] = useState(false);


    const [budgetForm,setBudgetForm] = useState({
        vessel:parseInt(id),
        budget_amount:'',
        budget_head_id:'',
        remarks:'',
    })

    const [modifyBudgetForm,setModifyBudgeForm] = useState({
        vessel:parseInt(id),
        budget_amount:'',
        budget_head_id:'',
        remarks:'',
        year:'',
        budget_id:''
    })


    const [expenseForm,setExpenseForm] = useState({
        vessel:parseInt(id),
        budget_head_id:'',
        expense_year:'',
        month:'',
        amount:'',
        remarks:'',
    })

    const [modifyExpenseForm,setModifyExpenseForm] = useState({
           vessel:parseInt(id),
           budget_head_id:'',
           expense_year:'',
           month:'',
           amount:'',
           remarks:'',
           expense_id:''
    })





    const getReportData = () => {
        axiosClient.get('/company/budget/' + id)
            .then(({data}) => {
                setReportData(data?.data)
                setVesselName(data?.vessel_name)
                setBudgetHeadData(data?.budgetHeadData)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])

    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const handleBudgetModal = () => {
        setBudgetModal(true)
    }

    const handelBudgetModalClose = () => {
        setBudgetModal(false)
    }

    const handleBudgetSubmit = (e) => {
         e.preventDefault()

        axiosClient.post('/company/budget/store',budgetForm)
            .then(({data}) => {
                toast.success(data.message);
                getReportData()
                setBudgetModal(false)
                setBudgetForm({
                    vessel:parseInt(id),
                    budget_amount:'',
                    budget_head_id:'',
                    remarks:'',
                })
            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }

    const handleModifyBudgetModal = () => {
        setModifyBudgetModal(true)
    }

    const handelModifyBudgetModalClose = () => {
        setModifyBudgetModal(false)
    }

    const handleBudgetModifySubmit = (e) => {
        e.preventDefault()

        axiosClient.post('/company/budget/modify',modifyBudgetForm)
            .then(({data}) => {
                toast.success(data.message);
                getReportData()
                setModifyBudgetModal(false)
                setModifyBudgeForm({
                    vessel:parseInt(id),
                    budget_amount:'',
                    budget_head_id:'',
                    remarks:'',
                    year:'',
                    budget_id:''
                })
            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })

    }


    const getModifyAmountData = () => {
        axiosClient.post('/company/budget/get-modify-budget-amount',{
            budget_vessel:id,
            budget_year:modifyBudgetForm.year,
            budget_head:modifyBudgetForm.budget_head_id,
        })
            .then(({data}) => {
                setModifyBudgeForm((prevForm) => ({
                    ...prevForm,
                    budget_amount: data?.amount,
                    remarks: data?.remarks,
                    budget_id: data?.budget_id,
                }));
            }).catch(() => {
        })
    }

    useEffect(() => {
        getModifyAmountData()
    },[modifyBudgetForm.year,modifyBudgetForm.budget_head_id])


    const handelExpenseModal = () => {
        setExpenseModal(true)
    }

    const handelExpenseModalClose = () => {
        setExpenseModal(false)
    }

    const handleExpenseSubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/expense/store',expenseForm)
            .then(({data}) => {
                toast.success(data.message);
                getReportData()
                setExpenseModal(false)
                setExpenseForm({
                    vessel:parseInt(id),
                    budget_head_id:'',
                    expense_year:'',
                    month:'',
                    amount:'',
                    remarks:'',
                })
            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }


    const handelExpenseModifyModal = () => {
        setModifyExpenseModal(true)
    }

    const handelExpenseModifyModalClose = () => {
        setModifyExpenseModal(false)
    }

    const getModifyExpenseData = () => {
        axiosClient.post('/company/expense/get-modify-expense-amount',{
            vessel:id,
            expense_year:modifyExpenseForm.expense_year,
            expense_month:modifyExpenseForm.month,
            budget:modifyExpenseForm.budget_head_id,
        })
            .then(({data}) => {
                setModifyExpenseForm((prevForm) => ({
                    ...prevForm,
                    amount: data?.amount,
                    remarks: data?.remarks,
                    expense_id: data?.expense_id,
                }));
            }).catch((res) => {
        })
    }

    useEffect(() => {
        getModifyExpenseData()
    },[modifyExpenseForm.expense_year,modifyExpenseForm.month,modifyExpenseForm.budget_head_id])


    const handleExpenseModifySubmit = (e) => {
        e.preventDefault();
        axiosClient.post('/company/expense/update',modifyExpenseForm)
            .then(({data}) => {
                toast.success(data.message);
                getReportData()
                setModifyExpenseModal(false)
                setModifyExpenseForm({
                    vessel:parseInt(id),
                    budget_head_id:'',
                    expense_year:'',
                    month:'',
                    amount:'',
                    remarks:'',
                    expense_id:''
                })
            }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete this budget?`)){
            return false;
        }
        axiosClient.post('/company/budget/delete',{
            year:data.budget_year,
            vessel_id:data?.vessel_id
        })
            .then((data) => {
                toast.success(data.data.message);
                getReportData();
            })
    }

    return (
         <>

             <Breadcrumb title={`${vesselName} Budget  `} prev={COMPANYROUTE.VESSEL}  />
             <div className="content-header">
                 <div className="container-fluid">
                     <div className="row mb-2">

                         <div className="col-12">
                             <div className="float-right">
                                 <button onClick={() => handleBudgetModal()} className='btn btn-info mr-2'><i className="fa fa-plus-circle"></i> Current Budget</button>
                                 <button onClick={() => handleModifyBudgetModal()} className='btn btn-info mr-2'><i className="fa fa-plus-circle"></i> Modify Budget</button>
                                 <button onClick={() => handelExpenseModal()} className='btn btn-danger mr-2'><i className="fa fa-plus-circle"></i> Add Expense</button>
                                 <button onClick={() => handelExpenseModifyModal()} className='btn btn-danger mr-2'><i className="fa fa-plus-circle"></i> Modify Expense</button>
                             </div>

                         </div>
                     </div>
                 </div>
             </div>

             <section className="content">
                 <div className="container-fluid">
                     <div className="row">
                         <div className="col-12">
                             <div className="card">


                                 <div className="card-body">
                                     <table id="data-table" className="table table-bordered table-hover">
                                         <thead className='bg-info'>
                                         <tr>
                                             <th>#</th>
                                             <th>Title</th>
                                             <th>Year</th>
                                             <th>Budget</th>
                                             <th>Expense</th>
                                             <th>Variance</th>
                                             <th>Action</th>
                                         </tr>
                                         </thead>
                                         <tbody>
                                         {
                                            reportData?.length > 0 ? reportData.map((data,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{data?.title}</td>
                                                        <td>{data?.budget_year}</td>
                                                        <td>{data?.amount_format}</td>
                                                        <td>{data?.expense_amount}</td>
                                                        <td>{data?.balance}</td>
                                                        <td>
                                                             <Link to={`${COMPANYROUTE.VESSEL_BUDGET_SHOW}${data?.budget_year}/${data?.vessel_id}`} className="btn btn-primary btn-sm mr-2"><i className="fa fa-eye"></i></Link>
                                                             <Link to={`${COMPANYROUTE.VESSEL_BUDGET_EDIT}${data?.budget_year}/${data?.vessel_id}`} className="btn btn-info btn-sm mr-2"><i className="fa fa-pencil-alt"></i></Link>
                                                            <button  onClick={e => onDelete(data)}   className="btn btn-sm btn-danger text-white"  ><i className="fa fa-trash"></i></button>

                                                        </td>
                                                    </tr>
                                                )
                                            }) : <tr>
                                                <td colSpan={8} className='text-center'>No Data Found</td>
                                            </tr>

                                         }

                                         </tbody>

                                     </table>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </section>

         {/*    current budget */}

             {
            budgetModal &&
                 <div className="modal show fade "  style={modelStyle} >
                     <div className="modal-dialog modal-lg">
                         <div className="modal-content"  >
                             <div className="modal-header">
                                 <h5 className="modal-title">Budget Year {new Date().getFullYear()} </h5>
                             </div>
                             <form  onSubmit={handleBudgetSubmit}>
                                 <div className="modal-body">
                                     <div className="form-group">
                                         <label htmlFor="">Select Budget</label>
                                         <select onChange={e => setBudgetForm({...budgetForm,budget_head_id:e.target.value})}
                                                    value={budgetForm.budget_head_id}
                                                    className="form-control">
                                                <option value="">Select Budget</option>
                                             {
                                                 budgetHeadData?.length > 0 ? budgetHeadData.map((data,index) => {
                                                     return (
                                                         <>
                                                             <option key={index} value={data?.budget_head_id}>{data?.name}</option>
                                                             {data?.child.length > 0 && data?.child.map((child,i) => (
                                                                 <option key={i} value={child.budget_head_id}>{child.name}</option>
                                                             ))}
                                                         </>
                                                     )
                                                 }) : ''
                                             }
                                            </select>
                                     </div>
                                     <div className="form-group">
                                         <label htmlFor="">Budget Amount</label>
                                         <input  onChange={e => setBudgetForm({...budgetForm,budget_amount:e.target.value})}
                                                 value={budgetForm.budget_amount}
                                                 type="number" className="form-control"/>
                                     </div>

                                     <div className="form-group">
                                         <label htmlFor="">Remarks</label>
                                         <input  onChange={e => setBudgetForm({...budgetForm,remarks:e.target.value})}
                                                 value={budgetForm.remarks}
                                                 type="text" className="form-control"/>
                                     </div>
                                 </div>
                                 <div className="modal-footer justify-content-between">
                                     <button type="button" className="btn btn-danger" onClick={handelBudgetModalClose}  >Close</button>
                                     <button type="submit" className="btn btn-success">Submit</button>

                                 </div>
                             </form>
                         </div>
                     </div>
                 </div>
             }



             {
                 modifyBudgetModal &&
                 <div className="modal show fade "  style={modelStyle} >
                     <div className="modal-dialog modal-lg">
                         <div className="modal-content"  >
                             <div className="modal-header">
                                 <h5 className="modal-title">Budget Year {new Date().getFullYear()} </h5>
                             </div>
                             <form  onSubmit={handleBudgetModifySubmit}>
                                 <div className="modal-body">
                                     <div className="form-group">
                                         <label htmlFor="">Select Budget Year</label>
                                         <select onChange={e => setModifyBudgeForm({...modifyBudgetForm,year:e.target.value})}
                                                 value={modifyBudgetForm.year}
                                                 className="form-control">
                                             <option value="">Select Budget Year</option>
                                                 {Array.from(Array(21).keys(),x => x+2022).map((item,index) => (
                                                         <option value={item}>{item}</option>
                                                 ))}
                                         </select>
                                     </div>
                                     <div className="form-group">
                                         <label htmlFor="">Select Budget</label>
                                         <select onChange={e => setModifyBudgeForm({...modifyBudgetForm,budget_head_id:e.target.value})}
                                                 value={modifyBudgetForm.budget_head_id}
                                                 className="form-control">
                                             <option value="">Select Budget</option>
                                             {
                                                 budgetHeadData?.length > 0 ? budgetHeadData.map((data,index) => {
                                                     return (
                                                         <>
                                                             <option key={index} value={data?.budget_head_id}>{data?.name}</option>
                                                             {data?.child.length > 0 && data?.child.map((child,i) => (
                                                                 <option key={i} value={child.budget_head_id}>{child.name}</option>
                                                             ))}
                                                         </>
                                                     )
                                                 }) : ''
                                             }
                                         </select>
                                     </div>
                                     <div className="form-group">
                                         <label htmlFor="">Budget Amount</label>
                                         <input  onChange={e => setModifyBudgeForm({...modifyBudgetForm,budget_amount:e.target.value})}
                                                 value={modifyBudgetForm.budget_amount}
                                                 type="number" className="form-control"/>
                                     </div>

                                     <div className="form-group">
                                         <label htmlFor="">Remarks</label>
                                         <input  onChange={e => setModifyBudgeForm({...modifyBudgetForm,remarks:e.target.value})}
                                                 value={modifyBudgetForm.remarks}
                                                 type="text" className="form-control"/>
                                     </div>
                                 </div>
                                 <div className="modal-footer justify-content-between">
                                     <button type="button" className="btn btn-danger" onClick={handelModifyBudgetModalClose}  >Close</button>
                                     <button type="submit" className="btn btn-success">Submit</button>

                                 </div>
                             </form>
                         </div>
                     </div>
                 </div>
             }


             {
                 expenseModal &&
                 <div className="modal show fade "  style={modelStyle} >
                     <div className="modal-dialog modal-lg">
                         <div className="modal-content"  >
                             <div className="modal-header">
                                 <h5 className="modal-title">Create Expense </h5>
                             </div>
                             <form  onSubmit={handleExpenseSubmit}>
                                 <div className="modal-body">
                                     <div className="form-group">
                                         <label htmlFor="">Select Expense Year</label>
                                         <select onChange={e => setExpenseForm({...expenseForm,expense_year:e.target.value})}
                                                 value={expenseForm.expense_year}
                                                 className="form-control">
                                             {Array.from(Array(21).keys(),x => x+2022).map((item,index) => (
                                                 <option value={item}>{item}</option>
                                             ))}
                                         </select>
                                     </div>
                                     <div className="form-group">
                                         <label htmlFor="">Select Expense  Month</label>
                                         <select onChange={e => setExpenseForm({...expenseForm,month:e.target.value})}
                                                 value={expenseForm.month}
                                                 className="form-control">
                                             <option value="">Select Month</option>
                                             <option value="1">January</option>
                                             <option value="2">February</option>
                                             <option value="3">March</option>
                                             <option value="4">April</option>
                                             <option value="5">May</option>
                                             <option value="6">June</option>
                                             <option value="7">July</option>
                                             <option value="8">August</option>
                                             <option value="9">September</option>
                                             <option value="10">October</option>
                                             <option value="11">November</option>
                                             <option value="12">December</option>
                                         </select>
                                     </div>

                                     <div className="form-group">
                                         <label htmlFor="">Select Budget</label>
                                         <select onChange={e => setExpenseForm({...expenseForm,budget_head_id:e.target.value})}
                                                 value={expenseForm.budget_head_id}
                                                 className="form-control">
                                             <option value="">Select Budget</option>
                                             {
                                                 budgetHeadData?.length > 0 ? budgetHeadData.map((data,index) => {
                                                     return (
                                                         <>
                                                             <option key={index} value={data?.budget_head_id}>{data?.name}</option>
                                                             {data?.child.length > 0 && data?.child.map((child,i) => (
                                                                 <option key={i} value={child.budget_head_id}>{child.name}</option>
                                                             ))}
                                                         </>
                                                     )
                                                 }) : ''
                                             }
                                         </select>
                                     </div>
                                     <div className="form-group">
                                         <label htmlFor="">Expense Amount</label>
                                         <input  onChange={e => setExpenseForm({...expenseForm,amount:e.target.value})}
                                                 value={expenseForm.amount}
                                                 type="number" className="form-control"/>
                                     </div>

                                     <div className="form-group">
                                         <label htmlFor="">Remarks</label>
                                         <input  onChange={e => setExpenseForm({...expenseForm,remarks:e.target.value})}
                                                 value={expenseForm.remarks}
                                                 type="text" className="form-control"/>
                                     </div>
                                 </div>
                                 <div className="modal-footer justify-content-between">
                                     <button type="button" className="btn btn-danger" onClick={handelExpenseModalClose}  >Close</button>
                                     <button type="submit" className="btn btn-success">Submit</button>

                                 </div>
                             </form>
                         </div>
                     </div>
                 </div>
             }

             {
                 modifyExpenseModal &&
                 <div className="modal show fade "  style={modelStyle} >
                     <div className="modal-dialog modal-lg">
                         <div className="modal-content"  >
                             <div className="modal-header">
                                 <h5 className="modal-title">Create Expense </h5>
                             </div>
                             <form  onSubmit={handleExpenseModifySubmit}>
                                 <div className="modal-body">
                                     <div className="form-group">
                                         <label htmlFor="">Select Expense Year</label>
                                         <select onChange={e => setModifyExpenseForm({...modifyExpenseForm,expense_year:e.target.value})}
                                                 value={modifyExpenseForm.expense_year}
                                                 className="form-control">
                                             {Array.from(Array(21).keys(),x => x+2022).map((item,index) => (
                                                 <option value={item}>{item}</option>
                                             ))}
                                         </select>
                                     </div>
                                     <div className="form-group">
                                         <label htmlFor="">Select Expense  Month</label>
                                         <select onChange={e => setModifyExpenseForm({...modifyExpenseForm,month:e.target.value})}
                                                 value={modifyExpenseForm.month}
                                                 className="form-control">
                                             <option value="">Select Month</option>
                                             <option value="1">January</option>
                                             <option value="2">February</option>
                                             <option value="3">March</option>
                                             <option value="4">April</option>
                                             <option value="5">May</option>
                                             <option value="6">June</option>
                                             <option value="7">July</option>
                                             <option value="8">August</option>
                                             <option value="9">September</option>
                                             <option value="10">October</option>
                                             <option value="11">November</option>
                                             <option value="12">December</option>
                                         </select>
                                     </div>

                                     <div className="form-group">
                                         <label htmlFor="">Select Budget</label>
                                         <select onChange={e => setModifyExpenseForm({...modifyExpenseForm,budget_head_id:e.target.value})}
                                                 value={modifyExpenseForm.budget_head_id}
                                                 className="form-control">
                                             <option value="">Select Budget</option>
                                             {
                                                 budgetHeadData?.length > 0 ? budgetHeadData.map((data,index) => {
                                                     return (
                                                         <>
                                                             <option key={index} value={data?.budget_head_id}>{data?.name}</option>
                                                             {data?.child.length > 0 && data?.child.map((child,i) => (
                                                                 <option key={i} value={child.budget_head_id}>{child.name}</option>
                                                             ))}
                                                         </>
                                                     )
                                                 }) : ''
                                             }
                                         </select>
                                     </div>
                                     <div className="form-group">
                                         <label htmlFor="">Expense Amount</label>
                                         <input  onChange={e => setModifyExpenseForm({...modifyExpenseForm,amount:e.target.value})}
                                                 value={modifyExpenseForm.amount}
                                                 type="number" className="form-control"/>
                                     </div>

                                     <div className="form-group">
                                         <label htmlFor="">Remarks</label>
                                         <input  onChange={e => setModifyExpenseForm({...modifyExpenseForm,remarks:e.target.value})}
                                                 value={modifyExpenseForm.remarks}
                                                 type="text" className="form-control"/>
                                     </div>
                                 </div>
                                 <div className="modal-footer justify-content-between">
                                     <button type="button" className="btn btn-danger" onClick={handelExpenseModifyModalClose}  >Close</button>
                                     <button type="submit" className="btn btn-success">Submit</button>

                                 </div>
                             </form>
                         </div>
                     </div>
                 </div>
             }
         </>
    );
};

export default VesselBudget;