import React, {useEffect, useState} from 'react';
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {useNavigate} from "react-router-dom";
import DocumentRenewalForm from "./DocumentRenewalForm";

const DocumentRenewalSearchModal = ({data,hide}) => {
    const [disable,setDisable] = useState(false)

    const [documentType,setDocumentType] = useState('all')
    const [documentList,setDocumentList] = useState([])

    const navigate = useNavigate();



    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    const hideModal = () => {
        hide(false)
    }

    const handleInputChange= (e) => {
        const value= e.target.value;

        if(e.target.checked){
            setDocumentList([...documentList,value])
        }else{
            setDocumentList(documentList.filter(item => item !== value))
        }


    }




    const handleSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.post('/company/notification/document-renewal/search',{
            document_type:documentType,
            document:documentList,
        })
            .then(({data}) => {
                hide(false)
                setDisable(false)
                navigate(`${COMPANYROUTE.DOCUMENT_PRINT_PREVIEW}`,{ state: { data:data ,title:'Document Renewal',documentType,documentList} })


            }).catch(err => {
            setDisable(false)
            const response = err.response;
            if(response && response.status === 422){
                if(response.data?.errors){

                }
            }
        })



    }

    useEffect(() => {

    }, [documentList]);


    return (
        <>
            <div className="modal show fade "  style={modelStyle} >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content"  >
                        <h6 className="text-center mt-3">Print Document</h6>
                        <DocumentRenewalForm
                            handleSubmit={handleSubmit}
                            setDocumentType={setDocumentType}
                            documentType={documentType}
                            data={data}
                            handleInputChange={handleInputChange}
                            documentList={documentList}
                            hideModal={hideModal}
                            disable={disable}
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

export default DocumentRenewalSearchModal;