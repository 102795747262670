import React from 'react';
import {Link} from "react-router-dom";
import * as UserRoute from "../../../constants/UserRoute";

const CertificationList = ({certificationData,route,onDelete}) => {
    return (
        <table className="table table-bordered ">
            <thead>
            <tr>
                <th>STCW Certification / Training Title</th>
                <th>Training Type</th>
                <th>STCW Grade</th>
                <th>Certificate Number</th>
                <th>Flag State</th>
                <th>Issued</th>
                <th>Expiry</th>
                <th>Issued at</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {certificationData && certificationData.length > 0 ? certificationData.map((data,index) => (
                <tr key={index}>
                    <td>{data?.certificate_title}</td>
                    <td>{data?.type}</td>
                    <td>{data?.grade}</td>
                    <td>{data?.number}</td>
                    <td>{data?.flag_state}</td>
                    <td>{data?.issued}</td>
                    <td>{data?.valid}</td>
                    <td>{data?.issued_at}</td>
                    <td>
                        <Link to={`${route}${data?.certificate_id}`} className="btn btn-info btn-sm mr-2"><i className="fas fa-pencil-alt"></i></Link>
                        <button onClick={e => onDelete(data)}  className="btn btn-danger btn-sm"><i className="fas fa-trash"></i></button>
                    </td>
                </tr>
            )) : "" }

            </tbody>
        </table>

    );
};

export default CertificationList;