import React, {useEffect, useReducer, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import PersonalInfo from "../../../Components/Seafarer/Profile/PersonalInfo";
import Education from "../../../Components/Seafarer/Profile/Education";
import Certification from "../../../Components/Seafarer/Profile/Certification";
import Experience from "../../../Components/Seafarer/Profile/Experience";
import Family from "../../../Components/Seafarer/Profile/Family";
import Others from "../../../Components/Seafarer/Profile/Others";
import axiosClient from "../../../axios-client";
import {ACTION_TYPES, INITIAL_STATE,profileReducer} from "../../../reducers/Company/ProfileReducer";
import LoadingBar from 'react-top-loading-bar';
import './styles/profile.css'
import {toast} from "react-toastify";
import DocumentVisa from "../../../Components/Seafarer/Profile/Document_Visa";
import usePrintWindow from "../../../hooks/usePrintWindow";

const Profile = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [progress, setProgress] = useState(10);
    const [state,dispatch] = useReducer(profileReducer,INITIAL_STATE);
    const tab = location.state?.type;



    const getProfileData = () => {
        setProgress(50)
        dispatch({type:ACTION_TYPES.GET_PROFILE_DATA});
        axiosClient.get('/seafarer/profile')
            .then(({data}) => {
                if (data.status === false){
                    toast.error("This User is not available")
                    setTimeout(() => {
                        navigate(-1)
                    },500)
                }else {
                    dispatch(
                        {type: ACTION_TYPES.GET_PROFILE_DATA_SUCCESS, payload: data}
                    );
                    setProgress(100)
                }

            }).catch((res) => {
            dispatch({type:ACTION_TYPES.GET_PROFILE_DATA_ERROR});

        })
    }

    const { handlePrint, loading } = usePrintWindow({
        url: '/seafarer/cv/print',
    });

    useEffect(() => {
        getProfileData()
    },[])
    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='User Profile '    />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="d-flex justify-content-end">
                                    <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                </div>
                                <div className="card-header p-2">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab === 'personalInfo' ? 'active' : ''}`} href="#personalInfo" data-toggle="tab">Personal Info</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab === 'documentVisa' ? 'active' : ''}`} href="#documentVisa" data-toggle="tab">Document & Visa</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab === 'education' ? 'active' : ''}`} href="#education" data-toggle="tab">Education</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab === 'certification' ? 'active' : ''}`} href="#certification" data-toggle="tab">Certification</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab === 'experience' ? 'active' : ''}`} href="#experience" data-toggle="tab">Experience</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab === 'family' ? 'active' : ''}`} href="#family" data-toggle="tab">Family</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab === 'others' ? 'active' : ''}`} href="#others" data-toggle="tab">Others</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <div className={`tab-pane ${tab === 'personalInfo' ? 'active' : ''}`} id="personalInfo">
                                            <PersonalInfo user={state.data?.user} empData={state.data?.empData} />
                                        </div>
                                        <div className={`tab-pane ${tab === 'documentVisa' ? 'active' : ''}`} id="documentVisa">
                                              <DocumentVisa passportData={state.data?.passportData}  visaData={state.data?.visaData} />
                                        </div>

                                        <div className={`tab-pane ${tab === 'education' ? 'active' : ''}`} id="education">
                                            <Education educationData={state.data?.educationData}/>
                                        </div>
                                        <div className={`tab-pane ${tab === 'certification' ? 'active' : ''}`} id="certification">
                                            <Certification certificationData={state.data?.certificationData} />
                                        </div>

                                        <div className={`tab-pane ${tab === 'experience' ? 'active' : ''}`} id="experience">
                                            <Experience experienceData={state.data?.experienceData} />
                                        </div>
                                        <div className={`tab-pane ${tab === 'family' ? 'active' : ''}`} id="family">
                                            <Family familyData={state.data?.familyData} />
                                        </div>
                                        <div className={`tab-pane ${tab === 'others' ? 'active' : ''}`} id="others">
                                            <Others medicalData={state.data?.medicalData}
                                                    surgeryData={state.data?.surgeryData}
                                                    medicalVaccinationData={state.data?.medicalVaccinationData}
                                                    othersTrainingData={state.data?.othersTrainingData}
                                                    othersData={state.data?.othersData}
                                                    bankData={state.data?.bankData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
    );
};

export default Profile;