import React from 'react';
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";

const EducationList = ({educationData,onDelete,route}) => {
    return (
        <table className="table table-bordered ">
            <thead>
            <tr>
                <th>Degree/ Certification</th>
                <th>Institute</th>
                <th>Country</th>
                <th>Passing Year</th>
                <th>Duration</th>
                <th>Result</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {educationData && educationData.length > 0 ? educationData.map((data,index) => (
                <tr key={index}>
                    <td>{data?.degree}</td>
                    <td>{data?.institute}</td>
                    <td>{data?.country}</td>
                    <td>{data?.passing_year}</td>
                    <td>{data?.duration}</td>
                    <td>{data?.result}</td>
                    <td>
                        <Link to={`${route}${data?.education_id}`} className="btn btn-info btn-sm mr-2"><i className="fas fa-pencil-alt"></i></Link>
                        <button onClick={e => onDelete(data)}  className="btn btn-danger btn-sm"><i className="fas fa-trash"></i></button>

                    </td>
                </tr>
            )) : "" }

            </tbody>
        </table>

    );
};

export default EducationList;