
const ExcelJS = require('exceljs');



   const UseExportHook = (fileExtension,data,title,file_name,columns) => {
       const workBook = new ExcelJS.Workbook();

        const sheet = workBook.addWorksheet(title);
        sheet.properties.defaultRowHeight = 20;

        sheet.getRow(1).font = {
            family: 2,
            size: 10,
            bold: true,
        };

       columns.forEach((column, columnIndex) => {
           sheet.getColumn(columnIndex + 1).key = column.key;
           sheet.getColumn(columnIndex + 1).header = column.header;
           sheet.getColumn(columnIndex + 1).width = column.width;
       });

       data?.data?.forEach((item, index) => {
           const row = {};
           columns.forEach((column) => {
               if (column.key === 'sl') {
                   row[column.key] = index + 1;
               } else {
                   row[column.key] = item[column.key];
               }
           });
           sheet.addRow(row);
       });

       if (data?.totalBudgetData) {
           sheet.addRow({
               sl: '',
               vessel_name: '',
               title: 'Total',
               budget: data?.totalBudgetData,
               expense: data?.totalExpense,
               variance: data?.totalVariance,
           });

       }



       const writeBufferPromise =
            fileExtension === 'xlsx' ? workBook.xlsx.writeBuffer() : workBook.csv.writeBuffer();

        writeBufferPromise.then((data) => {
            const blob = new Blob([data], {
                type:
                    fileExtension === 'xlsx'
                        ? 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet'
                        : 'application/vnd.openxmlformats-officedocument.spreadsheet.csv',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `${file_name}.${fileExtension}`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

export default UseExportHook;