export const INITIAL_STATE = {
    loading:false,
    error:false,
    data:{},
}

export const ACTION_TYPES = {
    GET_DATA: 'GET_DATA',
    GET_DATA_SUCCESS: 'GET_DATA_SUCCESS',
    GET_DATA_ERROR: 'GET_DATA_ERROR',
}

export const EarningDeductionParticularAmountReducer = (state,action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_DATA:
            return {
                loading:true,
                error:false,
            }
        case ACTION_TYPES.GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                data:action.payload
            }
        case  ACTION_TYPES.GET_DATA_ERROR:
            return {
                loading:false,
                error:true,
            }
        default:
            return state
    }

}