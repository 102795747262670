import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {RANK_GROUP} from "../../../constants/companyRoutes";

const RankPermission = () => {
    const [reportData,setReportData] = useState([])


    const getReportData = () => {
        axiosClient.get('/company/rank-permission')
            .then(({data}) => {
                 setReportData(data?.data)
            }).catch((err) => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete this rank permission?`)){
            return false;
        }
        axiosClient.delete(`/company/rank-permission/${data}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData()
            }).catch((err) => {
        })
     }


  return (
        <>
            <Breadcrumb title='Rank Permission'      />
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">

                        <div className="col-12">
                            <div className="float-right">
                                <Link to={COMPANYROUTE.RANK_PERMISSION_ADD} className='btn btn-sm btn-info'>Add New</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">


                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>SL</th>
                                            <th>Group Name</th>
                                            <th>User Name</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData?.length > 0 ? reportData.map((item,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{item?.group_name}</td>
                                                        <td>{item?.first_name+' ' +item?.last_name}</td>

                                                        <td>
                                                            <Link to={`${COMPANYROUTE.RANK_PERMISSION_EDIT}${item?.id}`} className='btn btn-sm btn-info mr-2'><i className="fa fa-pencil-alt"></i></Link>
                                                            <button className='btn btn-sm btn-danger' onClick={e => onDelete(item?.id)} ><i className="fa fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <tr><td colSpan={5} className='text-center'>No Data Found</td></tr>
                                        }


                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RankPermission;