import React from 'react';
import {Link} from "react-router-dom";
import * as COMPANYROUTE from "../../../constants/companyRoutes";

const ExperienceList = ({experienceData,route,onDelete}) => {
    return (
        <table className="table table-bordered ">
            <thead>
            <tr>
                <th>Vessel</th>
                <th>Manning Company</th>
                <th>Shipping Company</th>
                <th>Flag</th>
                <th>Type</th>
                <th>DWT</th>
                <th>Grt</th>
                <th>Engine type</th>
                <th>BHP</th>
                <th>Cargo Crane</th>
                <th>Rank</th>
                <th>Sign-On Date</th>
                <th>Sign-Off Date</th>
                <th>Duration</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {experienceData && experienceData.length > 0 ? experienceData.map((data,index) => (
                <tr key={index}>
                    <td>{data?.vessel_name}</td>
                    <td>{data?.manning_company}</td>
                    <td>{data?.shipping_company}</td>
                    <td>{data?.flag}</td>
                    <td>{data?.vessel_type}</td>
                    <td>{data?.dwt}</td>
                    <td>{data?.grt}</td>
                    <td>{data?.engine_type}</td>
                    <td>{data?.bhp}</td>
                    <td>{data?.cargo_crane}</td>
                    <td>{data?.rank}</td>
                    <td>{data?.from_date}</td>
                    <td>{data?.to_date}</td>
                    <td>{data?.duration}</td>
                    <td>
                        <Link to={`${route}${data?.experience_id}`} className="btn btn-info btn-sm mr-2"><i className="fas fa-pencil-alt"></i></Link>
                        <button onClick={e => onDelete(data)}  className="btn btn-danger btn-sm"><i className="fas fa-trash"></i></button>
                    </td>
                </tr>
            )) : "" }

            </tbody>
        </table>

    );
};

export default ExperienceList;