import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const Create = () => {
    const navigate = useNavigate();

    const [rankGroup,setRankGroup] = useState([]);
    const [userData,setUserData] = useState([]);

    const [disabled,setDisabled] = useState(false);

    const [user,setUser] = useState('');
    const [group,setGroup] = useState('');


    const getData = () => {
        axiosClient.get('/company/rank-permission/create')
            .then(({data}) => {
                setRankGroup(data?.rankGroup)
                setUserData(data?.usersData)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getData()
    },[])




    const handleSubmit = (e) => {
        e.preventDefault();
         setDisabled(true)
        axiosClient.post('/company/rank-permission',{
            user_id:user,
            group_id:group
        })
            .then(({data}) => {
                setDisabled(false)
                toast.success(data.message);
               navigate(`${COMPANYROUTE.RANK_PERMISSION}`);
            }).catch(err => {
            setDisabled(false)
            const response = err.response;
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }
    return (
        <div>
            <Breadcrumb title='Add New Rank Permission' prev={COMPANYROUTE.RANK_GROUP}    />
            <section className="content">
                <div className="container-fluid">

                            <div className="card card-primary">

                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <div className="col-12  ">
                                                    <div className="form-group">
                                                        <label htmlFor="grt">Group Name</label>
                                                        <select  className="form-control"
                                                                 value={group}
                                                                onChange={e => setGroup(e.target.value)}
                                                        >
                                                            <option value="">Select Group Name</option>
                                                            {
                                                               rankGroup?.length > 0 &&  rankGroup.map((item,index) => {
                                                                    return (
                                                                        <option key={index} value={item?.id}>{item?.group_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-12  ">
                                                    <div className="form-group">
                                                        <label htmlFor="grt">User</label>
                                                        <select  className="form-control"
                                                                    value={user}
                                                                    onChange={e => setUser(e.target.value)}
                                                        >
                                                            <option value="">Select User</option>
                                                            {
                                                                userData?.length > 0 &&  userData.map((item,index) => {
                                                                    return (
                                                                        <option key={index}
                                                                                value={item?.id}>{item?.first_name + ' ' + item?.last_name}</option>
                                                                    )
                                                                })
                                                         }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-success">{
                                            disabled ? 'Please Wait....' : 'Save'
                                        }</button>
                                    </div>
                                </form>

                            </div>

                </div>
            </section>
        </div>

    );
};

export default Create;