import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import React, { useState} from "react";
import axiosClient from "../../../axios-client";

import usePrintWindow from "../../../hooks/usePrintWindow";
import UseExportHook from "../../../hooks/UseExportHook";
import {useLocation} from "react-router-dom";
import DocumentPrintTable from "../../../Components/Company/documentRenewal/DocumentPrintTable";
const DocumentPrintPreviewThirtyDay = () => {

    //use navigate data get with location state
    const location = useLocation();

    const data = location.state?.data;
    const title = location.state?.title;
    const documentType = location.state?.documentType;
    const documentList = location.state?.documentList;

   const [excelLoading,setExcelLoading] = useState(false)
   const [csvLoading,setCsvLoading] = useState(false)




    const { handlePrint, loading } = usePrintWindow({
        url: '/company/notification/document-renewal-thirty-days/print',
        payload: {document_type:documentType, document:documentList },
    });

    const exportColumns = [
        { header: '#', key: 'sl', width: 10 },
        { header: 'Seafarer Name', key: 'name', width: 20 },
        { header: 'Email', key: 'email', width: 20 },
        { header: 'Mobile', key: 'mobile', width: 20 },
        { header: 'CDC No', key: 'cdc_no', width: 20 },
        { header: 'Vessel Name', key: 'vessel_name', width: 20 },
        { header: 'Document Type', key: 'info_type', width: 20 },
        { header: 'Expiry Date', key: 'expiry_date', width: 20 },

    ];
    const exportExcelFile = () => {
        setExcelLoading(true)
        axiosClient.post('/company/notification/document-renewal-thirty-days/export',{
            document_type:documentType, document:documentList
        })
            .then(({data}) => {
                setExcelLoading(false)
                UseExportHook( 'xlsx',data,'Document Renewal Thirty Days','document-renewal-thirty-days',exportColumns)
            }).catch(() => {
            setExcelLoading(false)

        })
    };

    const exportCSVFile = () => {
        setCsvLoading(true)
        axiosClient.post('/company/notification/document-renewal-thirty-days/export',{
            document_type:documentType, document:documentList
        })
            .then(({data}) => {
                setCsvLoading(false)
                UseExportHook( 'csv',data,'Document Renewal Thirty Days','document-renewal-thirty-days',exportColumns)
            }).catch(() => {
            setCsvLoading(false)

        })
    };



    return (
        <>
            <Breadcrumb title={title}    />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-12 col-md-9">

                                        </div>

                                        <div className="col-12 col-md-3">
                                            <div className="float-right">
                                                <button className='btn   btn-sm  btn-success mr-2 mb-1' onClick={handlePrint} disabled={loading} ><i className="fa fa-print"></i>  {loading ? 'Printing...' : 'Print'}</button>
                                                <button className='btn  btn-sm  btn-info mr-2 mb-1' onClick={exportCSVFile} disabled={csvLoading}><i className="fa fa-file-csv"></i>  {csvLoading ? 'CSV...' : 'CSV'}</button>
                                                <button className='btn  btn-sm  btn-success mr-2 mb-1' onClick={exportExcelFile} disabled={excelLoading}><i className="fa fa-file-excel"></i>  {excelLoading ? 'Exporting...' : 'Export'}</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body ">

                                    <div className="card-body  "  style={{paddingTop: '0px'}} >

                                      <DocumentPrintTable data={data}/>



                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DocumentPrintPreviewThirtyDay;