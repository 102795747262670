import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import CertificationForm from "../../../../Components/Profile/Certification/CertificationForm";

const EditCertification = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disable,setDisable] = useState(false)
    const [form,setForm] = useState({
        user_id:"",
        certificate_title:"",
        type:"",
        grade:"",
        number:"",
        flag_state:"",
        issued:"",
        valid:"",
        issued_at:"",
    });

    const getData = () => {
        axiosClient.get('/seafarer/certification/'+id+ '/edit')
            .then(({data}) => {
                setForm(data.data)
            }).catch(() => {
            toast.error("Something went wrong");

        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.put('/seafarer/certification/'+id, form)
            .then(({data}) => {
                toast.success(data.message);
                navigate(-1)
                setDisable(false)
            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    useEffect(() => {
        getData();
    },[])
    return (
        <>
            <Breadcrumb title='Edit Certification' backButton={true}       />
            <section className="content">
                <div className="container-fluid">
                    <div className="col-md-6 col-12">
                        <form onSubmit={onSubmit}>
                           <CertificationForm form={form} setForm={setForm} disable={disable} />
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditCertification;