import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import axiosClient from "../../../axios-client";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {EDIT_COMPANY_USER_PASSWORD} from "../../../constants/companyRoutes";

const CompanyUserList = () => {
    const [reportData,setReportData] = useState([])


    const getReportData = () => {
        axiosClient.get('/company/company-users')
            .then(({data}) => {
                setReportData(data?.data)
            }).catch((e) => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])

    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete this module?`)){
            return false;
        }
        axiosClient.delete(`/company/company-users/${data}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData()
            })
    }

    return (
        <>
            <Breadcrumb title='Company User List' addnew={true} route={COMPANYROUTE.CREATE_COMPANY_USER}  />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">


                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#</th>
                                            <th>Seafarer Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Role</th>
                                            <th>Start Working Hours </th>
                                            <th>End Working Hours </th>
                                            <th>Status </th>
                                            <th>Action </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            reportData.map((data,index) => {
                                                return (
                                                    <tr>
                                                        <td>{index+1}</td>
                                                        <td>{data?.first_name+ ' ' + data?.last_name}</td>
                                                        <td>{data.email}</td>
                                                        <td>{data.mobile}</td>
                                                        <td>
                                                            {
                                                                data?.role_id === 1 ? 'Super Admin' : data?.role_id === 2 ? 'General Admin' : data?.role_id === 4 ? 'Priviliged User' : ''
                                                            }
                                                        </td>
                                                        <td>{data.start_working_hours}</td>
                                                        <td>{data.end_working_hours}</td>
                                                        <td>
                                                            {
                                                                data.status == 1 ?  <span className="badge badge-success">Active</span> : <span className="badge badge-danger">InActive</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Link to={`${COMPANYROUTE.EDIT_COMPANY_USER}${data.id}`} className='btn btn-sm btn-info mr-2'><i className="fa fa-pencil-alt"></i></Link>
                                                            <Link to={`${COMPANYROUTE.EDIT_COMPANY_USER_PASSWORD}${data.id}`} title="Password Change" className='btn btn-sm btn-warning text-white mr-2'><i className="fa fa-lock"></i></Link>
                                                            <button onClick={() => onDelete(data.id)} className='btn btn-sm btn-danger'><i className="fa fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }



                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CompanyUserList;