import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {Link, useNavigate, useParams} from "react-router-dom";
import Logo from "../../Common/logo";

const CrewDocumentReviewModal = ({data,hide,vessel}) => {


    let modelStyle = {
        display:'block',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }
    let modalCustomSize = {
        maxWidth: '1500px',
    }

    const hideModal = () => {
      hide(false)
    }


    return (
        <div className="modal show fade "  style={modelStyle} >
            <div className="modal-dialog modal-xl" style={modalCustomSize}>
                <div className="modal-content" style={{overflowY:'scroll', height:'800px'}}>
                    <div className="modal-body">
                        <div className="card-body">
                            <div className="justify-content-center d-flex">
                                <div className="d-flex">
                                    <div className="logo">
                                        <Logo />
                                    </div>
                                    <div className="header text">
                                        <h5 className="text-center"> Brave Royal Ship Management Ltd. </h5>
                                        <h6 className="text-center">Crew Planners</h6>
                                        <h6 className="text-center"> Vessel: {vessel} </h6>
                                    </div>
                                </div>

                            </div>
                            <table id="data-table" className="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Name</th>
                                    <th>Nationality</th>
                                    <th>CDC No</th>
                                    <th>Joining Date</th>
                                    <th>Contact Period(+/-1 Month)</th>
                                    <th>Due Date</th>
                                    <th>Extended Date</th>
                                    <th>Days to Due Date</th>
                                    <th>Days to Extended Date</th>
                                </tr>
                                </thead>
                                <tbody>


                                {
                                    data?.length > 0  ? data?.map((item,index) => {
                                        return(
                                            <tr key={index+1}>
                                                <td>{item?.rank}</td>
                                                <td>{item?.name}</td>
                                                <td>{item?.nationality}</td>
                                                <td>{item?.cdc_no}</td>
                                                <td>{item?.joiningDate}</td>
                                                <td>{item?.contractMonth}</td>
                                                <td>{item?.dueDate}</td>
                                                <td>{item?.extendedDueDate}</td>
                                                <td>{item?.daysDueDate}</td>
                                                <td>{item?.daysExtendedDueDate}</td>

                                            </tr>
                                        )
                                    }) : ''
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-danger" onClick={hideModal}  >Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrewDocumentReviewModal;