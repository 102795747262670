import React from 'react';
import Header from "./Header";
import Sidebar from "./Sidebar";
import {Link, Navigate} from "react-router-dom";
import {useStateContext} from "../../../../context/ContextProvider";
import * as UserRoute from "../../../../constants/UserRoute";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";

const CompanyLayout = ({children}) => {
    const {token,userType} =  useStateContext()

    if(!token && userType !== 'company'){
        return <Navigate to="/login" />
    }
    if(token && userType === 'seafarer'){
        return <Navigate to={UserRoute.DASHBOARD} />
    }

    return (
        <>
          <Header />
          <Sidebar />
                <div className="content-wrapper">
                    {children}
                </div>
            <Link to={COMPANYROUTE.CHAT} className="open-button" ><i className="fa fa-comments"></i></Link>
           {/*<Footer />*/}
         </>
    );
};

export default CompanyLayout;