import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../../constants/companyRoutes";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient from "../../../../axios-client";
import {toast} from "react-toastify";
import MedicalInfoForm from "../../../../Components/Profile/OtherInfo/MedicalInfoForm";

const MedicalInfo = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [disable,setDisable] = useState(false)
    const [medicalInfo,setMedicalInfo] = useState({
        medical_id:0,
        status:'',
        vessel:'',
        signed_date:'',
        reason:'',
    });

    const [surgery,setSurgery] = useState({
        surgery_id:0,
        status:'',
        surgery_date:'',
        details:'',
    });



    const [medicalVaccination,setMedicalVaccination] = useState([
        {
             user_id:id,
             vaccination_id:"",
             title:'Yellow Fever',
             issue_date:'',
             expiry_date:'',
             issue_at:'',
        },
        {
             user_id:id,
             vaccination_id:"",
             title:'Cholera Vaccination',
             issue_date:'',
             expiry_date:'',
             issue_at:'',
        },
        {
            user_id:id,
            vaccination_id:"",
            title:'Covid Vaccination',
            issue_date:'',
            expiry_date:'',
            issue_at:'',
        },

    ]);



    const handleVaccinationChange = (e,index) => {
        const {name,value} = e.target;
        const list = [...medicalVaccination];
        list[index][name] = value;
        setMedicalVaccination(list);
    }

    const handleVaccinationIssueDateChange = (e,index) => {
        const list = [...medicalVaccination];
        list[index]['issue_date'] = e;
        setMedicalVaccination(list);
    }
    const handleVaccinationExpiryDateChange = (e,index) => {
        const list = [...medicalVaccination];
        list[index]['expiry_date'] = e;
        setMedicalVaccination(list);
    }


    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true)
        axiosClient.post('/company/save-medical-information', {
                user_id:id,
                medical_id:medicalInfo.medical_id,
                medical_status:medicalInfo.status,
                medical_vessel:medicalInfo.vessel,
                medical_signed_date:medicalInfo.signed_date,
                medical_reason:medicalInfo.reason,
                surgery_id:surgery.surgery_id,
                surgery_status:surgery.status,
                surgery_date:surgery.surgery_date,
                surgery_details:surgery.details,
                medical_vaccination:medicalVaccination,
         })
            .then(({data}) => {
                toast.success(data.message);
                navigate(`${COMPANYROUTE.USER_PROFILE}${id}`, {state: {type: 'others'}});
                setDisable(false)
            }).catch(err => {
            const response = err.response;
            setDisable(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }





    useEffect(() => {
        axiosClient.get('/company/medical-information/'+id)
            .then(({data}) => {
                if(data?.medical_vaccination.length > 0){
                    const vacinationState = data?.medical_vaccination?.map((item)=> {
                           return {
                                user_id:item?.user_id,
                                vaccination_id:item?.vaccination_id,
                                title:item?.title,
                                issue_date:item?.issue_date,
                                expiry_date:item?.expiry_date,
                                issue_at:item?.issue_at,
                            }
                    })
                    setMedicalVaccination(vacinationState)
                }
                if(data.medical_info !== null){
                    setMedicalInfo(data.medical_info);
                }
                if(data.surgery !== null) {
                    setSurgery(data.surgery);
                }
            }).catch(() => {
            toast.error("Something went wrong");

        })
    },[id])

    return (
        <>
            <Breadcrumb title='Medical Info' prev={`${COMPANYROUTE.USER_PROFILE}${id}`} state="others"   />
            <section className="content">
                <div className="container-fluid">
                    <div className=" col-12">
                        <form onSubmit={onSubmit}>
                            <MedicalInfoForm
                                setMedicalInfo={setMedicalInfo}
                                medicalInfo={medicalInfo}
                                setSurgery={setSurgery}
                                surgery={surgery}
                                medicalVaccination={medicalVaccination}
                                handleVaccinationChange={handleVaccinationChange}
                                handleVaccinationIssueDateChange={handleVaccinationIssueDateChange}
                                handleVaccinationExpiryDateChange={handleVaccinationExpiryDateChange}
                                disable={disable}
                            />
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MedicalInfo;