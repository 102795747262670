import React, {useEffect, useReducer, useState} from "react";
import Pagination from "react-js-pagination";
import LoadingBar from 'react-top-loading-bar';
import {Link, useParams} from "react-router-dom";

import axiosClient from "../../../axios-client";
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {toast} from "react-toastify";
const Index = () => {
    const [progress, setProgress] = useState(10);
    const [reportData, setReportData] = useState([]);


    const getReportData = () => {
        setProgress(50)
        axiosClient.get('/company/vessel-type')
            .then(({data}) => {
                console.log(data?.data)
                setProgress(100);
                setReportData(data?.data)
            }).catch(() => {

        })
    }



    useEffect(() => {
        getReportData()
    },[])


    const onDelete = (data) => {
        if(!window.confirm(`Are you sure you want to delete?`)){
            return false;
        }
        axiosClient.delete(`/company/vessel-type/${data?.vessel_type_id}`)
            .then((data) => {
                toast.success(data.data.message);
                getReportData();
            })
    }


    return (
        <>
            <LoadingBar
                color='#f39c12'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                shadow={true}
                height={4}
                transitionTime={400}
            />
            <Breadcrumb title='Vessel Type List'    addnew={true}  route={COMPANYROUTE.VESSEL_TYPE_ADD}     />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className=" table-responsive new-card-body"  style={{paddingTop: '0px'}} >

                                    <table id="" className="table table-bordered table-hover seafarer-list ">
                                        <thead >
                                        <tr>
                                            <th>#</th>
                                            <th>Vessel Category</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            reportData?.length > 0 ? reportData.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{item?.vessel_type_name}</td>
                                                        <td className="text-center">
                                                            <Link to={`${COMPANYROUTE.VESSEL_TYPE_EDIT}${item.vessel_type_id}`} className="btn btn-primary btn-sm">
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Link>
                                                            <button  onClick={e => onDelete(item)}   className="btn btn-danger btn-sm ml-2"  ><i className="fa fa-trash"></i></button>

                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            ) : <tr>
                                                <td colSpan={3} className="text-center">No Data Found</td>
                                            </tr>
                                        }
                                        </tbody>

                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
};

export default Index;