import React, {useEffect, useRef, useState} from 'react';
import '../../styles/chat.css'
import ConversationList from "../../../Components/Chat/ConversationList";
import {useNavigate, useNavigation, useParams} from "react-router-dom";
import axiosClient from "../../../axios-client";
import {toast} from "react-toastify";
import MessageForm from "../../../Components/Chat/MessageForm";
import MessageBox from "../../../Components/Chat/MessageBox";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import SearchUserList from "../../../Components/Chat/SearchUserList";
import {userSearch} from "../../../Components/Chat/userSearch";
import SearchInput from "../../../Components/Chat/SearchInput";
import UserInfo from "../../../Components/Chat/UserInfo";
import CompanyChatSidebar from "../../../Components/Chat/CompanyChatSidebar";
import io from "socket.io-client";


const socket = io(process.env.REACT_APP_SOCKET_URL);


const NewInbox = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const [conversation,setConversation] = useState([]);
    const [messages,setMessages] = useState([]);
    const [userData,setUserData] = useState("")
    const [disabled,setDisabled] = useState(false)
    const [receiverId,setReceiverId] = useState("");
    const [messageText,setMessageText] = useState("")
    const [fileSelected, setFileSelected] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [files,setFiles] = useState(null)
    const [image,setImage] = useState(null)
    const [searchExist,setSearchExist] = useState(false)



    const getData = () => {
        axiosClient.get(`/company/chat/new/${id}` )
            .then(({data}) => {
                setMessages(data?.messages)
                setUserData(data?.user_info)
                setReceiverId(data?.receiver_id)
                setConversation(data?.conversation_list)
                setSearchExist(false)

            }).catch((e) => {
            toast(e?.response?.data?.message)
        })
    }
    useEffect(() => {
        getData()
    },[id])

    const onSubmit = (e) => {
        e.preventDefault();

        axiosClient.post('/company/chat/send-message', {
            message_text: messageText,
            user_id: receiverId,
            message_file:files,
            message_image:image
        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                const modifyMessage = {...data?.data,message_type:'receiver',slug:data?.conversation?.slug}
                socket.emit('joinRoom', data?.conversation?.user_id);
                socket.emit('sendMessageToServer',{
                    message:modifyMessage,
                    conversation:data?.receiver_conversation_list
                })

                setMessageText("")
                fileInputRef.current.value = null;
                imageInputRef.current.value = null;
                setFileSelected(false)
                setImageSelected(false)
                setDisabled(true)
                setFiles(null)
                setImage(null)
                navigate(`${COMPANYROUTE.INBOX}${data?.conversation?.slug}`);

            }).catch(err => {
            const response = err.response;
            setDisabled(false)
            if (response && response.status === 422) {
                if (response.data?.errors) {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        toast.error(errors[key][0]);
                    }
                }
            }
        })
    }

    useEffect(() => {
    }, [messages,conversation]);


    useEffect(() => {

    },[searchExist])



    return (
        <>
            <div id="container">
                <CompanyChatSidebar
                    searchExist={searchExist}
                    setSearchExist={setSearchExist}
                    conversation={conversation}
                />
                <main>
                    <header>
                        <UserInfo userData={userData} />
                    </header>
                    <MessageBox   messages={messages} />

                    <footer>
                        <MessageForm
                            onSubmit={onSubmit}
                            messageText={messageText}
                            setMessageText={setMessageText}
                            setFiles={setFiles}
                            setImage={setImage}
                            fileInputRef={fileInputRef}
                            imageInputRef={imageInputRef}
                            fileSelected={fileSelected}
                            setFileSelected={setFileSelected}
                            imageSelected={imageSelected}
                            setImageSelected={setImageSelected}
                            disabled={disabled}
                            setDisabled={setDisabled}
                        />
                    </footer>
                </main>
            </div>
        </>
    );
};

export default NewInbox;