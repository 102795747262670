import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../../Components/Company/Common/Breadcrumb";
import * as COMPANYROUTE from "../../../constants/companyRoutes";
import {Link, useParams} from "react-router-dom";
import axiosClient from "../../../axios-client";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import CrewRemoveModal from "../../../Components/Company/CrewList/CrewRemoveModal";
import PortArrivalModal from "../../../Components/Company/CrewList/PortArrivalModal";
const VesselCrewList = () => {
    const {id} = useParams();
    const [reportData,setReportData] = useState([]);
    const [vesselName,setVesselName] = useState('')
    const [modal,showModal] = useState(false);
    const [modalData,setModalData] = useState({});
    const [vesselData,setVesselData] = useState({});

    const [crewModal,setCrewModal] = useState(false);




    const getReportData = (dateFromData = null) => {
        axiosClient.get('/company/vessel-crew/' + id)
            .then(({data}) => {
                setVesselName(data?.vesselData?.vessel_name)
                setVesselData(data?.vesselData)
                setReportData(data?.data)
            }).catch(() => {
        })
    }

    useEffect(() => {
        getReportData()
    },[])






    const handleModal = (data) => {
        showModal(true)
        setModalData(data)
    }

    const handleCrewModal = () => {
        setCrewModal(true)
    }


    return (
        <>
            <Breadcrumb title={`${vesselName} Crews  List `}   />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-end ">
                                        <button onClick={() => handleCrewModal()} className='btn btn-primary mr-2'>Print Crews List </button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <table id="data-table" className="table table-bordered table-hover">
                                        <thead className='bg-info'>
                                        <tr>
                                            <th>#SL No.</th>
                                            <th>Crew</th>
                                            <th>Rank</th>
                                            <th>Vessel name</th>
                                            <th>Joining date</th>
                                            <th>Contract Expiration Date</th>
                                            <th>Nationality</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>


                                        {
                                            reportData?.length > 0  ? reportData?.map((item,index) => {
                                                return(
                                                    <tr key={index+1}>
                                                        <td>{index+1}</td>
                                                        <td>{item?.sur_name}</td>
                                                        <td>{item?.rank}</td>
                                                        <td>{item?.vessel_name}</td>
                                                        <td>{item?.joining_date}</td>
                                                        <td>{item?.expiry_date}</td>
                                                        <td>{item?.country_name}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-danger btn-sm mr-2"
                                                                    onClick={() => handleModal(item)}>
                                                                 Remove
                                                            </button>
                                                            <Link className="btn btn-info  btn-sm" to={`${COMPANYROUTE.USER_PROFILE}${item?.emp_id}`}>Profile</Link>

                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''
                                        }



                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {
                modal === true && <CrewRemoveModal data={modalData} hide={() => showModal(false)}   />
            }
            {
                crewModal === true && <PortArrivalModal data={reportData} vesselData={vesselData} hide={() => setCrewModal(false)}   />
            }
        </>
    );
};

export default VesselCrewList;